import { supabase } from './../../supabaseClient'
// Importa useStore

const fetchUserPosts = async user => {
  try {
    if (!user) throw error
    const { data, error } = await supabase.from('posts').select('*').eq('user_id', user.id)
    //   console.log(data);
    if (error) throw error

    // setposts(data);
    return data
  } catch (error) {
    //setError(error.message);
  } finally {
    //setLoading(false);
  }
}

export { fetchUserPosts }
