import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookMessengerIcon from '@mui/icons-material/Facebook'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import SyncIcon from '@mui/icons-material/Sync'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

const SyncSocial = ({ bot }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  // Ejemplo de estados, puedes ajustar según tus datos
  const socialStatuses = {
    telegram: 'syncing', // 'syncing', 'error', 'correct'
    instagram: 'error',
    messenger: 'error',
    whatsapp: 'correct',
  }

  const getStatusIcon = status => {
    switch (status) {
      case 'syncing':
        return <SyncIcon sx={{ color: 'orange' }} />
      case 'correct':
        return <CheckCircleIcon sx={{ color: 'green' }} />
      case 'error':
        return <ErrorIcon sx={{ color: 'red' }} />
      default:
        return null
    }
  }

  return (
    <Box>
      
      {false && (
        <Button onClick={isCollapsed => setIsCollapsed(!isCollapsed)} sx={{ mb: 1, zIndex: 3 }}>
          {isCollapsed ? 'Ver más' : 'Ver menos'}
        </Button>
      )}

      {!isCollapsed && (
        <Box>
          {false && <> <Box display="flex" alignItems="center" mt={1}>
            <TelegramIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" sx={{ marginRight: 1 }}>
              Telegram
            </Typography>
            {getStatusIcon(socialStatuses.telegram)}
          </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <FacebookMessengerIcon sx={{ marginRight: 1 }} />
              <Typography variant="body2" sx={{ marginRight: 1 }}>
                Messenger
              </Typography>
              {getStatusIcon(socialStatuses.messenger)}
            </Box></>
          }
          <Box display="flex" alignItems="center" mt={1}>
            <InstagramIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" sx={{ marginRight: 1 }}>
              Instagram
            </Typography>
            {getStatusIcon(socialStatuses.instagram)}
          </Box>
          <Box display="flex" alignItems="center">
            <WhatsAppIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" sx={{ marginRight: 1 }}>
              WhatsApp
            </Typography>
            {getStatusIcon(socialStatuses.whatsapp)}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default SyncSocial
