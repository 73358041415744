import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as turf from '@turf/turf'
import BasicCard from '../Rooms/BasicCard'
import config from '../../../config'
import {
  Popup,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Marker,
  Map,
} from 'react-map-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import ControlPanel from './control-panel'
import Pin from './pin'

import { useStore } from './../../../StoreContext'
import './Map.css'

const MapComponent = props => {
  const {
    isEditing = false,
    pointers,
    pointer_selected,
    setPoint,
    triggerGeolocate,
    onLocationUpdate,
    isFixed = false,
    isHidden = false,
  } = props
  const { userProfile, user } = useStore()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const geolocateControlRef = useRef(null)
  const mapRef = useRef(null)
  const mapContainerRef = useRef(null)
  const [pointing, setPointer] = useState(null)
  const [marker, setMarker] = useState({
    latitude: 40,
    longitude: -100,
  })
  const [events, logEvents] = useState({})
  const [pos, setPos] = useState(
    JSON.parse(localStorage.getItem('lastPosition')) || {
      lat: 0,
      lng: -36,
      zoom: 0.9,
    }
  )
  const [zoom, setZoom] = useState(1)
  const [features, setFeatures] = useState({})
  const [area, setArea] = useState(0)

  const [spinEnabled, setSpinEnabled] = useState(false)
  const [spinInterval, setSpinInterval] = useState(1000)

  const draw = useRef(
    new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    })
  )

  //console.log(user)
  useEffect(() => {
    if (triggerGeolocate && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          if (onLocationUpdate) {
            onLocationUpdate({ latitude, longitude })
          }
        },
        error => {
          console.error('Error getting location', error)
        }
      )
    }
  }, [triggerGeolocate, onLocationUpdate])

  const handleSpin = useCallback(() => {
    if (mapRef.current && zoom < 2 && spinEnabled) {
      const map = mapRef.current.getMap()
      const center = map.getCenter()
      map.setCenter({ lon: center.lng - 0.05, lat: center.lat }) // Ajustar la velocidad de rotación si es necesario
    }
  }, [zoom, spinEnabled])

  useEffect(() => {
    if (spinEnabled) {
      const interval = setInterval(handleSpin, 200)
      // setZoom(1);

      setSpinInterval(interval)
    } else if (spinInterval) {
      clearInterval(spinInterval)
      setSpinInterval(null)
    }

    return () => {
      if (spinInterval) clearInterval(spinInterval)
    }
  }, [zoom, spinEnabled, handleSpin, mapRef.current])

  const updateArea = useCallback(() => {
    const data = draw.current.getAll()
    if (data.features.length > 0) {
      const polygon = data.features[0]
      const area = turf.area(polygon)
      setArea(area)
    } else {
      setArea(0)
    }
  }, [])

  useEffect(() => {
    if (triggerGeolocate && geolocateControlRef.current) {
      geolocateControlRef.current.trigger()
    }
  }, [triggerGeolocate])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (geolocateControlRef.current && geolocateControlRef.current.trigger) {
          geolocateControlRef.current.trigger()
        }

        const {
          coords: { latitude: lat, longitude: lng },
        } = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })

        const pos = {
          lat,
          lng,
        }
        localStorage.setItem('lastPosition', JSON.stringify(pos))

        setPos({ ...pos, zoom: 17 })
      } catch (error) {
        console.error('Error fetching user location:', error)
        setPos({ lat: 0, lng: 0, zoom: 1 })
      }
    }

    fetchData()
  }, [user])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setPitch(0)
      if (pointer_selected && pointer_selected.longitude && pointer_selected.latitude) {
        mapRef.current.flyTo({
          zoom: user ? 16 : 1,
          center: [pointer_selected.longitude, pointer_selected.latitude],
          essential: true,
        })
      } else if (!user) {
        // Si no hay usuario, ajustar el zoom al mínimo y habilitar la rotación
        mapRef.current.flyTo({
          zoom: 0.9,
          center: [0, 0], // Centrar en el ecuador
          essential: true,
        })
        setSpinEnabled(true) // Activar la rotación del planeta
      }
    }
  }, [pointer_selected, user, mapRef.current])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mapRef.current) {
        mapRef.current.resize()
      }
    })

    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current)
    }

    return () => {
      if (mapContainerRef.current) {
        resizeObserver.unobserve(mapContainerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isEditing && mapRef.current) {
      const map = mapRef.current
      map.addControl(draw.current)
      map.on('draw.create', updateArea)
      map.on('draw.delete', updateArea)
      map.on('draw.update', updateArea)

      return () => {
        if (map) {
          map.off('draw.create', updateArea)
          map.off('draw.delete', updateArea)
          map.off('draw.update', updateArea)
          map.removeControl(draw.current)
        }
      }
    }
  }, [updateArea, mapRef.current])

  const pins = useCallback(
    pointers => {
      return pointers?.map((pointer, index) => (
        <Marker
          key={`marker-${pointer._id || index}`}
          longitude={pointer.longitude}
          latitude={pointer.latitude}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation()
            setPointer(pointer)
            if (setPoint) setPoint(pointer)
          }}
        >
          <Pin type={pointer.type} />
        </Marker>
      ))
    },
    [setPoint]
  )

  const onUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {
        ...currFeatures,
      }
      for (const f of e.features) {
        newFeatures[f.id] = f
      }
      return newFeatures
    })
  }, [])

  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {
        ...currFeatures,
      }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }
      return newFeatures
    })
  }, [])

  const onMarkerDragStart = useCallback(event => {
    logEvents(_events => ({
      ..._events,
      onDragStart: event.lngLat,
    }))
  }, [])

  const onMarkerDrag = useCallback(event => {
    logEvents(_events => ({
      ..._events,
      onDrag: event.lngLat,
    }))
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    })
  }, [])

  const onMarkerDragEnd = useCallback(
    event => {
      logEvents(_events => ({
        ..._events,
        onDragEnd: event.lngLat,
      }))
      if (setPoint) {
        setPoint({
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
        })
      }
    },
    [setPoint]
  )

  if (!pointers && (!pointer_selected?.longitude || pointer_selected?.longitude)) return null

  return (
    <div
      className="mapBox"
      style={{
        opacity: isHidden ? '0.5' : '1',
        position: isFixed ? 'fixed' : '',
        top: isFixed ? '0px' : '',
      }}
      ref={mapContainerRef}
    >
      <Map
        projection="globe"
        ref={mapRef}
        onZoom={e => {
          setZoom(e.viewState.zoom)
        }}
        onPitch={e => {}}
        showAccuracyCircle={true}
        mapboxAccessToken={config.automatic.mapBox_apiKey}
        initialViewState={{
          latitude: pos.lat,
          longitude: pos.lng,
          zoom: pos.zoom || 17,
          bearing: 0,
          pitch: 30,
        }}
        mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
      >
        {isEditing ? (
          <>
            <Marker
              longitude={marker.longitude}
              latitude={marker.latitude}
              anchor="bottom"
              draggable
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
            >
              <Pin size={20} type={pointer_selected?.type ? pointer_selected.type : ''} />
            </Marker>
            {false && <ControlPanel polygons={Object.values(features)} events={events} />}
            <div className="area-info">
              {area > 0 && (
                <p>
                  {t('Area')}: {turf.convertArea(area, 'meters', 'kilometers').toFixed(2)} km²
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            {pointing && (
              <Popup
                anchor="top"
                longitude={Number(pointing.longitude)}
                latitude={Number(pointing.latitude)}
                onClose={() => setPointer(null)}
                maxWidth="350px"
              >
                <BasicCard
                  backgroundColor="pointer"
                  pointer={pointing}
                  showImage={true}
                  onExplore={() => navigate('/' + pointing.type + '/' + pointing.id)}
                  onEdit={() => navigate('/' + pointing.type + '/' + pointing.id + '/edit')}
                />
              </Popup>
            )}
            {pins(pointers)}
          </>
        )}
        {!isHidden && (
          <>
            <GeolocateControl
              ref={geolocateControlRef}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
              showAccuracyCircle={true}
              showUserHeading={true}
              showUserLocation={true}
              onGeolocate={pos => {
                setPos({ ...pos.coords, zoom: user ? 16 : 1 })
              }}
              style={{ top: 10, left: 10 }}
            />
            <FullscreenControl position="bottom-left" />
            <NavigationControl position="bottom-right" />
            <ScaleControl />
          </>
        )}
      </Map>
    </div>
  )
}

export default MapComponent
