import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { supabase } from './../../../supabaseClient'
import { 
  Paper, 
  Grid, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  CircularProgress, 
  Tooltip, 
  Slider, 
  Avatar 
} from '@mui/material'
import { 
  LocalizationProvider, 
  DatePicker 
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { startOfDay, endOfDay, format } from 'date-fns'
import InfoIcon from '@mui/icons-material/Info'
import { useTheme, alpha } from '@mui/material/styles'

const BotMetricsChart = ({ currentBot }) => {
  const [messagesFiltered, setMessagesFiltered] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [appliedFilter, setAppliedFilter] = useState(null)

  // Estado para los rangos de cada gráfica
  const [chartRanges, setChartRanges] = useState({
    'Total Duration': [0, 0],
    'Load Duration': [0, 0],
    'Prompt Eval Count': [0, 0],
    'Prompt Eval Duration': [0, 0],
    'Eval Count': [0, 0],
    'Eval Duration': [0, 0],
  })

  // Descripciones para cada métrica
  const metricDescriptions = {
    'Total Duration': 'Tiempo total que el modelo tomó para procesar y responder al mensaje.',
    'Load Duration': 'Tiempo dedicado a cargar recursos y dependencias necesarios para generar la respuesta.',
    'Prompt Eval Count': 'Número de evaluaciones realizadas sobre el prompt proporcionado.',
    'Prompt Eval Duration': 'Tiempo total dedicado a evaluar el prompt.',
    'Eval Count': 'Cantidad de evaluaciones internas realizadas para refinar la respuesta.',
    'Eval Duration': 'Tiempo invertido en cada evaluación interna para optimizar la respuesta final.',
  }

  // Estados para perfiles de usuario
  const [userProfiles, setUserProfiles] = useState({})
  const [profilesLoading, setProfilesLoading] = useState(true)
  const [profilesError, setProfilesError] = useState(null)

  const theme = useTheme()

  // Función para obtener los datos filtrados
  const fetchData = async () => {
    if (!currentBot || !currentBot.id) return

    setLoading(true)
    setProfilesLoading(true)
    setProfilesError(null)

    try {
      let query = supabase
        .from('bots_messages')
        .select('*')
        .eq('bot_id', currentBot.id)
        .eq('role', 'assistant') // Agregar filtro por role
        .order('created_at', { ascending: true })

      if (appliedFilter && appliedFilter.start) {
        query = query.gte('created_at', appliedFilter.start.toISOString())
      }

      if (appliedFilter && appliedFilter.end) {
        query = query.lte('created_at', appliedFilter.end.toISOString())
      }

      const { data: messagesData, error: messagesError } = await query

      if (messagesError) {
        console.error('Error fetching bot messages', messagesError)
      } else {
        setMessagesFiltered(messagesData)

        // Extraer y deduplicar números de teléfono desde receiver_id
        const phoneNumbers = messagesData.map(message => {
          const number = message.receiver_id.split('@')[0] // Cambiado de 'from' a 'receiver_id'
          return `+${number}`
        })
        const uniqueNumbers = [...new Set(phoneNumbers)]

        // Fetch user profiles
        await fetchUserProfiles(uniqueNumbers)
      }
    } catch (error) {
      console.error('Error loading bot and messages', error)
    } finally {
      setLoading(false)
    }
  }

  // Función para obtener los perfiles de usuario
  const fetchUserProfiles = async (phoneNumbers) => {
    try {
      console.log(phoneNumbers)
      if (phoneNumbers.length === 0) {
        setUserProfiles({})
        setProfilesLoading(false)
        return
      }

      // Fetch user profiles matching the phone numbers
      const { data: profilesData, error: profilesError } = await supabase
        .from('user_profiles')
        .select('phone_number, profile_picture_url, alias')
        .in('phone_number', phoneNumbers.map(num => num.replace('', ''))) // Remover '+' para coincidir con la base de datos

      if (profilesError) {
        throw profilesError
      }

      // Create a mapping from phone number to profile
      const profilesMap = {}
      profilesData.forEach(profile => {
        profilesMap[`${profile.phone_number}`] = profile
      })

      setUserProfiles(profilesMap)
      setProfilesLoading(false)
    } catch (err) {
      console.error('Error fetching user profiles:', err)
      setProfilesError('Hubo un error al cargar los perfiles de usuarios.')
      setProfilesLoading(false)
    }
  }

  // useEffect que se ejecuta cuando cambia el bot actual o se aplica un filtro
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, appliedFilter])

  // Definir las categorías para el eje X basadas en created_at
  const categories = messagesFiltered.map(item => format(new Date(item.created_at), 'yyyy-MM-dd HH:mm'))

  // useEffect para inicializar los rangos de las gráficas basados en los datos filtrados
  useEffect(() => {
    if (messagesFiltered.length === 0) return

    // Obtener los timestamps de los mensajes filtrados
    const timestamps = messagesFiltered.map(item => new Date(item.created_at).getTime())
    const minTimestamp = Math.min(...timestamps)
    const maxTimestamp = Math.max(...timestamps)

    // Inicializar los rangos de cada gráfica si aún no están establecidos
    setChartRanges(prevRanges => {
      const newRanges = { ...prevRanges }
      Object.keys(newRanges).forEach(metric => {
        if (newRanges[metric][0] === 0 && newRanges[metric][1] === 0) {
          newRanges[metric] = [minTimestamp, maxTimestamp]
        }
      })
      return newRanges
    })
  }, [messagesFiltered])

  // Opciones generales para los gráficos
  const commonOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        background: '#333', // Fondo oscuro para el toolbar
        foreColor: '#fff', // Color de los iconos
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Fecha y Hora',
      },
      labels: {
        rotate: -45,
        hideOverlappingLabels: true,
        trim: true,
        maxHeight: 80,
      },
    },
    yaxis: {
      title: {
        text: 'Valores',
      },
    },
    tooltip: {
      theme: 'dark',
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const message = messagesFiltered[dataPointIndex]
        const formattedDate = format(new Date(message.created_at), 'yyyy-MM-dd HH:mm')
        const phoneNumber = `+${message.receiver_id.split('@')[0]}` // Cambiado de 'from' a 'receiver_id'
        console.log(userProfiles)
        const userProfile = userProfiles[phoneNumber]
        console.log(userProfile)
        return `
          <div style="background: #000; color: #fff; padding: 10px; border-radius: 5px; display: flex; align-items: center;">
            ${
              userProfile && userProfile.profile_picture_url
                ? `<img src="${userProfile.profile_picture_url}" alt="${userProfile.alias || phoneNumber}" style="width:40px; height:40px; border-radius:50%; margin-right:10px;" />`
                : `<div style="width:40px; height:40px; border-radius:50%; background-color:#555; display:flex; align-items:center; justify-content:center; margin-right:10px; color:#fff;">
                     ${phoneNumber.replace(/\D/g, '').slice(-2)}
                   </div>`
            }
            <div>
              <strong>${userProfile.alias ? userProfile.alias : phoneNumber}</strong><br/>
              <strong>Fecha:</strong> ${formattedDate}<br/>
              <strong>${w.globals.seriesNames[seriesIndex]}:</strong> ${series[seriesIndex][dataPointIndex]}
            </div>
          </div>
        `
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
  }

  // Función para generar series de datos dinámicamente
  const generateSeries = (name, data) => [
    {
      name,
      data: data.map(item => (item !== undefined ? item : 0)),
    },
  ]

  // Función para aplicar el filtro global
  const handleApplyFilter = () => {
    setAppliedFilter({
      start: startDate ? startOfDay(startDate) : null,
      end: endDate ? endOfDay(endDate) : null,
    })

    // Reiniciar los rangos de las gráficas al aplicar un nuevo filtro global
    setChartRanges(prevRanges => {
      const newRanges = { ...prevRanges }
      Object.keys(newRanges).forEach(metric => {
        newRanges[metric] = [0, 0] // Reiniciar para que se vuelvan a inicializar
      })
      return newRanges
    })
  }

  // Handler para actualizar el rango de una gráfica específica
  const handleChartRangeChange = (metric, newValue) => {
    setChartRanges(prevRanges => ({
      ...prevRanges,
      [metric]: newValue,
    }))
  }

  // Función para filtrar los mensajes según el rango seleccionado de una gráfica
  const getFilteredData = (metric) => {
    const [rangeStart, rangeEnd] = chartRanges[metric]
    if (rangeStart === 0 && rangeEnd === 0) return messagesFiltered

    return messagesFiltered.filter(item => {
      const timestamp = new Date(item.created_at).getTime()
      return timestamp >= rangeStart && timestamp <= rangeEnd
    })
  }

  // Helper para convertir timestamp a formato legible
  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), 'yyyy-MM-dd HH:mm')
  }

  // Función para formatear fechas (reutilizada)
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <Grid container spacing={2}>
      {/* Filtro por Fechas Global */}
      <Grid item xs={12}>
        <Paper sx={{ padding: 2, margin: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} md={3}>
                <DatePicker
                  label="Fecha de Inicio"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue)
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <DatePicker
                  label="Fecha de Fin"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue)
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApplyFilter}
                  fullWidth
                  disabled={!startDate && !endDate}
                >
                  Aplicar Filtro
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Paper>
      </Grid>

      {/* Mostrar Cargando o Error */}
      {loading || profilesLoading ? (
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : profilesError ? (
        <Grid item xs={12}>
          <Typography color="error" align="center">
            {profilesError}
          </Typography>
        </Grid>
      ) : (
        <>
          {/* Gráficos con Range Sliders */}
          {[
            'Total Duration',
            'Load Duration',
            'Prompt Eval Count',
            'Prompt Eval Duration',
            'Eval Count',
            'Eval Duration',
          ].map((metric) => (
            <Grid item xs={12} md={6} key={metric}>
              <Paper sx={{ padding: 2 }}>
                <Box>
                  {/* Título con Info Tooltip */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h6">{metric}</Typography>
                    <Tooltip title={metricDescriptions[metric]} placement="right">
                      <InfoIcon fontSize="small" sx={{ marginLeft: 0.5, cursor: 'pointer' }} />
                    </Tooltip>
                  </Box>

                  {/* Gráfica */}
                  <Chart
                    options={{
                      ...commonOptions,
                      yaxis: { 
                        ...commonOptions.yaxis, 
                        title: { text: metric.includes('Duration') ? `${metric} (ms)` : metric } 
                      },
                    }}
                    series={generateSeries(metric, getFilteredData(metric).map(item => item[metric.toLowerCase().replace(/ /g, '_')]))}
                    type="bar"
                    height={300}
                  />

                  {/* Range Slider */}
                  <Box mt={2} p="35px">
                    <Typography variant="body2" gutterBottom>
                      Refinar Rango de Fechas:
                    </Typography>
                    <Slider
                      value={chartRanges[metric]}
                      min={Math.min(...messagesFiltered.map(item => new Date(item.created_at).getTime()))}
                      max={Math.max(...messagesFiltered.map(item => new Date(item.created_at).getTime()))}
                      onChange={(event, newValue) => handleChartRangeChange(metric, newValue)}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => formatTimestamp(value)}
                      marks={[
                        { value: Math.min(...messagesFiltered.map(item => new Date(item.created_at).getTime())), label: formatTimestamp(Math.min(...messagesFiltered.map(item => new Date(item.created_at).getTime()))) },
                        { value: Math.max(...messagesFiltered.map(item => new Date(item.created_at).getTime())), label: formatTimestamp(Math.max(...messagesFiltered.map(item => new Date(item.created_at).getTime()))) },
                      ]}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}

          {/* Mostrar mensaje si no hay datos */}
          {messagesFiltered.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No se encontraron mensajes para el rango de fechas seleccionado.
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default BotMetricsChart
