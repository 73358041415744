import { createTheme } from '@mui/material/styles'

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#grey',
    },
    secondary: {
      main: '#ff6600',
    },
    background: {
      default: 'transparent',
    },
    header: {
      default: '#000',
    },
    text: {
      primary: '#FFF', // Color de texto general para asegurar legibilidad
      secondary: '#2e2e2e', // Texto en contraste para usar sobre fondos oscuros o coloridos
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#000!important', // Establece el fondo del body como negro
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '3px',
          margin: '2px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        outlined: {
          color: 'gray!important',
        }, // Color del label cuando se minimiza
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input': {
            color: '#FFF', // Color del label cuando se minimiza
          },
          '& textarea': {
            color: '#FFF', // Color del label cuando se minimiza
          },
          '& .MuiInputLabel-outlined': {
            color: 'gray', // Color del label cuando se minimiza
          },
          '& .Mui-disabled': {
            '-webkit-text-fill-color': 'grey!important', // Color del label cuando se minimiza
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&.input': {
            color: '#FFF',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#061627cf',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '4px',
          backgroundColor: '#0e3861', // Fondo oscuro
          color: '#fff', // Texto claro
          '&.Mui-disabled': {
            'background-color': '#8080806e!important', // Color del label cuando se minimiza
          },
          '&:hover': {
            backgroundColor: '#555',
          }, // Fondo más oscuro al pasar el ratón por encima
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Color del texto blanco
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#061627', // Fondo oscuro
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
          border: 'none!important', // Eliminar el borde inferior del AppBar
          zIndex: 2,
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px',
          backgroundColor: '#061627', // Fondo oscuro
          color: '#FFF!important',
          '&:last-child': {
            paddingBottom: '0px',
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Color del ícono blanco
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          color: '#FFFFFF!important', // Color del texto blanco
        },
        root: {
          color: '#FFFFFF!important', // Color del texto blanco
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Color del ícono blanco
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#061627e6',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '3px',
          '&.MuiDrawer-paper': {
            backgroundColor: '#061627e6 !important',
            color: '#FFF !important',
            borderRadius: '8px',
            border: '0.1px solid #ffffff6e',
            height: 'auto',
          },
          backgroundColor: '#061627e6',
          color: '#FFFFFF',
          borderRadius: '8px',
          border: '0.1px solid #ffffff54',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: '#0e3861', // Dark border
          borderRadius: '4px', // Rounded corners
        },
        input: {
          '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
          }, // Adjust this value as needed
        },
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }, // Adjust border color if needed
        '&.Mui-focused': {
          notchedOutline: {
            borderColor: 'rgba(0, 0, 0, 0.6)',
          },
        }, // Adjust border color on focus if needed
      },
    },
  },
  typography: {
    fontFamily: '"Nunito", sans-serif', // Puedes elegir una tipografía más elegante
    fontSize: 12,
    h1: {
      fontWeight: 300,
      fontSize: '6rem',
      letterSpacing: '-0.01562em',
    },
    // ... otras personalizaciones de tipografía
  },
  // Personalizaciones adicionales, como sombras, pueden ir aquí
  shadows: Array(25).fill('0px 0px 0px 0px rgba(51, 51, 51, .3)'), // Sombra personalizada
  // Más configuraciones...
})
