import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { StoreProvider } from './StoreContext' // Asegúrate de que esta sea la ruta correcta
import Layout from './components/layout/Layout'
import RouteController from './RouteController' // Importa RouteController si lo has creado
//require('dotenv').config();

import { ThemeProvider } from './ThemeProvider'

import './i18n'

import 'react-flow-renderer/dist/style.css'
import 'react-flow-renderer/dist/theme-default.css'
import './App.scss'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <StoreProvider>
      <ThemeProvider>
        <Router>
          <Layout>
            <RouteController />
          </Layout>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>
)

reportWebVitals()
