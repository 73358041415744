import { supabase } from './../../supabaseClient'
// Importa useStore

const loadChatsBots = async botId => {
  //console.log("loading chats");

  try {
    if (!botId) throw error

    //console.log(botId)
    const { data, error } = await supabase.from('bots_chats').select('*').eq('bot_id', botId)
    if (error) {
      console.error('Error fetching bot messages', error)
    } else {
      //setUserBots(data);
      //   console.log(data);
      return data
    }
  } catch (error) {
    console.error('Error loading bot and messages', error)
  }
}

const fetchUserBots = async user => {
  try {
    if (!user) throw error
    const { data, error } = await supabase.from('bots').select('*').eq('user_id', user.id)
    //console.log(data);
    if (error) throw error

    // setStores(data);
    return data
  } catch (error) {
    //setError(error.message);
  } finally {
    //setLoading(false);
  }
}

export { loadChatsBots, fetchUserBots }
