import React, { useEffect, useState } from 'react'
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'

const CallEdgeFunction = () => {
  const { session } = useStore()
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const callEdgeFunction = async () => {
      if (!session) {
        setError('User is not authenticated')
        return
      }

      try {
        const { data, error } = await supabase.functions.invoke('hello', {
          body: JSON.stringify({ foo: 'bar' }),
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        })

        if (error) {
          throw error
        }

        setResponse(data)
      } catch (err) {
        setError(`Error calling edge function: ${err.message}`)
      }
    }

    callEdgeFunction()
  }, [session])

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!response) {
    return <div>Loading...</div>
  }

  return null

  return (
    <div>
      <h1>Response from Edge Function</h1>
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </div>
  )
}

export default CallEdgeFunction
