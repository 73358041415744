import React from 'react'
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import { Grid } from '@mui/material'
import { supabase } from './../../../supabaseClient'
import { useStore } from './../../../StoreContext'

// This value is from the props in the UI
const style = {
  layout: 'vertical',
}

function generateRandomSKU(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

const randomSKU = generateRandomSKU(8)

async function createOrder(orderData, session) {
  console.log(orderData)

  try {
    // Use Supabase to create the order
    const { data, error } = await supabase.functions.invoke('paypal', {
      body: JSON.stringify({
        cart: [
          {
            sku: randomSKU,
            quantity: orderData.quantity,
          },
        ],
      }),
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    })

    if (error) {
      throw new Error(`Error creating order: ${error.message}`)
    }

    console.log('Order created:', data)
    return data.orderId // Assuming 'orderId' is the ID of the order in the response
  } catch (error) {
    console.error('Error creating PayPal order:', error)
    return null
  }
}

async function onApprove(data) {
  try {
    const response = await fetch(
      'https://react-paypal-js-storybook.fly.dev/api/paypal/capture-order',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderID: data.orderID,
        }),
      }
    )

    const orderData = await response.json()
    console.log('Order captured:', orderData)
  } catch (error) {
    console.error('Error capturing PayPal payment:', error)
  }
}

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner, order, session }) => {
  const [{ isPending }] = usePayPalScriptReducer()

  return (
    <>
      {isPending && showSpinner && <div>Loading...</div>}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={() => createOrder(order, session)}
        onApprove={data => onApprove(data, order)}
        onError={error => {
          console.error('PayPal error:', error)
        }}
      />
    </>
  )
}

export default function Paypal({ order }) {
  const { session } = useStore()

  return !order ? null : (
    <Grid container>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          minHeight: '200px',
        }}
      >
        <PayPalScriptProvider
          options={{
            clientId:
              'Ab3tN0B_EJzGKWuhccu_cfg40C6RKiMc_SbxYdMNk1WTPWr8h2eaKGVddvlEB7asBzuBE-e1mv0rgfFy',
            components: 'buttons',
            currency: 'USD',
          }}
        >
          <ButtonWrapper showSpinner={false} order={order} session={session} />
        </PayPalScriptProvider>
      </div>
    </Grid>
  )
}
