import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  Grid,
  List,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  CircularProgress,
  Alert,
  Paper,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'
import axios from 'axios'

const Domains = () => {
  const [domains, setDomains] = useState([])
  const [filteredDomains, setFilteredDomains] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    provider: '',
    expires_on: '',
    user_id: '',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedDomain, setSelectedDomain] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { userProfile } = useStore()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editingDomain, setEditingDomain] = useState(null)

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const confirmDeleteDomain = async () => {
    try {
      const response = await axios.delete('https://api.example.com/domains/' + selectedDomain.id)
      enqueueSnackbar('Domain deleted successfully', {
        variant: 'success',
      })
      loadDomains()
      closeDeleteModal()
    } catch (error) {
      enqueueSnackbar('Error deleting domain: ' + error.message, {
        variant: 'error',
      })
    }
    setSelectedDomain(null)
  }

  useEffect(() => {
    loadDomains()
  }, [userProfile])

  useEffect(() => {
    applyFilters()
  }, [searchTerm, domains])

  const loadDomains = async () => {
    if (!userProfile) return

    try {
      const { data, error } = await supabase
        .from('domains')
        .select('*')
        .eq('user_id', userProfile?.user_id)

      if (error) throw error

      setDomains(data)
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error loading domains: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const applyFilters = () => {
    let filtered = domains
    if (searchTerm) {
      filtered = filtered.filter(
        domain =>
          domain?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          domain?.provider?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    setFilteredDomains(filtered)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    loadDomains()
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e, user) => {
    e.preventDefault()

    try {
      const { data, error } = await supabase.from('domains').insert([
        {
          name: formData.name,
          provider: formData.provider,
          expires_on: formData.expires_on,
          user_id: user.user_id,
        },
      ])

      if (error) throw error

      enqueueSnackbar('Domain added successfully', {
        variant: 'success',
      })
      handleClose()
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error adding domain: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const handleDeleteDomain = domainId => {
    setSelectedDomain(domainId)
    openDeleteModal()
  }

  const handleSelectDomain = domain => {
    setSelectedDomain(domain)
  }

  const handleEditDomain = async () => {
    try {
      const { data, error } = await supabase
        .from('domains')
        .update({
          name: formData.name,
          provider: formData.provider,
          expires_on: formData.expires_on,
        })
        .eq('id', editingDomain.id)

      if (error) throw error

      enqueueSnackbar('Domain updated successfully', {
        variant: 'success',
      })
      handleClose()
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error updating domain: ' + error.message, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Modal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2" gutterBottom>
            Confirmar eliminación
          </Typography>
          <Typography id="delete-modal-description" gutterBottom>
            ¿Estás seguro de que quieres eliminar este dominio? Esta acción no se puede revertir.
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={closeDeleteModal}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" onClick={confirmDeleteDomain}>
              Eliminar
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'white',
        }}
      >
        Dominios
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Proveedor"
            variant="outlined"
            value={formData.provider}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleOpen} fullWidth>
            Agregar nuevo dominio
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Agregar nuevo dominio
          </Typography>
          <form onSubmit={e => handleSubmit(e, userProfile)}>
            <Box mb={2}>
              <TextField
                fullWidth
                name="name"
                label="Nombre del dominio"
                value={formData.name}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                name="provider"
                label="Proveedor"
                value={formData.provider}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                name="expires_on"
                label="Fecha de vencimiento"
                type="date"
                value={formData.expires_on}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Agregar
            </Button>
          </form>
        </Paper>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <List>
            {filteredDomains.map((domain, index) => (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  mb: 2,
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box
                      onClick={() => handleSelectDomain(domain)}
                      sx={{
                        cursor: 'pointer',
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        {domain.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`Proveedor: ${domain.provider}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`Vencimiento: ${new Date(domain.expires_on).toLocaleDateString()}`}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip title="Editar dominio">
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => setEditingDomain(domain)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar dominio">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteDomain(domain.id)}
                          sx={{
                            color: 'error.main',
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </List>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {selectedDomain && (
            <Box textAlign="center">
              <Typography variant="h5" component="h2" gutterBottom>
                {selectedDomain.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={2}>
                {`Proveedor: ${selectedDomain.provider}`}{' '}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Vencimiento: {new Date(selectedDomain.expires_on).toLocaleDateString()}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>

      {editingDomain && (
        <Modal
          open={Boolean(editingDomain)}
          onClose={() => setEditingDomain(null)}
          aria-labelledby="edit-domain-modal-title"
          aria-describedby="edit-domain-modal-description"
        >
          <Paper sx={modalStyle}>
            <Typography id="edit-domain-modal-title" variant="h6" component="h2" gutterBottom>
              Editar dominio
            </Typography>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleEditDomain(editingDomain.id)
                setEditingDomain(null)
              }}
            >
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="name"
                  label="Nombre del dominio"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="provider"
                  label="Proveedor"
                  value={formData.provider}
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  name="expires_on"
                  label="Fecha de vencimiento"
                  type="date"
                  value={formData.expires_on}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Guardar
              </Button>
            </form>
          </Paper>
        </Modal>
      )}
    </Box>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  p: 4,
}

export default Domains
