import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { useTranslation } from 'react-i18next'
import './BasicCard.css' // Asegúrate de importar tu hoja de estilos
import { useStore } from './../../../StoreContext'
import RoomRender from './../RoomRender'

export default function BasicCard(props) {
  const { pointer, onExplore, onEdit } = props
  const { t } = useTranslation()
  const [showEnterPreview, setShowEnterPreview] = useState(false)
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { user } = useStore()

  const onTouchHandler = () => {
    setShowEnterPreview(true)
    setTimeout(() => {
      setShowEnterPreview(false)
    }, 2500)
  }

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: pointer.name,
          text: pointer.title,
          url: window.location.href + pointer.type + "/"+ pointer.id,
        })
        console.log('Content shared successfully')
      } catch (error) {
        console.error('Error sharing content:', error)
      }
    } else {
      console.error('Web Share API not supported in this browser')
    }
  }

  if (!pointer || !user) return null

  return (
    <Card
      className={'basicCard'}
      variant=""
      sx={{
        minWidth: '320px',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Typography
          variant="h5"
          fontSize="md"
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          {pointer.name}
        </Typography>
        <Typography variant="body2">{pointer.title}</Typography>
      </Box>
      <IconButton
        aria-label="Bookmark pointer"
        variant="plain"
        color="neutral"
        size="small"
        sx={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
        }}
      >
        <BookmarkAddOutlinedIcon />
      </IconButton>

      <Box
        style={{
          height: '26px',
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <VisibilityOutlinedIcon fontSize="small" />
        <Typography fontSize={10} variant="body3" sx={{ ml: 0.5 }}>
          {pointer.visitors}
        </Typography>
        <IconButton
          aria-label="Share pointer"
          size="small"
          sx={{ ml: 1 }}
          onClick={handleShareClick}
        >
          <ShareOutlinedIcon />
        </IconButton>
        <Button
          variant="soft"
          size="small"
          color="grey"
          className={'buttonCard'}
          aria-label="Explore pointer"
          sx={{
            ml: 'auto',
            fontWeight: 600,
            minHeight: '20px',
            padding: '2px 6px',
            fontSize: '10px',
            lineHeight: '1',
          }}
          onClick={() => setShowMoreInfo(!showMoreInfo)}
        >
          {showMoreInfo ? t('rooms.hide') : t('rooms.showMore')}
        </Button>
      </Box>

      {showMoreInfo && (
        <Box
          sx={{
            marginTop: '10px',
          }}
        >
          {pointer.type === 'rooms' && <RoomRender room_id={pointer.id} />}

          {pointer.type === 'post' && (
            <Box>
              <Typography variant="body1">{pointer.description}</Typography>

              {pointer.file_id && (
                <Box mt={2}>
                  <Typography variant="body2">
                    {t('posts.mediaFile')}: {pointer.file_id}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          {false && onEdit && (
            <Box
              style={{
                height: '26px',
              }}
              sx={{
                display: 'flex',
              }}
            >
              <Button
                variant="soft"
                size="small"
                color="transparent"
                aria-label="Edit pointer"
                sx={{
                  ml: 'auto',
                  fontWeight: 600,
                }}
                onClick={() => onEdit()}
              >
                {t('rooms.edit')}
              </Button>
            </Box>
          )}
          {false && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{
                marginTop: '0px',
              }}
              onClick={() => onExplore()}
            >
              {t('rooms.explore')}
            </Button>
          )}
        </Box>
      )}
    </Card>
  )
}
