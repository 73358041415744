import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Avatar,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { supabase } from './../../supabaseClient'
import { fetchUserPosts } from './../../StoreContext/posts/index.js'
import { useStore } from './../../StoreContext'

const Post = ({ userLocation, onClose }) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)
  const [titleContent, setTitleContent] = useState('')
  const [textContent, setTextContent] = useState('')
  const [mediaFile, setMediaFile] = useState(null)
  const [maskPosition, setMaskPosition] = useState(true)
  const [latitude, setLatitude] = useState(userLocation.latitude)
  const [longitude, setLongitude] = useState(userLocation.longitude)
  const { user, setUserPosts } = useStore()

  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
  }

  // Actualizar latitud y longitud cuando cambie userLocation
  useEffect(() => {
    if (userLocation) {
      setLatitude(userLocation.latitude)
      setLongitude(userLocation.longitude)
    }
  }, [userLocation]) // Se ejecuta cuando userLocation cambia

  const toggleFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const handleTitleChange = event => {
    setTitleContent(event.target.value)
  }

  const handleTextChange = event => {
    if (event.target.value.length <= 500) {
      setTextContent(event.target.value)
    }
  }

  const handleFileChange = event => {
    setMediaFile(event.target.files[0])
  }

  const handleMaskPositionChange = event => {
    setMaskPosition(event.target.checked)
  }

  const generateRandomCoordinates = (latitude, longitude, radius) => {
    const getRandomOffset = () => (Math.random() - 0.5) * radius * 2
    return {
      latitude: latitude + getRandomOffset() / 111320,
      longitude: longitude + getRandomOffset() / (111320 * Math.cos(latitude * (Math.PI / 180))),
    }
  }

  const handleSubmit = async () => {
    const { latitude: postLat, longitude: postLong } = maskPosition
      ? generateRandomCoordinates(latitude, longitude, 500)
      : { latitude, longitude }

    const postsData = [
      {
        file_id: mediaFile ? mediaFile.name : null,
        title: titleContent,
        description: textContent,
        longitude: postLong,
        latitude: postLat,
        masked: maskPosition,
      },
    ]

    const { data, error } = await supabase.from('posts').insert(postsData)

    if (error) {
      console.error('Error al insertar datos:', error)
    } else {
      const posts = await fetchUserPosts(user)
      setUserPosts(posts)
      onClose() // Cerrar el componente después de publicar
    }
  }

  const handleClickOutside = (event) => {
    if (event.target.id === 'modal-container') {
      onClose()
    }
  }

  return (
    <Box
      id="modal-container"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Fondo semi-transparente
        zIndex: 1300, // Para asegurarse de que esté por encima de otros elementos
      }}
      onClick={handleClickOutside}
    >
      <Card sx={{ maxWidth: 400, width: '100%', borderRadius: 3, overflow: 'hidden', margin: 2 }}>
        <CardContent sx={{ padding: 1 }}>
          <Typography variant="body" sx={{ marginBottom: 2, textAlign: 'left' }}>
            {t('Comparte algo...')}
          </Typography>
          <TextField
            label={t('Título')}
            variant="outlined"
            fullWidth
            value={titleContent}
            onChange={handleTitleChange}
            margin="normal"
          />
          <TextField
            label={t('Escribe aquí')}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={textContent}
            onChange={handleTextChange}
            margin="normal"
            inputProps={{ maxLength: 500 }}
            helperText={`${textContent.length}/500`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={maskPosition}
                onChange={handleMaskPositionChange}
                name="maskPosition"
                color="primary"
              />
            }
            label={t('Enmascarar posición')}
            sx={{ marginTop: 2 }}
          />
          <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-between' }}>
            <input
              accept="image/*,video/*"
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="media-file"
            />
            <label htmlFor="media-file">
              <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                {t('Foto')}
              </Button>
            </label>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {t('Publicar')}
            </Button>
          </Box>
          {mediaFile && (
            <Box mt={3} display="flex" alignItems="center">
              <Avatar
                variant="rounded"
                src={URL.createObjectURL(mediaFile)}
                alt="Vista previa de media"
                sx={{ width: 56, height: 56, marginRight: 2 }}
              />
              <Typography variant="body2">{mediaFile.name}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export default Post
