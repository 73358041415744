import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { supabase } from './../../../supabaseClient'
import { useStore } from './../../../StoreContext'

// Este valor puede venir de los props de la UI
const style = {
  layout: 'vertical',
}

async function createPreference(orderData, session) {
  console.log(orderData)

  try {
    // Usar Supabase para crear la preferencia
    const { data, error } = await supabase.functions.invoke('mercadopago', {

      body: JSON.stringify({

        items: [
          {
            id: "MLB2907679857",
            title: "Smartphone",
            description: "Smartphone de última generación con pantalla OLED",
            picture_url: "https://example.com/images/smartphone.jpg",
            category_id: "electronics",
            quantity: 1,
            unit_price: orderData.amount,
            type: "physical",
            event_date: "2024-12-31T23:59:59.000Z",
            warranty: true
          }
        ],
      }),
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    })
    
    console.log(data);

    if (error) {
      console.log(error)
      throw new Error(`Error creando la preferencia: ${error.message}`)
    }

    console.log('Preferencia creada:', data)
    return data.preferenceId // Asume que 'preferenceId' es el ID de la preferencia en la respuesta
  } catch (error) {
    console.error('Error creando la preferencia de Mercado Pago:', error)
    return null
  }
}

function onApprove(response) {
  console.log('Payment approved:', response)
  // Aquí puedes enviar los datos a Supabase o realizar otras acciones necesarias
}

function onError(error) {
  console.log(error)
  console.error('Error en el pago de Mercado Pago:', error)
  // Muestra un mensaje de error o redirige a una página de error
}

export default function MercadoPagoComponent({ order }) {
  const { session } = useStore()
  const [preferenceId, setPreferenceId] = useState(null)

  useEffect(() => {
    // Inicializar Mercado Pago con tu Public Key
    initMercadoPago('APP_USR-9f209342-e109-4513-af91-cf93fafe748d')

    // Crear la preferencia y guardar el ID en el estado
    createPreference(order, session).then((id) => {
      setPreferenceId(id)
    })
  }, [order, session])

  return !order || !preferenceId ? null : (
    <Grid container>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          minHeight: '200px',
        }}
      >
        <Wallet
          initialization={{
            preferenceId: preferenceId, // Utiliza el ID de la preferencia almacenado en el estado
          }}
          onSubmit={response => onApprove(response)} // Maneja la aprobación del pago
          onError={error => onError(error)} // Maneja los errores
        />
      </div>
    </Grid>
  )
}
