import React, { useState, useEffect } from 'react'
import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
  Paper,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'
import FileUploader from './FileUploader'
import FileRenderer from './FileRenderer'

const Files = () => {
  const [files, setFiles] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterCategory, setFilterCategory] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { userProfile } = useStore()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [newFileName, setNewFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  // Crear un caché de URLs
  const urlCache = new Map()

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    p: 4,
  }

  const loadFiles = async () => {
    if (!userProfile) return

    try {
      const folderPath = `${userProfile.user_id}`
      const { data, error } = await supabase.storage.from('files').list(folderPath, {
        limit: 100,
      })

      if (error) throw error

      setFiles(data)
      setFilteredFiles(data)
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error loading files: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadFiles()
  }, [userProfile])

  useEffect(() => {
    applyFilters()
  }, [searchTerm, filterCategory, files])

  const applyFilters = () => {
    let filtered = files

    if (searchTerm) {
      filtered = filtered.filter(
        file =>
          file?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          file?.description?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    if (filterCategory) {
      filtered = filtered.filter(file => file.metadata.mimetype === filterCategory)
    }

    setFilteredFiles(filtered)
  }

  const handleDownloadFile = async file => {
    // Verificar si la URL está en caché
    if (urlCache.has(file.name)) {
      const cachedUrl = urlCache.get(file.name)
      console.log('Using cached URL:', cachedUrl)
      downloadFile(cachedUrl, file.name)
      return
    }

    // Generar la URL si no está en caché
    try {
      const filePath = `${userProfile.user_id}/${file.name}`
      const { data, error } = await supabase.storage.from('files').download(filePath)

      if (error) {
        enqueueSnackbar('Error downloading file: ' + error.message, {
          variant: 'error',
        })
        return
      }

      const url = URL.createObjectURL(data)
      // Cachear la URL por 60 segundos
      urlCache.set(file.name, url)
      setTimeout(() => {
        urlCache.delete(file.name)
        console.log('Cache expired for:', file.name)
      }, 60000)

      downloadFile(url, file.name)
    } catch (error) {
      enqueueSnackbar('Error downloading file: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const downloadFile = (url, fileName) => {
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    URL.revokeObjectURL(url)
  }

  // Definición de la función handleDeleteFile
  const handleDeleteFile = file => {
    setSelectedFile(file)
    setDeleteModalOpen(true)
  }

  // Definición de la función confirmDeleteFile
  const confirmDeleteFile = async () => {
    try {
      const filePath = `${userProfile.user_id}/${selectedFile.name}`
      const { error } = await supabase.storage.from('files').remove([filePath])

      if (error) throw error

      enqueueSnackbar('File deleted successfully', {
        variant: 'success',
      })

      loadFiles()
      setDeleteModalOpen(false)
    } catch (error) {
      enqueueSnackbar('Error deleting file: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const handleRenameFile = file => {
    setSelectedFile(file)
    setNewFileName(file.name)
    setRenameModalOpen(true)
  }

  const confirmRenameFile = async () => {
    try {
      const oldFilePath = `${userProfile.user_id}/${selectedFile.name}`
      const newFilePath = `${userProfile.user_id}/${newFileName}`

      // Copiar el archivo con el nuevo nombre
      const { data, error } = await supabase.storage.from('files').move(oldFilePath, newFilePath)

      if (error) throw error

      enqueueSnackbar('File renamed successfully', {
        variant: 'success',
      })

      loadFiles()
      setRenameModalOpen(false)
    } catch (error) {
      enqueueSnackbar('Error renaming file: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const handleSelectFile = file => {
    setSelectedFile(file)
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  return (
    <Box p={4} sx={{ minHeight: '100vh' }}>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2" gutterBottom>
            Confirmar eliminación
          </Typography>
          <Typography id="delete-modal-description" gutterBottom>
            ¿Estás seguro de que quieres eliminar este archivo? Esta acción no se puede revertir.
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={() => setDeleteModalOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" onClick={confirmDeleteFile}>
              Eliminar
            </Button>
          </Box>
        </Paper>
      </Modal>

      <Modal
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        aria-labelledby="rename-modal-title"
        aria-describedby="rename-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="rename-modal-title" variant="h6" component="h2" gutterBottom>
            Renombrar archivo
          </Typography>
          <TextField
            label="Nuevo nombre del archivo"
            variant="outlined"
            fullWidth
            value={newFileName}
            onChange={e => setNewFileName(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" onClick={() => setRenameModalOpen(false)}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={confirmRenameFile}>
              Renombrar
            </Button>
          </Box>
        </Paper>
      </Modal>

      <FileUploader
        open={open}
        handleClose={() => setOpen(false)}
        userProfile={userProfile}
        handleUpload={loadFiles}
      />

      <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
        Archivos
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={filterCategory}
              onChange={e => setFilterCategory(e.target.value)}
              label="Tipo"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="image/jpeg">Imágenes (JPEG)</MenuItem>
              <MenuItem value="image/png">Imágenes (PNG)</MenuItem>
              <MenuItem value="image/gif">Imágenes (GIF)</MenuItem>
              <MenuItem value="video/mp4">Videos (MP4)</MenuItem>
              <MenuItem value="video/webm">Videos (WebM)</MenuItem>
              <MenuItem value="video/ogg">Videos (OGG)</MenuItem>
              <MenuItem value="audio/mp3">Audios (MP3)</MenuItem>
              <MenuItem value="audio/wav">Audios (WAV)</MenuItem>
              <MenuItem value="audio/ogg">Audios (OGG)</MenuItem>
              <MenuItem value="model/gltf+json">Modelos 3D (GLTF/GLB)</MenuItem>

              <MenuItem value="application/geo+json">GeoJSON</MenuItem>
              <MenuItem value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                Excel (XLSX)
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button onClick={() => setOpen(true)} variant="contained" color="primary" fullWidth>
            Subir archivo
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1}>
            {filteredFiles.map((file, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  variant="outlined"
                  onClick={() => handleSelectFile(file)}
                  sx={{ cursor: 'pointer' }}
                  
                >
                  <CardContent style={{padding: 0}}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <FileIcon sx={{ mr: 1 }} />
                        <Typography
                          variant="h6"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                      <Box style={{ minWidth: "120px" }}>
                        <Tooltip title="Renombrar">
                          <IconButton onClick={() => handleRenameFile(file)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                          <IconButton onClick={() => handleDeleteFile(file)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Descargar">
                          <IconButton onClick={() => handleDownloadFile(file)}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        {false &&
                          (file.ispublic ? (
                            <Tooltip title="Público">
                              <VisibilityIcon />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Privado">
                              <VisibilityOffIcon />
                            </Tooltip>
                          ))}
                      </Box>
                    </Box>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      {file.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {selectedFile && (
          <Grid item xs={12} sm={8} height={"50vh"}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h5" component="h2">
                Vista previa del archivo: {selectedFile.name}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setSelectedFile(null)}
                sx={{ ml: 2 }}
              >
                X
              </Button>
            </Box>
            <FileRenderer file={selectedFile} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Files
