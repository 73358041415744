import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import GetStatus from './GetStatus'
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Box,
  Grid,
  Modal,
  IconButton,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import QRCodeReact from 'qrcode.react'
import { CircularProgress } from '@mui/material'
import { parseISO, format } from 'date-fns'
import { useStore } from './../../StoreContext'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import AddIcon from '@mui/icons-material/Add'
import SyncSocial from './features/SyncSocial'

const BotsList = () => {
  const [bots, setBots] = useState(null)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [currentBot, setCurrentBot] = useState(null)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })
  const [editBotName, setEditBotName] = useState('')
  const [editingBotId, setEditingBotId] = useState(null)
  const [newName, setNewName] = useState('')
  const { user, userProfile } = useStore()
  const [selectedBot, setSelectedBot] = useState(null)
  const [newBotName, setNewBotName] = useState('')
  const [newBotProfilePicture, setNewBotProfilePicture] = useState('')

  const [currentQRCode, setCurrentQRCode] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredBots, setFilteredBots] = useState(null)
  const [isQRModalOpen, setIsQRModalOpen] = useState(false)
  const [qrMessage, setQRMessage] = useState('')

  const openQRModal = message => {
    setIsQRModalOpen(true)
    setQRMessage(message)
  }

  const closeQRModal = () => {
    setIsQRModalOpen(false)
    setQRMessage('')
  }

  const handleOpenQRModal = qrCode => {
    setCurrentQRCode(qrCode)
    setIsQRModalOpen(true)
  }

  const startEditing = bot => {
    setEditingBotId(bot.id)
    setNewName(bot.name)
  }

  const cancelEditing = () => {
    setEditingBotId(null)
    setNewName('')
  }

  const handleClickOpenEditDialog = bot => {
    setCurrentBot(bot)
    setEditBotName(bot.name)
    setOpenEditDialog(true)
  }

  const handleSaveEditBot = async () => {
    const updatedData = {
      name: editBotName,
    }
    await handleUpdateBot(currentBot.id, updatedData)
    handleCloseEditDialog()
  }

  useEffect(() => {
    if (isQRModalOpen && selectedBot) {
      const updatedBot = bots?.find(bot => bot.id === selectedBot.id)
      if (updatedBot && updatedBot.status !== 'qr') {
        setIsQRModalOpen(false)
        setQRMessage('')
        setSelectedBot(null)
      }
    }
  }, [bots, selectedBot, isQRModalOpen])

  const formatDate = dateString => {
    if (!dateString) return 'Fecha no disponible'
    try {
      const date = parseISO(dateString)
      return format(date, 'PPP')
    } catch (error) {
      console.error('Error formatting date', error)
      return 'Fecha inválida'
    }
  }

  const fetchBots = async () => {
    if (!user) {
      setBots([])
      return
    }
    const { data: bots, error } = await supabase
      .from('bots')
      .select('*')
      .eq('user_id', user.id)
      .is('deleted', null)

    if (error) {
      console.error('Error fetching bots', error)
    } else {
      setBots(bots || [])
    }
  }

  const handleAddBot = async botData => {
    if (!user) {
      console.error('No user logged in')
      return
    }
    const botWithUserId = {
      ...botData,
      user_id: user.id,
    }
    const { data, error } = await supabase.from('bots').insert([botWithUserId])

    if (error) {
      console.error('Error adding bot', error)
      setSnackbar({
        open: true,
        message: 'Error al añadir el bot.',
        severity: 'error',
      })
    } else {
      setBots([...bots, ...[botWithUserId]])
      setSnackbar({
        open: true,
        message: 'Bot añadido exitosamente.',
        severity: 'success',
      })
    }
  }

  const handleUpdateBot = async (botId, updatedData) => {
    const { error } = await supabase.from('bots').update(updatedData).match({
      id: botId,
    })

    if (error) {
      console.error('Error updating bot', error)
    } else {
      setBots(
        bots?.map(bot =>
          bot.id === botId
            ? {
                ...bot,
                ...updatedData,
              }
            : bot
        )
      )
    }
  }

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true)
    //navigate to create
  }

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false)
    setNewBotName('')
    setNewBotProfilePicture('')
  }

  const handleSaveNewBot = () => {
    // Verificamos si el nombre del nuevo bot tiene al menos 3 letras
    if (userProfile.balance < 5000) {
      setSnackbar({
        open: true,
        message: 'No tienes saldo suficiente para crear un bot',
        severity: 'error',
      })
      return
    }

    if (newBotName.length < 3) {
      setSnackbar({
        open: true,
        message: 'El nombre del bot debe tener al menos tres letras.',
        severity: 'error',
      })
      return // Evitamos guardar el bot si el nombre no cumple con los requisitos
    }

    const newBot = {
      name: newBotName,
      profilePicture: newBotProfilePicture,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      status: 'creando',
    }
    handleAddBot(newBot)
    handleCloseAddDialog()
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
    setCurrentBot(null)
  }

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    })
  }

  useEffect(() => {
    fetchBots()
  }, [user])

  useEffect(() => {
    if (!searchTerm) {
      setFilteredBots(bots)
    } else {
      setFilteredBots(
        bots?.filter(bot => bot.name.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    }
  }, [searchTerm, bots])

  if (bots === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Añadir nuevo Bot</DialogTitle>
        <DialogContent>
          <DialogContentText>Completa la información del nuevo bot.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre del Bot"
            type="text"
            fullWidth
            variant="standard"
            value={newBotName}
            onChange={e => setNewBotName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Cancelar</Button>
          <Button onClick={handleSaveNewBot}>Guardar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{
            width: '100%',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box
        p={4}
        sx={{
          bgcolor: 'background.default',
          minHeight: '100vh',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2,
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                color: 'white',
              }}
            >
              Tus bots
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  label="Buscar Bots"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  sx={{
                    marginBottom: 2,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item>
            <Link
              onClick={handleClickOpenAddDialog}
              style={{
                textDecoration: 'none',
              }}
            >
              <Card style={{ width: '130px' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                    <Avatar
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: 'primary.main', // Puedes cambiar el color si lo deseas
                      }}
                    >
                      <AddIcon />
                    </Avatar>
                  </Box>

                  <Box sx={{ padding: 0, margin: 0 }}>
                    <Typography variant="h6" align="center">
                      Crear Agente IA
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          {filteredBots?.map(bot => (
            <Grid item key={bot.id}>
              <Link
                to={`/bots/${bot.id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Card style={{ width: '130px' }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                      <Avatar
                        src={bot.profilePicture}
                        sx={{
                          width: 56,
                          height: 56,
                          marginRight: 2,
                        }}
                      />
                      <Box
                        sx={{
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Box
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        ></Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center" mb={0}>
                        <Typography variant="h5" component="div">
                          {bot.pushname}
                        </Typography>
                      </Box>
                      <SyncSocial bot={bot} />

                      {false && bot?.from && (
                        <Box
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <PhoneInput
                            placeholder="Enter phone number"
                            value={'+' + bot?.from?.split('@')[0]}
                            readOnly={true}
                            style={{
                              background: 'transparent!important',
                            }}
                            onChange={() => {}}
                          />
                        </Box>
                      )}

                      {false && (
                        <Box
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <GetStatus bot={bot} />
                        </Box>
                      )}
                    </Box>

                    {false && bot?.from && (
                      <Typography variant="subtitle2" color="text.secondary">
                        Actualizado: {formatDate(bot.updated_at)}
                      </Typography>
                    )}

                    {false && (
                      <>
                        <Typography variant="body2" color="text.secondary">
                          Fecha de Creación: {formatDate(bot.created_at)}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Modal
          open={isQRModalOpen}
          onClose={closeQRModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              width: 'fit-content',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={closeQRModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'gray',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Código QR para {selectedBot?.name}
            </Typography>
            {currentQRCode && <QRCodeReact value={currentQRCode} size={256} />}
            {qrMessage && (
              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 2,
                }}
              >
                {qrMessage}
              </Typography>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  )
}

export default BotsList
