import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { Avatar, Card, CardContent, CardHeader, Grid, Typography, Box } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { styled } from '@mui/material/styles'

const StyledTimeline = styled(VerticalTimeline)(({ theme }) => ({
  maxHeight: '100%',
  overflowY: 'auto',
  padding: '0 0 20px',
  [theme.breakpoints.down('sm')]: {
    '.vertical-timeline-element-content': {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    '.vertical-timeline-element-date': {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  '& .vertical-timeline-element': {
    margin: '0 0 20px',
  },
}))

const PostTimeLine = () => {
  return (
    <Box
      sx={{
        maxHeight: '100%',
        overflow: 'auto',
      }}
    >
      <StyledTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{
            background: 'grey',
            color: '#000',
          }}
          contentArrowStyle={{
            borderRight: '7px solid  #fff',
          }}
          date="2024-06-16"
          iconStyle={{
            background: '#1976d2',
            color: '#fff',
          }}
          icon={<ImageIcon />}
        >
          <Card variant="outlined">
            <CardHeader
              avatar={<Avatar>A</Avatar>}
              title="Usuario 1"
              subheader="Publicación de imagen"
            />
            <CardContent>
              <Grid container justifyContent="center">
                <img
                  src="https://placeimg.com/400/300/any"
                  alt="Publicación"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </Grid>
            </CardContent>
          </Card>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{
            background: '#fff',
            color: '#000',
          }}
          contentArrowStyle={{
            borderRight: '7px solid  #fff',
          }}
          date="2024-06-15"
          iconStyle={{
            background: '#e91e63',
            color: '#fff',
          }}
          icon={<VideoLibraryIcon />}
        >
          <Card variant="outlined">
            <CardHeader
              avatar={<Avatar>B</Avatar>}
              title="Usuario 2"
              subheader="Publicación de video"
            />
            <CardContent>
              <Grid container justifyContent="center">
                <video
                  controls
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
            </CardContent>
          </Card>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element"
          contentStyle={{
            background: '#fff',
            color: '#000',
          }}
          contentArrowStyle={{
            borderRight: '7px solid  #fff',
          }}
          date="2024-06-14"
          iconStyle={{
            background: '#ff9800',
            color: '#fff',
          }}
          icon={<PlayCircleOutlineIcon />}
        >
          <Card variant="outlined">
            <CardHeader
              avatar={<Avatar>C</Avatar>}
              title="Usuario 3"
              subheader="Publicación de audio"
            />
            <CardContent>
              <Typography variant="body1">
                Aquí podría ir la reproducción de un archivo de audio.
              </Typography>
            </CardContent>
          </Card>
        </VerticalTimelineElement>
      </StyledTimeline>
    </Box>
  )
}

export default PostTimeLine
