import React, { useState } from 'react'
import { Button, Box, TextField, Typography, Grid } from '@mui/material'
import FileUploader from '../cloud/FileUploader'

const PostsEdit = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    file_id: '', // Assuming you manage file_id in your form state
    room_id: '',
    event_id: '',
    // Other form fields
  })

  const [openFileUploader, setOpenFileUploader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileId, setFileId] = useState('') // State to manage file ID

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleOpenFileUploader = () => {
    setOpenFileUploader(true)
  }

  const handleCloseFileUploader = () => {
    setOpenFileUploader(false)
  }

  const handleFileUpload = () => {
    // Handle file upload completion and set fileId state
    // Example: setFileId(newFileId);
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Add your form submission logic here
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 3,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Crear Nuevo Post
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="name"
              label="Título"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="description"
              label="Descripción"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleOpenFileUploader}>
              Subir Archivo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="file_id"
              label="File ID"
              name="file_id"
              value={fileId}
              onChange={e => setFileId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="room_id"
              label="Room ID"
              name="room_id"
              value={formData.room_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="event_id"
              label="Event ID"
              name="event_id"
              value={formData.event_id}
              onChange={handleChange}
            />
          </Grid>
          {/* Example of integrating a MapComponent */}
          {/* <Grid item xs={12}>
            <MapComponent
              isEditing={true}
              pointer_selected={formData}
              setPoint={handleMapClick}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              Crear Post
            </Button>
          </Grid>
        </Grid>

        {/* FileUploader component integrated */}
        <FileUploader
          open={openFileUploader}
          handleClose={handleCloseFileUploader}
          handleUpload={handleFileUpload}
          setFileId={setFileId} // Pass setFileId function to update fileId state
        />
      </Box>
    </>
  )
}

export default PostsEdit
