import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSpring, animated, useTransition } from 'react-spring'
import { useTranslation } from 'react-i18next'
import TitleFeature from './../../../components/layout/TitleFeature'
import DemoFeature from './../DemoFeature'
import { Grid, IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { config } from 'react-spring'
import menuMetadata from './../../../components/layout/menu-metadata.json'
import Carousel from 'react-spring-3d-carousel'
import MediaCard from './MediaCard'

const AnimatedGrid = animated(Grid)

const CardsCarouselSpring = () => {
  const [goToSlide, setGoToSlide] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  const { t } = useTranslation()

  const handlePause = useCallback(pause => {
    setIsPaused(pause)
  }, [])

  const preloadImage = src => {
    const img = new Image()
    img.src = src
  }

  const preloadImages = menus => {
    menus.forEach(menu => {
      if (menu.image) {
        preloadImage(menu.image)
      }
    })
  }

  // Preload images
  useEffect(() => {
    preloadImages(menuMetadata.menus)
  }, [])

  const cards = useMemo(() => {
    return menuMetadata.menus
      .map(menu => {
        if (menu.disabled || menu.link?.includes('admin') || menu.link === '/') {
          return null
        }
        return {
          key: uuidv4(),
          content: (
            <MediaCard
              setGoToSlide={setGoToSlide}
              menu={menu}
              color={menu.color}
              handlePause={handlePause}
            />
          ),
          color: menu.color2,
        }
      })
      .filter(Boolean)
  }, [handlePause])

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setGoToSlide(prev => (prev + 1) % cards.length)
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [isPaused, cards.length])

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getCardWidth = () => {
    const { width } = windowDimensions
    if (width < 600) {
      return '100%'
    } else if (width < 960) {
      return '60%'
    } else {
      return '60%'
    }
  }

  const handlePrevSlide = () => {
    setGoToSlide(prev => (prev - 1 + cards.length) % cards.length)
  }

  const handleNextSlide = () => {
    setGoToSlide(prev => (prev + 1) % cards.length)
  }

  // Main component animation
  const mainAnimation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    config: {
      duration: 1000,
    },
  })

  // TitleFeature animation
  const titleFeatureAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 500,
    config: {
      duration: 1000,
    },
  })

  // DemoFeature animation
  const demoFeatureAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 1000,
    config: {
      duration: 1000,
    },
  })

  // AnimatedGrid animation
  const gridTransition = useTransition(cards, {
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    trail: 200,
  })

  return (
    <animated.div style={mainAnimation} role="region" aria-label={t('Carousel de tarjetas')}>
      <AnimatedGrid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: '5vh' }}>
          <div
            style={{
              position: 'relative',
              width: getCardWidth(),
              height: '400px',
              margin: '0 auto',
              overflow: 'hidden',
            }}
            className="media-card-container"
          >
            <IconButton
              style={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
              onClick={handlePrevSlide}
            >
              <ArrowBackIosIcon className={'latido'} />
            </IconButton>
            <Carousel
              slides={cards}
              goToSlide={goToSlide}
              offsetRadius={3}
              showNavigation={false}
              animationConfig={config.gentle}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
              onClick={handleNextSlide}
            >
              <ArrowForwardIosIcon className={'latido'} />
            </IconButton>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            {cards.map((_, index) => (
              <span
                key={index}
                style={{
                  height: goToSlide === index ? '15px' : '10px',
                  width: goToSlide === index ? '15px' : '10px',
                  margin: '0 5px',
                  backgroundColor: _.color,
                  borderRadius: '50%',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={() => setGoToSlide(index)}
              />
            ))}
          </div>
        </Grid>

        {false && (
          <Grid item xs={12}>
            <animated.div style={titleFeatureAnimation}>
              <TitleFeature isStaticMenu={true} setIsStaticMenu={() => {}} />
            </animated.div>
          </Grid>
        )}

        <Grid item xs={12}>
          <animated.div style={demoFeatureAnimation}>
            <DemoFeature />
          </animated.div>
        </Grid>
      </AnimatedGrid>
    </animated.div>
  )
}

export default CardsCarouselSpring
