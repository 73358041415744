import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
  Avatar,
  CircularProgress,
  Alert,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/ViewModule'
import EventIcon from '@mui/icons-material/Event'
import AddIcon from '@mui/icons-material/Add'
import MapComponent from './../../components/metaverse/Map/MapComponent.js'
import { useStore } from './../../StoreContext'
import EventView from './EventView.js'
import config from './../../config.js'

function EventList() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [events, setEvents] = useState([])
  const [profiles, setProfiles] = useState([]) // Lista de perfiles para seleccionar
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [newEvent, setNewEvent] = useState({
    descripcion: '',
    fecha: '',
    hora: '',
    invited_profile_id: [],
    confirmed_profile_id: [],
    longitude: '',
    latitude: '',
  })
  const { userSettings } = useStore()

  useEffect(() => {
    fetchEvents()
    fetchProfiles()
  }, [])

  const fetchEvents = async () => {
    try {
      setError(null)
      setLoading(true)

      const { data, error } = await supabase.from('events').select('*')

      if (error) throw error

      setEvents(data)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchProfiles = async () => {
    try {
      const { data, error } = await supabase.from('user_profiles').select('*')
      if (error) throw error
      setProfiles(data)
    } catch (error) {
      console.error('Error fetching profiles:', error.message)
    }
  }

  const saveEvent = async (event) => {
    try {
      setLoading(true)
      let response

      const normalizedEvent = {
        ...event,
        invited_profile_id: event?.invited_profile_id?.length > 0 ? event?.invited_profile_id : null,
        confirmed_profile_id: event?.confirmed_profile_id?.length > 0 ? event?.confirmed_profile_id : null,
      }

      if (event.id) {
        response = await supabase.from('events').update(normalizedEvent).eq('id', event.id)
      } else {
        response = await supabase.from('events').insert([normalizedEvent])
      }

      if (response.error) throw response.error
      fetchEvents()
      setIsCreateDialogOpen(false)
      setNewEvent({
        descripcion: '',
        fecha: '',
        hora: '',
        invited_profile_id: [],
        confirmed_profile_id: [],
        longitude: '',
        latitude: '',
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenCreateDialog = () => {
    setIsCreateDialogOpen(true)
  }

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false)
    setNewEvent({
      descripcion: '',
      fecha: '',
      hora: '',
      invited_profile_id: [],
      confirmed_profile_id: [],
      longitude: '',
      latitude: '',
      id: ""
    })
    setError(null)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleProfileChange = (event, value, field) => {
    setNewEvent((prevState) => ({
      ...prevState,
      [field]: value.map(profile => profile?.id),
    }))
    setError(null)
  }

  const handleCreateEvent = () => {
    const errors = validateForm()
    if (Object.keys(errors).length > 0) {
      setError(Object.values(errors).join(' '))
      return
    }

    saveEvent(newEvent)
  }

  const validateForm = () => {
    const errors = {}

    if (newEvent.descripcion.trim() === '') {
      errors.descripcion = t('pleaseEnterEventName') || 'El nombre es obligatorio.'
    }
    if (!newEvent.fecha) {
      errors.fecha = t('dateRequired') || 'La fecha es obligatoria.'
    }
    if (!newEvent.hora) {
      errors.hora = t('timeRequired') || 'La hora es obligatoria.'
    }
    if (!newEvent.longitude) {
      errors.longitude = t('longitudeRequired') || 'La longitud es obligatoria.'
    }
    if (!newEvent.latitude) {
      errors.latitude = t('latitudeRequired') || 'La latitud es obligatoria.'
    }

    return errors
  }

  const handleViewEvent = (event) => {
    navigate(`/events/${event.id}`)
  }

  const handleEditEvent = (event) => {
    navigate(`/events/edit/${event.id}`)
  }

  const handleViewDetails = (id) => {
    const event = events.find((event) => event.id === id)
    setSelectedEvent(event)
  }

  const handleMapClick = (e) => {
    const { lng, lat } = e.lngLat
    setNewEvent((prevState) => ({
      ...prevState,
      longitude: lng.toFixed(6),
      latitude: lat.toFixed(6),
    }))
    setError(null)
  }

  const handleMarkerDragEnd = (e) => {
    const { lng, lat } = e.lngLat
    setNewEvent((prevState) => ({
      ...prevState,
      longitude: lng.toFixed(6),
      latitude: lat.toFixed(6),
    }))
    setError(null)
  }

  const filteredEvents = events.filter((event) =>
    event.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
  )

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
        }}
      >
        {t('eventList')}
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={8}>
          <TextField
            label={t('search')}
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: <EventIcon />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateDialog}
            fullWidth={{ xs: true, md: false }}
          >
            {t('createEvent')}
          </Button>
        </Grid>
      </Grid>
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}
      <Grid container spacing={3}>
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <Grid item key={event.id} xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
                onClick={() => handleViewDetails(event.id)}
                aria-label={`${t('view')} ${event.descripcion}`}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      sx={{
                        bgcolor: 'secondary.main',
                        marginRight: 2,
                      }}
                    >
                      <EventIcon />
                    </Avatar>
                    <Typography variant="h6" component="div" noWrap>
                      {event.descripcion}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    <strong>{t('date')}:</strong> {new Date(event.fecha).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>{t('time')}:</strong> {event.hora}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>{t('guests')}:</strong> {event.invited_profile_id?.length > 0 ? event.invited_profile_id.join(', ') : t('noGuests')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>{t('confirmed')}:</strong> {event.confirmed_profile_id?.length > 0 ? event.confirmed_profile_id.join(', ') : t('noConfirmed')}
                  </Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <CheckCircleIcon sx={{ color: 'green', marginRight: 0.5 }} />
                    <Typography variant="body2" color="text.secondary">
                      {t('status')}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title={t('view')}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        handleViewEvent(event)
                      }}
                      aria-label={t('view')}
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('edit')}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEditEvent(event)
                      }}
                      aria-label={t('edit')}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {t('noEventsFound')}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={selectedEvent ? 6 : 12}>
          <Box
            sx={{
              height: { xs: '300px', md: '500px' },
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 3,
            }}
          >
            <MapComponent
              isEditing={false} // Cambia a true si estás en modo edición
              pointers={filteredEvents.filter(event => event.longitude && event.latitude)} // Solo pasar eventos con coordenadas válidas
              pointer_selected={selectedEvent}
              setPoint={(point) => {
                if (selectedEvent) {
                  // Podrías actualizar el evento seleccionado con nuevas coordenadas
                  setSelectedEvent({
                    ...selectedEvent,
                    longitude: point.longitude,
                    latitude: point.latitude,
                  })
                }
              }}
              triggerGeolocate={false} // Cambia a true si deseas activar la geolocalización
              onLocationUpdate={({ latitude, longitude }) => {
                // Opcional: maneja actualizaciones de ubicación
              }}
              isFixed={false} // Cambia según tus necesidades
              isHidden={false} // Cambia según tus necesidades
              mapStyle={
                userSettings?.dark_theme_enabled
                  ? 'mapbox://styles/mapbox/dark-v10'
                  : 'mapbox://styles/mapbox/streets-v12'
              }
              mapboxToken={config.automatic.mapBox_apiKey} // Asegúrate de que este valor esté correctamente definido en tu config.js
            />
          </Box>
        </Grid>
        {selectedEvent && (
          <Grid item xs={12} md={6}>
            <EventView event_id={selectedEvent.id} />
          </Grid>
        )}
      </Grid>

      {/* Diálogo para Crear Evento */}
      <Dialog open={isCreateDialogOpen} onClose={handleCloseCreateDialog} fullWidth maxWidth="sm">
        <DialogTitle>{t('createEvent')}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('description')}
                name="descripcion"
                variant="outlined"
                fullWidth
                value={newEvent.descripcion}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('date')}
                name="fecha"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={newEvent.fecha}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('time')}
                name="hora"
                type="time"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={newEvent.hora}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={profiles}
                getOptionLabel={(option) => option.name} // Ajusta según la propiedad de nombre de perfil
                value={profiles.filter(profile => newEvent.invited_profile_id.includes(profile.id))}
                onChange={(event, value) => handleProfileChange(event, value, 'invited_profile_id')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('invitedGuests')}
                    placeholder={t('selectGuests')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={profiles}
                getOptionLabel={(option) => option.name}
                value={profiles.filter(profile => newEvent.confirmed_profile_id.includes(profile.id))}
                onChange={(event, value) => handleProfileChange(event, value, 'confirmed_profile_id')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('confirmedGuests')}
                    placeholder={t('selectConfirmedGuests')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('longitude')}
                name="longitude"
                variant="outlined"
                fullWidth
                value={newEvent.longitude}
                onChange={handleInputChange}
                required
                type="number"
                inputProps={{ step: '0.000001' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('latitude')}
                name="latitude"
                variant="outlined"
                fullWidth
                value={newEvent.latitude}
                onChange={handleInputChange}
                required
                type="number"
                inputProps={{ step: '0.000001' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleCreateEvent} variant="contained" color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EventList
