import React, { useState, useEffect } from 'react'
import { supabase } from './../../../supabaseClient'
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  Card,
  CardContent,
  Avatar,
  DialogActions,
  DialogTitle,
} from '@mui/material'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate, useParams } from 'react-router-dom'

const QRCodeEdit = () => {
  const [selectedQR, setSelectedQR] = useState(null)
  const [newQRData, setNewQRData] = useState({
    description: '',
    url_destino: '',
  })
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const navigate = useNavigate()
  const { qrId } = useParams()

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const { data, error } = await supabase.from('qr_codes').select('*').eq('id', qrId).single()

        if (error) throw error

        setSelectedQR(data)
        setNewQRData({
          description: data.description,
          url_destino: data.url_destino,
        })
      } catch (error) {
        console.error('Error fetching QR code', error)
      }
    }

    fetchQRCode()
  }, [qrId])

  const handleFormSubmit = async event => {
    event.preventDefault()

    try {
      const { description, url_destino } = newQRData
      const { error } = await supabase
        .from('qr_codes')
        .update({
          description,
          url_destino,
        })
        .eq('id', qrId)

      if (error) throw error

      navigate('/ruta-donde-redireccionar')
    } catch (error) {
      console.error('Error updating QR code', error)
    }
  }

  const handleDeleteButtonClick = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase.from('qr_codes').delete().eq('id', qrId)

      if (error) throw error

      navigate('/ruta-donde-redireccionar')
    } catch (error) {
      console.error('Error deleting QR code', error)
    }
  }

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  if (!selectedQR) {
    return <Typography variant="body1">Loading...</Typography>
  }

  return (
    <Box
      p={4}
      sx={{
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'white',
        }}
      >
        Editar código QR
      </Typography>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              sx={{
                bgcolor: 'primary.main',
                marginRight: 2,
              }}
            >
              <QrCode2Icon />
            </Avatar>
            <Typography variant="h5" component="div">
              {selectedQR.description}
            </Typography>
          </Box>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Descripción"
              fullWidth
              margin="dense"
              value={newQRData.description}
              onChange={e =>
                setNewQRData({
                  ...newQRData,
                  description: e.target.value,
                })
              }
            />
            <TextField
              label="URL Destino"
              fullWidth
              margin="dense"
              value={newQRData.url_destino}
              onChange={e =>
                setNewQRData({
                  ...newQRData,
                  url_destino: e.target.value,
                })
              }
            />
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Guardar cambios
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteButtonClick}
                sx={{
                  ml: 2,
                }}
              >
                Eliminar QR
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>

      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>¿Estás seguro de que quieres eliminar este código QR?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default QRCodeEdit
