import React, { useState } from 'react'
import { supabase } from './../../supabaseClient'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  CircularProgress,
  Typography,
  Container,
  Box,
  Alert,
  Paper,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: '/password-reset-success', // URL de redirección después de restablecer la contraseña
      })

      if (error) {
        throw error
      }

      navigate('/password-reset-success')
    } catch (error) {
      console.error('Error:', error.message)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography component="h1" variant="h5">
          {t('login.recovery_password')}
        </Typography>
        {error && (
          <Alert
            severity="error"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            Error: {error}
          </Alert>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('login.recovery_password.email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {loading ? <CircularProgress size={24} /> : t('login.recovery_password.send')}
          </Button>
        </Box>
      </Paper>
    </Container>
  )
}

export default ForgotPassword
