import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { useStore } from './../../../StoreContext'
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material'

const AnimatedCard = animated(Card)

const MediaCard = ({ menu, color, handlePause }) => {
  const { setMenuOption } = useStore()
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageLoaded(true)
    }
    img.src = menu.image || '/images/landing/botija1.gif'

    return () => {
      img.onload = null
    }
  }, [menu.image])

  const animationProps = useSpring({
    transform: show ? 'scale(1.05)' : 'scale(1)',
    boxShadow: show ? '0 20px 25px rgba(0, 0, 0, 0.50)' : '0 2px 10px rgba(0, 0, 0, 0.50)',
    config: {
      duration: 300,
    },
  })

  const handleCardClick = event => {
    event.stopPropagation()
    if (!menu.demo_available) return
    setMenuOption(menu)
  }

  const handleViewMoreClick = event => {
    event.preventDefault()
    const element = event.currentTarget.closest('.media-card-container')
  }

  return (
    <animated.div
      onClick={handleCardClick}
      style={animationProps}
      onTouchStart={e => {
        setShow(true)
        handlePause(true)
        handleCardClick(e)
      }}
      onTouchEnd={() => {
        setShow(false)
        handlePause(false)
      }}
      role="button"
      tabIndex={0}
    >
      <AnimatedCard
        sx={{
          width: 250,
          margin: '0 auto',
          backgroundColor: color,
        }}
      >
        {imageLoaded ? (
          <CardMedia
            sx={{
              height: 150,
            }}
            image={menu.image || '/images/landing/botija1.gif'}
            title={menu.name}
          />
        ) : null}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {t(menu.name)}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t(menu.description)}
          </Typography>
        </CardContent>
        <CardActions sx={{}}>
          {menu.demo_available && (
            <Button
              size="large"
              fullWidth
              className="latido3"
              href={menu.link}
              onClick={handleViewMoreClick}
              onTouchStart={handleViewMoreClick}
              sx={{
                backgroundColor: menu.color2,
                margin: '0, 2px, 0, 2px',
                color: '#fff',
                '&:hover': {
                  backgroundColor: menu.color3,
                },
              }}
            >
              {t('Ver más')}
            </Button>
          )}
        </CardActions>
      </AnimatedCard>
    </animated.div>
  )
}

export default MediaCard
