import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  IconButton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'
import DeleteIcon from '@mui/icons-material/Delete'
import Carousel from 'react-material-ui-carousel'

const eCommerceApps = [
  {
    name: 'Shopify',
    cost: '2000 créditos/mes',
    description: 'Plataforma completa para crear y gestionar tu tienda online.',
    features: ['Fácil de usar', 'Soporte 24/7', 'Integración con múltiples pasarelas de pago'],
    imageUrl: '/cloud/shopify-logo.png',
  },
  {
    name: 'Wordpress',
    cost: '2000 créditos/mes',
    description: 'Sistema de gestión de contenido flexible y ampliamente utilizado.',
    features: [
      'Gran cantidad de plugins',
      'Altamente personalizable',
      'Fácil integración con WooCommerce',
    ],
    imageUrl: '/cloud/wordpress-logo.png',
  },
  {
    name: 'Rednode',
    cost: '1500 créditos/mes',
    description: 'Plataforma open source para gestión de redes y automatización.',
    features: ['Escalable', 'Interfaz intuitiva', 'Soporte para múltiples protocolos de red'],
    imageUrl: '/cloud/rednode-logo.png',
  },
  {
    name: 'Magento',
    cost: '2500 créditos/mes',
    description: 'Plataforma de comercio electrónico altamente personalizable.',
    features: [
      'Gran capacidad de personalización',
      'Amplia comunidad de desarrolladores',
      'Integración avanzada de pasarelas de pago',
    ],
    imageUrl: '/cloud/magento-logo.png',
  },
  {
    name: 'PrestaShop',
    cost: '1800 créditos/mes',
    description: 'Solución open source para tiendas en línea con múltiples funcionalidades.',
    features: [
      'Fácil de instalar y usar',
      'Módulos extensibles',
      'Soporte multilingüe y multimoneda',
    ],
    imageUrl: '/cloud/prestashop-logo.png',
  },
  {
    name: 'OpenCart',
    cost: '1600 créditos/mes',
    description: 'Sistema de gestión de comercio electrónico sencillo y eficaz.',
    features: [
      'Interfaz amigable',
      'Soporte para múltiples tiendas',
      'Variedad de plugins y temas',
    ],
    imageUrl: '/cloud/opencart-logo.png',
  },
]
const CloudApps = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [queueConfig, setQueueConfig] = useState([])
  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteConfigId, setDeleteConfigId] = useState(null)
  const [newConfig, setNewConfig] = useState({
    name: '',
    details: '',
    app: '',
    status: 'pendiente',
    creation_date: new Date().toISOString(),
  })
  const { enqueueSnackbar } = useSnackbar()
  const { userProfile } = useStore()

  const loadConfig = async () => {
    if (!userProfile) return

    try {
      const { data, error } = await supabase
        .from('cloud_apps')
        .select('*')
        .eq('user_id', userProfile?.user_id)

      if (error) throw error

      if (data) {
        setQueueConfig(data)
      }
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error cargando la configuración: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadConfig()
  }, [userProfile])

  const handleOpen = (appName = '') => {
    setNewConfig({
      ...newConfig,
      app: appName,
    })
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const handleDeleteOpen = id => {
    setDeleteConfigId(id)
    setDeleteOpen(true)
  }
  const handleDeleteClose = () => setDeleteOpen(false)

  const handleChange = e => {
    const { name, value } = e.target
    setNewConfig({
      ...newConfig,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      const { data, error } = await supabase
        .from('cloud_apps')
        .insert([
          {
            name: newConfig.name,
            details: newConfig.details,
            app: newConfig.app,
            status: newConfig.status,
            creation_date: newConfig.creation_date,
            user_id: userProfile?.user_id,
          },
        ])
        .select()

      if (error) throw error

      setQueueConfig([...queueConfig, ...data])
      enqueueSnackbar('Configuración guardada con éxito', {
        variant: 'success',
      })
      handleClose()
      loadConfig()
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error guardando la configuración: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const handleDelete = async () => {
    try {
      const { error } = await supabase.from('cloud_apps').delete().eq('id', deleteConfigId)

      if (error) throw error

      setQueueConfig(queueConfig.filter(config => config.id !== deleteConfigId))
      enqueueSnackbar('Configuración eliminada con éxito', {
        variant: 'success',
      })
      handleDeleteClose()
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error eliminando la configuración: ' + error.message, {
        variant: 'error',
      })
    }
  }

  const [cardsPerView, setCardsPerView] = useState(3)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setCardsPerView(1)
      } else if (window.innerWidth < 960) {
        setCardsPerView(2)
      } else {
        setCardsPerView(3)
      }
    }

    handleResize() // Set initial value
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const chunkArray = (arr, size) => {
    const result = []
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size))
    }
    return result
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  const appChunks = chunkArray(eCommerceApps, cardsPerView)

  return (
    <Box>
      <Carousel autoPlay={true} indicators={true} navButtonsAlwaysVisible={true}>
        {appChunks.map((chunk, index) => (
          <Grid
            container
            spacing={2}
            key={index}
            sx={{
              p: 2,
            }}
          >
            {chunk.map((app, idx) => (
              <Grid item xs={12 / cardsPerView} key={idx}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <CardContent>
                    <img
                      src={app.imageUrl}
                      alt={app.name}
                      style={{
                        height: 100,
                        width: 'auto',
                        marginBottom: 16,
                      }}
                    />
                    <Typography variant="h5">{app.name}</Typography>
                    <Typography variant="body1">{app.cost}</Typography>
                    <Typography variant="body2">{app.description}</Typography>
                    <ul>
                      {app.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                    </ul>
                  </CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpen(app.name)}
                    >
                      Configurar {app.name}
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Carousel>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            p: 4,
            boxShadow: 24,
            m: 'auto',
            mt: 5,
            maxWidth: 500,
          }}
        >
          <Typography variant="h6" component="h2">
            Nueva Configuración de App
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Nombre"
            name="name"
            value={newConfig.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Detalles"
            name="details"
            value={newConfig.details}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="app-select-label">App</InputLabel>
            <Select
              labelId="app-select-label"
              name="app"
              value={newConfig.app}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>Ninguna</em>
              </MenuItem>
              {eCommerceApps.map(app => (
                <MenuItem value={app.name} key={app.name}>
                  {app.name} - {app.cost}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
            Guardar Configuración
          </Button>
        </Paper>
      </Modal>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 4,
        }}
      >
        {queueConfig.map(config => (
          <Grid item xs={12} sm={6} md={4} key={config.id}>
            <Card>
              <CardHeader
                title={config.name}
                subheader={`Creado el: ${new Date(config.creation_date).toLocaleDateString()}`}
                action={
                  <IconButton onClick={() => handleDeleteOpen(config.id)}>
                    <DeleteIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <Typography variant="body2">Detalles: {config.details}</Typography>
                <Typography variant="body2">App: {config.app}</Typography>
                <Typography variant="body2">Estado: {config.status}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal open={deleteOpen} onClose={handleDeleteClose}>
        <Paper
          sx={{
            p: 4,
            boxShadow: 24,
            m: 'auto',
            mt: 5,
            maxWidth: 500,
          }}
        >
          <Typography variant="h6" component="h2">
            Confirmar Eliminación
          </Typography>
          <Typography>¿Estás seguro de que deseas eliminar esta configuración?</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{
              mt: 2,
            }}
          >
            Eliminar
          </Button>
          <Button
            variant="outlined"
            onClick={handleDeleteClose}
            sx={{
              mt: 2,
            }}
          >
            Cancelar
          </Button>
        </Paper>
      </Modal>
    </Box>
  )
}

export default CloudApps
