import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'

const CreatePost = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    file_id: null,
    user_id: '',
    room_id: null,
    event_id: null,
    longitude: null,
    latitude: null,
  })
  const [posts, setPosts] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const { userProfile, userSettings } = useStore()
  const [openFileUploader, setOpenFileUploader] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setFormData(prevFormData => ({
        ...prevFormData,
        user_id: userProfile.user_id,
      }))
      fetchPosts()
    }
  }, [userProfile])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleFileUpload = file => {
    setFormData(prevFormData => ({
      ...prevFormData,
      file_id: file.id,
    }))
  }

  const handleMapClick = location => {
    setFormData(prevFormData => ({
      ...prevFormData,
      longitude: location.longitude,
      latitude: location.latitude,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const { data, error } = await supabase.from('posts').insert([formData])

      if (error) throw error

      enqueueSnackbar('Post creado exitosamente', {
        variant: 'success',
      })
      setFormData({
        name: '',
        description: '',
        file_id: null,
        user_id: userProfile.user_id,
        room_id: null,
        event_id: null,
        longitude: null,
        latitude: null,
      })
      fetchPosts()
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error al crear el post: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenFileUploader = () => {
    setOpenFileUploader(true)
  }

  const handleCloseFileUploader = () => {
    setOpenFileUploader(false)
  }

  const fetchPosts = async () => {
    setLoading(true)

    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('user_id', userProfile.user_id)

      if (error) throw error

      setPosts(data)
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error al cargar los posts: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  return (
    <Box>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          mt: 5,
        }}
      >
        Posts Anteriores
      </Typography>
      <List>
        {posts.map(post => (
          <ListItem key={post.id}>
            <ListItemText primary={post.name} secondary={post.description} />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default CreatePost
