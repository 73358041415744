import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import {
  Grid,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  Switch,
  Box,
  TextField,
  Tabs,
  Tab,
} from '@mui/material'
import { useStore } from './../../StoreContext'
import LangSelector from './../../components/layout/LangSelector.jsx'
import { useTranslation } from 'react-i18next'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import LockIcon from '@mui/icons-material/Lock'

const UserSettingsPage = () => {
  const { t } = useTranslation()
  const [notificationEnabled, setNotificationEnabled] = useState(false)
  const [darkThemeEnabled, setDarkThemeEnabled] = useState(false)
  const { user, setMenuOption } = useStore()
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    fetchUserSettings()
    setMenuOption(null)
  }, [user])

  const fetchUserSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('user_settings')
        .select('notification_enabled, dark_theme_enabled')
        .eq('user_id', user.id)
        .single()

      if (error) {
        throw error
      }

      if (data) {
        setNotificationEnabled(data.notification_enabled)
        setDarkThemeEnabled(data.dark_theme_enabled)
      }
    } catch (error) {
      console.error('Error fetching user settings:', error.message)
    }
  }

  const handleNotificationToggle = async () => {
    try {
      const { error } = await supabase.from('user_settings').upsert(
        {
          user_id: user.id,
          notification_enabled: !notificationEnabled,
        },
        {
          onConflict: ['user_id'],
        }
      )

      if (error) {
        throw error
      }

      setNotificationEnabled(!notificationEnabled)
    } catch (error) {
      console.error('Error updating user settings:', error.message)
    }
  }

  const handleThemeToggle = async () => {
    try {
      const { error } = await supabase.from('user_settings').upsert(
        {
          user_id: user.id,
          dark_theme_enabled: !darkThemeEnabled,
        },
        {
          onConflict: ['user_id'],
        }
      )

      if (error) {
        throw error
      }

      setDarkThemeEnabled(!darkThemeEnabled)

      window.location.reload()
    } catch (error) {
      console.error('Error updating user settings:', error.message)
    }
  }

  const handlePasswordChange = async () => {
    // Lógica para cambiar la contraseña del usuario
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        color: '#FFFFFF',
        minHeight: '100vh',
        padding: 3,
        width: '100%',
      }}
    >
      <Grid item xs={12}>
        <Box
          elevation={3}
          sx={{
            p: 3,
            color: '#FFFFFF',
            borderRadius: '8px',
            mb: 2,
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            {t('userSettings')}
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="inherit"
            indicatorColor="secondary"
            sx={{
              '& .MuiTab-root': {
                color: '#FFFFFF',
                fontWeight: 'bold',
              },
            }}
          >
            <Tab label={t('preferences')} />
            <Tab label={t('languageSettings')} />
            <Tab label={t('changePassword')} />
          </Tabs>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            {[0, 1, 2].map(index => (
              <Box
                key={index}
                sx={{
                  height: 10,
                  width: 10,
                  borderRadius: '50%',
                  backgroundColor: tabIndex === index ? 'secondary.main' : 'grey.500',
                  mx: 0.5,
                }}
              />
            ))}
          </Box>

          <Paper
            sx={{
              m: 2,
              p: 3,
            }}
          >
            {tabIndex === 0 && (
              <Box>
                <Typography variant="h5" align="center" gutterBottom>
                  {t('notifications')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <NotificationsIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch checked={notificationEnabled} onChange={handleNotificationToggle} />
                    }
                    label={t('receiveNotifications')}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Brightness4Icon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <FormControlLabel
                    control={<Switch checked={darkThemeEnabled} onChange={handleThemeToggle} />}
                    label={darkThemeEnabled ? t('darkTheme') : t('lightTheme')}
                  />
                </Box>
              </Box>
            )}

            {tabIndex === 1 && (
              <Box>
                <Typography variant="h5" align="center" gutterBottom>
                  {t('languageSettings')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LangSelector />
                </Box>
              </Box>
            )}

            {tabIndex === 2 && (
              <Box>
                <Typography variant="h5" align="center" gutterBottom>
                  {t('changePassword')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LockIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <TextField
                    variant="filled"
                    label={t('currentPassword')}
                    fullWidth
                    sx={{
                      input: {
                        color: '#FFFFFF',
                      },
                      label: {
                        color: '#888',
                      },
                      borderRadius: '4px',
                    }}
                    type="password"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LockIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <TextField
                    variant="filled"
                    label={t('newPassword')}
                    fullWidth
                    sx={{
                      input: {
                        color: '#FFFFFF',
                      },
                      label: {
                        color: '#888',
                      },
                      borderRadius: '4px',
                    }}
                    type="password"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <LockIcon
                    sx={{
                      mr: 1,
                    }}
                  />
                  <TextField
                    variant="filled"
                    label={t('confirmNewPassword')}
                    fullWidth
                    sx={{
                      input: {
                        color: '#FFFFFF',
                      },
                      label: {
                        color: '#888',
                      },
                      borderRadius: '4px',
                    }}
                    type="password"
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handlePasswordChange}
                >
                  {t('update')}
                </Button>
              </Box>
            )}
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UserSettingsPage
