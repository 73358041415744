import React, { useEffect } from 'react'

const ARComponent = () => {
  useEffect(() => {
    const loadARScene = async () => {
      // Espera a que los paquetes se carguen antes de inicializar la escena de AR

      const scene = document.querySelector('a-scene')
      if (scene?.hasLoaded) {
        // Dispatch a custom event (if needed)
        window.dispatchEvent(new CustomEvent('ARSceneLoaded'))
      } else {
        scene?.addEventListener('loaded', () => {
          console.log('AR scene loaded')
          // Dispatch a custom event (if needed)
        })
      }
    }

    loadARScene()
  }, [])

  useEffect(() => {
    const handleSceneLoaded = () => {
      console.log('AR scene loaded (from event)')
      // Perform actions after the scene loads (optional)
    }

    window.addEventListener('ARSceneLoaded', handleSceneLoaded)

    return () => {
      window.removeEventListener('ARSceneLoaded', handleSceneLoaded)
      // ... rest of cleanup logic
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
      }}
    >
      <div className="arjs-loader">
        <h5
          style={{
            color: 'white',
            padding: '10px',
          }}
        >
          Su dispositivo no soporta realidad aumentada
        </h5>
      </div>
      <a-scene>
        <a-nft
          type="nft"
          url="https://raw.githack.com/AR-js-org/AR.js/master/aframe/examples/image-tracking/nft/trex/trex-image/trex.iset"
          smooth="true"
          smoothCount="10"
          smoothTolerance=".01"
          smoothThreshold="5"
        >
          <a-entity
            gltf-model="https://raw.githack.com/AR-js-org/AR.js/master/aframe/examples/image-tracking/nft/trex/scene.gltf"
            scale="5 5 5"
            position="150 300 -100"
          ></a-entity>
        </a-nft>
        <a-entity camera></a-entity>
      </a-scene>
    </div>
  )
}

export default ARComponent
