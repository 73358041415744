import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { useStore } from './../../StoreContext'
import { useTranslation } from 'react-i18next'

const BalanceComponent = () => {
  const { userProfile } = useStore()
  const { t } = useTranslation()

  return (
    <Box
      elevation={3}
      sx={{
        padding: 3,
        margin: '10px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" gutterBottom></Typography>
      <Typography variant="h4">
        {t('coins.currentBalance')}:
        <Box className="balance">
          <MonetizationOnIcon
            sx={{
              width: 24,
              marginBottom: 2,
              color: '#ffdd00',
            }}
          />{' '}
          {userProfile && userProfile.balance !== undefined ? (
            userProfile.balance
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Typography>
    </Box>
  )
}

export default BalanceComponent
