import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import MercadoPago from './Payments/MercadoPago'
import Binance from './Payments/Binance'
import Paypal from './Payments/Paypal'

const GateWay = ({ paymentMethod, order }) => {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(paymentMethod)

  useEffect(() => {
    setCurrentPaymentMethod(paymentMethod)
  }, [paymentMethod])

  const GateWayComponent = (paymentMethod, order) => {
    switch (paymentMethod) {
      case 'paypal':
        return <Paypal order={order} />
      case 'mercadopago':
        return <MercadoPago order={order} />
      case 'binance':
        return <Binance />
      default:
        return null
    }
  }

  return (
    <Box
      style={{
        width: '100%',
      }}
    >
      <Box
        style={{
          ml: 1,
          overflow: '',
          width: '100%',
        }}
      >
        <Box
          style={{
            width: '100%',
          }}
        ></Box>

        <Box
          style={{
            width: '100%',
          }}
        >
          {GateWayComponent(currentPaymentMethod, order)}
        </Box>
      </Box>
    </Box>
  )
}

export default GateWay
