import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import {
  Modal,
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Divider,
  Alert,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
} from 'react-map-gl'
import config from './../../config.js'
import 'mapbox-gl/dist/mapbox-gl.css'

const EditStorePage = () => {
  const { t } = useTranslation()
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [store, setStore] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [validationName, setValidationName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const fieldsToDisable = ['id']

  useEffect(() => {
    const fetchStore = async () => {
      try {
        setLoading(true)
        const { data, error } = await supabase.from('stores').select('*').eq('id', storeId).single()
        if (error) throw error
        setStore(data)

        setLatitude(data.latitude)
        setLongitude(data.longitude)
      } catch (error) {
        console.error('Error fetching store', error)
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }
    fetchStore()
  }, [storeId])

  useEffect(() => {
    setLatitude(store?.latitude)
    setLongitude(store?.longitude)
  }, [store])

  const handleInputChange = e => {
    const { name, value } = e.target
    setStore({
      ...store,
      [name]: value,
    })
  }

  const handleSave = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      const { error } = await supabase
        .from('stores')
        .update({
          ...store,
          latitude,
          longitude,
        })
        .eq('id', storeId)
      if (error) throw error
      navigate('/stores')
    } catch (error) {
      console.error('Error updating store', error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteStore = async () => {
    try {
      setLoading(true)
      const { error } = await supabase.from('stores').delete().eq('id', storeId)
      if (error) throw error
      navigate('/stores')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const handleDelete = async () => {
    if (validationName === store.name) {
      await deleteStore()
      handleCloseModal()
    } else {
      alert(t('store.deleteNameMismatch'))
    }
  }

  const formatUrl = url => url?.replace(/^https?:\/\//, '')

  const handleMapClick = event => {
    const { lng, lat } = event?.lngLat
    setStore(prevRoom => ({
      ...prevRoom,
      longitude: lng,
      latitude: lat,
    }))
    setLongitude(lng)
    setLatitude(lat)
  }

  const buildUrls = storename => {
    const base_url = `https://stores.nieriverso.com/${storename}`
    const admin_url = `https://stores.nieriverso.com/${storename}/admin`
    const api_url = `https://api.stores.nieriverso.com/${storename}`
    return {
      base_url,
      admin_url,
      api_url,
    }
  }

  useEffect(() => {
    if (store) {
      const { base_url, admin_url, api_url } = buildUrls(store.name)
      setStore(prevStore => ({
        ...prevStore,
        base_url,
        admin_url,
        api_url,
      }))
    }
  }, [store])

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  if (loading)
    return (
      <Typography variant="h5">
        {t('loading')}
        ...
      </Typography>
    )
  if (error) return <Alert severity="error">{error}</Alert>

  if (!store) return

  return (
    <Box mt={5} mx={2}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('store.editStore')}
        </Typography>

        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="store tabs">
          <Tab label={t('store.generalInfo')} />
          {store.has_location && <Tab label={t('store.location')} />}
          <Tab label={t('store.urls')} />
          <Tab label={t('store.dangerZone')} />
        </Tabs>

        {tabValue === 0 && (
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label={t('store.name')}
                  variant="outlined"
                  fullWidth
                  required
                  name="storename"
                  value={store.name}
                  onChange={handleInputChange}
                  margin="normal"
                  disabled={true || fieldsToDisable.includes('storename')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  <strong>{t('store.storeStatus')}:</strong>{' '}
                  {store.state === 'ready' ? (
                    <span
                      style={{
                        color: 'green',
                      }}
                    >
                      {t('store.stateReady')}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: 'orange',
                      }}
                    >
                      {t('store.stateBuilding')}
                    </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 1 && store.has_location && (
          <Box mt={2}>
            <Typography variant="h6">{t('store.location')}</Typography>
            <Box
              sx={{
                height: 400,
                marginBottom: 2,
              }}
            >
              <Map
                initialViewState={{
                  longitude: longitude || -56.16459861450349,
                  latitude: latitude || -34.91104565891153,
                  zoom: 15,
                }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
                mapboxAccessToken={config.automatic.mapBox_apiKey}
                onClick={handleMapClick}
              >
                <NavigationControl />
                <FullscreenControl />
                <GeolocateControl />
                <ScaleControl />
                {longitude && latitude && <Marker longitude={longitude} latitude={latitude} />}
              </Map>
            </Box>
            <TextField
              label="Latitude"
              name="latitude"
              value={latitude || 0}
              fullWidth
              sx={{
                marginBottom: 2,
              }}
              disabled
            />
            <TextField
              label="Longitude"
              name="longitude"
              value={longitude || 0}
              fullWidth
              sx={{
                marginBottom: 2,
              }}
              disabled
            />
          </Box>
        )}

        {tabValue === 2 && (
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('store.domain')}:</Typography>
                <a
                  href={store.base_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    marginBottom: '8px',
                    textDecoration: 'none',
                  }}
                >
                  {formatUrl(store?.base_url)}
                </a>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('store.domain_admin')}:</Typography>
                <a
                  href={store.admin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    marginBottom: '8px',
                    textDecoration: 'none',
                  }}
                >
                  {formatUrl(store?.admin_url)}
                </a>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 3 && (
          <Accordion
            sx={{
              mt: 4,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <WarningIcon
                  sx={{
                    color: 'red',
                    mr: 1,
                  }}
                />
                {t('store.dangerZone')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Divider mb={2} />
                <Typography>{t('store.deleteWarning')}</Typography>
                <Button
                  type="button"
                  onClick={handleOpenModal}
                  sx={{
                    mt: 2,
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  }}
                  variant="contained"
                  color="error"
                >
                  {t('store.deleteStore')}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-delete-store"
          aria-describedby="modal-delete-store-description"
        >
          <Paper
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-delete-store" variant="h6" component="h2" gutterBottom>
              {t('store.deleteStore')}
            </Typography>
            <Typography
              id="modal-delete-store-description"
              sx={{
                mt: 2,
              }}
            >
              {t('store.deleteInstruction')}
            </Typography>
            <TextField
              label={t('store.storeName')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={validationName}
              onChange={e => setValidationName(e.target.value)}
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                onClick={handleCloseModal}
                sx={{
                  mr: 1,
                }}
              >
                {t('cancel')}
              </Button>
              <Button onClick={handleDelete} variant="contained" color="error">
                {t('store.deleteStore')}
              </Button>
            </Box>
          </Paper>
        </Modal>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
            {t('store.saveStore')}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default EditStorePage
