import React from 'react'
import { Grid, Typography } from '@mui/material'

const StoreLinks = ({ baseURL, adminURL, t }) => {
  return (
    <>
      {baseURL && adminURL && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">{t('store.domain')}:</Typography>
          <a href={baseURL} target="_blank" rel="noopener noreferrer">
            {baseURL}
          </a>
        </Grid>
      )}
      {adminURL && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">{t('store.domain_admin')}:</Typography>
          <a href={adminURL} target="_blank" rel="noopener noreferrer">
            {adminURL}
          </a>
        </Grid>
      )}
    </>
  )
}

export default StoreLinks
