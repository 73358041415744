import React, { useState } from 'react'
import { Paper, Typography, IconButton, Collapse } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import InfoIcon from '@mui/icons-material/Info'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import creditosDescription from './../../assets/menu/audio/es/creditosDescription.mp3'
import { useTranslation } from 'react-i18next'

const CreditsInfo = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        margin: '10px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t('coins.whatAreCredits')}
        <MonetizationOnIcon
          sx={{
            marginBottom: 2,
            color: '#ffdd00',
            width: 24,
          }}
        />
        <IconButton onClick={handleToggle} sx={{ marginLeft: 1 }}>
          {expanded ? <ExpandLessIcon /> : <InfoIcon />}
        </IconButton>
      </Typography>

      <Collapse in={expanded}>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
          }}
        >
          {t('coins.creditsDescription1')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
          }}
        >
          {t('coins.creditsDescription2')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginBottom: 2,
          }}
        >
          {t('coins.creditsDescription3')}
        </Typography>
        <audio controls src={creditosDescription}>
          {t('coins.audioNotSupported')}
        </audio>
      </Collapse>
    </Paper>
  )
}

export default CreditsInfo
