import React, { useEffect, useRef, useState } from 'react'
//import 'babylonjs-loaders'
import { supabase } from './../../supabaseClient'

const RoomRender = ({ room_id }) => {
  const canvasRef = useRef(null)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  )
}

export default RoomRender
