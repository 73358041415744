import React, { useState, useEffect, useRef } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom'
import { supabase } from './../supabaseClient'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material'
import { useStore } from './../StoreContext'

const AskPhone = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otpFields, setOtpFields] = useState(['', '', ''])
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [defaultCountry, setDefaultCountry] = useState('UY')
  const { user } = useStore()
  const navigate = useNavigate()
  const inputRefs = [useRef(null), useRef(null), useRef(null)]

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        setDefaultCountry(data.country_code)
      } catch (error) {
        console.error('Error fetching country code:', error)
      }
    }

    fetchCountryCode()
  }, [])

  const generateOtp = () => {
    let otp = ''
    for (let i = 0; i < 3; i++) {
      otp += Math.floor(Math.random() * 90 + 10).toString() + ' '
    }
    return otp.trim()
  }

  const handleSendCode = async () => {
    const otp = generateOtp()
    const expires_at = new Date(Date.now() + 15 * 60000)

    try {
      const { error } = await supabase.from('otp').insert({
        phone_number: phoneNumber,
        otp,
        expires_at,
      })

      if (error) throw error
      setIsCodeSent(true)
    } catch (error) {
      console.error('Error sending verification code:', error)
    }
  }

  const handleOtpChange = (index, value) => {
    if (value.length > 2) return
    const newOtpFields = [...otpFields]
    newOtpFields[index] = value
    setOtpFields(newOtpFields)

    if (value.length === 2 && index < 2) {
      inputRefs[index + 1].current.focus()
    }
  }

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otpFields[index].length === 0 && index > 0) {
      inputRefs[index - 1].current.focus()
    }
  }

  const handleVerifyCode = async () => {
    const otp = otpFields.join(' ')

    try {
      const { data, error } = await supabase
        .from('otp')
        .select('otp, phone_number, validated')
        .eq('phone_number', phoneNumber)
        .eq('user_id', user.id)
        .order('created_at', {
          ascending: false,
        })
        .limit(1)
        .single()

      if (error) throw error

      if (otp === data.otp) {
        console.log('otp validado')
        const { updateError } = await supabase
          .from('user_profiles')
          .update({
            checked_phone: true,
            phone_number: data.phone_number,
          })
          .eq('user_id', user.id)

        if (updateError) throw updateError

        window.location.reload()
      } else {
        console.error('Código de verificación incorrecto o no validado.')
      }
    } catch (error) {
      console.error('Error verifying code:', error)
    }
  }

  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>Verificación de Teléfono</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">
            Ingresa tu número de teléfono para recibir un código de verificación.
          </Typography>
        </Box>
        <Box mb={3}>
          <PhoneInput
            placeholder="Número de teléfono"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry={defaultCountry}
            style={{
              width: '100%',
              marginBottom: '16px',
              fontSize: '16px', // Ajustar el tamaño de la fuente
            }}
          />
        </Box>

        <Box mb={2}>
          <Typography variant="body2" color="textSecondary">
            Asegúrate de tener WhatsApp asociado a este número para recibir el mensaje de
            confirmación.
          </Typography>
        </Box>

        {isCodeSent && (
          <Box display="flex" justifyContent="space-between">
            {otpFields.map((field, index) => (
              <TextField
                type="number"
                key={index}
                inputRef={inputRefs[index]}
                value={field}
                onChange={e => handleOtpChange(index, e.target.value)}
                onKeyDown={e => handleKeyDown(index, e)}
                inputProps={{
                  maxLength: 2,
                }}
                variant="outlined"
                style={{
                  width: '30%',
                  marginRight: index < 2 ? '5px' : '0',
                }}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!isCodeSent && phoneNumber && isValidPhoneNumber(phoneNumber) ? (
          <Button onClick={handleSendCode} color="primary" variant="contained">
            Enviar Código
          </Button>
        ) : (
          isCodeSent && (
            <Button onClick={handleVerifyCode} color="primary" variant="contained">
              Verificar Código
            </Button>
          )
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AskPhone
