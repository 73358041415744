import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { useStore } from './../../StoreContext'
import { useTranslation } from 'react-i18next'

const CreateStore = ({ handleCloseAddDialog, fetchStores }) => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { userProfile, user, setFetchUserItems } = useStore()
  const { t } = useTranslation()
  const [storeData, setStoreData] = useState({})
  const [template, setTemplate] = useState('')
  const [baseURL, setBaseURL] = useState('')
  const [adminURL, setAdminURL] = useState('')
  const { balance } = userProfile || {}

  const handleSubmit = async event => {
    event.preventDefault() // Prevent the default form submission behavior
    const success = await handleSave()

    if (success) {
      navigate('/stores') // Recargar las tiendas
      handleCloseAddDialog()
      fetchStores()
    }
  }

  useEffect(() => {
    if (!user) return

    const fetchUserAndSetInitialState = async () => {
      if (user?.user_id) {
        setStoreData(prevState => ({
          ...prevState,
          user_id: user.user_id,
        }))
      } else {
        console.error(t('error_no_user_data'))
      }
    }

    fetchUserAndSetInitialState()
  }, [user, t])

  useEffect(() => {
    if (name) {
      const generatedBaseURL = `https://stores.nieriverso.com/${name}`
      const generatedAdminURL = `${generatedBaseURL}/admin`
      setBaseURL(generatedBaseURL)
      setAdminURL(generatedAdminURL)
      setStoreData(prevStoreData => ({
        ...prevStoreData,
        base_url: generatedBaseURL,
        admin_url: generatedAdminURL,
      }))
    }
  }, [name])

  const handleSave = async () => {
    setIsSubmitted(true)
    if (balance < 5000) {
      setError(t('error_insufficient_credits'))
      return false
    }
    if (error || !validateStoreName(name)) {
      return false
    }
    const { data: existingStores, error: selectError } = await supabase
      .from('stores')
      .select('name')
      .eq('name', name)
      .maybeSingle()
    if (selectError) {
      console.error(t('error_check_store_existence'), selectError)
      return false
    }
    if (existingStores) {
      alert(t('error_store_exists'))
      return false
    }
    const { error: insertError } = await supabase.from('stores').insert([storeData])

    if (insertError) {
      console.log(t('error_create_store'), insertError)
      return false
    } else {
      setFetchUserItems(true)
      navigate('/stores')
      return true
    }
  }

  const handleInputChange = e => {
    const { value } = e.target
    setName(value)
    if (value.length >= 3 && /^[A-Za-z]+$/.test(value)) {
      setError('')
      setStoreData(prevState => ({
        ...prevState,
        name: value,
      }))
    } else {
      setError(t('error_invalid_store_name'))
    }
  }

  const validateStoreName = name => {
    const regex = /^[A-Za-z]{3,}$/
    if (!regex.test(name)) {
      setError(t('error_invalid_store_name'))
      return false
    }
    setError('')
    return true
  }

  const handleTemplateChange = event => {
    setTemplate(event.target.value)
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 5,
      }}
    >
      <Box
        elevation={3}
        sx={{
          p: 4,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t('create_store')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t('creation_cost')}{' '}
          <MonetizationOnIcon
            className="saltar"
            sx={{
              marginBottom: -1,
              color: '#ffdd00',
              width: 24,
            }}
          />
          4000 {t('one_time')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t('maintenance_cost')}{' '}
          <MonetizationOnIcon
            className="saltar"
            sx={{
              marginBottom: -1,
              color: '#ffdd00',
              width: 24,
            }}
          />
          100 {t('per_day')}
        </Typography>
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <br />
                <br />
                <TextField
                  label={t('store_name')}
                  variant="outlined"
                  fullWidth
                  required
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  margin="normal"
                  error={!!error}
                  helperText={error}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>{t('template')}</InputLabel>
                  <Select value={template} onChange={handleTemplateChange}>
                    <MenuItem value={1}>{t('template1')}</MenuItem>
                    <MenuItem value={2}>{t('template2')}</MenuItem>
                    <MenuItem value={3}>{t('template3')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={balance < 5000 || !!error}
            >
              {t('buy_store')}
              <MonetizationOnIcon
                className="saltar"
                sx={{
                  marginBottom: -1,
                  color: '#ffdd00',
                  width: 24,
                }}
              />
              5000
            </Button>

            {(balance < 5000 || error) && (
              <Typography
                color="error"
                sx={{
                  marginTop: 2,
                }}
              >
                {error || t('error_insufficient_credits')}
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default CreateStore
