import React, { useEffect, useMemo, useState } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { useStore } from './StoreContext'
import { darkTheme } from './themes/dark.js'
import { lightTheme } from './themes/light.js'

export const ThemeProvider = ({ children }) => {
  const { userSettings } = useStore()
  const [themePreference, setThemePreference] = useState(
    () => localStorage.getItem('theme') || 'dark'
  )

  useEffect(() => {
    if (userSettings) {
      const preferredTheme = userSettings.dark_theme_enabled ? 'dark' : 'light'
      setThemePreference(preferredTheme)
      localStorage.setItem('theme', preferredTheme)
    }
  }, [userSettings])

  const theme = useMemo(
    () => (themePreference === 'dark' ? darkTheme : lightTheme),
    [themePreference]
  )

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
