import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Card,
  Avatar,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useStore } from './../../../StoreContext'
import ShareIcon from '@mui/icons-material/Share'
import GetStatus from './../GetStatus'
import BotAvatar from './../../../assets/avatar.jpg'
import { formatDistanceToNow, parseISO } from 'date-fns'
import QRCodeReact from 'qrcode.react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { supabase } from './../../../supabaseClient' // Asegúrate de que la ruta es correcta

const BotForm = ({
  bot,
  handleDownloadClick,
  handleSubmit,
  handleInputChange,
}) => {
  // Estado local para manejar los datos del bot
  const [botData, setBotData] = useState(bot)
  const [isQRModalOpen, setIsQRModalOpen] = useState(false)
  const qrRef = useRef(null)
  const [copySuccess, setCopySuccess] = useState('')
  const { userSettings, userStores, userBots, userEvents, userRooms, setMenuOption, userPosts } =
    useStore()
  const openQRModal = () => setIsQRModalOpen(true)
  const closeQRModal = () => setIsQRModalOpen(false)

  // Hook para sincronizar props externas con el estado local
  useEffect(() => {
    setBotData(bot)
  }, [bot])

  // Hook para realizar fetch periódico utilizando Supabase
  useEffect(() => {
    let intervalId

    const fetchBotData = async () => {
      try {
        // Reemplaza 'bots' con el nombre real de tu tabla en Supabase
        const { data, error } = await supabase
          .from('bots') // Cambia 'bots' por el nombre de tu tabla
          .select('id, qr_code_base64, is_ready, last_alive, created_at, profilePicture, alias, pushname, from, context, event_associed_id, store_associed_id')
          .eq('id', bot.id)
          .single()

        if (error) {
          console.error('Error al obtener los datos del bot:', error.message)
          return
        }

        // Actualiza el estado local con los nuevos datos
        setBotData(prev => ({
          ...prev,
          qr_code_base64: data.qr_code_base64,
          is_ready: data.is_ready,
          last_alive: data.last_alive,
          created_at: data.created_at,
          profilePicture: data.profilePicture,
          alias: data.alias,
          pushname: data.pushname,
          from: data.from,
          context: data.context,
          event_associed_id: data.event_associed_id,
          store_associed_id: data.store_associed_id,
        }))

        // Si el bot está listo, limpia el intervalo
        if (data.is_ready) {
          clearInterval(intervalId)
        }
      } catch (error) {
        console.error('Error en la consulta de Supabase:', error)
      }
    }

    if (!botData.is_ready) {
      // Realiza el primer fetch inmediatamente
      fetchBotData()
      // Configura el intervalo para realizar el fetch cada 5 segundos
      intervalId = setInterval(fetchBotData, 5000)
    }

    // Limpia el intervalo cuando el componente se desmonte o cuando is_ready cambie
    return () => clearInterval(intervalId)
  }, [bot.id, botData.is_ready])

  // Función para descargar el QR como PNG
  const downloadQR = () => {
    const canvas = qrRef.current.querySelector('canvas')
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${botData.alias}_QR.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } else {
      // Manejar caso donde el QR no está en un canvas (por ejemplo, SVG)
      const svg = qrRef.current.querySelector('svg')
      if (svg) {
        const serializer = new XMLSerializer()
        const svgString = serializer.serializeToString(svg)
        const canvasElement = document.createElement('canvas')
        const img = new Image()
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)

        img.onload = () => {
          canvasElement.width = img.width
          canvasElement.height = img.height
          const ctx = canvasElement.getContext('2d')
          ctx.drawImage(img, 0, 0)
          URL.revokeObjectURL(url)
          const pngUrlFinal = canvasElement.toDataURL('image/png')
          const downloadLinkFinal = document.createElement('a')
          downloadLinkFinal.href = pngUrlFinal
          downloadLinkFinal.download = `${botData.alias}_QR.png`
          document.body.appendChild(downloadLinkFinal)
          downloadLinkFinal.click()
          document.body.removeChild(downloadLinkFinal)
        }
        img.src = url
      }
    }
  }

  // Función para compartir el enlace del bot
  const shareBotLink = async () => {
    const botLink = botData.qr_code_base64
      ? `https://m.nieriverso.com/bot/${botData.id}` // Reemplaza con el enlace real de tu bot
      : `http://wa.me/${botData.from.split("@")[0]}`

    if (navigator.share) {
      try {
        await navigator.share({
          title: `${botData.alias} - Chatbot`,
          text: `¡Chatea con mi bot en WhatsApp!`,
          url: botLink,
        })
      } catch (error) {
        console.error('Error al compartir:', error)
      }
    } else {
      // Fallback: Copiar al portapapeles
      try {
        await navigator.clipboard.writeText(botLink)
        setCopySuccess('Enlace copiado al portapapeles!')
        setTimeout(() => setCopySuccess(''), 3000)
      } catch (err) {
        console.error('Failed to copy!', err)
      }
    }
  }

  return (
    <Box sx={{ padding: 2, margin: 2 }}>
      <Grid container spacing={3}>
        {/* Información del Bot */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: 'center', padding: 2 }}>
            <Avatar
              src={botData.profilePicture || BotAvatar}
              alt={botData.alias}
              sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              {botData.alias?.replace(/\b\w/g, char => char.toUpperCase())}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {botData.pushname}
            </Typography>
            <PhoneInput
              placeholder="Número de teléfono"
              value={'+' + (botData?.from?.split('@')[0] || '')}
              readOnly
              disabled
              className="phone-input"
              inputStyle={{ width: '100%' }}
              containerStyle={{ marginTop: '16px' }}
            />
            {/* Campos de Alias y Contexto */}
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Alias"
                    name="alias"
                    value={botData.alias || ''}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    InputProps={{ style: { color: 'black' } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Contexto"
                    name="context"
                    value={botData.context || ''}
                    onChange={handleInputChange}
                    margin="normal"
                    fullWidth
                    InputProps={{ style: { color: 'black' } }}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        {/* QR Code y Estado */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ textAlign: 'center', padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              {botData.qr_code_base64
                ? 'Escanea el código con WhatsApp para vincular'
                : 'Escanea para chatear con el bot'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }} ref={qrRef}>
              <QRCodeReact
                value={botData.qr_code_base64 || `http://wa.me/${botData.from}`}
                size={300} // Tamaño ajustado según tu preferencia
                includeMargin={true}
              />
            </Box>
            <GetStatus bot={botData} />
            <Box sx={{ mt: 2 }}>
              {botData?.created_at && (
                <Typography variant="body2" color="textSecondary">
                  Creado hace:{' '}
                  <Typography component="span" fontWeight="bold" color="primary">
                    {formatDistanceToNow(parseISO(botData.created_at), { addSuffix: true })}
                  </Typography>
                </Typography>
              )}
              {botData.is_ready && (
                <Typography variant="body2" color="textSecondary">
                  Última comunicación:{' '}
                  <Typography component="span" fontWeight="bold" color="primary">
                    {formatDistanceToNow(parseISO(botData.last_alive), { addSuffix: true })}
                  </Typography>
                </Typography>
              )}
            </Box>
            {/* Botones para ver, descargar y compartir QR */}
            <Box sx={{ mt: 2 }}>
              <Button variant="outlined" color="primary" onClick={openQRModal} fullWidth>
                Ver QR Code
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={downloadQR}
                sx={{ mt: 2 }}
                fullWidth
              >
                Descargar QR
              </Button>
              {botData.is_ready && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={shareBotLink}
                  sx={{ mt: 2 }}
                  fullWidth
                  startIcon={<ShareIcon />}
                >
                  Compartir Enlace
                </Button>
              )}
              {/* Mensaje de éxito al copiar */}
              {copySuccess && (
                <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                  {copySuccess}
                </Typography>
              )}
            </Box>
          </Card>
        </Grid>

        {/* Selectores de Evento y Tienda */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ padding: 2 }}>
            {/* Selector de Evento */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="select-event-label">Seleccionar Evento</InputLabel>
              <Select
                labelId="select-event-label"
                id="select-event"
                name="event_associed_id"
                value={botData.event_associed_id || ''}
                label="Seleccionar Evento"
                onChange={handleInputChange}
              >
                {userEvents && userEvents.length > 0 ? (
                  userEvents.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No hay eventos disponibles
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            {/* Selector de Tienda */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="select-store-label">Seleccionar Tienda</InputLabel>
              <Select
                labelId="select-store-label"
                id="select-store"
                name="store_associed_id"
                value={botData.store_associed_id || ''}
                label="Seleccionar Tienda"
                onChange={handleInputChange}
              >
                {userStores && userStores.length > 0 ? (
                  userStores.map(store => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No hay tiendas disponibles
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Card>
        </Grid>

        {/* Botón para Guardar */}
        <Grid item xs={12}>
          <Card sx={{ padding: 2, textAlign: 'center' }}>
            <Button variant="contained" color="primary" type="submit" onClick={handleSubmit} fullWidth>
              Guardar
            </Button>
          </Card>
        </Grid>
      </Grid>

      {/* Modal para QR Code */}
      <Modal
        open={isQRModalOpen}
        onClose={closeQRModal}
        aria-labelledby="modal-qr-title"
        aria-describedby="modal-qr-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-qr-title" variant="h6" component="h2" gutterBottom>
            Código QR del Bot
          </Typography>
          <QRCodeReact
            value={botData.qr_code_base64 || `http://wa.me/${botData.from}`}
            size={200}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={downloadQR}
            sx={{ mt: 2 }}
          >
            Descargar QR
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export default BotForm
