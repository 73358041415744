import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext' // Importa useStore

const useGetUser = async () => {
  const { setUser } = useStore()

  try {
    const { data } = await supabase.auth.getSession()

    if (data.session?.user) {
      setUser && setUser(data.session.user)
    }
  } catch (error) {
    console.error('Error fetching user:', error)
  }
}

const fetchUserSettings = async user => {
  if (!user) return

  try {
    const { data, error } = await supabase
      .from('user_settings')
      .select('*')
      .eq('user_id', user.id)
      .single()

    if (error && error.code !== 'PGRST116') {
      throw error
    }

    if (!data) {
      const { data: newSettings, error: insertError } = supabase
        .from('user_settings')
        .insert([
          {
            user_id: user.id,
          },
        ])
        .single()

      if (insertError) {
        throw insertError
      } else {
        return newSettings
      }
    } else {
      return data
    }
  } catch (err) {
    console.error('Error fetching or creating user settings:', err)
  }
}
const fetchUserProfile = async user => {
  if (!user) return

  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('user_id', user.id)
      .single()

    //console.log(data);
    if (!data) {
      console.error('User profile not found, trying to create one:', error)
      if (error?.code === 'PGRST116') {
        try {
          console.log('Trying to create profile')
          const { data: profileData, error: profileError } = await supabase
            .from('user_profiles')
            .insert([
              { user_id: user.id }, // Asegurarse de que user_id se incluya
            ])
            .single()

          console.log(profileData)
          if (profileError) {
            console.error('Error creating user profile:', profileError)
          } else {
            return profileData
          }
        } catch (err) {
          console.error('Error creating user profile:', err)
        }
      }
    } else {
      return data
    }
  } catch (err) {
    console.error('Error fetching user profile:', err)
  }
}

export { useGetUser, fetchUserProfile, fetchUserSettings }
