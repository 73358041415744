import React from 'react'
import { Container, Typography, Box, Button, Avatar, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline' // Importa el ícono de éxito
import { useTranslation } from 'react-i18next'

const PasswordResetSuccess = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 2,
        }}
      >
        {' '}
        {/* Añade Paper como fondo con sombra */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              m: 1,
              bgcolor: 'success.main',
            }}
          >
            {' '}
            {/* Usa un color que denote éxito */}
            <CheckCircleOutlineIcon /> {/* Ícono de éxito */}
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('password_reset_success.title')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              textAlign: 'center',
            }}
          >
            {t('password_reset_success.message')}
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
            }}
            onClick={() => navigate('/login')}
          >
            {t('password_reset_success.login_button')}
          </Button>
        </Box>
      </Paper>
    </Container>
  )
}

export default PasswordResetSuccess
