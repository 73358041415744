// src/components/Following.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  CircularProgress,
  Box,
  Pagination,
} from '@mui/material';
import { supabase } from '../../supabaseClient'; // Asegúrate de que la ruta es correcta
import { Link } from 'react-router-dom'; // Para los enlaces a perfiles
import { AccountCircle } from '@mui/icons-material';

const Following = ({ userData }) => {
  const [followingData, setFollowingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const followingPerPage = 10;

  // Memorizar followingIds para evitar recrear el array en cada render
  const followingIds = useMemo(() => userData?.following || [], [userData]);

  useEffect(() => {
    const fetchFollowing = async () => {
      if (!followingIds.length) {
        setFollowingData([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Consultar los perfiles de los usuarios que se están siguiendo usando los IDs
        const { data: followingProfiles, error: followingError } = await supabase
          .from('user_profiles')
          .select('user_id, alias, profile_picture_url')
          .in('user_id', followingIds);

        if (followingError) {
          throw followingError;
        }

        setFollowingData(followingProfiles);
      } catch (err) {
        console.error('Error al obtener los seguidos:', err);
        setError('No se pudieron cargar los seguidos.');
      } finally {
        setLoading(false);
      }
    };

    fetchFollowing();
  }, [followingIds]);

  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Opcional: Scroll hacia arriba al cambiar de página
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Calcular los seguidos a mostrar en la página actual
  const indexOfLastFollowing = currentPage * followingPerPage;
  const indexOfFirstFollowing = indexOfLastFollowing - followingPerPage;
  const currentFollowing = followingData.slice(indexOfFirstFollowing, indexOfLastFollowing);
  const totalPages = Math.ceil(followingData.length / followingPerPage);

  if (loading) {
    return (
      <Paper
        sx={{
          margin: 3,
          padding: 2,
          borderRadius: 2,
          bgcolor: 'grey.900',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <CircularProgress color="inherit" />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper
        sx={{
          margin: 3,
          padding: 2,
          borderRadius: 2,
          bgcolor: 'grey.900',
          color: 'red',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">{error}</Typography>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        margin: 3,
        padding: 2,
        borderRadius: 2,
        bgcolor: 'grey.900',
        color: 'white',
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        Siguiendo
      </Typography>
      {followingData.length === 0 ? (
        <Typography variant="body1" align="center" color="grey.500">
          Este usuario no está siguiendo a nadie.
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'center',
              width: '100%',
            }}
          >
  

            {/* Lista de usuarios siguiendo */}
            {currentFollowing.map((following) => (
              <ListItem
                key={following.user_id}
                component={Link}
                to={`/user/profile/${following.user_id}`} // Ajusta la ruta según tu configuración
                sx={{
                  bgcolor: 'transparent',
                  borderRadius: 2,
                  width: 80, // Tamaño reducido
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 1,
                  textDecoration: 'none', // Quitar subrayado
                  color: 'inherit', // Mantener el color del texto
                  '&:hover': {
                    bgcolor: 'grey.800',
                  },
                }}
              >
                <Avatar
                  alt={following.alias || 'Usuario'}
                  src={following.profile_picture_url || ''}
                  sx={{
                    width: 40, // Tamaño reducido
                    height: 40,
                    marginBottom: 0.5,
                  }}
                />
                <Typography variant="body2" color="white" noWrap>
                  {following.alias || 'Sin Alias'}
                </Typography>
              </ListItem>
            ))}
          </List>
          {/* Paginación */}
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ marginTop: 2 }}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default Following;
