import React, { useState, useEffect } from 'react'
import { Box, Grid, Paper, IconButton, Typography } from '@mui/material'
import { supabase } from './../../../supabaseClient'
import QRCodeReact from 'qrcode.react'
import { useParams } from 'react-router-dom'
import GetAppIcon from '@mui/icons-material/GetApp'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useTranslation } from 'react-i18next'
// Asegúrate de importar i18n

const QRPreview = ({ qr }) => {
  console.log(qr)
  const [qrCode, setQrCode] = useState(null)
  const [staticUrl, setStaticUrl] = useState(null)
  const { t } = useTranslation()

  const clave = 827346872364 // La misma clave utilizada para el cifrado

  let { qrId } = useParams()

  const handleDownloadClick = () => {
    html2canvas(document.getElementById('qrCodeContainer')).then(canvas => {
      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF()
      pdf.addImage(imgData, 'PNG', 10, 10, 100, 100)
      pdf.save('qrCode.pdf')
    })
  }

  useEffect(() => {
    if (qr) {
      setQrCode(qr)
    }
  }, [qr])

  useEffect(() => {
    if (qrId) {
      const fetchQRCode = async () => {
        try {
          const { data, error } = await supabase
            .from('qr_codes')
            .select('*')
            .eq('hash', qrId)
            .single() // Filtrar por la URL actual

          if (error) {
            console.error('Error fetching QR codes', error)
          } else {
            setQrCode(data)

            // Actualizar la base de datos con el nuevo valor de visits
            const { error: updateError } = await supabase
              .from('qr_codes')
              .update({
                visits: data.visits + 1,
              })
              .eq('hash', qrId)

            if (updateError) {
              console.error('Error updating QR code visits', updateError)
            }
          }
        } catch (error) {
          console.error('Error fetching QR codes', error)
        }
      }

      if (!qrCode) {
        fetchQRCode()
      }
    }
  }, [qrId])

  useEffect(() => {
    if (!qrCode) return
    const staticURLAux = `${window.location.origin}/qr/${qrCode.hash}` // Concatenate the hostname with the hash to create the static URL
    setStaticUrl(staticURLAux)
  }, [qrCode])

  if (!qrCode) {
    return (
      <Paper
        sx={{
          margin: 3,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Box>
            <Typography variant="h6">{t('qr_not_found')}</Typography>
          </Box>
        </Grid>
      </Paper>
    )
  }

  /*
    declare type QRProps = {
        value: string;
        size?: number;
        level?: string;
        bgColor?: string;
        fgColor?: string;
        style?: CSSProperties;
        includeMargin?: boolean;
        imageSettings?: ImageSettings;
    };
    */

  return (
    <Box
      sx={{
        margin: 3,
        padding: 3,
      }}
      id="qrCodeContainer"
    >
      <Grid container spacing={2} justifyContent="center">
        <Box key={qrCode.id}>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <Typography variant="h4">{t('qr_preview')}</Typography>
            </Grid>
          </Grid>
          <br />
          <QRCodeReact value={staticUrl} size={300} />
          <br />
          <Grid container xs={12}>
            <Grid item xs={6}>
              <IconButton>
                <Typography variant="h6">
                  {t('visits')}: {qrCode.visits}
                </Typography>
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton onClick={handleDownloadClick}>
                {t('download')} <GetAppIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h6">
            {t('description')}: {qrCode.description}
          </Typography>
          <br />
          <Typography>
            {t('redirect_to')} <br />
            <a href={qrCode.url_destino} target="_blank" rel="noopener noreferrer">
              {qrCode.url_destino}
            </a>
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

export default QRPreview
