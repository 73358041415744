import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { supabase } from './../supabaseClient'
import { fetchUserProfile, fetchUserSettings } from './users'
import { fetchStores } from './stores'
import { fetchUserBots, loadChatsBots } from './bots'
import { fetchUserRooms } from './rooms'
import { fetchUserEvents } from './events'
import { fetchUserPosts } from './posts/index.js'

const StoreContext = createContext(null)

export const useStore = () => useContext(StoreContext)

export const StoreProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)
  const [menuOption, setMenuOption] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [userSettings, setUserSettings] = useState(null)
  const [userStores, setUserStores] = useState(null)
  const [userBots, setUserBots] = useState(null) // Nuevo estado para los bots del usuario
  const [userRooms, setUserRooms] = useState(null) // Nuevo estado para los bots del usuario
  const [userEvents, setUserEvents] = useState(null) // Nuevo estado para los bots del usuario
  const [userPosts, setUserPosts] = useState(null) // Nuevo estado para los bots del usuario
  const [fetchUserItems, setFetchUserItems] = useState(false)

  const [isOnline, setIsOnline] = useState(null) // Nuevo estado para los bots del usuario

  const handleAuthChange = useCallback((event, session) => {
    setUser(session?.user ?? null)
    setSession(session)
  }, [])

  useEffect(() => {
    // Escuchar los cambios en el estado de autenticación
    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthChange)

    // Limpieza del efecto
    return () => {
      if (authListener?.unsubscribe) {
        authListener.unsubscribe()
      }
    }
  }, [handleAuthChange])

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setUserProfile(null)
        setUserSettings(null)
        setUserStores(null)
        setUserBots(null) // Establecer userBots a null cuando no hay usuario
        setUserRooms(null)
        setUserEvents(null)
        setUserPosts(null)
        return
      }

      try {
        const [profile, settings, stores, bots, rooms, events, posts] = await Promise.all([
          fetchUserProfile(user),
          fetchUserSettings(user),
          fetchStores(user),
          fetchUserBots(user), // Supongamos que hay una función para obtener los bots del usuario
          fetchUserRooms(user), // Supongamos que hay una función para obtener los bots del usuario
          fetchUserEvents(user),
          fetchUserPosts(user),
        ])

        setUserProfile(profile)
        setUserSettings(settings)
        setUserStores(stores)
        setUserBots(bots) // Establecer userBots con los bots obtenidos
        setUserRooms(rooms) // Establecer userBots con los bots obtenidos
        setUserEvents(events)
        setUserPosts(posts)
        //console.log(profile, settings, stores, bots);
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    setFetchUserItems(false)

    fetchData()
  }, [user, fetchUserItems])

  const value = {
    user,
    session,
    menuOption,
    userSettings,
    userStores,
    userProfile,
    userBots, // Agregar userBots al objeto de contexto
    userRooms,
    userEvents,
    userPosts,
    loadChatsBots,
    setMenuOption,
    setFetchUserItems,
    setUserPosts,
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}
