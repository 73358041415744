import React from 'react'
import { Box, Grid } from '@mui/material'

const PortalPreview = () => {
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <iframe
            src="https://portal.nieriverso.com"
            width="100%"
            height="600" // Ajusta el tamaño del iframe según el tamaño de la pantalla
            style={{
              border: 'none',
              zIndex: 1,
              position: 'relative',
              borderRadius: '70px!important',
            }} // Añade bordes redondeados
            title="Bienvenidos al Portal"
            loading="lazy" // Mejora el rendimiento con lazy loading
            allowFullScreen
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PortalPreview
