import React, { useState, useEffect, useRef } from 'react'
import { supabase } from './../../supabaseClient'
import { Box, Grid, CircularProgress, Alert } from '@mui/material'
import { Chart, registerables } from 'chart.js'
import { useStore } from './../../StoreContext'
import { useTranslation } from 'react-i18next'
import BalanceComponent from './BalanceComponent'
import CreditPurchaseSection from './CreditPurchaseSection'
import CreditsInfo from './CreditsInfo'
import TransactionsTable from './TransactionsTable'

const Coins = () => {
  const [transactions, setTransactions] = useState([])
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { user, setMenuOption } = useStore()
  const chartRef = useRef(null)
  const [chartInstance, setChartInstance] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    setMenuOption(null)
    Chart.register(...registerables)
  }, [])

  const fetchTransactions = async () => {
    if (!user || !user.id) return // Asegurarse de que user y user.id están definidos
    try {
      const { data, error } = await supabase.from('transactions').select('*').eq('user_id', user.id)
      if (error) throw error
      setTransactions(data || [])
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTransactions()
  }, [user])

  useEffect(() => {
    if (transactions.length > 0) {
      const updatedChartData = {
        labels: transactions.map(t => new Date(t.fecha).toLocaleDateString()),
        datasets: [
          {
            label: t('coins.balanceOverTime'),
            data: transactions.map(t => t.current_balance),
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgba(75, 192, 192, 0.2)',
          },
        ],
      }
      setChartData(updatedChartData)
      updateChart(updatedChartData)
    }
  }, [transactions, t])

  const updateChart = data => {
    if (chartRef.current) {
      if (chartInstance) chartInstance.destroy()
      const newChart = new Chart(chartRef.current, {
        type: 'line',
        data: data,
        options: {},
      })
      setChartInstance(newChart)
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Alert severity="error">{t('coins.errorFetchingTransactions')}</Alert>
      </Box>
    )
  }

  return (
    <Box>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6} display="flex" justifyContent="center" lg={4}>
          <CreditsInfo />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <BalanceComponent />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <CreditPurchaseSection />
        </Grid>

        <Grid item xs={12} md={6}>
          <TransactionsTable transactions={transactions} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Coins
