import React, { useState, useEffect } from 'react'
import './Typewriter.css' // Asegúrate de tener este archivo CSS para los estilos

const TypewriterEffect = ({ text, speed, reset, size, className, width }) => {
  const [content, setContent] = useState('')
  //console.log(text, speed, reset, size, className, width)
  useEffect(() => {
    let timer

    if (reset) {
      setContent('') // Limpia el contenido si reset es verdadero
    } else {
      timer = setTimeout(() => {
        if (content.length < text.length) {
          setContent(content + text.charAt(content.length))
        }
      }, speed)
    }

    return () => clearTimeout(timer)
  }, [text, speed, content, reset])

  return (
    <span
      className={className || ' pixelated-text'}
      style={{
        fontSize: size,
        width: width,
      }}
    >
      {content}
    </span>
  )
}

export default TypewriterEffect
