import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import DangerZone from './features/DangerZone'
import { useStore } from './../../StoreContext'
import BotTokenChart from './features/BotTokenChart'
import FineTuning from './../../components/widgets/FineTuning'
import ChatbotConsole from './../dashboard/ChatbotConsole'
import BotInfo from './features/BotInfo'
import Conversations from './features/Conversations'
import Settings from './features/Settings'
import Agenda from './features/Agenda'


const BotsView = () => {
  const { loadChatsBots } = useStore()
  const { botId } = useParams()
  const [currentBot, setCurrentBot] = useState(null)
  const [logMessages, setLogMessages] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [chatSelected, setChatSelected] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const { t } = useTranslation()

  // Fetch bot data
  useEffect(() => {
    const fetchBot = async () => {
      try {
        const { data: bot, error } = await supabase
          .from('bots')
          .select('*')
          .eq('id', botId)
          .single()
        if (error) {
          console.error('Error fetching bot by ID', error)
        } else {
          setCurrentBot(bot)
        }
      } catch (error) {
        console.error('Error loading bot', error)
      }
    }
    fetchBot()

    // const intervalId = setInterval(fetchBot, 5000) // Llama a fetchBot cada 5 segundos

    // return () => clearInterval(intervalId) // Limpia el intervalo al desmontar el componente
  }, [botId])

  // Load bot chats
  useEffect(() => {
    const loadChats = async () => {
      loadChatsBots(botId)
    }

    loadChats()
  }, [botId])

  const subscribeToChannel = () => {
    const channel = supabase.channel('bots_messages_channel')

    channel
      .on(
        'broadcast',
        {
          event: 'new-message',
        },
        payload => {
          console.log('New message received!', payload)
          getMessages() // Actualiza los mensajes cuando ocurra un cambio
        }
      )
      .subscribe(status => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to bots_messages_channel')
        }
      })

    return channel
  }

  const getMessages = async () => {
    if (chatSelected) {
      try {
        const { data: messagesData, error: messagesError } = await supabase
          .from('bots_messages')
          .select('*')
          .eq('chat_id', chatSelected.id)
          .order('created_at', { ascending: true }) // Ordenar por `created_at`

        if (messagesError) {
          console.error('Error fetching bot messages', messagesError)
        } else {
          setLogMessages(messagesData)
        }
      } catch (error) {
        console.error('Error loading bot and messages', error)
      }
    }
  }

  useEffect(() => {
    if (chatSelected) {
      // Obtener los mensajes inicialmente
      getMessages()

      // Suscribirse a los cambios en tiempo real
      const channel = subscribeToChannel()

      // Limpiar la suscripción cuando el componente se desmonte o el chat seleccionado cambie
      return () => {
        channel.unsubscribe()
      }
    }
  }, [chatSelected])
  // Handlers
  const handleInputChange = e => {
    const { name, value } = e.target
    setCurrentBot(prevBot => ({
      ...prevBot,
      [name]: value,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const { data, error } = await supabase
        .from('bots')
        .update({
          name: currentBot.name,
        })
        .eq('id', currentBot.id)
      if (error) {
        console.error('Error updating bot:', error)
      } else {
        console.log('Bot updated successfully:', data)
      }
    } catch (error) {
      console.error('Error updating bot:', error)
    }
  }

  const handleDownloadClick = () => {
    html2canvas(document.getElementById('qrCodeContainer')).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      pdf.addImage(imgData, 'PNG', 10, 10, 100, 100)
      pdf.save('qrCode.pdf')
    })
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <><BotInfo
            bot={currentBot}
            handleDownloadClick={handleDownloadClick}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange} /><Grid item xs={12} md={12}>

            </Grid></>
        )
      case 1:
        return (
          <>
            <Grid container xs={12} md={12}>

              <Grid item xs={12} md={6}>

                <Conversations
                  bot={currentBot}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setChatSelected={setChatSelected}
                />
              </Grid>
              {
                chatSelected && <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      padding: 2,
                      margin: 2,
                    }}
                  >
                    <ChatbotConsole bot={currentBot} logMessages={logMessages} />
                  </Paper>
                </Grid>
              }
            </Grid>

          </>
        )
      case 2:
        return (

          <Grid item xs={12} md={12}>
            <Paper
              sx={{
                padding: 2,
                margin: 2,
              }}
            >
              <Agenda bot={currentBot} logMessages={logMessages} />
            </Paper>
          </Grid>
        )
      case 3:
        return (
          <Grid item xs={12} md={12}>
            {//Uso de tokens
            }
            <BotTokenChart currentBot={currentBot} />
          </Grid>
        )
      case 4:
        return (
          <Grid item xs={12} md={12}>
      
              <FineTuning />
          </Grid>
        )
      case 5:
        return (
          <Grid item xs={12} md={12}>
            <DangerZone bot={currentBot} />
          </Grid>
        )
      default:
        return null
    }
  }

  if (!currentBot) {
    return null // or render a loading indicator
  }

  //console.log(currentBot)
  return (
    <>
      <form>
        <Grid container>
          {currentBot.is_ready || Date.now() - Date.parse(currentBot.last_alive) < 600000 ? (
            <>
              <Grid item xs={12}>
                {!currentBot.qr_code_base64 && (
                  <>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="inherit"
                      indicatorColor="secondary"
                      sx={{
                        '& .MuiTab-root': {
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <Tab label="Configura el agente" />
                      <Tab label="Conversaciones" />
                      <Tab label="Agenda" />
                      <Tab label="Estadisticas" />
                      <Tab label="Ajuste Fino" />
                      <Tab label="Zona de peligro" />
                      <Tab label="Misiones" />

                    </Tabs>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                    >
                      {[...Array(6).keys()].map(index => (
                        <Box
                          key={index}
                          sx={{
                            height: 10,
                            width: 10,
                            borderRadius: '50%',
                            backgroundColor: tabIndex === index ? 'secondary.main' : 'grey.500',
                            mx: 0.5,
                          }}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                {renderTabContent()}
              </Grid>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100vw',
              }}
            >
              <Typography variant="h6" color="textSecondary">
                El bot no responde
              </Typography>
            </Box>
          )}
        </Grid>
      </form>
    </>
  )
}

export default BotsView
