import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import QRPreview from './QRPreview'

const QRPreviewPage = ({ qrId }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box display="flex" alignItems="center" mb={2}>
        <Paper>
          <QRPreview />
        </Paper>
      </Box>
    </Grid>
  )
}

export default QRPreviewPage
