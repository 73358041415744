import React from 'react'

const Applications = () => {
  return (
    <div>
      <h1>Aplicaciones</h1>
      {/* Explicaciones de los servicios */}
    </div>
  )
}

export default Applications
