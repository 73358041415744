import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useStore } from './StoreContext'

import LandingPage from './pages/LandingPage/LandingPage'
import Dashboard from './pages/dashboard/Dashboard'
import Login from './pages/login/Login'
import ForgotPassword from './pages/login/ForgotPassword'

import Coins from './pages/coins/Coins'
import BotsList from './pages/bots/BotsList'
import Flows from './pages/Flows'
import Events from './pages/events/EventList'
import EventsEdit from './pages/events/EventEdit'
import EventView from './pages/events/EventView'

import Posts from './pages/posts/Posts'

import PostsEdit from './pages/posts/PostsEdit'

import Files from './pages/cloud/Files'
import CloudApps from './pages/cloud/CloudApps'
import Domains from './pages/cloud/Domains'
import People from './pages/social/People'
import TaskBots from './pages/TaksBots'
import BotView from './pages/bots/BotView'
import ChatView from './pages/ChatView'
import Inventario from './components/metaverse/Inventario'
import MapEdit from './components/metaverse/Map/MapEdit.js'
import Applications from './components/Applications'
import RequireAuth from './components/RequireAuth'
import PasswordResetSuccess from './pages/login/PasswordResetSuccess'
import AboutUs from './pages/AboutUs'
import Help from './pages/Help'
import AdminUsers from './pages/admin/Users'
import QRCode from './pages/comercio/codigos-qr'
import QRCodeEdit from './pages/comercio/codigos-qr/Edit'
import VisorVR from './pages/portal/visorVR/index.js'
import PortalPreview from './pages/portal/PortalPreview.js'
import BackendPreview from './pages/admin/BackendPreview.js'
import DatacenterPreview from './pages/admin/DatacenterPreview.js'
import EventQueue from './pages/admin/EventQueue.js'
import Transactions from './pages/admin/Transactions.js'
import UserProfile from './pages/user/Profile.js'
import UserSettingsPage from './pages/user/Settings.js'
import QRCodeReader from './pages/comercio/qr/QRCodeReader'
import QRPreviewPage from './pages/comercio/qr/QRPreviewPage'
import Automation from './pages/automation/Automation'
import BlockedModal from './components/BlockedModal'

import RoomList from './components/metaverse/RoomList'
import RoomEdit from './components/metaverse/RoomEdit'
import RoomView from './components/metaverse/RoomView'

import CreateStore from './pages/stores/CreateStore'
import StoreList from './pages/stores/StoreList'
import EditStorePage from './pages/stores/EditStorePage'
import StoreView from './pages/stores/StoreView'

import AskPhone from './components/AskPhone'

const RouteController = () => {
  const { userProfile, user, session } = useStore()
  const navigate = useNavigate()

  const isAdmin = userProfile && userProfile.is_admin

  useEffect(() => {
    if (userProfile && userProfile.blocked) {
      navigate('/')
    }
  }, [userProfile, navigate])

  useEffect(() => {
    if (session && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          console.log('Geolocation permission granted.')
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            alert('sin permiso GPS')
          }
        }
      )
    } else {
      session && alert('Geolocation is not supported by this browser.')
    }
  }, [session, userProfile])

  if (userProfile && userProfile.blocked) {
    return <BlockedModal />
  }

  if (userProfile && !userProfile.phone_number) {
    return <AskPhone />
  }



  /*
  
  
  agregar las rutas de pago
  
  success: "https://m.nieriverso.com/coins/success",
          failure: "https://m.nieriverso.com/coins/failure",
          pending: "https://m.nieriverso.com/coins/pending"
          
  */
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={user ? <Dashboard /> : <LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/ayuda" element={<Help />} />
      <Route path="/flows" element={<Flows />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/qr" element={<QRCodeReader />} />
      <Route path="/qr/:qrId" element={<QRPreviewPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-reset-success" element={<PasswordResetSuccess />} />

      {/* Authenticated Routes */}
      <Route
        path="/qr/codes"
        element={
          <RequireAuth>
            <QRCode />
          </RequireAuth>
        }
      />
      <Route
        path="/qr/codes/editar/:qrId"
        element={
          <RequireAuth>
            <QRCodeEdit />
          </RequireAuth>
        }
      />
      <Route path="/comercio/agenda-inteligente" element={<RequireAuth />} />
      <Route
        path="/portal/visor-vr"
        element={
          <RequireAuth>
            <VisorVR />
          </RequireAuth>
        }
      />
      <Route
        path="/bots"
        element={
          <RequireAuth>
            <BotsList />
          </RequireAuth>
        }
      />
      <Route
        path="/bots/:botId"
        element={
          <RequireAuth>
            <BotView />
          </RequireAuth>
        }
      />
      <Route
        path="/bots/:botId/:chatId"
        element={
          <RequireAuth>
            <ChatView />
          </RequireAuth>
        }
      />
      <Route path="/comunicaciones/instagram" element={<RequireAuth />} />
      <Route path="/comunicaciones/web" element={<RequireAuth />} />
      <Route path="/comunicaciones/api" element={<RequireAuth />} />
      <Route path="/automation-ia/generador-texto" element={<RequireAuth />} />
      <Route path="/automation-ia/generador-imagenes" element={<RequireAuth />} />
      <Route path="/automation-ia/generador-videos" element={<RequireAuth />} />
      <Route path="/automation-ia/sintetizador-audio" element={<RequireAuth />} />
      <Route path="/automation-ia/moderador" element={<RequireAuth />} />
      <Route
        path="/stores/:storeId"
        element={
          <RequireAuth>
            <StoreView />
          </RequireAuth>
        }
      />
      <Route
        path="/stores/"
        element={
          <RequireAuth>
            <StoreList />
          </RequireAuth>
        }
      />
      <Route
        path="/stores/edit"
        element={
          <RequireAuth>
            <StoreList />
          </RequireAuth>
        }
      />
      <Route
        path="/stores/edit/:storeId"
        element={
          <RequireAuth>
            <EditStorePage />
          </RequireAuth>
        }
      />
      <Route
        path="/stores/crear"
        element={
          <RequireAuth>
            <CreateStore />
          </RequireAuth>
        }
      />
      <Route
        path="/portal/visorvr"
        element={
          <RequireAuth>
            <VisorVR />
          </RequireAuth>
        }
      />
      <Route
        path="/portal/maps"
        element={
          <RequireAuth>
            <MapEdit />
          </RequireAuth>
        }
      />
      <Route
        path="/rooms"
        element={
          <RequireAuth>
            <RoomList />
          </RequireAuth>
        }
      />
      <Route
        path="/rooms/create"
        element={
          <RequireAuth>
            <RoomEdit isEdit={false} />
          </RequireAuth>
        }
      />
      <Route
        path="/rooms/:roomId/edit"
        element={
          <RequireAuth>
            <RoomEdit isEdit={true} />
          </RequireAuth>
        }
      />
      <Route
        path="/rooms/:roomId"
        element={
          <RequireAuth>
            <RoomView />
          </RequireAuth>
        }
      />
      <Route
        path="/rooms/items"
        element={
          <RequireAuth>
            <Inventario />
          </RequireAuth>
        }
      />
      <Route
        path="/coins"
        element={
          <RequireAuth>
            <Coins />
          </RequireAuth>
        }
      />
      <Route
        path="/apps"
        element={
          <RequireAuth>
            <Applications />
          </RequireAuth>
        }
      />
      <Route
        path="/portal/preview"
        element={
          <RequireAuth>
            <PortalPreview />
          </RequireAuth>
        }
      />
      <Route
        path="/user/profile"
        element={
          <RequireAuth>
            <UserProfile />
          </RequireAuth>
        }
      />
      <Route
        path="/user/profile/:userId"
        element={
          <RequireAuth>
            <UserProfile />
          </RequireAuth>
        }
      />

      <Route
        path="/user/settings"
        element={
          <RequireAuth>
            <UserSettingsPage />
          </RequireAuth>
        }
      />

      <Route
        path="/events"
        element={
          <RequireAuth>
            <Events />
          </RequireAuth>
        }
      />

      <Route
        path="/events/:eventId"
        element={
          <RequireAuth>
            <EventView />
          </RequireAuth>
        }
      />

      <Route
        path="/events/create"
        element={
          <RequireAuth>
            <EventsEdit />
          </RequireAuth>
        }
      />

      <Route
        path="/events/edit/:eventId"
        element={
          <RequireAuth>
            <EventsEdit />
          </RequireAuth>
        }
      />

      <Route
        path="/posts"
        element={
          <RequireAuth>
            <Posts />
          </RequireAuth>
        }
      />
      <Route
        path="/posts/:postId"
        element={
          <RequireAuth>
            <PostsEdit />
          </RequireAuth>
        }
      />

      <Route
        path="/users"
        element={
          <RequireAuth>
            <People />
          </RequireAuth>
        }
      />
      <Route
        path="/automation"
        element={
          <RequireAuth>
            <Automation />
          </RequireAuth>
        }
      />
      <Route
        path="/comunicaciones/tasks"
        element={
          <RequireAuth>
            <TaskBots />
          </RequireAuth>
        }
      />
      <Route
        path="/cloud/storage"
        element={
          <RequireAuth>
            <Files />
          </RequireAuth>
        }
      />
      <Route
        path="/cloud/apps"
        element={
          <RequireAuth>
            <CloudApps />
          </RequireAuth>
        }
      />
      <Route
        path="/cloud/domains"
        element={
          <RequireAuth>
            <Domains />
          </RequireAuth>
        }
      />

      {/* Admin Routes */}
      {isAdmin && (
        <>
          <Route
            path="/admin/users"
            element={
              <RequireAuth>
                <AdminUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/api"
            element={
              <RequireAuth>
                <BackendPreview />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/datacenter"
            element={
              <RequireAuth>
                <DatacenterPreview />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/messagequeue"
            element={
              <RequireAuth>
                <EventQueue />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/transactions"
            element={
              <RequireAuth>
                <Transactions />
              </RequireAuth>
            }
          />
        </>
      )}
    </Routes>
  )
}

export default RouteController
