// En UserBalance.js
import React from 'react'
import { Box } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn' // Ícono de monedas
import { useNavigate } from 'react-router-dom'
import './UserBalance.scss'
import { useStore } from './../StoreContext'

const UserBalance = ({ isMobile }) => {
  const navigate = useNavigate()
  const { userProfile } = useStore()

  const handleBoxClick = () => {
    navigate('/coins')
  }

  return (
    <Box
      sx={{
        bgcolor: '#0e38610',
        margin: isMobile ? 0 : 1,
        cursor: 'pointer',
        padding: isMobile ? 1 : 2,
        fontSize: isMobile ? '0.75rem' : '1rem',
        width: isMobile ? '50%' : '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      onClick={handleBoxClick}
    >
      <MonetizationOnIcon
        className="saltar"
        sx={{
          marginRight: isMobile ? 0.5 : 1,
          color: '#ffdd00',
          fontSize: isMobile ? '1rem' : '1.5rem',
        }}
      />
      <span
        className="boldLargeText"
        style={{
          fontSize: isMobile ? '1rem' : '1.5rem',
        }}
      >
        {userProfile?.balance}
      </span>
    </Box>
  )
}

export default UserBalance
