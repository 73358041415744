import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import { supabase } from './../../../supabaseClient'

import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const DangerZone = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [botToDelete, setBotToDelete] = useState(null)

  const handleOpenModal = () => setOpenModal(true)

  const handleDeleteBot = async botId => {
    const { data, error } = await supabase
      .from('bots')
      .update({
        deleted: true,
      })
      .match({
        id: botId,
      })
    if (error) {
      console.error('Error deleting bot', error)
    } else {
    }
  }
  const handleClickOpenDeleteDialog = bot => {
    setBotToDelete(bot)
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setBotToDelete(null)
  }

  const handleDeleteConfirmed = async () => {
    if (botToDelete) {
      await handleDeleteBot(botToDelete.id)
      setBotToDelete(null)
    }
    setOpenDeleteDialog(false)
  }

  return (
    <Box
      sx={{
        padding: 2,
        margin: 2,
      }}
    >
      <Accordion
        sx={{
          mt: 4,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WarningIcon
              sx={{
                color: 'red',
                mr: 1,
              }}
            />
            {t('store.dangerZone')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Divider mb={2} />
            <Typography>{t('store.deleteWarning')}</Typography>
            <Button
              type="button"
              onClick={handleOpenModal}
              sx={{
                mt: 2,
                backgroundColor: 'red',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
              variant="contained"
              color="error"
            >
              {t('bots.deleteBot')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default DangerZone
