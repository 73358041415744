import React, { useState } from 'react'
import { Box, Button, Grid, Paper, TextField, Typography, Modal } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn' // Ícono de monedas
import { useStore } from './../../StoreContext' // Import the useStore hook
import { supabase } from './../../supabaseClient'
import GateWay from './Gateway'
import { useTranslation } from 'react-i18next'

function CreditPurchaseComponent() {
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false) // Estado para controlar el loading
  const [payment, setPayment] = useState({}) // Estado para controlar el loading
  const [paymentMethod, setPaymentMethod] = useState('') // Estado para controlar el método de pago seleccionado
  const [order, setOrder] = useState({})
  const { user } = useStore()
  const [showDiscountButtons, setShowDiscountButtons] = useState(false) // New state variable for toggling discount buttons
  const [calculatedPrice, setCalculatedPrice] = useState(0)
  const [openModal, setOpenModal] = useState(false) // State for modal visibility
  const [description, setDescription] = useState('') // Estado para la descripción del pago
  const [isVipUser, setIsVipUser] = useState(false) // Estado para determinar si el usuario es VIP
  const { t } = useTranslation()

  const handlePaymentMethodChange = event => {
    setPaymentMethod(event.target.value)
  }

  const handlePaymentSubmission = async response => {
    // Check if the response is valid
    if (user && response) {
      try {
        // Replace 'your-user-id' with the actual user ID
        const userId = user.id // Este debe ser el ID de usuario real

        // Insert the new transaction into the Supabase 'transactions' table
        const { data, error } = await supabase.from('transactions').insert([
          {
            user_id: userId, // Asegúrate de incluir un user_id válido
            amount: payment.amount,
            email: payment.email,
            description: payment.description,
            status: response.status,
            errormessage: response.error ? response.error.message : '',
          },
        ])

        if (error) throw error

        console.log('Transaction recorded:', data)
      } catch (error) {
        console.error('Error recording transaction:', error.message)
      }
    } else {
      console.log('Invalid payment response')
    }

    handleCloseModal()
  }

  const handleAmountChange = e => {
    const newAmount = parseInt(e.target.value, 10)

    // Solo actualiza el estado si el nuevo valor es mayor a 399
    if (newAmount > 399) {
      setAmount(newAmount)
      setCalculatedPrice(calculatePrice(newAmount))
    } else {
      setAmount(newAmount) // Puedes decidir cómo manejar valores menores a 400, aquí se reinicia
    }
  }

  // Functions to handle modal open/close
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const formatDescription = (credits, price, discount) => {
    // Asegúrate de que 'price' tenga un valor válido
    const finalPrice = price ? price.toFixed(1) : '0.00'
    let desc = `${credits} ${t('coins.credits_for_value')} U$D ${finalPrice}`
    if (discount && discount > 0) {
      desc += `. ${t('coins.includes_discount', { discount })}`
    }
    return desc
  }

  const handleBuy = async (selectedPack = null) => {
    console.log('handleBuy')

    setLoading(true)

    let credits = selectedPack ? selectedPack.credits : amount
    let price = selectedPack ? selectedPack.price : calculatedPrice
    let discount = selectedPack ? selectedPack.discount : 0

    // Verifica que 'price' y 'discount' no sean undefined
    if (typeof price === 'undefined') {
      price = 0
    }
    if (typeof discount === 'undefined') {
      discount = 0
    }

    setDescription(formatDescription(credits, price, discount))

    let order = {
      order_id: '',
      amount: price,
      currency: 'USD',
      email: user.email,
    }

    setOrder(order)

    setOpenModal(true)

    setLoading(false)
  }

  // Define calculatePrice dentro del componente
  function calculatePrice(credits) {
    return credits / 40 // asumiendo que 1 dólar = 40 pesos
  }

  function calculateNormalPrice(credits) {
    return credits / 40 // Suponiendo que 1 crédito = 1/40 USD
  }

  // Function to toggle the visibility of discount buttons
  const toggleDiscountButtons = () => {
    setShowDiscountButtons(!showDiscountButtons)
  }

  const creditPacks = [
    {
      credits: 2500,
      discount: 5,
      price: calculateNormalPrice(2500) * 0.95,
    },
    {
      credits: 5000,
      discount: 10,
      price: calculateNormalPrice(5000) * 0.9,
    },
    {
      credits: 20000,
      discount: 15,
      price: calculateNormalPrice(20000) * 0.85,
    },
  ]

  const creditVipPacks = [
    {
      credits: 50000,
      discount: 20,
      price: calculateNormalPrice(50000) * 0.8,
    },
    {
      credits: 200000,
      discount: 25,
      price: calculateNormalPrice(200000) * 0.85,
    },
    {
      credits: 500000,
      discount: 30,
      price: calculateNormalPrice(500000) * 0.9,
    },
  ]

  return user ? (
    <Box
      sx={{
        p: 2,
      }}
    >
      {/* Modal para el proceso de pago */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        // ... (resto de tus propiedades Modal)
        sx={{
          width: '90%',
          maxWidth: '400px',
          mx: 'auto', // Centra el modal horizontalmente
          p: 2, // Padding alrededor del contenido del modal
        }}
      >
        <Paper
          sx={{
            p: 3,
            textAlign: 'center',
          }}
        >
          {!paymentMethod ? (
            <>
              <Typography
                variant="h4"
                sx={{
                  mb: 1,
                }}
              >
                {t('coins.you_are_about_to_acquire')}
              </Typography>
              <Typography
                id="modal-description"
                sx={{
                  mb: 2,
                }}
              >
                <MonetizationOnIcon
                  className="skip saltar"
                  sx={{
                    marginBottom: 2,
                    color: '#ffdd00',
                    width: 24,
                  }}
                />
                {description}
              </Typography>
            </>
          ) : null}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading && <div className="loading-spinner"></div>}

            {/* Selector de métodos de pago */}
            {!loading && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                {!paymentMethod ? (
                  <>
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 1,
                      }}
                    >
                      {t('coins.select_payment_method')}
                    </Typography>
                    <br />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      {' '}
                      {/* Utiliza flexDirection: 'column' para apilar elementos verticalmente */}
                      <Button
                        fullWidth
                        variant={paymentMethod === 'paypal' ? 'contained' : 'outlined'}
                        onClick={() => setPaymentMethod('paypal')}
                        sx={{
                          bgcolor: paymentMethod === 'paypal' ? '#0070ba' : 'white',
                          color: paymentMethod === 'paypal' ? 'white' : 'black',
                          transition: 'background-color 0.3s ease, color 0.3s ease', // Transiciones suaves
                          '&:hover': {
                            bgcolor: paymentMethod === 'paypal' ? '#005c99' : '#f0f0f0',
                            color: paymentMethod === 'paypal' ? 'white' : 'black',
                          },
                        }}
                      >
                        PayPal
                      </Button>
                      <Button
                        fullWidth
                        variant={paymentMethod === 'mercadopago' ? 'contained' : 'outlined'}
                        onClick={() => setPaymentMethod('mercadopago')}
                        sx={{
                          bgcolor: paymentMethod === 'mercadopago' ? '#32C03C' : 'white',
                          color: paymentMethod === 'mercadopago' ? 'white' : 'black',
                          transition: 'background-color 0.3s ease, color 0.3s ease', // Transiciones suaves
                          '&:hover': {
                            bgcolor: paymentMethod === 'mercadopago' ? '#28a745' : '#f0f0f0',
                            color: paymentMethod === 'mercadopago' ? 'white' : 'black',
                          },
                        }}
                      >
                        Mercado Pago
                      </Button>
                      {false && (
                        <>
                          <Button
                            fullWidth
                            variant={paymentMethod === 'binance' ? 'contained' : 'outlined'}
                            onClick={() => setPaymentMethod('binance')}
                            sx={{
                              bgcolor: paymentMethod === 'binance' ? '#F0B90B' : 'white',
                              color: paymentMethod === 'binance' ? 'black' : 'black',
                              transition: 'background-color 0.3s ease, color 0.3s ease', // Transiciones suaves
                              '&:hover': {
                                bgcolor: paymentMethod === 'binance' ? '#d8a20a' : '#f0f0f0',
                                color: paymentMethod === 'binance' ? 'black' : 'black',
                              },
                            }}
                          >
                            Binance
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : null}

                {/* Gateway de pago */}
                {paymentMethod && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                      }}
                    >
                      {t('coins.selected_payment_method')}: {paymentMethod}
                    </Typography>
                    <GateWay
                      paymentMethod={paymentMethod}
                      order={order}
                      description={description}
                      onPaymentSuccess={handlePaymentSubmission}
                    />
                    <Button onClick={() => setPaymentMethod('')}>
                      {t('coins.change_payment_method')}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Modal>

      {/* Sección principal para comprar créditos */}
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t('coins.buy_credits')}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('coins.amount')}
              type="number"
              fullWidth
              variant="outlined"
              value={amount}
              onChange={handleAmountChange}
              autoComplete="off"
              inputProps={{
                min: 400,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">U$D {calculatedPrice.toFixed(1)}</Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleBuy()}
              disabled={!amount || amount < 400}
            >
              {loading ? t('coins.loading') : t('coins.buy')}
            </Button>
          </Grid>
          {amount < 400 && amount > 0 && (
            <Grid item xs={12}>
              <Typography color="error">{t('coins.minimum_purchase')}</Typography>
            </Grid>
          )}

          {/* Botón para mostrar/ocultar los packs de descuentos */}
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={toggleDiscountButtons} // Usando la función para alternar visibilidad
                size="small"
              >
                {showDiscountButtons ? t('coins.hide_discounts') : t('coins.view_discount_packs')}
              </Button>
            </Box>
          </Grid>
          {showDiscountButtons && (
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 2,
                }}
              >
                {creditPacks.map((pack, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    fullWidth
                    onClick={() => handleBuy(pack)}
                    sx={{
                      mb: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <MonetizationOnIcon
                        className="saltar"
                        sx={{
                          color: '#ffdd00',
                          width: 24,
                        }}
                      />
                      {pack.credits} {t('coins.credits')}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          textDecoration: 'line-through',
                        }}
                      >
                        U$D {calculateNormalPrice(pack.credits).toFixed(1)}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        U$D {pack.price.toFixed(1)}
                      </Typography>
                      <Typography variant="caption" color="green">
                        {t('coins.save')} U$D{' '}
                        {(calculateNormalPrice(pack.credits) - pack.price).toFixed(1)} (
                        {pack.discount}% OFF)
                      </Typography>
                    </Box>
                  </Button>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  ) : (
    <Typography variant="h6" color="error">
      {t('coins.unauthenticated_user')}
    </Typography>
  )
}

export default CreditPurchaseComponent
