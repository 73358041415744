// ThreeViewer.jsx
import React, { useState, useRef, useEffect, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, useGLTF, Html, useProgress } from '@react-three/drei'
import { TransformControls } from '@react-three/drei'
import { Box as MuiBox, Button, Grid, Typography } from '@mui/material'
import { XR, XROrigin } from '@react-three/xr' // Asegúrate de no importar useXR
import Controller from './Controller'
import Hands from './Hands'

const Loader = () => {
  const { progress } = useProgress()
  return (
    <Html center>
      <div style={{ color: 'white' }}>{progress.toFixed(0)} % cargado</div>
    </Html>
  )
}

const ThreeViewer = ({ fileUrl }) => {
  const [autoRotate, setAutoRotate] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isARSupported, setIsARSupported] = useState(false)
  const [isVRSupported, setIsVRSupported] = useState(false)
  const transformControlsRef = useRef()
  const modelRef = useRef()
  const containerRef = useRef(null)

  useEffect(() => {
    if (navigator.xr && navigator.xr.isSessionSupported) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        setIsARSupported(supported)
      })
      navigator.xr.isSessionSupported('immersive-vr').then((supported) => {
        setIsVRSupported(supported)
      })
    }
  }, [])

  const handleAutoRotateToggle = () => {
    setAutoRotate(!autoRotate)
  }

  const enterFullscreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen()
    } else if (containerRef.current.webkitRequestFullscreen) {
      // Safari
      containerRef.current.webkitRequestFullscreen()
    } else if (containerRef.current.msRequestFullscreen) {
      // IE11
      containerRef.current.msRequestFullscreen()
    }
  }

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      // Safari
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      // IE11
      document.msExitFullscreen()
    }
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  const startAR = () => {
    if (navigator.xr && navigator.xr.requestSession) {
      navigator.xr.requestSession('immersive-ar', { requiredFeatures: ['hit-test'] })
        .then(session => {
          // Manejar la sesión AR si es necesario
          console.log('Sesión AR iniciada:', session)
          // Aquí podrías integrar la sesión AR con @react-three/xr si es necesario
        })
        .catch(err => {
          console.error('Error al iniciar la sesión AR:', err)
        })
    } else {
      console.error('AR no es soportado en este dispositivo')
    }
  }

  const startVR = () => {
    if (navigator.xr && navigator.xr.requestSession) {
      navigator.xr.requestSession('immersive-vr')
        .then(session => {
          // Manejar la sesión VR si es necesario
          console.log('Sesión VR iniciada:', session)
          // Aquí podrías integrar la sesión VR con @react-three/xr si es necesario
        })
        .catch(err => {
          console.error('Error al iniciar la sesión VR:', err)
        })
    } else {
      console.error('VR no es soportado en este dispositivo')
    }
  }

  if (!fileUrl) {
    return null
  }

  return (
    <MuiBox
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        height: true ? '100vh' : 'auto',
      }}>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          zIndex: 1,
          position: isFullscreen ? 'absolute' : 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
          margin: isFullscreen ? '10px' : '0',
        }}>
        <Button
          variant="contained"
          color={autoRotate ? 'secondary' : 'primary'}
          onClick={handleAutoRotateToggle}
          sx={{ marginBottom: '10px' }}>
          {autoRotate ? 'Detener Auto-Rotación' : 'Iniciar Auto-Rotación'}
        </Button>

        {!isFullscreen ? (
          <Button
            variant="contained"
            color="primary"
            onClick={enterFullscreen}
            sx={{ marginBottom: '10px' }}>
            Pantalla Completa
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={exitFullscreen}
            sx={{ marginBottom: '10px' }}>
            Salir de Pantalla Completa
          </Button>
        )}

        {isARSupported && (
          <Button
            variant="contained"
            color="primary"
            onClick={startAR}
            sx={{ marginBottom: '10px' }}>
            Iniciar AR
          </Button>
        )}
        {isVRSupported && (
          <Button
            variant="contained"
            color="primary"
            onClick={startVR}
            sx={{ marginBottom: '10px' }}>
            Iniciar VR
          </Button>
        )}

        <Typography variant="h6" sx={{ marginTop: '5px' }}>
          Soporte de AR: {isARSupported ? 'Sí' : 'No'}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '5px' }}>
          Soporte de VR: {isVRSupported ? 'Sí' : 'No'}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          flex: 1,
          height: isFullscreen ? '100vh' : 'auto',
        }}>
        <Canvas camera={{ position: [0, 0, 20] }}>
          {isARSupported || isVRSupported ? (
            <XR>
              <XROrigin>
                {/* Controladores personalizados */}
                <Controller hand="left" />
                <Controller hand="right" />

                {/* Manos */}
                <Hands />

                {/* Luces para iluminar la escena */}
                <ambientLight intensity={2.5} />
                <directionalLight position={[10, 10, 10]} intensity={3} />
                <pointLight position={[-10, -10, -10]} intensity={2} />
                <spotLight position={[0, 20, 10]} intensity={2.5} angle={0.3} />

                <Suspense fallback={<Loader />}>
                  <Model
                    fileUrl={fileUrl}
                    transformControlsRef={transformControlsRef}
                    modelRef={modelRef}
                    autoRotate={autoRotate}
                  />
                </Suspense>

                {/* Implementación de OrbitControls para rotación y zoom */}
                <OrbitControls
                  enableZoom={true}
                  zoomSpeed={0.6}
                  autoRotate={autoRotate}
                  autoRotateSpeed={1.5}
                  enablePan={true}
                  minDistance={1}
                  maxDistance={50000}
                />
              </XROrigin>
            </XR>
          ) : (
            <>
              {/* Sin XR: Renderizar la escena sin características de AR/VR */}
              {/* Luces para iluminar la escena */}
              <ambientLight intensity={2.5} />
              <directionalLight position={[10, 10, 10]} intensity={3} />
              <pointLight position={[-10, -10, -10]} intensity={2} />
              <spotLight position={[0, 20, 10]} intensity={2.5} angle={0.3} />

              <Suspense fallback={<Loader />}>
                <Model
                  fileUrl={fileUrl}
                  transformControlsRef={transformControlsRef}
                  modelRef={modelRef}
                  autoRotate={autoRotate}
                />
              </Suspense>

              {/* Implementación de OrbitControls para rotación y zoom */}
              <OrbitControls
                enableZoom={true}
                zoomSpeed={0.6}
                autoRotate={autoRotate}
                autoRotateSpeed={1.5}
                enablePan={true}
                minDistance={1}
                maxDistance={50000}
              />
            </>
          )}
        </Canvas>
      </Grid>
    </MuiBox>
  )
}

export default ThreeViewer

const Model = ({ fileUrl, transformControlsRef, modelRef, autoRotate }) => {
  const gltf = useGLTF(fileUrl)

  useFrame(() => {
    if (autoRotate && modelRef.current) {
      modelRef.current.rotation.y += 0.01 // Rotación automática del modelo
    }
  })

  return (
    <TransformControls ref={transformControlsRef}>
      <primitive object={gltf.scene} ref={modelRef} scale={2} />
    </TransformControls>
  )
}
