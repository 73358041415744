import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Container,
  keyframes,
} from '@mui/material'
import { IconComponent } from './../IconComponent'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Link, useLocation } from 'react-router-dom'
// Importa el ícono de ancla
import DisabledByDefault from '@mui/icons-material/DisabledByDefault' // Asumiendo que este podría ser tu "StreamingIcon"

import serviciosWeb from './../../../assets/menu/audio/es/serviciosWeb.mp3'
import serviciosWebTiendasOnline from './../../../assets/menu/audio/es/serviciosWebTiendasOnline.mp3'
import cloud from './../../../assets/menu/audio/es/cloud.mp3'
import cloudWordress from './../../../assets/menu/audio/es/cloudWordress.mp3'
import generadoresIa from './../../../assets/menu/audio/es/generadoresIa.mp3'
import generadorTexto from './../../../assets/menu/audio/es/generadorTexto.mp3'
import generadorImagenes from './../../../assets/menu/audio/es/generadorImagenes.mp3'
import generadorVideos from './../../../assets/menu/audio/es/generadorVideos.mp3'
import sintetizadorAudio from './../../../assets/menu/audio/es/sintetizadorAudio.mp3'
import moderador from './../../../assets/menu/audio/es/moderador.mp3'
import nieriverso from './../../../assets/menu/audio/es/nieriverso.mp3'
import nieriversoMapa from './../../../assets/menu/audio/es/nieriversoMapa.mp3'
import nieriversoEspacios3D from './../../../assets/menu/audio/es/nieriversoEspacios3D.mp3'
import nieriversoInventarioObjetos3D from './../../../assets/menu/audio/es/nieriversoInventarioObjetos3D.mp3'
import nieriversoRadios from './../../../assets/menu/audio/es/nieriversoRadios.mp3'
import gptBots from './../../../assets/menu/audio/es/gptBots.mp3'
import gptBotsWhatsapp from './../../../assets/menu/audio/es/gptBotsWhatsapp.mp3'
import gptBotsInstagram from './../../../assets/menu/audio/es/gptBotsInstagram.mp3'
import gptBotsWeb from './../../../assets/menu/audio/es/gptBotsWeb.mp3'
import gptBotsApi from './../../../assets/menu/audio/es/gptBotsApi.mp3'
import eventosTickets from './../../../assets/menu/audio/es/eventosTickets.mp3'
import eventosTicketsMisEventos from './../../../assets/menu/audio/es/eventosTicketsMisEventos.mp3'
import eventosTicketsMisTickets from './../../../assets/menu/audio/es/eventosTicketsMisTickets.mp3'
import eventosTicketsCalendarioEventos from './../../../assets/menu/audio/es/eventosTicketsCalendarioEventos.mp3'
import eventosTicketsVentaTickets from './../../../assets/menu/audio/es/eventosTicketsVentaTickets.mp3'
import eventosTicketsOrganizacionEventos from './../../../assets/menu/audio/es/eventosTicketsOrganizacionEventos.mp3'
import eventosTicketsHistorialEventos from './../../../assets/menu/audio/es/eventosTicketsHistorialEventos.mp3'
import eventosTicketsFavoritos from './../../../assets/menu/audio/es/eventosTicketsFavoritos.mp3'
import serviciosWebInmobiliariaInmersiva from './../../../assets/menu/audio/es/serviciosWebInmobiliariaInmersiva.mp3'
import serviciosWebBlogWebpage from './../../../assets/menu/audio/es/serviciosWebBlogWebpage.mp3'
import serviciosWebGeneradorQr from './../../../assets/menu/audio/es/serviciosWebGeneradorQr.mp3'
import serviciosWebHoteleriaResidenciales from './../../../assets/menu/audio/es/serviciosWebHoteleriaResidenciales.mp3'
import serviciosWebAgenda from './../../../assets/menu/audio/es/serviciosWebAgenda.mp3'
import serviciosWebReservaHoras from './../../../assets/menu/audio/es/serviciosWebReservaHoras.mp3'
import cloudDominios from './../../../assets/menu/audio/es/cloudDominios.mp3'
import cloudAlmacenamiento from './../../../assets/menu/audio/es/cloudAlmacenamiento.mp3'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { useStore } from './../../../StoreContext' // Import the useStore hook
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew' // Ícono que apunta hacia la izquierda

import PlayArrowIcon from '@mui/icons-material/PlayArrow' // Importar ícono de reproducción

/*
import inicio from './../../../assets/menu/audio/es/inicio.mp3';
import cloudWordpress from './../../../assets/menu/audio/es/cloud-wordpress.mp3';
import cloudDominios from './../../../assets/menu/audio/es/cloud-dominios.mp3';
*/
// Ícono de flecha para señalar la opción seleccionada
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

const Menu = ({
  menuData,
  userPermissions = [],
  isStaticMenu,
  setIsStaticMenu,
  showArrow = true,
  hideDisabled = false,
  forceStaticMenu = false,
  hideAdmin = true,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(null)
  const [tooltip, setTooltip] = useState({
    open: false,
    text: '',
    x: 0,
    y: 0,
  })
  const [tooltipTimeout, setTooltipTimeout] = useState(null)
  const [playingAudio, setPlayingAudio] = useState(null)
  const { user, menuOption, userProfile, setMenuOption } = useStore() // Use the user from the context
  const [selectedOption, setSelectedOption] = useState(null)

  const location = useLocation()

  useEffect(() => {
    // Verificar si el path actual es '/'
    if (location.pathname === '/') {
      setMenuOption(null) // Establecer menuOption como null si el path es '/'
    }
  }, [location.pathname, setMenuOption])

  const toggleStaticMenu = () => {
    setIsStaticMenu(!isStaticMenu)
  }

  const audioFiles = {
    '/comercio': serviciosWeb,
    '/stores': serviciosWebTiendasOnline,
    '/cloud': cloud,
    '/cloud/wordpress': cloudWordress,
    '/automation-ia': generadoresIa,
    '/automation-ia/generador-texto': generadorTexto,
    '/automation-ia/generador-imagenes': generadorImagenes,
    '/automation-ia/generador-videos': generadorVideos,
    '/automation-ia/sintetizador-audio': sintetizadorAudio,
    '/automation-ia/moderador': moderador,
    '/nieriverso': nieriverso,
    '/nieriverso/mapa': nieriversoMapa,
    '/nieriverso/espacios-3d': nieriversoEspacios3D,
    '/nieriverso/inventario-objetos-3d': nieriversoInventarioObjetos3D,
    '/nieriverso/radios': nieriversoRadios,
    '/comunicaciones': gptBots,
    '/bots': gptBotsWhatsapp,
    '/comunicaciones/instagram': gptBotsInstagram,
    '/comunicaciones/web': gptBotsWeb,
    '/comunicaciones/api': gptBotsApi,
    '/eventos-tickets': eventosTickets,
    '/eventos-tickets/mis-eventos': eventosTicketsMisEventos,
    '/eventos-tickets/mis-tickets': eventosTicketsMisTickets,
    '/eventos-tickets/calendario-eventos': eventosTicketsCalendarioEventos,
    '/eventos-tickets/venta-tickets': eventosTicketsVentaTickets,
    '/eventos-tickets/organizacion-eventos': eventosTicketsOrganizacionEventos,
    '/eventos-tickets/historial-eventos': eventosTicketsHistorialEventos,
    '/eventos-tickets/favoritos': eventosTicketsFavoritos,
    '/comercio/inmobiliaria-inmersiva': serviciosWebInmobiliariaInmersiva,
    '/comercio/blog-webpage': serviciosWebBlogWebpage,
    '/qr/codes': serviciosWebGeneradorQr,
    '/comercio/hoteleria-residenciales': serviciosWebHoteleriaResidenciales,
    '/comercio/agenda': serviciosWebAgenda,
    '/comercio/reserva-horas': serviciosWebReservaHoras,
    '/cloud/domains': cloudDominios,
    '/cloud/storage': cloudAlmacenamiento,
    // ... puedes continuar añadiendo más elementos siguiendo este patrón ...
  }

  const audioRef = useRef(null) // Referencia para el objeto Audio actual
  const { t } = useTranslation()

  const playAudio = permissionRequired => {
    // Restablece cualquier audio que se esté reproduciendo
    if (audioRef.current) {
      audioRef.current.pause()
    }

    if (permissionRequired && audioFiles[permissionRequired]) {
      audioRef.current = new Audio(audioFiles[permissionRequired])
      audioRef.current.play().catch(e => console.error('Error al reproducir el audio:', e))

      // Actualiza el estado para reflejar que este audio se está reproduciendo
      setPlayingAudio(permissionRequired)

      audioRef.current.onended = () => {
        // Restablece el estado cuando el audio termina
        setPlayingAudio(null)
        setPlayingAudio(permissionRequired)
      }

      audioRef.current.onended = () => {
        // Solo restablece el estado cuando el audio termina
        setPlayingAudio(null)
      }
      audioRef.current.onstop = () => {
        // Solo restablece el estado cuando el audio termina
        setPlayingAudio(null)
      }
    }
  }

  const playAudioAndPreventClose = (event, permissionRequired) => {
    event.stopPropagation() // Previene que el evento se propague al ListItem
    playAudio(permissionRequired)
  }
  const handleClick2 = (e, subMenu) => {
    e.stopPropagation()
    // Si el path actual es "/", desactivar el comportamiento del enlace
    setMenuOption(subMenu) // Llama a setMenuOption si el path no es "/"
  }
  const handleMouseEnter = (text, event) => {
    if (isMobile) {
      // Si es un dispositivo móvil, no hacer nada (o sea, no mostrar el tooltip)
      return
    }

    // Cancela cualquier timeout existente
    if (tooltipTimeout) clearTimeout(tooltipTimeout)

    const newTimeout = setTimeout(() => {
      setTooltip({
        open: true,
        text: text,
        x: event.clientX + 15,
        y: event.clientY + 15,
      })
    }, 300)

    setTooltipTimeout(newTimeout)
  }

  const handleMouseLeave = () => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout)
    setTooltip({
      ...tooltip,
      open: false,
    })
  }

  // Define la animación de vaivén
  const vaivenAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`

  const hasPermission = permissionRequired => {
    return true || !permissionRequired || userPermissions.includes(permissionRequired)
  }

  function hexToRGBA(hex, opacity) {
    let r = parseInt(hex.slice(1, 3), 16)
    let g = parseInt(hex.slice(3, 5), 16)
    let b = parseInt(hex.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  const handleClick = menuItem => {
    setOpenSubMenu(openSubMenu === menuItem.name ? null : menuItem.name)
    setMenuOption(menuItem)
  }
  const hasSubMenuPermission = subMenus => {
    return subMenus.some(subMenu => hasPermission(subMenu.permission_required))
  }

  const isSelectedOption = subMenu => {
    // Get the current path in the browser
    const currentPath = window.location.pathname

    // Check if the current path includes the subMenu.link
    const isSelected = currentPath.includes(subMenu.link)

    // Return true if the subMenu.name matches and if it's selected based on the link
    return (subMenu.name && isSelected) || menuOption?.name == subMenu?.name
  }

  return (
    <Container
      style={{
        marginTop: '10px',
        paddingLeft: '10px',
      }}
    >
      <Box
        style={{
          top: 0,
          position: 'relative',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          position="relative"
          style={{
            top: 0,
            position: 'relative',
          }}
        >
          {/* Botón de alternancia de pantalla completa con posicionamiento absoluto */}
          {!forceStaticMenu && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 3,
                p: 0,
                zIndex: 11,
              }}
            >
              {isStaticMenu && (
                <FullscreenExitIcon
                  onClick={toggleStaticMenu}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              )}

              {!isStaticMenu && !isMobile && (
                <FullscreenIcon
                  onClick={toggleStaticMenu}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          )}

          <List
            style={{
              backgroundColor: 'transparent',
              flexGrow: 1,
            }}
          >
            {menuData.menus.map(menuItem => {
              const displayMenuItem =
                hasPermission(menuItem.permission_required) ||
                (menuItem.subMenus && hasSubMenuPermission(menuItem.subMenus))
              const isPlaying = playingAudio === menuItem.permission_required

              if (hideAdmin && menuItem.permission_required.includes('admin')) return null

              return (
                <React.Fragment key={menuItem.name}>
                  {menuItem.disabled && !userProfile?.is_debugger ? (
                    <></>
                  ) : (
                    <div>
                      <ListItem
                        onClick={() => handleClick(menuItem)}
                        onMouseLeave={handleMouseLeave}
                        className={openSubMenu === menuItem.name ? 'latido3' : ''}
                        button
                        sx={{
                          position: 'relative',
                          '&::before': {
                            transition: 'width 0.5s ease' /* Define the transition properties */,

                            content: '""',
                            position: 'absolute',
                            left: 10, // Ajuste para centrar detrás del icono
                            top: '50%',
                            transform: 'translateY(-50%)',

                            width: 35,

                            height: 40, // Tamaño del cuadrado
                            borderRadius: '10%', // Bordes ligeramente redondeados

                            //  background: hexToRGBA(menuItem.color, 1),
                            //background: hexToRGBA("#000", 1),

                            //isSelectedOption(subMenu.name) ? 0.8 : 0.5

                            // filter: 'blur(3px)', // Eliminado para quitar el efecto difuminado
                            zIndex: 1,
                            opacity: 0.8,
                          },
                          '&:hover': {
                            '& .MuiListItemText-primary': {
                              fontSize: '0.8em', // Aumenta el tamaño de la fuente
                              fontWeight: 'bold', // Cambia el estilo de la fuente a negrita
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          className={openSubMenu === menuItem.name ? 'latido0' : ''}
                          style={{
                            color: '#000',
                            zIndex: 3,
                            scale: '1.4',
                            position: 'relative',
                            left: '10px',
                          }}
                          onClick={e => playAudioAndPreventClose(e, menuItem.permission_required)}
                        >
                          {isPlaying ? (
                            <PlayArrowIcon />
                          ) : (
                            <IconComponent iconName={menuItem.icon} />
                          )}
                        </ListItemIcon>
                        <Link
                          to={menuItem.link}
                          style={{
                            zIndex: 10,
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'flex',
                            alignItems: 'right',
                            width: '100%',
                          }}
                        >
                          <ListItemText
                            style={{
                              zIndex: 2,
                              color: openSubMenu == menuItem.name ? 'black' : 'white',
                            }}
                            primary={t(menuItem.name)}
                          />
                        </Link>

                        {menuItem.show_arrow_expand && menuItem.subMenus ? (
                          openSubMenu === menuItem.name ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : null}
                      </ListItem>

                      <div
                        style={{
                          height: openSubMenu === menuItem.name ? '3px' : '1px',

                          width: openSubMenu === menuItem.name ? '90%' : 44,

                          backgroundColor: hexToRGBA(menuItem.color, 1),
                          position: 'relative',
                          bottom: '7px',
                          left: '5px',
                          zIndex: -1,
                          borderRadius: '5px',
                        }}
                      ></div>
                    </div>
                  )}

                  {menuItem.subMenus && openSubMenu === menuItem.name && (
                    <Collapse in={openSubMenu === menuItem.name} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {menuItem.subMenus.map(subMenu => {
                          const isPlaying2 = playingAudio === subMenu.permission_required
                          return (
                            // hasPermission(subMenu.permission_required) &&

                            (userProfile?.is_debugger ? (
                              <></>
                            ) : subMenu.disabled ? (
                              false
                            ) : (
                              true
                            )) && (
                              <ListItem
                                onClick={e => handleClick2(e, subMenu)}
                                onMouseEnter={e => handleMouseEnter(subMenu.description, e)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                  paddingTop: '5px',
                                  paddingBottom: '2px',
                                  zIndex: 2,
                                  position: 'relative',
                                  '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 9, // Ajuste para centrar detrás del icono
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: 35, // Tamaño del círculo
                                    height: 35, // Tamaño del círculo
                                    borderRadius: '30%', // Hacerlo redondo
                                    background: hexToRGBA(menuItem.color, 1),
                                    filter: 'blur(0px)', // Difuminar los bordes
                                    zIndex: -99,
                                    opacity: isSelectedOption(subMenu) ? 0.9 : 0.2,
                                  },
                                  '&:hover': {
                                    '& .MuiListItemText-primary': {
                                      // Cambiar el tamaño de fuente y otras propiedades aquí
                                      fontSize: '1.0em', // Aumenta el tamaño de la fuente
                                      transition: 'font-size 0.01s', // Suaviza la transición del tamaño
                                      fontWeight: 'italic', // Cambia el estilo de la fuente a negrita
                                    },
                                  },
                                }}
                                key={subMenu.name}
                              >
                                <ListItemIcon
                                  className="latido0"
                                  style={{
                                    color: '#000',
                                  }}
                                  onClick={e =>
                                    playAudioAndPreventClose(e, subMenu.permission_required)
                                  }
                                >
                                  {isPlaying2 ? (
                                    <PlayArrowIcon
                                      x={{
                                        transform: 'scale(2.5)',
                                      }}
                                    />
                                  ) : (
                                    <IconComponent iconName={subMenu.icon} />
                                  )}

                                  {subMenu.disabled && (
                                    <DisabledByDefault
                                      style={{
                                        color: '#b00000',
                                        left: '0px',
                                        top: '10px',
                                        position: 'relative',
                                      }}
                                      iconName={'Disabled'}
                                    />
                                  )}
                                </ListItemIcon>

                                <Link
                                  to={user ? subMenu.link : '/'}
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <ListItemText fontSize={2} primary={t(subMenu.name)} />

                                  {showArrow && isSelectedOption(subMenu) && (
                                    <ArrowBackIosNewIcon
                                      sx={{
                                        color: 'grey', // Hereda el color del ListItem
                                        position: 'relative',
                                        transform: 'scale(1.5)',
                                        animation: `${vaivenAnimation} 1s ease-in-out infinite`, // Aplica la animación
                                      }}
                                    />
                                  )}
                                </Link>
                              </ListItem>
                            )
                          )
                        })}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              )
            })}
          </List>

          <Box
            sx={{
              textAlign: 'center',
              pb: 2,
            }}
          >
            <Divider />
            {false && (
              <Link
                to="/ayuda"
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <HelpOutlineIcon
                  sx={{
                    mr: 1,
                  }}
                />
                Ayuda
              </Link>
            )}
          </Box>

          {tooltip.open && (
            <Box
              style={{
                position: 'fixed',
                top: tooltip.y,
                left: tooltip.x,
                backgroundColor: 'white',
                border: '1px solid black',
                padding: '10px',
                zIndex: 4,
              }}
            >
              {tooltip.text}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default Menu
