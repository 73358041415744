import React from 'react'
import { Box, Typography } from '@mui/material'

const ExperienceGraph = () => {
  // Datos de ejemplo para la experiencia
  const experienceData = [
    {
      day: '2024-01-01',
      experience: 10,
    },
    {
      day: '2024-01-02',
      experience: 15,
    },
    {
      day: '2024-01-03',
      experience: 20,
    },
    {
      day: '2024-01-04',
      experience: 18,
    },
    {
      day: '2024-01-05',
      experience: 25,
    },
    // Agregar más datos de experiencia aquí...
  ]

  return (
    <Box
      sx={{
        marginBottom: 3,
      }}
    >
      <Typography variant="p" gutterBottom>
        Experiencia 0
      </Typography>
      <br/>
      <Typography variant="p" gutterBottom>
        Puntos de influencia 0
      </Typography>
    </Box>
  )
}

export default ExperienceGraph
