import React from 'react'
import { Handle } from 'react-flow-renderer'
import { Box, Typography, Paper } from '@mui/material'
import { styled } from '@mui/system'

import 'react-flow-renderer/dist/style.css'
import 'react-flow-renderer/dist/theme-default.css'

const NodeContainer = styled(Paper)(({ theme, background }) => ({
  padding: '10px',
  borderRadius: '8px',
  color: '#FFF',
  background: background,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: theme.shadows[3],
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[6],
  },
}))

const CustomNodeComponent = ({ data }) => {
  let Icon
  if (data.icon === 'GPTBotsIcon') {
    Icon = () => (
      <span
        role="img"
        aria-label="Bot"
        style={{
          fontSize: '32px',
        }}
      >
        🤖
      </span>
    )
  } else if (data.icon === 'StoreIcon') {
    Icon = () => (
      <span
        role="img"
        aria-label="Store"
        style={{
          fontSize: '32px',
        }}
      >
        🏬
      </span>
    )
  } else {
    Icon = () => null
  }

  return (
    <NodeContainer background={data.background}>
      {data.type === 'bot' && (
        <Handle
          type="target"
          position="left"
          style={{
            background: '#555',
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
        }}
      >
        <div
          style={{
            zIndex: 2,
          }}
        >
          <Icon />
        </div>
      </Box>
      <Typography
        variant="body2"
        align="center"
        sx={{
          marginTop: '5px',
          color: 'white',
        }}
      >
        {data.label}
      </Typography>
      {data.type === 'store' && (
        <Handle
          type="source"
          position="right"
          style={{
            background: '#555',
          }}
        />
      )}
    </NodeContainer>
  )
}

export default CustomNodeComponent
