import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom' // Importar Link desde react-router-dom
import { supabase } from '../supabaseClient'
import { Box, Typography, Paper, Grid, ListItem } from '@mui/material'

import ChatbotConsole from './dashboard/ChatbotConsole'

const Message = ({ message }) => {
  if (!message || !message.created_at) return null

  return (
    <ListItem>
      <Typography variant="body1">{message.message_content}</Typography>
      <Typography variant="caption" color="text.secondary">
        {message.created_at}
      </Typography>
    </ListItem>
  )
}

const BotsView = () => {
  const { botId } = useParams()
  const [currentChat, setcurrentChat] = useState(null)
  const [messages, setMessages] = useState([])

  // Estado individual para cada campo del formulario
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [context, setContext] = useState('')

  const handleInputChange = event => {
    const { name, value } = event.target
    // Actualizar el estado correspondiente según el nombre del campo
    switch (name) {
      case 'name':
        setName(value)
        break
      case 'description':
        setDescription(value)
        break
      case 'context':
        setContext(value)
        break
      default:
        break
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const { data, error } = await supabase
        .from('bots')
        .update({
          name,
          description,
          context,
        })
        .eq('id', botId)
      if (error) {
        console.error('Error updating bot:', error.message)
      } else {
        console.log('Bot updated successfully:', data)
      }
    } catch (error) {
      console.error('Error updating bot:', error.message)
    }
  }

  const [isQRModalOpen, setIsQRModalOpen] = useState(false)

  const openQRModal = () => {
    setIsQRModalOpen(true)
  }

  const closeQRModal = () => {
    setIsQRModalOpen(false)
  }

  const loadBot = async () => {
    try {
      const { data: bot, error } = await supabase
        .from('bots_chats')
        .select('*')
        .eq('id', botId)
        .single()
      if (error) {
        console.error('Error fetching bot by ID', error)
      } else {
        setcurrentChat(bot)
        setName(bot.name || '') // Update name state
        setDescription(bot.description || '') // Update description state
        setContext(bot.context || '') // Update context state
      }
    } catch (error) {
      console.error('Error loading bot', error)
    }
  }

  useEffect(() => {
    // Iniciar el intervalo para realizar la solicitud de fetch cada 10 segundos
    if (!currentChat) {
      loadBot()
      //loadMessages();
    }

    // Limpiar el intervalo al desmontar el componente
  }, [botId])

  const handleQRCodeClick = bot => {
    if (bot.qr_code_base64) {
      setcurrentChat(bot)
    }
  }

  return (
    <>
      <Grid item md={12}>
        <Grid item md={12}>
          <Box
            style={{
              padding: 20,
            }}
          >
            <Paper
              style={{
                padding: 20,
              }}
            >
              Configuración de esta conversación
              {currentChat && (
                <Box
                  sx={{
                    padding: 2,
                    margin: 2,
                  }}
                >
                  <Typography variant="h5">Mensajes con {currentChat.from}</Typography>
                </Box>
              )}
            </Paper>
          </Box>
        </Grid>

        <Grid container md={12}>
          <Grid item md={6}>
            <Box
              style={{
                padding: 20,
              }}
            >
              <Paper
                style={{
                  padding: 20,
                }}
              >
                Configuración de esta conversación
              </Paper>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box
              style={{
                padding: 20,
              }}
            >
              <Paper
                style={{
                  padding: 20,
                }}
              >
                Configuración de esta conversación
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              padding: 20,
            }}
          >
            <ChatbotConsole
              readOnly={true}
              showMessage={true}
              sx={{
                borderColor: 'primary.main', // Theme-based border color
                '&:hover': {
                  borderColor: 'primary.light', // Lighten on hover
                },
                '& input': {
                  padding: '10px', // Comfortable padding inside the input
                },
                '& button': {
                  padding: '10px 20px', // Padding for the button
                  marginLeft: '8px', // Spacing between button and input
                  backgroundColor: 'primary.main', // Theme-based button color
                  color: 'white', // Text color for the button
                  '&:hover': {
                    backgroundColor: 'primary.dark', // Darken on hover
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default BotsView
