import React from 'react'
import { Container, Grid } from '@mui/material'
import BotComponent from '../components/molecules/BotComponent'

const Help = () => {
  return (
    <Container maxWidth="lg">
      <Grid item xs={12} md={12}>
        <BotComponent />
      </Grid>
    </Container>
  )
}

export default Help
