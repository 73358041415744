// GeojsonMap.js
import React from 'react'
import { Box } from '@mui/material'
import Map, {
  Source,
  Layer,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import config from './../../config.js'

const GeojsonMap = ({ geojsonData }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        marginBottom: 2,
      }}
    >
      <Map
        initialViewState={{
          longitude: -58.37723, // Ajusta según las coordenadas de tu geojsonData
          latitude: -34.61315, // Ajusta según las coordenadas de tu geojsonData
          zoom: 12,
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
        mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
        mapboxAccessToken={config.automatic.mapBox_apiKey}
      >
        <NavigationControl />
        <FullscreenControl />
        <GeolocateControl />
        <ScaleControl />
        {geojsonData && (
          <Source id="my-data" type="geojson" data={geojsonData}>
            <Layer
              id="multipolygon"
              type="fill"
              paint={{
                'fill-color': '#007cbf',
                'fill-opacity': 0.5,
              }}
            />
            <Layer
              id="polygon"
              type="fill"
              paint={{
                'fill-color': '#007cbf',
                'fill-opacity': 0.5,
              }}
            />
            <Layer
              id="line"
              type="line"
              paint={{
                'line-width': 2,
                'line-color': '#007cbf',
              }}
            />
            <Layer
              id="point"
              type="circle"
              paint={{
                'circle-radius': 5,
                'circle-color': '#007cbf',
              }}
            />
          </Source>
        )}
      </Map>
    </Box>
  )
}

export default GeojsonMap
