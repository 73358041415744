import React from 'react'
import { Container, Typography, Box, Button, Grid, Paper } from '@mui/material'

const AboutUs = () => {
  return (
    <Container maxWidth="lg">
      <Box
        my={4}
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Acerca de Nuevoshopping
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Una plataforma de comercio electrónico avanzado con metaverso e inteligencia artificial.
        </Typography>
      </Box>

      <Paper
        elevation={3}
        sx={{
          padding: 3,
          margin: 'auto',
          maxWidth: 900,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" component="h2">
                Autenticación Segura y Gestión de Perfiles
              </Typography>
              <Typography paragraph>
                Regístrate, inicia sesión y recupera tu contraseña con facilidad. Administra los
                ajustes de seguridad de tu equipo a la medida.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" component="h2">
                Gestión de Tiendas Personalizada
              </Typography>
              <Typography paragraph>
                Crea y personaliza tu tienda online, eligiendo cada detalle que refleje tu marca.
                Utiliza herramientas intuitivas y accesibles para la administración.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" component="h2">
                Explora el Metaverso
              </Typography>
              <Typography paragraph>
                Sumérgete en experiencias de realidad virtual y aumentada únicas. Gestiona una
                colección de artículos tridimensionales para tu tienda o entretenimiento personal.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" component="h2" gutterBottom>
                Únete a Nosotros
              </Typography>
              <Typography paragraph>
                Descubre un ecosistema donde tu negocio puede crecer, innovar y conectar con
                clientes de maneras nunca antes vistas.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box my={4} textAlign="center">
        <Button variant="contained" color="primary" size="large">
          Comienza ahora
        </Button>
      </Box>
    </Container>
  )
}

export default AboutUs
