import * as React from 'react'
import { Us, Es, Br, De, Fr, It, Il } from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import './LangSelector.css'

const LangSelector = props => {
  const { className } = props
  const { t } = useTranslation()

  const changeLang = lng => {
    changeLanguage(lng)
  }

  return (
    <div className={'lang-container ' + className}>
      {props.showText && <div>{t('settings.chooseLang')}</div>}
      <div className="lang">
        <span onClick={() => changeLang('es')}>
          <Es /> ES
        </span>
        <span onClick={() => changeLang('en')}>
          <Us /> EN
        </span>

        {false && (
          <>
            <span onClick={() => changeLang('br')}>
              <Br /> BR
            </span>
            <span onClick={() => changeLang('de')}>
              <De /> DE
            </span>
            {false && (
              <>
                <span onClick={() => changeLang('fr')}>
                  <Fr /> FR
                </span>
                <span onClick={() => changeLang('it')}>
                  <It /> IT
                </span>
              </>
            )}

            <span onClick={() => changeLang('il')}>
              <Il /> IL
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default LangSelector
