// src/components/ProfilePictureEditor.js

import React, { useState, useRef } from 'react';
import { supabase } from './../../supabaseClient';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Button,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import { useStore } from './../../StoreContext'

const ProfilePictureEditor = ({ userId, profilePictureUrl, onUpdateProfilePicture, editable }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { userProfile} = useStore()

  const fileInputRef = useRef(null);

  const handleEditAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleChangeImage = () => {
    handleEditAvatarClose();
    fileInputRef.current.click();
  };

  const handleDeleteImage = async () => {
    handleEditAvatarClose();
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({ profile_picture_url: null })
        .eq('id', userId);

      if (error) throw error;

      onUpdateProfilePicture(null);
    } catch (error) {
      console.error('Error deleting image:', error.message);
    }
  };

  const handleZoomImage = () => {
    handleEditAvatarClose();
    setImageSrc(profilePictureUrl);
    setOpenEditDialog(true);
  };

  const handleCenterImage = () => {
    handleEditAvatarClose();
    setImageSrc(profilePictureUrl);
    setOpenEditDialog(true);
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setOpenEditDialog(true);
      });
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error reading file:', error.message);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    try {
      setUploading(true);
      const croppedImageBlob = await getCroppedImage(imageSrc, croppedAreaPixels);

      const fileName = `${userId}.jpg`;
      const filePath = `${userProfile.user_id}/${fileName}`

      let { error: uploadError } = await supabase.storage
        .from('profiles')
        .upload(filePath, croppedImageBlob, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl }, error: urlError } = supabase.storage
        .from('profiles')
        .getPublicUrl(filePath);

      if (urlError) throw urlError;

      const updates = {
        profile_picture_url: publicUrl,
      };

      const { error: updateError } = await supabase
        .from('user_profiles')
        .update(updates)
        .eq('id', userId);

      if (updateError) throw updateError;

      onUpdateProfilePicture(publicUrl);

      setOpenEditDialog(false);
      setUploading(false);
    } catch (error) {
      console.error('Error saving cropped image:', error.message);
      setUploading(false);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const getCroppedImage = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, 'image/jpeg');
    });
  };

  return (
    <>
      <Box position="relative">
        <Avatar
          alt="Foto de Perfil"
          src={profilePictureUrl}
          sx={{
            width: 100,
            height: 100,
            border: '2px solid white',
          }}
        />
        {editable && (
          <>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                bgcolor: 'rgba(0,0,0,0.6)',
                borderRadius: '50%',
              }}
            >
              <IconButton
                color="primary"
                onClick={handleEditAvatarClick}
                sx={{
                  color: 'white',
                  p: 0.5,
                }}
              >
                {uploading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Edit />
                )}
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleEditAvatarClose}
            >
              <MenuItem onClick={handleChangeImage}>Cambiar imagen</MenuItem>
              <MenuItem onClick={handleDeleteImage}>Eliminar imagen</MenuItem>
              <MenuItem onClick={handleZoomImage}>Zoom</MenuItem>
              <MenuItem onClick={handleCenterImage}>Centrar imagen</MenuItem>
            </Menu>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </>
        )}
      </Box>

      {/* Diálogo para editar imagen */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Editar imagen</DialogTitle>
        <DialogContent>
          {imageSrc ? (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: 400,
                backgroundColor: '#333',
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </Box>
          ) : (
            <Typography>No se ha seleccionado una imagen</Typography>
          )}
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, value) => setZoom(value)}
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancelar</Button>
          <Button onClick={handleCropSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePictureEditor;
