// src/components/BlockedModal.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from './../supabaseClient'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

const BlockedModal = () => {
  const navigate = useNavigate()

  const handleLogout = async () => {
    // Lógica para cerrar la sesión del usuario
    await supabase.auth.signOut()
    window.location.href = '/'
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Acceso Restringido</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tu cuenta ha sido bloqueada. Por favor, contacta al soporte para más información.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} color="primary">
          Cerrar Sesión
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BlockedModal
