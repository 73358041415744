import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from './../StoreContext'
import { supabase } from './../supabaseClient'
import { Menu, MenuItem, Typography, Divider, ListItemIcon } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import CloudIcon from '@mui/icons-material/Cloud'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import StarIcon from '@mui/icons-material/Star'
import { useTranslation } from 'react-i18next'

const UserMenu = ({ anchorEl, open, handleClose }) => {
  const navigate = useNavigate()
  const { user } = useStore()
  const { t } = useTranslation()

  const handleLogout = async () => {
    await supabase.auth.signOut()
    navigate('/')
    handleClose()
  }

  const getUsernameFromEmail = email => {
    const parts = email.split('@')
    return parts[0]
  }

  if (!user) return null

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem disabled>
        <Typography variant="subtitle1">
          {t('settings.greeting', {
            username: getUsernameFromEmail(user.email),
          })}
        </Typography>
      </MenuItem>
      <Divider />

      <MenuItem onClick={() => navigate('/user/profile')}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.profile')}
      </MenuItem>

      <MenuItem onClick={() => navigate('/cloud/storage')}>
        <ListItemIcon>
          <CloudIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.files')}
      </MenuItem>

      <MenuItem onClick={() => navigate('/keys')}>
        <ListItemIcon>
          <VpnKeyIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.keys')}
      </MenuItem>

      <MenuItem onClick={() => navigate('/devices')}>
        <ListItemIcon>
          <VpnKeyIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.devices')}
      </MenuItem>
      
      <MenuItem onClick={() => navigate('/favorites')}>
        <ListItemIcon>
          <StarIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.favorites')}
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => navigate('/user/settings')}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.settings')}
      </MenuItem>
      <Divider />

      {false && (
        <MenuItem onClick={() => navigate('/club')}>
          <ListItemIcon>
            <CardMembershipIcon fontSize="small" />
          </ListItemIcon>
          {t('settings.membership')}
        </MenuItem>
      )}

      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        {t('settings.logout')}
      </MenuItem>
    </Menu>
  )
}

export default UserMenu
