import React, { useState, useEffect, useRef, useMemo } from 'react'
import debounce from 'lodash.debounce'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Autocomplete,
  Chip,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers'
import 'mapbox-gl/dist/mapbox-gl.css'
import config from './../../config.js'
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  ScaleControl,
} from 'react-map-gl'
import dayjs from 'dayjs'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import QRCode from 'qrcode.react'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete' // Ícono para eliminar imágenes
import { useStore } from './../../StoreContext'

function EventsView({ event_id }) {
  const { userProfile} = useStore()

  const { t } = useTranslation()
  const { eventId } = useParams()
  const navigate = useNavigate()
  const [event, setEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState({
    name: '',
    descripcion: '',
    fecha: dayjs(), // Fecha por defecto hoy
    hora: dayjs(),
    fechaFin: dayjs().add(1, 'day'), // Fecha de Fin por defecto: un día después
    horaFin: dayjs(),
    invited_profile_id: '',
    confirmed_profile_id: '',
    longitude: '',
    latitude: '',
    images: [], // Archivos de Imágenes Seleccionadas
  })
  const [formErrors, setFormErrors] = useState({})
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [imagePreviews, setImagePreviews] = useState([]) // Vistas Previas de Imágenes Seleccionadas
  const [existingImages, setExistingImages] = useState([]) // URLs de Imágenes Existentes
  
  // Ref para el QR Code
  const qrRef = useRef(null)

  // Estados para Autocomplete de perfiles invitados
  const [invitedProfilesOptions, setInvitedProfilesOptions] = useState([])
  const [loadingInvitedProfiles, setLoadingInvitedProfiles] = useState(false)
  const [selectedInvitedProfiles, setSelectedInvitedProfiles] = useState([])

  // Función de búsqueda con debouncing
  const fetchInvitedProfiles = async (input) => {
    if (!input) {
      setInvitedProfilesOptions([])
      return
    }
    
    try {
      setLoadingInvitedProfiles(true)
      const { data, error } = await supabase
        .from('user_profiles')
        .select('id, alias')
        .ilike('alias', `%${input}%`)
        .limit(10)
      
      if (error) throw error

      setInvitedProfilesOptions(data)
    } catch (error) {
      console.error('Error fetching invited profiles:', error.message)
    } finally {
      setLoadingInvitedProfiles(false)
    }
  }

  const debouncedFetchInvitedProfiles = useMemo(
    () => debounce(fetchInvitedProfiles, 300),
    []
  )

  useEffect(() => {
    fetchEvent()
    return () => {
      debouncedFetchInvitedProfiles.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId || event_id])

  const fetchEvent = async () => {
    try {
      setError(null)
      setLoading(true)

      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('id', eventId || event_id)
        .single()

      if (error) throw error

      // Obtener los perfiles invitados
      let invitedProfiles = []
      if (data.invited_profile_id && data.invited_profile_id.length > 0) {
        const { data: profiles, error: profilesError } = await supabase
          .from('user_profiles')
          .select('id, alias')
          .in('id', data.invited_profile_id)

        if (profilesError) throw profilesError
        invitedProfiles = profiles
      }

      setSelectedInvitedProfiles(invitedProfiles)

      // Obtener imágenes asociadas al evento desde Supabase Storage
      const { data: imageData, error: imageError } = await supabase
        .storage
        .from('events') // **Cambiar a 'events'**
        .list(`${data.id}/`, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })

      if (imageError && imageError.code !== 'PGRST116') { // 'PGRST116' es código para "No hay archivos"
        throw imageError
      }

      const imageUrls = imageData?.map(file => supabase.storage.from('events').getPublicUrl(`${data.id}/${file.name}`).data.publicUrl) || []
      setExistingImages(imageUrls)

      setEvent(data)
      setFormValues({
        name: data.name || '',
        descripcion: data.descripcion || '',
        fecha: data.fecha ? dayjs(data.fecha) : dayjs(),
        hora: data.hora ? dayjs(`1970-01-01T${data.hora}`) : dayjs(),
        fechaFin: data.fecha_fin ? dayjs(data.fecha_fin) : dayjs().add(1, 'day'), // Fecha de Fin
        horaFin: data.hora_fin ? dayjs(`1970-01-01T${data.hora_fin}`) : dayjs(),
        invited_profile_id: data.invited_profile_id
          ? data.invited_profile_id.join(', ')
          : '',
        confirmed_profile_id: data.confirmed_profile_id
          ? data.confirmed_profile_id.join(', ')
          : '',
        longitude: data.longitude ? data.longitude.toString() : '',
        latitude: data.latitude ? data.latitude.toString() : '',
        images: [], // Inicializar como vacío; imágenes existentes ya se cargan en existingImages
      })
      setImagePreviews([]) // Limpiar vistas previas de imágenes seleccionadas
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }))
  }

  const handleDateChange = (date) => {
    setFormValues((prevState) => ({
      ...prevState,
      fecha: date,
      // Actualizar fechaFin si es antes de la nueva fecha
      fechaFin: dayjs(date).add(1, 'day'),
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      fecha: '',
      fechaFin: '',
    }))
  }

  const handleTimeChange = (time) => {
    setFormValues((prevState) => ({
      ...prevState,
      hora: time,
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      hora: '',
    }))
  }

  // Nuevas Funciones para Fecha y Hora de Fin
  const handleFechaFinChange = (date) => {
    setFormValues((prevState) => ({
      ...prevState,
      fechaFin: date,
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      fechaFin: '',
    }))
  }

  const handleHoraFinChange = (time) => {
    setFormValues((prevState) => ({
      ...prevState,
      horaFin: time,
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      horaFin: '',
    }))
  }

  // Funciones para Manejar Imágenes
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    setFormValues((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],
    }))

    // Generar vistas previas
    const previews = files.map(file => URL.createObjectURL(file))
    setImagePreviews(prev => [...prev, ...previews])

    // Limpiar el input
    e.target.value = null
  }

  const handleRemoveImage = (index) => {
    setFormValues((prevState) => {
      const newImages = [...prevState.images]
      newImages.splice(index, 1)
      return { ...prevState, images: newImages }
    })
    setImagePreviews((prevState) => {
      const newPreviews = [...prevState]
      newPreviews.splice(index, 1)
      return newPreviews
    })
  }

  const validateForm = () => {
    const errors = {}
    const today = dayjs().startOf('day')

    if (!formValues.name.trim()) {
      errors.name = t('nameRequired') || 'El nombre es obligatorio.'
    }
    if (!formValues.descripcion.trim()) {
      errors.descripcion = t('descriptionRequired') || 'La descripción es obligatoria.'
    }
    if (!formValues.fecha) {
      errors.fecha = t('dateRequired') || 'La fecha es obligatoria.'
    } else if (formValues.fecha.isBefore(today, 'day')) {
      errors.fecha = t('dateCannotBePast') || 'La fecha no puede ser anterior a hoy.'
    }
    if (!formValues.hora) {
      errors.hora = t('timeRequired') || 'La hora es obligatoria.'
    } else if (
      formValues.fecha &&
      formValues.fecha.isSame(today, 'day') &&
      formValues.hora.isBefore(dayjs(), 'minute')
    ) {
      errors.hora = t('timeCannotBePast') || 'La hora no puede ser anterior a la actual.'
    }

    // Validación de Fecha y Hora de Fin
    if (!formValues.fechaFin) {
      errors.fechaFin = t('endDateRequired') || 'La fecha de fin es obligatoria.'
    } else if (formValues.fechaFin.isBefore(today, 'day')) {
      errors.fechaFin = t('endDateCannotBePast') || 'La fecha de fin no puede ser anterior a hoy.'
    }

    if (!formValues.horaFin) {
      errors.horaFin = t('endTimeRequired') || 'La hora de fin es obligatoria.'
    } else if (
      formValues.fechaFin &&
      formValues.fechaFin.isSame(formValues.fecha, 'day') &&
      formValues.horaFin.isBefore(formValues.hora, 'minute')
    ) {
      errors.horaFin = t('endTimeCannotBeBeforeStartTime') || 'La hora de fin no puede ser anterior a la hora de inicio.'
    }

    // Asegurar que Fecha de Fin sea después de Fecha de Inicio
    if (formValues.fecha && formValues.fechaFin && formValues.fechaFin.isBefore(formValues.fecha, 'day')) {
      errors.fechaFin = t('endDateMustBeAfterStartDate') || 'La fecha de fin debe ser posterior a la fecha de inicio.'
    }

    if (formValues.invited_profile_id) {
      const ids = formValues.invited_profile_id.split(',').map(id => id.trim())
      const invalidIds = ids.filter(id => !isValidUUID(id))
      if (invalidIds.length > 0) {
        errors.invited_profile_id = t('invitedIdsInvalid') || 'Algunos IDs de invitados son inválidos.'
      }
    }

    if (formValues.longitude && isNaN(formValues.longitude)) {
      errors.longitude = t('longitudeInvalid') || 'La longitud debe ser un número.'
    }
    if (formValues.latitude && isNaN(formValues.latitude)) {
      errors.latitude = t('latitudeInvalid') || 'La latitud debe ser un número.'
    }

    return errors
  }

  // Función para validar UUID (simplificada)
  const isValidUUID = (uuid) => {
    // Puedes implementar una validación más estricta si es necesario
    return true
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return uuidRegex.test(uuid)
  }

  const handleSave = async () => {
    const errors = validateForm()
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
  
    try {
      setError(null)
      setLoading(true)
  
      // Convertir los strings de IDs en arrays
      const invitedIds = formValues.invited_profile_id
        ? formValues.invited_profile_id.split(',').map(id => id.trim())
        : []
      const confirmedIds = formValues.confirmed_profile_id
        ? formValues.confirmed_profile_id.split(',').map(id => id.trim())
        : []
  
      // Subir imágenes a Supabase Storage
      const uploadedImageUrls = []
      if (formValues.images.length > 0) {
        for (const image of formValues.images) {
          const fileExt = image.name.split('.').pop()
          const filePath = `${userProfile.user_id}/${event.id}}.${fileExt}`
  
          const { error: uploadError } = await supabase
            .storage
            .from('events') // Bucket 'events'
            .upload(filePath, image)
  
          if (uploadError) {
            throw uploadError
          }
  
          const { data: publicData } = supabase
            .storage
            .from('events')
            .getPublicUrl(filePath)
  
          if (publicData) {
            uploadedImageUrls.push(publicData.publicUrl)
          }
        }
      }
  
      // Combinar URLs de imágenes existentes con las nuevas
      const allImageUrls = [...existingImages, ...uploadedImageUrls]
  
      const normalizedValues = {
        name: formValues.name.trim(),
        descripcion: formValues.descripcion.trim(),
        fecha: formValues.fecha ? formValues.fecha.format('YYYY-MM-DD') : null,
        hora: formValues.hora ? formValues.hora.format('HH:mm') : null,
        fecha_fin: formValues.fechaFin ? formValues.fechaFin.format('YYYY-MM-DD') : null,
        hora_fin: formValues.horaFin ? formValues.horaFin.format('HH:mm') : null,
        invited_profile_id: invitedIds,
        confirmed_profile_id: confirmedIds,
        longitude: formValues.longitude ? parseFloat(formValues.longitude) : null,
        latitude: formValues.latitude ? parseFloat(formValues.latitude) : null,
      }
  
      const { error } = await supabase
        .from('events')
        .update(normalizedValues)
        .eq('id', eventId || event_id)
  
      if (error) throw error
  
      setSuccessMessage(t('eventUpdatedSuccessfully') || 'Evento actualizado con éxito.')
      setIsDialogOpen(false)
      fetchEvent()
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }
  

  const handleMapClick = (event) => {
    const { lng, lat } = event.lngLat
    setFormValues((prevState) => ({
      ...prevState,
      longitude: lng.toFixed(6),
      latitude: lat.toFixed(6),
    }))
    setFormErrors((prevState) => ({
      ...prevState,
      longitude: '',
      latitude: '',
    }))
  }

  const handleCancel = () => {
    navigate('/events')
  }

  // Datos del Bot (hardcoded con id 93)
  const bot = {
    id: 93,
    name: 'StoreBot',
    number: '59899321629', // Reemplaza con el número real del bot
    profilePicture: 'https://yourdomain.com/path_to_bot_avatar.jpg', // Reemplaza con la URL real de la imagen del bot
  }

  // Generar el enlace para el bot
  const botLink = `https://wa.me/${bot.number}` // Enlace de WhatsApp para el bot

  // Función para descargar el QR como PNG
  const downloadQR = () => {
    const canvas = qrRef.current.querySelector('canvas')
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `${bot.name}_QR.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } else {
      // Manejar caso donde el QR no está en un canvas (por ejemplo, SVG)
      const svg = qrRef.current.querySelector('svg')
      if (svg) {
        const serializer = new XMLSerializer()
        const svgString = serializer.serializeToString(svg)
        const canvasElement = document.createElement('canvas')
        const img = new Image()
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)

        img.onload = () => {
          canvasElement.width = img.width
          canvasElement.height = img.height
          const ctx = canvasElement.getContext('2d')
          ctx.drawImage(img, 0, 0)
          URL.revokeObjectURL(url)
          const pngUrlFinal = canvasElement.toDataURL('image/png')
          const downloadLinkFinal = document.createElement('a')
          downloadLinkFinal.href = pngUrlFinal
          downloadLinkFinal.download = `${bot.name}_QR.png`
          document.body.appendChild(downloadLinkFinal)
          downloadLinkFinal.click()
          document.body.removeChild(downloadLinkFinal)
        }
        img.src = url
      }
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              color: 'primary.main',
              fontWeight: 'bold',
            }}
          >
            {t('eventDetails') || 'Detalles del Evento'}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title={t('goBack') || 'Volver'}>
            <IconButton onClick={handleCancel} color="secondary">
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3}>
            {/* Nombre del Evento */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('name') || 'Nombre'}
                name="name"
                variant="outlined"
                fullWidth
                value={formValues.name}
                onChange={handleInputChange}
                error={Boolean(formErrors.name)}
                helperText={
                  formErrors.name ||
                  (t('nameHelper') || 'Ingrese el nombre del evento.')
                }
                required
              />
            </Grid>
            {/* Descripción del Evento */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('description') || 'Descripción'}
                name="descripcion"
                variant="outlined"
                fullWidth
                value={formValues.descripcion}
                onChange={handleInputChange}
                error={Boolean(formErrors.descripcion)}
                helperText={
                  formErrors.descripcion ||
                  (t('descriptionHelper') || 'Ingrese una breve descripción del evento.')
                }
                required
              />
            </Grid>
            {/* Fecha del Evento */}
            <Grid item xs={12} md={6}>
              <DatePicker
                label={t('date') || 'Fecha'}
                value={formValues.fecha}
                onChange={handleDateChange}
                disablePast
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(formErrors.fecha)}
                    helperText={
                      formErrors.fecha ||
                      (t('dateHelper') || 'Seleccione la fecha en que se realizará el evento.')
                    }
                    required
                  />
                )}
              />
            </Grid>
            {/* Hora del Evento */}
            <Grid item xs={12} md={6}>
              <TimePicker
                label={t('time') || 'Hora'}
                value={formValues.hora}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(formErrors.hora)}
                    helperText={
                      formErrors.hora ||
                      (t('timeHelper') || 'Seleccione la hora en que se realizará el evento.')
                    }
                    required
                  />
                )}
              />
            </Grid>

            {/* Nuevos Campos: Fecha de Fin y Hora de Fin */}
            {/* Fecha de Fin del Evento */}
            <Grid item xs={12} md={6}>
              <DatePicker
                label={t('endDate') || 'Fecha de Fin'}
                value={formValues.fechaFin}
                onChange={handleFechaFinChange}
                disablePast
                minDate={formValues.fecha || dayjs()} // Asegura que fechaFin no sea antes de fecha
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(formErrors.fechaFin)}
                    helperText={
                      formErrors.fechaFin ||
                      (t('endDateHelper') || 'Seleccione la fecha de fin del evento.')
                    }
                    required
                  />
                )}
              />
            </Grid>
            {/* Hora de Fin del Evento */}
            <Grid item xs={12} md={6}>
              <TimePicker
                label={t('endTime') || 'Hora de Fin'}
                value={formValues.horaFin}
                onChange={handleHoraFinChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(formErrors.horaFin)}
                    helperText={
                      formErrors.horaFin ||
                      (t('endTimeHelper') || 'Seleccione la hora de fin del evento.')
                    }
                    required
                  />
                )}
              />
            </Grid>

            {/* Campo para Subir Imágenes */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t('eventImages') || 'Imágenes del Evento'}
              </Typography>
              <Button
                variant="contained"
                component="label"
                startIcon={<DownloadIcon />}
              >
                {t('uploadImages') || 'Subir Imágenes'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                />
              </Button>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {t('imageHelper') || 'Puedes subir una o varias imágenes para el evento.'}
              </Typography>
              {/* Mostrar Vistas Previas de Imágenes Seleccionadas */}
              {imagePreviews.length > 0 && (
                <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                  {imagePreviews.map((src, index) => (
                    <Box key={index} position="relative">
                      <img src={src} alt={`Preview ${index}`} width={100} height={100} style={{ objectFit: 'cover', borderRadius: 8 }} />
                      <IconButton
                        size="small"
                        sx={{ position: 'absolute', top: 0, right: 0, bgcolor: 'rgba(255,255,255,0.7)' }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
              {/* Mostrar Imágenes Existentes */}
              {existingImages.length > 0 && (
                <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                  {existingImages.map((url, index) => (
                    <Box key={index} position="relative">
                      <img src={url} alt={`Existing ${index}`} width={100} height={100} style={{ objectFit: 'cover', borderRadius: 8 }} />
                      {/* Opcional: Botón para eliminar imágenes existentes */}
                      {/* Puedes implementar la eliminación si es necesario */}
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>

            {/* IDs de Invitados con Autocomplete */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                options={invitedProfilesOptions}
                getOptionLabel={(option) => option.alias}
                filterSelectedOptions
                loading={loadingInvitedProfiles}
                value={selectedInvitedProfiles}
                onInputChange={(event, value) => {
                  debouncedFetchInvitedProfiles(value)
                }}
                onChange={(event, value) => {
                  setSelectedInvitedProfiles(value)
                  setFormValues((prevState) => ({
                    ...prevState,
                    invited_profile_id: value.map(profile => profile.id).join(', '),
                  }))
                  setFormErrors((prevState) => ({
                    ...prevState,
                    invited_profile_id: '',
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('invitedProfiles') || 'Perfiles Invitados'}
                    variant="outlined"
                    placeholder="Seleccione perfiles invitados"
                    error={Boolean(formErrors.invited_profile_id)}
                    helperText={
                      formErrors.invited_profile_id ||
                      (t('invitedProfilesHelper') ||
                        'Seleccione hasta 10 perfiles invitados.')
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingInvitedProfiles ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                limitTags={10}
                noOptionsText={t('noProfilesFound') || 'No se encontraron perfiles'}
              />
            </Grid>
            {/* IDs de Confirmados */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('confirmedProfiles') || 'IDs de Confirmados'}
                name="confirmed_profile_id"
                variant="outlined"
                fullWidth
                value={formValues.confirmed_profile_id}
                onChange={handleInputChange}
                error={Boolean(formErrors.confirmed_profile_id)}
                helperText={
                  formErrors.confirmed_profile_id ||
                  (t('confirmedProfilesHelper') ||
                    'Ingrese los IDs de confirmados separados por comas.')
                }
                placeholder="e.g., id1, id2, id3"
              />
            </Grid>
            {/* Longitud */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('longitude') || 'Longitud'}
                name="longitude"
                variant="outlined"
                fullWidth
                value={formValues.longitude}
                onChange={handleInputChange}
                error={Boolean(formErrors.longitude)}
                helperText={
                  formErrors.longitude ||
                  (t('longitudeHelper') || 'Ingrese la longitud geográfica del evento.')
                }
                type="number"
                inputProps={{ step: '0.000001' }}
              />
            </Grid>
            {/* Latitud */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('latitude') || 'Latitud'}
                name="latitude"
                variant="outlined"
                fullWidth
                value={formValues.latitude}
                onChange={handleInputChange}
                error={Boolean(formErrors.latitude)}
                helperText={
                  formErrors.latitude ||
                  (t('latitudeHelper') || 'Ingrese la latitud geográfica del evento.')
                }
                type="number"
                inputProps={{ step: '0.000001' }}
              />
            </Grid>
            {/* Mapa de Ubicación */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('location') || 'Ubicación'}
              </Typography>
              <Box
                sx={{
                  height: { xs: '300px', md: '400px' },
                  borderRadius: 2,
                  overflow: 'hidden',
                  boxShadow: 3,
                }}
              >
                <Map
                  initialViewState={{
                    longitude:
                      formValues.longitude !== '' ? parseFloat(formValues.longitude) : -100.486052,
                    latitude:
                      formValues.latitude !== '' ? parseFloat(formValues.latitude) : 37.830348,
                    zoom: 3.5,
                  }}
                  style={{ width: '100%', height: '100%' }}
                  mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
                  mapboxAccessToken={config.automatic.mapBox_apiKey}
                  onClick={handleMapClick}
                >
                  <GeolocateControl position="top-left" />
                  <FullscreenControl position="top-left" />
                  <NavigationControl position="top-left" />
                  <ScaleControl />
                  {formValues.longitude !== '' && formValues.latitude !== '' && (
                    <Marker
                      longitude={parseFloat(formValues.longitude)}
                      latitude={parseFloat(formValues.latitude)}
                      anchor="bottom"
                      draggable
                      onDragEnd={(e) => {
                        setFormValues((prevState) => ({
                          ...prevState,
                          longitude: e.lngLat.lng.toFixed(6),
                          latitude: e.lngLat.lat.toFixed(6),
                        }))
                      }}
                    />
                  )}
                </Map>
              </Box>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {t('clickOnMapToSelectLocation') || 'Haz clic en el mapa para seleccionar la ubicación.'}
              </Typography>
            </Grid>

            {/* Sección para el QR Code del Bot Asociado a la Tienda */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                {t('chatWithBot') || 'Chatea con el Bot de la Tienda'}
              </Typography>
              <Box
                ref={qrRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 2,
                }}
              >
                <QRCode value={botLink} size={200} includeMargin={true} />
                <Typography variant="body1" mt={2}>
                  {t('scanToChat') || 'Escanea el código QR para chatear con el bot'}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={downloadQR}
                  sx={{ mt: 2 }}
                >
                  {t('downloadQR') || 'Descargar QR'}
                </Button>
              </Box>
            </Grid>

            {/* Botones para Guardar */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                >
                  {t('cancel') || 'Cancelar'}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={() => setIsDialogOpen(true)}
                >
                  {t('save') || 'Guardar'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>

      {/* Diálogo de Confirmación */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>{t('confirmSave') || 'Confirmar Guardado'}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('areYouSureYouWantToSave') ||
              '¿Estás seguro de que deseas guardar los cambios realizados en el evento?'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            {t('no') || 'No'}
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            {t('yes') || 'Sí'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EventsView
