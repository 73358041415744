import React, { useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { IconComponent } from './IconComponent'
import { useLocation } from 'react-router-dom'
import { useStore } from './../../StoreContext' // Import the useStore hook
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info' // Importa el icono de información

const TitleFeature = ({ isStaticMenu, setIsStaticMenu }) => {
  const [playingAudio, setPlayingAudio] = useState(null)
  const [expanded, setExpanded] = useState(false) // Estado para controlar la expansión
  const location = useLocation()
  const { user, setMenuOption, menuOption } = useStore()
  const { t } = useTranslation()

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      {menuOption && (
        <Box
          className={!isStaticMenu ? '' : 'block'}
          style={{
            margin: '3px',
          }}
          sx={{
            display: 'flex',
            justifyContent: 'center', // Centra horizontalmente
            alignItems: 'center',
            width: '100%', // Asegura que ocupe todo el ancho disponible
          }}
        >
          <Box
            sx={{
              padding: '20px', // Aumenta el padding para mejor espaciado
              backgroundColor: 'transparent', // Fondo transparente
              display: 'flex',
              flexDirection: 'column', // Disposición vertical
              alignItems: 'center', // Centra los elementos internamente
              position: 'relative', // Para posicionar el IconButton si es necesario
            }}
            className="tiles_feature"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                component="div"
                color="white"
                sx={{
                  fontWeight: 'bold',
                  fontSize: 25,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t(menuOption.name)}{' '}
                <IconComponent
                  style={{
                    backgroundColor: menuOption.color,
                    zIndex: 4,
                    marginLeft: '8px', // Espacio entre el texto y el icono
                  }}
                  size={40} // Tamaño del icono
                  iconName={menuOption.icon}
                />
              </Typography>

              <IconButton
                onClick={handleToggleExpand}
                sx={{
                  marginLeft: 'auto', // Empuja el botón al final del contenedor flex
                  color: 'white',
                  backgroundColor: 'transparent',
                }}
              >
                <InfoIcon /> {/* Icono de información */}
              </IconButton>
            </Box>

            {expanded && (
              <Box
                sx={{
                  marginTop: '15px', // Espacio superior
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    marginBottom: '10px', // Espacio inferior
                  }}
                  color="white"
                >
                  {menuOption.description}
                </Typography>

                {menuOption.disabled && (
                  <Typography variant="h4" color="white">
                    {t('general.not_available')}
                  </Typography>
                )}

                <Typography color="white">{menuOption.description2}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default TitleFeature
