import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material'
import StoreIcon from '@mui/icons-material/Store'
import AddIcon from '@mui/icons-material/Add'
import { supabase } from './../../supabaseClient'
import CreateStore from './CreateStore.js'

const StoreList = () => {
  const [stores, setStores] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [newStoreName, setNewStoreName] = useState('')
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' })
  const navigate = useNavigate()

  const fetchStores = async () => {
    const { data, error } = await supabase.from('stores').select('*')

    if (error) {
      setSnackbar({ open: true, message: 'Error al cargar las tiendas', severity: 'error' })
    } else {
      setStores(data)
    }
  }
  useEffect(() => {
    fetchStores()
  }, [])

  const filteredStores = stores.filter(store =>
    store.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true)
  }

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false)
    setNewStoreName('')
  }

  const handleCreateStore = async () => {
    // Crear una nueva tienda en Supabase
    const { data, error } = await supabase
      .from('stores') // Asegúrate de que este sea el nombre correcto de tu tabla en Supabase
      .insert([{ name: newStoreName }])

    if (error) {
      setSnackbar({ open: true, message: 'Error al crear la tienda', severity: 'error' })
    } else {
      setStores([...stores, ...data])
      setSnackbar({ open: true, message: 'Tienda creada con éxito', severity: 'success' })
      handleCloseAddDialog()
    }
  }

  return (
    <Box p={4} sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
            Tus Tiendas
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Buscar Tiendas"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item>
          <Card sx={{ width: '130px', cursor: 'pointer' }} onClick={handleOpenAddDialog}>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}>
                  <AddIcon />
                </Avatar>
              </Box>
              <Typography variant="h6" align="center">
                Crear Tienda
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {filteredStores.map(store => (
          <Grid item key={store.id}>
            <Card
              sx={{ width: '130px', cursor: 'pointer' }}
              onClick={() => navigate(`/stores/${store.id}`)}
            >
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                  <Avatar sx={{ width: 56, height: 56, marginRight: 2 }}>
                    <StoreIcon />
                  </Avatar>
                </Box>
                <Typography variant="h6" align="center">
                  {store.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        PaperProps={{ backGroundColor: 'transparent' }}
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <CreateStore handleCloseAddDialog={handleCloseAddDialog} fetchStores={fetchStores} />
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default StoreList
