import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  TextField,
  Modal,
  Button,
  Paper,
} from '@mui/material'
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer'
import { useStore } from './../../StoreContext'
import CustomNodeComponent from './CustomNodeComponent'
import { supabase } from './../../supabaseClient'
import { v4 as uuidv4 } from 'uuid'

import 'react-flow-renderer/dist/style.css'
import 'react-flow-renderer/dist/theme-default.css'

const Automation = () => {
  const { userStores, userBots } = useStore()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  // Hooks para manejar el estado de nodos y aristas
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pendingEdge, setPendingEdge] = useState(null)

  const reactFlowWrapper = useRef(null) // Referencia al contenedor de ReactFlow

  useEffect(() => {
    setLoading(true)
    // Obtener nodos desde Supabase
    const fetchNodes = async () => {
      try {
        const { data: nodesData, error } = await supabase.from('nodos').select('*')
        if (error) {
          throw new Error(error.message)
        }
        const mappedNodes = nodesData
          .filter(node => node.posicion_x !== 0 || node.posicion_y !== 0) // Filtrar nodos con posición (0, 0)
          .map(node => ({
            id: node.id.toString(),
            data: {
              label: node.nombre,
            },
            position: {
              x: node.posicion_x,
              y: node.posicion_y,
            },
          }))
        setNodes(mappedNodes)
        setLoading(false)
      } catch (error) {
        setError(error.message)
        setLoading(false)
      }
    }

    fetchNodes()
    // Obtener bordes desde Supabase
    const fetchEdges = async () => {
      try {
        const { data: edgesData, error } = await supabase.from('edges').select('*')
        if (error) {
          throw new Error(error.message)
        }
        const mappedEdges = edgesData.map(edge => ({
          id: edge.id.toString(),
          source: edge.nodo_inicio_id.toString(),
          target: edge.nodo_fin_id.toString(),
          animated: true,
        }))
        setEdges(mappedEdges)
      } catch (error) {
        setError(error.message)
      }
    }

    fetchEdges()
  }, [])

  const saveNodesToSupabase = async nodesToSave => {
    try {
      const formattedNodes = nodesToSave
        .filter(node => node.position.x !== 0 || node.position.y !== 0) // Filtrar nodos con posición (0, 0)
        .map(node => ({
          id: parseInt(node.id),
          nombre: node.data.label,
          posicion_x: node.position.x,
          posicion_y: node.position.y,
        }))
      await supabase.from('nodos').upsert(formattedNodes, {
        returning: 'minimal',
      })
    } catch (error) {
      console.error('Error al guardar nodos:', error.message)
    }
  }

  const saveEdgesToSupabase = async edgesToSave => {
    try {
      const formattedEdges = edgesToSave.map(edge => ({
        id: parseInt(edge.id), // Usar ID generado automáticamente
        nodo_inicio_id: parseInt(edge.source),
        nodo_fin_id: parseInt(edge.target),
        peso: 1, // Ejemplo: asigna un peso por defecto para el borde
      }))
      await supabase.from('edges').upsert(formattedEdges, {
        returning: 'minimal',
      })
    } catch (error) {
      console.error('Error al guardar bordes:', error.message)
    }
  }

  useEffect(() => {
    if (nodes.length > 0) {
      saveNodesToSupabase(nodes)
    }
  }, [nodes])

  useEffect(() => {
    if (edges.length > 0) {
      saveEdgesToSupabase(edges)
    }
  }, [edges])

  useEffect(() => {
    if (userBots && userStores) {
      // Una vez que los nodos se han cargado desde Supabase, actualizamos sus datos con los bots y las tiendas si no están en Supabase.
      const existingNodeIds = new Set(nodes.map(node => node.id))

      const botNodes = userBots.map(bot => ({
        id: `${bot.id}`,
        type: 'customNode',
        data: {
          label: bot.name,
          icon: 'GPTBotsIcon',
          background: '#21cc35',
          type: 'bot',
        },
        position: existingNodeIds.has(`${bot.id}`)
          ? nodes.find(node => node.id === `${bot.id}`).position
          : {
              x: 100,
              y: 100,
            }, // Si existe, usa la posición de Supabase; si no, usa { x: 100, y: 100 }
      }))

      const storeNodes = userStores.map(store => ({
        id: `${store.id}`,
        type: 'customNode',
        data: {
          label: store.name,
          icon: 'StoreIcon',
          background: '#cc6807',
          type: 'store',
        },
        position: existingNodeIds.has(`${store.id}`)
          ? nodes.find(node => node.id === `${store.id}`).position
          : {
              x: 100,
              y: 100,
            }, // Si existe, usa la posición de Supabase; si no, usa { x: 100, y: 100 }
      }))

      setNodes([...botNodes, ...storeNodes])
    }
  }, [userBots, userStores])

  useEffect(() => {
    // Función para ajustar el zoom para que todos los nodos sean visibles
    const fitView = () => {
      if (reactFlowWrapper.current) {
        const reactFlowInstance = reactFlowWrapper.current.getElements()
        reactFlowInstance.fitView()
      }
    }

    // Ajusta la vista una vez que se actualizan los nodos
    fitView()
  }, [nodes])

  const onConnect = params => {
    const { source, target } = params
    const sourceNode = nodes.find(node => node.id === source)
    const targetNode = nodes.find(node => node.id === target)

    if (
      sourceNode &&
      targetNode &&
      ((sourceNode.data.type === 'bot' && targetNode.data.type === 'store') ||
        (sourceNode.data.type === 'store' && targetNode.data.type === 'bot'))
    ) {
      setPendingEdge({
        source,
        target,
      })
      setIsModalOpen(true)
    } else {
      const newEdge = {
        id: uuidv4(), // Generar un ID único usando uuid
        source,
        target,
        animated: true,
      }
      setEdges(eds => {
        const updatedEdges = addEdge(newEdge, eds)
        saveEdgesToSupabase(updatedEdges)
        return updatedEdges
      })
    }
  }

  const onNodeDragStop = (event, node) => {
    setNodes(nds => {
      const updatedNodes = nds.map(n => (n.id === node.id ? node : n))
      saveNodesToSupabase(updatedNodes)
      return updatedNodes
    })
  }

  const handleConfirm = async () => {
    if (pendingEdge) {
      const newEdge = {
        id: uuidv4(), // Generar un ID único usando uuid
        source: pendingEdge.source,
        target: pendingEdge.target,
        animated: true,
      }
      setEdges(eds => {
        const updatedEdges = addEdge(newEdge, eds)
        saveEdgesToSupabase(updatedEdges)
        return updatedEdges
      })
      setPendingEdge(null)
      setIsModalOpen(false)

      // Aquí puedes añadir la lógica para vincular la información de la tienda con el contexto del bot
      // Ejemplo: llamar a una función para vincular la información
      // linkStoreToBot(pendingEdge.source, pendingEdge.target);
    }
  }

  const handleCancel = () => {
    setPendingEdge(null)
    setIsModalOpen(false)
  }

  const nodeTypes = useMemo(
    () => ({
      customNode: CustomNodeComponent,
    }),
    []
  )

  const handleEdgesChange = changedEdges => {
    setEdges(changedEdges)
    saveEdgesToSupabase(changedEdges)
  }

  const fitViewOptions = {
    padding: 10, // Espacio de relleno alrededor de los nodos (opcional)
    includeHiddenNodes: false, // Incluir nodos ocultos en el ajuste (opcional)
    minZoom: 0.5, // Zoom mínimo permitido
    maxZoom: 1.5, // Zoom máximo permitido
    duration: 3, // Duración de la animación en segundos
  }

  return (
    <Box
      p={4}
      sx={{
        minHeight: '100vh',
      }}
    >
      <Typography color={'white'} variant="h4" component="h1" gutterBottom>
        Gestión de Procesos Dinámicos
      </Typography>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <TextField
          label="Buscar"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Box>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <Box
          style={{
            height: '600px',
            width: '100%',
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            duration={2}
            fitView={fitViewOptions}
            onNodesChange={onNodesChange}
            onEdgesChange={handleEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            deleteKeyCode={46} // 'delete'-key
            snapToGrid={true} // Opcional: activar cuadrícula
            onNodeDragStop={onNodeDragStop}
          >
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
        </Box>
      )}

      <Modal open={isModalOpen} onClose={handleCancel}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Confirmar Vinculación
          </Typography>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            ¿Deseas vincular la información de la tienda con el contexto del bot?
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              sx={{
                mr: 1,
              }}
            >
              Confirmar
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancelar
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  )
}

export default Automation
