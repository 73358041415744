import React from 'react'
import { Box, Grid, Button } from '@mui/material'

const BackendPreview = () => {
  const openNewWindow = () => {
    window.open('https://api.nieriverso.com/project/default', '_blank')
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={openNewWindow}>
            Abrir gestor de Backend
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BackendPreview
