import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import * as XLSX from 'xlsx'
import ThreeViewer from './ThreeViewer' // Importar el nuevo componente
import GeojsonMap from './GeojsonMap' // Importar el nuevo componente
import { supabase } from './../../supabaseClient'
import { useStore } from './../../StoreContext'

const FileRenderer = ({ file }) => {
  const [geojsonData, setGeojsonData] = useState(null)
  const [xlsxData, setXlsxData] = useState(null)
  const [fileUrl, setFileUrl] = useState(null) // Estado para la URL firmada del archivo
  const { userProfile } = useStore()

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (file) {
        // Construye el path completo del archivo en el bucket
        const filePath = `${userProfile.user_id}/${file.name}` // Ajusta esto según la estructura correcta del path

        // Genera la URL firmada
        const { data, error } = await supabase.storage
          .from('files') // Nombre del bucket
          .createSignedUrl(filePath, 60) // Genera una URL firmada válida por 60 segundos
        console.log(data?.signedUrl)
        if (error) {
          console.error('Error generating signed URL:', error.message)
        } else {
          setFileUrl(data.signedUrl) // Establece la URL firmada en el estado
        }
      }
    }

    fetchSignedUrl()
  }, [file])

  useEffect(() => {
    if (file?.metadata?.mimetype === 'application/geo+json' && fileUrl) {
      fetch(fileUrl)
        .then(response => response.json())
        .then(data => setGeojsonData(data))
        .catch(error => console.error('Error fetching GeoJSON:', error))
    }
  }, [file, fileUrl])

  useEffect(() => {
    if (
      file?.metadata?.mimetype ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      fileUrl
    ) {
      fetch(fileUrl)
        .then(response => response.arrayBuffer())
        .then(data => {
          const workbook = XLSX.read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
          setXlsxData(worksheet)
        })
        .catch(error => console.error('Error fetching XLSX:', error))
    }
  }, [file, fileUrl])

  // Renderizar contenido según el tipo de archivo
  const renderContent = () => {
    if (!file || !file.metadata) return <Typography>Archivo no válido</Typography>

    const mimetype = file.metadata.mimetype
    console.log(file)
    switch (mimetype) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return (
          <img
            src={fileUrl} // Usa la URL firmada del archivo
            alt={file.name}
            style={{
              maxWidth: '100%',
            }}
          />
        )
      case 'video/mp4':
      case 'video/webm':
      case 'video/ogg':
        return (
          <video
            controls
            src={fileUrl} // Usa la URL firmada del archivo
            style={{
              maxWidth: '100%',
            }}
          />
        )
      case 'audio/mp3':
      case 'audio/wav':
      case 'audio/ogg':
        return <audio controls src={fileUrl} /> // Usa la URL firmada del archivo
      //case 'model/gltf+json': // Mimetype de archivos GLB/GLTF
      case "application/octet-stream":
        return <ThreeViewer fileUrl={fileUrl} /> // Usa la URL firmada del archivo
      case 'application/geo+json':
        return <GeojsonMap geojsonData={geojsonData} />
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {xlsxData &&
                    xlsxData[0] &&
                    xlsxData[0].map((col, index) => <TableCell key={index}>{col}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {xlsxData &&
                  xlsxData.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      default:
        return <Typography>Formato de archivo no soportado</Typography>
    }
  }

  return <Box>{renderContent()}</Box>
}

export default FileRenderer
