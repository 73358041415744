import React, { useState, useRef } from 'react'
import {
  Modal,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { supabase } from './../../supabaseClient'

const FileUploader = ({ open, handleClose, userProfile, handleUpload }) => {
  const [formData, setFormData] = useState({
    name: '', // Utilizaremos el nombre original del archivo seleccionado como nombre por defecto
    description: '',
    file: null,
    ispublic: false,
  })

  const [uploadProgress, setUploadProgress] = useState(0) // Estado para el progreso de carga
  const { enqueueSnackbar } = useSnackbar()
  const fileInputRef = useRef(null)
  const host_assets = process.env.REACT_APP_ASSETS_HOST || 'http://localhost:8080/upload'

  const handleChange = e => {
    const { name, value, files } = e.target

    if (files) {
      // Obtener el nombre del archivo seleccionado y establecerlo como nombre por defecto
      const fileName = files[0].name
      setFormData({
        ...formData,
        file: files[0],
        name: fileName,
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleDragOver = e => {
    e.preventDefault()
  }

  const handleDrop = e => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    const fileName = file.name
    setFormData({
      ...formData,
      file,
      name: fileName,
    })
  }

  const handleClickArea = () => {
    fileInputRef.current.click()
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!userProfile) {
      enqueueSnackbar('Usuario no definido. Por favor, inicia sesión.', {
        variant: 'error',
      })
      return
    }

    if (formData.file) {
      const filePath = `${userProfile.user_id}/${formData.file.name}` // Construye un path de archivo basado en el ID de usuario y el nombre del archivo

      try {
        // Subir archivo usando Supabase
        const { data, error } = await supabase.storage
          .from('files') // Asegúrate de que este es el nombre correcto de tu bucket
          .upload(filePath, formData.file, {
            cacheControl: '3600',
            upsert: false,
          })

        if (error) throw error

        enqueueSnackbar('Archivo subido correctamente', {
          variant: 'success',
        })
        handleUpload()
        handleClose()
      } catch (error) {
        enqueueSnackbar('Error al subir el archivo: ' + error.message, {
          variant: 'error',
        })
      }
    } else {
      enqueueSnackbar('Por favor, selecciona un archivo.', {
        variant: 'error',
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Subir archivo
        </Typography>
        <Box
          sx={{
            border: '2px dashed grey',
            borderRadius: '5px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClickArea}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="200px"
        >
          <Typography variant="body1" color="textSecondary" textAlign="center">
            Arrastra y suelta archivos aquí o haz clic para seleccionar uno.
          </Typography>
        </Box>
        <br />
        <br />

        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              name="name"
              label="Nombre del archivo"
              value={formData.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="description"
              label="Descripción"
              value={formData.description}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel>¿Es público?</InputLabel>
              <Select
                value={formData.ispublic}
                onChange={e =>
                  setFormData({
                    ...formData,
                    ispublic: e.target.value,
                  })
                }
                inputProps={{
                  name: 'ispublic',
                }}
              >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            mb={2}
            sx={{
              display: 'none',
            }}
          >
            <input type="file" ref={fileInputRef} onChange={handleChange} />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Subir
          </Button>
          {uploadProgress > 0 && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
              >{`${uploadProgress}% completado`}</Typography>
            </Box>
          )}
        </form>
      </Paper>
    </Modal>
  )
}

export default FileUploader
