import React, { useState, useEffect } from 'react'
import { supabase } from './../../../supabaseClient'
import {
  Box,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Card,
  CardContent,
  Avatar,
  CardActions,
  Link as RouterLink, // Alias para evitar conflictos con Link de react-router-dom
} from '@mui/material'
import { useStore } from './../../../StoreContext'

import QrCode2Icon from '@mui/icons-material/QrCode2'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close' // Importa CloseIcon
import AddIcon from '@mui/icons-material/Add'
// Importa Link de react-router-dom
import QRPreview from './../qr/QRPreview'

import MapComponent from './../../../components/metaverse/Map/MapComponent.js'

const QRCode = () => {
  const [qrCodes, setQrCodes] = useState(null)
  const { user } = useStore() // Asegúrate de importar y usar useStore correctamente
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredQRs, setFilteredQRs] = useState(null)
  const [selectedQR, setSelectedQR] = useState(null)
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false) // Define el estado isAddDialogOpen
  // Define la función openDeleteDialog si es necesario

  useEffect(() => {
    const fetchQRCodes = async () => {
      const { data, error } = await supabase.from('qr_codes').select('*').eq('user_id', user.id)

      if (error) console.log('Error fetching QR codes', error)
      else setQrCodes(data)
    }

    if (user) fetchQRCodes()
  }, [user])

  useEffect(() => {
    const filtered = qrCodes?.filter(qr =>
      qr?.description?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredQRs(filtered)
  }, [searchTerm, qrCodes])

  const handleQRClick = qrCode => {
    setSelectedQR(qrCode)
  }

  const handleCloseMap = () => {
    setSelectedQR(null)
  }

  const openDeleteDialog = (event, qrCode) => {
    setSelectedQR(null)
    // Implementa la lógica para abrir el diálogo de eliminación aquí
  }

  if (!user) {
    return <></>
  }

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'white',
        }}
      >
        Administración de códigos QR
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item xs={8}>
          <TextField
            label="Buscar código por descripción"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            sx={{
              marginBottom: 2,
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: 'right',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsAddDialogOpen(true)} // Usa setIsAddDialogOpen para abrir el diálogo
            sx={{
              marginBottom: 2,
            }}
          >
            Agregar Código QR
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {filteredQRs?.map(qrCode => (
          <Grid item key={qrCode.id} xs={12} sm={6} md={4} lg={2}>
            <Card onClick={() => handleQRClick(qrCode)}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    sx={{
                      bgcolor: 'primary.main',
                      marginRight: 2,
                    }}
                  >
                    <QrCode2Icon />
                  </Avatar>
                  <Typography variant="h5" component="div">
                    {qrCode.description}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Visitas: {qrCode.vistas}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Hash: {qrCode.hash}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  URL Estática: {`${window.location.origin}/${qrCode.hash}`}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  URL Destino: {qrCode.url_destino}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={event => openDeleteDialog(event, qrCode)}
                >
                  Eliminar
                </Button>
                <RouterLink
                  to={`/qr/codes/editar/${qrCode.id}`} // Usa RouterLink en lugar de Link
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Button size="small" color="primary">
                    Editar
                  </Button>
                </RouterLink>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 2,
        }}
      >
        <Grid item xs={12} md={selectedQR ? 6 : 12}>
          <Box
            style={{
              height: '500px',
            }}
          >
            <MapComponent
              pointer_selected={selectedQR}
              pointers={filteredQRs}
              mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
              />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedQR && (
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <IconButton
                onClick={handleCloseMap}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
              <CardContent
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  URL Estática: {`${window.location.origin}/qr/${selectedQR.hash}`}
                </Typography>

                <QRPreview qr={selectedQR} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default QRCode
