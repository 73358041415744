import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
  DialogTitle,
} from '@mui/material'
import { useStore } from './../../StoreContext'
import MapComponent from './../../components/metaverse/Map/MapComponent.js'
import CloseIcon from '@mui/icons-material/Close'
import StoreLinks from './StoreLinks.js'

function StoreList() {
  const { t } = useTranslation()
  const { userStores, userSettings } = useStore()
  const { storeId } = useParams()

  const [storeToEdit, setStoreToEdit] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedStore, setSelectedStore] = useState({})

  const [baseURL, setBaseURL] = useState('')
  const [adminURL, setAdminURL] = useState('')

  useEffect(() => {
    if (selectedStore.name) {
      const generatedBaseURL = `https://stores.nieriverso.com/${selectedStore.name}`
      const generatedAdminURL = `${generatedBaseURL}/admin`
      setBaseURL(generatedBaseURL)
      setAdminURL(generatedAdminURL)
      setSelectedStore(prevStoreData => ({
        ...prevStoreData,
        base_url: generatedBaseURL,
        admin_url: generatedAdminURL,
      }))
    }
  }, [selectedStore])

  useEffect(() => {
    const fetchStore = async () => {
      if (storeId) {
        setLoading(true)
        try {
          const { data, error } = await supabase
            .from('stores')
            .select('*')
            .eq('id', storeId)
            .single()
          if (error) throw error
          setSelectedStore(data)
        } catch (error) {
          setError(error.message)
        } finally {
          setLoading(false)
        }
      }
    }
    if (storeId) {
      fetchStore()
    } else {
    }
  }, [storeId])

  const filteredStores =
    userStores?.filter(store => store?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())) ||
    []

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  const formatUrl = url => url?.replace(/^https?:\/\//, '')

  return (
    <Box
      p={4}
      sx={{
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'white',
        }}
      >
        {selectedStore.name}
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 2,
        }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: 3,
              margin: '0 auto',
            }}
          >
            <StoreLinks baseURL={baseURL} adminURL={adminURL} t={t} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={selectedStore ? 6 : 12}>
          <Box
            style={{
              height: '500px',
            }}
          >
            <MapComponent
              pointer_selected={selectedStore}
              pointers={filteredStores}
              mapStyle={
                !userSettings?.dark_theme_enabled
                  ? 'mapbox://styles/mapbox/streets-v12'
                  : 'mapbox://styles/mapbox/dark-v10'
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={selectedStore ? 6 : 0}>
          {selectedStore ? (
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <IconButton
                onClick={() => setSelectedStore(null)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
              <CardContent
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  {selectedStore.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Creado por: {selectedStore.creator}
                </Typography>
                <Typography>{selectedStore.description}</Typography>

                {/* Aquí se agrega el iframe */}
                <Box
                  mt={2}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <iframe
                    src={selectedStore.base_url}
                    title="Store Preview"
                    frameBorder="0"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    allowFullScreen
                    sandbox="allow-scripts allow-same-origin"
                  />
                </Box>
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

export default StoreList
