import React, { useState, useEffect, useRef } from 'react'
import { Box, TextField, Button, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import TypewriterEffect from './../../components/molecules/Typewriter'
import './ChatbotConsole.css'

const MessageContent = ({ message }) => (
  <Typography variant="body1">
    <TypewriterEffect
      className={'chatbotText'}
      text={message.content}
      speed={5}
      reset={false}
      size={11}
    />
  </Typography>
)

const ChatbotConsole = ({ readOnly, logMessages = [], showMessage, bot }) => {
  const [input, setInput] = useState('')
  const [messages, setMessages] = useState([])
  const [showAssistantMessage, setShowAssistantMessage] = useState(showMessage)
  const messagesContainerRef = useRef(null)

  useEffect(() => {
    setMessages(logMessages)
  }, [logMessages])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const handleFocus = () => setShowAssistantMessage(false)
  const handleBlur = () => !input.trim() && setShowAssistantMessage(true)

  const handleInputChange = event => setInput(event.target.value)

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }

  const playSound = () => {
    // Implement sound playback logic here if needed
  }

  const handleSubmit = () => {
    if (input.trim()) {
      setMessages([
        ...messages,
        {
          message_content: input,
          message_direction: 'inbound',
        },
      ])
      const response = getRandomResponse()
      setMessages(prev => [
        ...prev,
        {
          message_content: response,
          message_direction: 'outbound',
        },
      ])
      setInput('')
      playSound()
    }
  }

  return (
    <Box className="chat-container">
      <Box ref={messagesContainerRef} className="messages-container">
        {messages?.map((message, index) => (
          <Box
            key={index}
            className={message.from !== bot.from ? 'chat-message-user' : 'chat-message-bot'}
          >
            {message.from === bot.from && (
              <img src={bot.profilePicture} alt="Bot" className="bot-profile-picture" />
            )}
            <MessageContent message={message} />
          </Box>
        ))}
      </Box>

      {!readOnly && (
        <>
          <Box className="chat-input-container">
            <TextField
              onFocus={handleFocus}
              onBlur={handleBlur}
              fullWidth
              value={input}
              onChange={handleInputChange}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleSubmit()
                  event.preventDefault()
                }
              }}
              variant="outlined"
              placeholder="Escribe un mensaje..."
              className="chat-input"
              autoComplete="off"
              autoCorrect="off"
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              onClick={handleSubmit}
              className="send-button"
            >
              Enviar
            </Button>
          </Box>
          {showAssistantMessage && (
            <Typography variant="body2" className="assistant-message">
              Comparte tus objetivos con la IA y permítele guiarte en el uso de nuestras
              herramientas para alcanzarlos.
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

const randomResponses = [
  'Lo siento, estoy fuera de servicio ahora mismo.',
  'No estoy seguro de entender, ¿puedes reformular?',
  'Vuelve a intentarlo más tarde, por favor.',
  // ... otras respuestas ...
]

const getRandomResponse = () => {
  const randomIndex = Math.floor(Math.random() * randomResponses.length)
  return randomResponses[randomIndex]
}

export default ChatbotConsole
