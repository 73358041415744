import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Paper,
  Button,
} from '@mui/material'
import { Scanner } from '@yudiel/react-qr-scanner'
import { styled } from '@mui/material/styles'
import { supabase } from './../../../supabaseClient'
import { useSpring, animated } from 'react-spring'
import { useStore } from './../../../StoreContext'

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  aspectRatio: '1/1',
  overflow: 'hidden',
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}))

const HistoryBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  marginTop: theme.spacing(2),
}))

const QRCodeReader = () => {
  const [scanResult, setScanResult] = useState('')
  const [history, setHistory] = useState([])
  const [cameras, setCameras] = useState([])
  const [selectedCamera, setSelectedCamera] = useState('')
  const [error, setError] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [isReaderActive, setIsReaderActive] = useState(true)
  const { user, setMenuOption } = useStore()

  const fetchQRCode = async qrId => {
    try {
      const { data, error } = await supabase.from('qr_codes').select('*').eq('hash', qrId).single()
      if (error) {
        console.error('Error fetching QR codes', error)
      }
    } catch (error) {
      console.error('Error fetching QR codes', error)
    }
  }

  const addOneVisit = async qrUrl => {
    const qrId = '' // Define logic to extract qrId from qrUrl
    if (qrUrl) {
      await fetchQRCode(qrId)
    }
  }

  useEffect(() => {
    setMenuOption(null)

    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(device => device.kind === 'videoinput')
        setCameras(videoDevices)
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0].deviceId)
        }
      } catch (error) {
        console.error('Error enumerating devices:', error)
        setError('Error al enumerar dispositivos de video.')
        setOpenSnackbar(true)
      }
    }

    getCameras()
  }, [])

  const handleCameraChange = event => {
    setSelectedCamera(event.target.value)
  }

  const handleScan = text => {
    if (text) {
      setScanResult(text)
      if (history[history.length - 1] !== text) {
        addOneVisit(text)
        setHistory(prevHistory => [...prevHistory, text])
      }
    }
  }

  const handleError = error => {
    console.error('Error en el lector de QR:', error)
    setError('Error al acceder a la cámara. Por favor verifica los permisos.')
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const containerAnimation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    config: {
      duration: 500,
    },
  })

  const itemAnimation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    delay: 200,
    config: {
      duration: 500,
    },
  })

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: '100vh',
      }}
    >
      <Paper
        sx={{
          padding: 3,
          width: '100%',
          maxWidth: 600,
          textAlign: 'center',
        }}
      >
        {cameras.length > 1 && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="camera-select-label">Cámara</InputLabel>
              <Select
                labelId="camera-select-label"
                value={selectedCamera}
                label="Cámara"
                onChange={handleCameraChange}
              >
                {cameras.map((camera, index) => (
                  <MenuItem key={camera.deviceId} value={camera.deviceId}>
                    {`Cámara ${index + 1}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <animated.div style={containerAnimation}>
            <Container>
              {isReaderActive && (
                <Scanner
                  onResult={(text, result, error) => {
                    if (result) handleScan(text)
                    if (error) handleError(error)
                  }}
                  constraints={{
                    video: {
                      deviceId: selectedCamera
                        ? {
                            exact: selectedCamera,
                          }
                        : undefined,
                    },
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Asegura que el video cubra todo el contenedor
                  }}
                />
              )}
            </Container>
          </animated.div>
        </Grid>
        <Grid item xs={12}>
          <animated.div style={itemAnimation}>
            <br />
            <Typography variant="h6">Historial de Escaneos:</Typography>
            <HistoryBox>
              {history.length > 0 ? (
                history.map((qr, index) => <Typography key={index}>{qr}</Typography>)
              ) : (
                <Typography>No hay escaneos recientes.</Typography>
              )}
            </HistoryBox>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={error}
            />
          </animated.div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsReaderActive(!isReaderActive)}
          >
            {isReaderActive ? 'Detener Cámara' : 'Iniciar Cámara'}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default QRCodeReader
