import React, { useEffect, useRef, useState } from 'react'
//import * as BABYLON from 'babylonjs'
//import 'babylonjs-loaders' // Asegúrate de instalar este paquete si no lo has hecho ya
//import 'babylonjs-gui'
//import 'babylonjs-materials'
import { supabase } from './../../supabaseClient'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material'

const RoomRenderEdit = ({ room_id }) => {
  return (
    <>

    </>
  )
}

export default RoomRenderEdit
