import React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { useTranslation } from 'react-i18next'

const TransactionsTable = ({ transactions }) => {
  const { t } = useTranslation()

  const renderTableBody = () => {
    if (transactions.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            {t('coins.noTransactions')}
          </TableCell>
        </TableRow>
      )
    }

    return transactions.map((transaction, index) => (
      <TableRow key={index}>
        <TableCell>
          <CheckCircleIcon
            sx={{
              color: 'green',
              verticalAlign: 'middle',
            }}
          />
        </TableCell>
        <TableCell>USD {transaction.amount}</TableCell>
        <TableCell>
          <MonetizationOnIcon
            className="saltar"
            sx={{
              marginBottom: 2,
              color: '#ffdd00',
            }}
          />
          {transaction.coin || -1}
        </TableCell>
        <TableCell>{transaction.description}</TableCell>
        <TableCell>{transaction.previous_balance}</TableCell>
        <TableCell>{transaction.current_balance}</TableCell>
      </TableRow>
    ))
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        overflowX: 'auto',
        margin: '10px',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t('coins.transactionHistory')}
      </Typography>
      <TableContainer>
        <Table
          sx={{
            maxWidth: '100%',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('coins.status')}</TableCell>
              <TableCell>{t('coins.transactionCredits')}</TableCell>
              <TableCell>{t('coins.description')}</TableCell>
              <TableCell>{t('coins.previousBalance')}</TableCell>
              <TableCell>{t('coins.currentBalance')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default TransactionsTable
