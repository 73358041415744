import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/ViewInAr.js'

import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  ScaleControl,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import config from './../../config.js'
import PostTimeLine from '../molecules/timeline/PostTimeLine'
import RoomRenderEdit from './RoomRenderEdit.js'

const RoomEdit = ({ isEdit }) => {
  const [room, setRoom] = useState({
    name: '',
    description: '',
    images: [],
    latitude: 0,
    longitude: 0,
  })
  const [viewport, setViewport] = useState({
    latitude: 40.4168,
    longitude: -3.7038,
    zoom: 14,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate()
  const { roomId } = useParams()

  useEffect(() => {
    if (isEdit && roomId) {
      const fetchRoom = async () => {
        setLoading(true)
        const { data, error } = await supabase.from('rooms').select('*').eq('id', roomId).single()
        if (error) {
          console.error('Error fetching room:', error)
          setError('Failed to fetch room data.')
        } else {
          setRoom(data)
          setViewport({
            latitude: data.latitude,
            longitude: data.longitude,
            zoom: 14,
          })
        }
        setLoading(false)
      }
      fetchRoom()
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords
          setRoom(prevRoom => ({
            ...prevRoom,
            latitude,
            longitude,
          }))
          setViewport({
            latitude,
            longitude,
            zoom: 14,
          })
        })
      }
    }
  }, [isEdit, roomId])

  const handleChange = e => {
    const { name, value } = e.target
    setRoom(prevRoom => ({
      ...prevRoom,
      [name]: name === 'images' ? value.split(',') : value,
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    if (isEdit) {
      const { error } = await supabase.from('rooms').update(room).eq('id', roomId)
      if (error) {
        console.error('Error updating room:', error)
        setError('Failed to update room.')
      } else {
        navigate('/rooms/' + room.id)
      }
    } else {
      const { error } = await supabase.from('rooms').insert([room])
      if (error) {
        console.error('Error creating room:', error)
        setError('Failed to create room.')
      } else {
        navigate('/rooms/' + room.id)
      }
    }
    setLoading(false)
  }

  const handleMapClick = event => {
    const { lng, lat } = event.lngLat
    setRoom(prevRoom => ({
      ...prevRoom,
      longitude: lng,
      latitude: lat,
    }))
    setViewport(prevViewport => ({
      ...prevViewport,
      longitude: lng,
      latitude: lat,
    }))
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} lg={12}>
          <Paper
            sx={{
              padding: 3,
              margin: '0 auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                }}
              >
                {isEdit ? 'Editor de ' + room.name : 'Crear espacio'}
              </Typography>

              {
                <Tooltip title="Editar">
                  <IconButton onClick={() => navigate(`/rooms/${room.id}`)}>
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
              }
            </Box>

            {error && <Alert severity="error">{error}</Alert>}
            {loading && <CircularProgress />}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              textColor="inherit"
              indicatorColor="secondary"
              sx={{
                '& .MuiTab-root': {
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                },
              }}
            >
              <Tab label="Editar" />
              <Tab label="Información" />
              <Tab label="Geolocalización" />
              <Tab label="Posts" />
              <Tab label="Desafios" />
              <Tab label="Eventos" />
            </Tabs>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              {[0, 1, 2, 3, 4, 5].map(index => (
                <Box
                  key={index}
                  sx={{
                    height: 10,
                    width: 10,
                    borderRadius: '50%',
                    backgroundColor: tabIndex === index ? 'secondary.main' : 'grey.500',
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <br />

            <form onSubmit={handleSubmit}>
              {tabIndex === 1 && (
                <Box>
                  <TextField
                    label="Name"
                    name="name"
                    value={room.name}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    required
                  />
                  <TextField
                    label="Description"
                    name="description"
                    value={room.description}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    required
                  />
                </Box>
              )}

              {tabIndex === 2 && (
                <Box>
                  <Typography variant="h6">Location</Typography>
                  <Box
                    sx={{
                      height: 400,
                      marginBottom: 2,
                    }}
                  >
                    <Map
                      initialViewState={viewport}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
                      mapboxAccessToken={config.automatic.mapBox_apiKey}
                      onClick={handleMapClick}
                    >
                      <NavigationControl />
                      <FullscreenControl />
                      <GeolocateControl />
                      <ScaleControl />
                      {room.longitude && room.latitude && (
                        <Marker longitude={room.longitude} latitude={room.latitude} />
                      )}
                    </Map>
                  </Box>
                  <TextField
                    label="Latitude"
                    name="latitude"
                    value={room.latitude}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    disabled
                  />
                  <TextField
                    label="Longitude"
                    name="longitude"
                    value={room.longitude}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    disabled
                  />
                </Box>
              )}

              {tabIndex === 3 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Desafios
                  </Typography>
                  {/* Aquí puedes agregar los componentes o campos para el contenido 3D */}
                </Box>
              )}

              {tabIndex === 3 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Posts
                  </Typography>
                  <PostTimeLine />
                  {/* Aquí puedes agregar los componentes o campos para el contenido 3D */}
                </Box>
              )}

              {tabIndex === 4 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Desafíos
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Los desafíos son actividades específicas diseñadas para fomentar la
                    participación y el compromiso. Cada desafío puede tener diferentes objetivos y
                    recompensas, motivando a los usuarios a explorar y aprender más sobre el entorno
                    virtual. Completar desafíos puede otorgar puntos, insignias o desbloquear nuevas
                    funcionalidades.
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Lista de Desafíos
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Desafío 1:</strong> Explora cinco habitaciones diferentes.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Desafío 2:</strong> Encuentra el objeto oculto en la sala principal.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Desafío 3:</strong> Participa en un evento en vivo.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              )}
              {tabIndex === 0 && <RoomRenderEdit room_id={roomId} />}

              <Button
                variant="contained"
                color="primary"
                startIcon={isEdit ? <EditIcon /> : <AddIcon />}
                type="submit"
                disabled={loading}
                sx={{
                  marginTop: 2,
                }}
              >
                {isEdit ? 'Guardar cambios' : 'Crear'}
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <br />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RoomEdit
