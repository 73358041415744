// src/themes.js
import { createTheme } from '@mui/material/styles'

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#grey',
    },
    secondary: {
      main: '#ff6600',
    },
    background: {
      default: '#d1d1d1',
    },
    header: {
      default: '#000',
    },
    text: {
      primary: '#000', // Texto negro para mejor legibilidad en fondos claros
      secondary: '#2e2e2e', // Texto en contraste para usar sobre fondos oscuros o coloridos
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        }, // Cambiado a negro para mejor contraste
        outlined: {
          color: 'gray!important',
        }, // Color del label cuando se minimiza
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'black',
        }, // Cambiado a negro para mejor contraste
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input': {
            color: '#000', // Cambiado a negro para mejor contraste
          },
          '& textarea': {
            color: '#000', // Cambiado a negro para mejor contraste
          },
          '& .MuiInputLabel-outlined': {
            color: 'gray', // Color del label cuando se minimiza
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
          '&.input': {
            color: '#000',
          }, // Cambiado a negro para mejor contraste
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '4px',
          backgroundColor: 'grey', // Fondo claro
          color: '#0e3861', // Texto oscuro
          '&:hover': {
            backgroundColor: '#EEE',
          }, // Fondo más claro al pasar el ratón por encima
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#000', // Cambiado a negro para mejor contraste
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEE', // Fondo claro
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',

          border: 'none!important', // Eliminar el borde inferior del AppBar
          zIndex: 2,
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEE', // Fondo claro
          color: '#000!important',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#000', // Cambiado a negro para mejor contraste
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          color: '#000!important', // Cambiado a negro para mejor contraste
        },
        root: {
          color: '#000!important', // Cambiado a negro para mejor contraste
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#000', // Cambiado a negro para mejor contraste
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiDrawer-paper': {
            backgroundColor: '#FFF',
          },
          color: 'black!important',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: '#EEE', // Light border
          borderRadius: '4px', // Rounded corners
        },
        input: {
          '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
          }, // Ajustar este valor según sea necesario
        },
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }, // Ajustar color del borde si es necesario
        '&.Mui-focused': {
          notchedOutline: {
            borderColor: 'rgba(0, 0, 0, 0.6)',
          },
        }, // Ajustar color del borde en enfoque si es necesario
      },
    },
  },
  typography: {
    fontFamily: '"Nunito", sans-serif', // Puedes elegir una tipografía más elegante
    fontSize: 12,
    h1: {
      fontWeight: 300,
      fontSize: '6rem',
      letterSpacing: '-0.01562em',
    },
    // ... otras personalizaciones de tipografía
  },
  // Personalizaciones adicionales, como sombras, pueden ir aquí
  shadows: Array(25).fill('0px 0px 0px 0px rgba(51, 51, 51, .3)'), // Sombra personalizada
  // Más configuraciones...
})
