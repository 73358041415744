import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import postProcessor from 'i18next-sprintf-postprocessor'
import LanguageDetector from 'i18next-browser-languagedetector'

const languages = ['en', 'es', 'br', 'de', 'fr', 'it', 'il']

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(postProcessor)
  .init(
    {
      lng: 'es',
      fallbackLng: 'es',
      react: {
        useSuspense: false, //   <---- this will do the magic
      },
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      whitelist: languages,
      detection: {
        order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie'], // Solo usa el caché de cookies, elimina "localStorage"
      },
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        crossDomain: true,
      },
      // Desactiva el caché del lado del cliente
      cache: {
        enabled: false,
      },
    },

    (err, t) => {
      if (err) return console.log('something went wrong loading', err)
      t('key') // -> same as i18next.t
    }
  )

export default i18n
