import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  TextField,
  Alert,
} from '@mui/material'
import { supabase } from './../../supabaseClient'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MapComponent from './../../components/metaverse/Map/MapComponent.js'
import { useStore } from './../../StoreContext'
import CloseIcon from '@mui/icons-material/Close'
import PostTimeLine from '../molecules/timeline/PostTimeLine'

const SpaceList = () => {
  const [rooms, setSpaces] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRoom, setSelectedRoom] = useState(null)
  const navigate = useNavigate()
  const { userSettings } = useStore()

  useEffect(() => {
    const fetchSpaces = async () => {
      try {
        setLoading(true)
        const { data, error } = await supabase.from('rooms').select('*')
        if (error) throw error
        setSpaces(data)
      } catch (error) {
        console.error('Error fetching rooms:', error)
        setError('Error al cargar los espacios.')
      } finally {
        setLoading(false)
      }
    }

    fetchSpaces()
  }, [])

  const handleViewDetails = id => {
    const room = rooms.find(room => room.id === id)
    setSelectedRoom(room)
  }

  const filteredSpaces = rooms.filter(space =>
    space.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <TextField
            label="Buscar Espacio"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: 'right',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/rooms/create')}
          >
            Añadir Espacio
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h4"
        sx={{
          marginTop: 2,
        }}
      >
        Tus Espacios
      </Typography>

      {error && (
        <Alert
          severity="error"
          sx={{
            marginTop: 2,
          }}
        >
          {error}
        </Alert>
      )}

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            container
            spacing={3}
            sx={{
              marginTop: 2,
            }}
          >
            {filteredSpaces.map(space => (
              <Grid item key={space.id} xs={12} sm={6} md={4} lg={2}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                    }}
                    onClick={() => handleViewDetails(space.id)}
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {space.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Creado por: {space.creator}
                    </Typography>
                    <Typography>{space.description}</Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 2,
                    }}
                  >
                    <Tooltip title="Ver Detalles">
                      <IconButton onClick={() => navigate(`/rooms/${space.id}`)}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton onClick={() => navigate(`/rooms/${space.id}/edit`)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: 2,
            }}
          >
            <Grid item xs={12} md={selectedRoom ? 6 : 12}>
              <Box
                style={{
                  height: '500px',
                }}
              >
                <MapComponent
                  pointer_selected={selectedRoom}
                  pointers={filteredSpaces}
                  mapStyle={
                    !userSettings?.dark_theme_enabled
                      ? 'mapbox://styles/mapbox/streets-v12'
                      : 'mapbox://styles/mapbox/dark-v10'
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={selectedRoom ? 6 : 0}>
              {selectedRoom ? (
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={() => setSelectedRoom(null)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <CardContent
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {selectedRoom.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Creado por: {selectedRoom.creator}
                    </Typography>
                    <Typography>{selectedRoom.description}</Typography>

                    <PostTimeLine />
                  </CardContent>
                </Card>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default SpaceList
