import React, { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  Paper,
  Box,
  IconButton,
  InputBase,
  Card,
  CardContent,
  Typography,
  Tab,
  Tabs,
} from '@mui/material'
import { useSpring, animated } from 'react-spring'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import RoomRender from './../../components/metaverse/RoomRender'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import MapComponent from './../../components/metaverse/Map/MapComponent'
import PostTimeLine from './../../components/molecules/timeline/PostTimeLine'
import { useStore } from './../../StoreContext'
import Filters from './Filters'
import Post from './Post'
import CallEdgeFunction from './CallEdgeFunction'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const Dashboard = () => {
  const { userSettings, userStores, userBots, userEvents, userRooms, setMenuOption, userPosts } =
    useStore()
  const { t } = useTranslation()
  const [stores, setStores] = useState([])
  const [rooms, setRooms] = useState([])
  const [events, setEvents] = useState([])
  const [posts, setPosts] = useState([])
  const [bots, setBots] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showWidgets, setShowWidgets] = useState(false)
  const [selectedWidgy, setWidgy] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isPostOpen, setIsPostOpen] = useState(false)
  const [map_points, setMap_points] = useState([])
  const [userLocation, setUserLocation] = useState({})

  
  useEffect(() => {
    setMenuOption(null)
  }, [setMenuOption])

  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
  }

  const handleChangeTab = newValue => {
    setTabValue(newValue)
  }

  const handleLocationUpdate = location => {
    console.log(location)
    setUserLocation(location)
  }

  const allWidgets = []

  const filterWidgets = (widgets, term) => {
    if (!term) return widgets
    return widgets.filter(widget => widget.name.toLowerCase().includes(term.toLowerCase()))
  }

  const widgets = allWidgets
  const filteredWidgets = filterWidgets(widgets, searchTerm)

  const animationProps = useSpring({
    opacity: showWidgets ? 1 : 0,
    marginTop: showWidgets ? 0 : -50,
    from: {
      opacity: 0,
      marginTop: -50,
    },
  })

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} style={{ zIndex: 2 }}>
          <CallEdgeFunction />

          <Paper
            sx={{
              mt: 3,
              position: 'fixed',
              width: '90%',
              top: '60px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputBase
                placeholder={t('widgets.searchPlaceholder')}
                sx={{
                  color: 'inherit',
                  ml: 1,
                  flex: 1,
                }}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen)
                  setIsPostOpen(false)
                }}
              >
                <FilterListIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => {
                  setIsPostOpen(!isPostOpen)
                  setIsFilterOpen(false)
                }}
              >
                <RecordVoiceOverIcon />
              </IconButton>
            </Box>
          </Paper>
        </Grid>

        {/* Map */}

        <animated.div style={animationProps}>
          <Box
            sx={{
              width: '100vw',
              overflow: 'hidden',
              zIndex: 1,
              position: 'absolute',
              paddingTop: '10px',
              left: 0,
            }}
          >
            {false && map_points && (
              <MapComponent
                setHidden={true}
                isFixed={true}
                onLocationUpdate={handleLocationUpdate}
                isEditing={false}
                setPoint={setWidgy}
                pointers={map_points}
                pointer_selected={selectedWidgy}
                triggerGeolocate={isPostOpen}
              />
            )}
          </Box>
        </animated.div>

        {(isFilterOpen || isPostOpen) && (
          <div
            onClick={() => {
              setIsFilterOpen(false)
              setIsPostOpen(false)
            }}
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <div
              onClick={e => {
                e.stopPropagation()
              }}
              style={{
                position: 'absolute',
                top: 10,
                zIndex: 3,
              }}
            >
              {isFilterOpen && <Filters onClose={setIsFilterOpen}  />}
            </div>

            <div
              onClick={e => {
                e.stopPropagation()
              }}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                zIndex: 2,
              }}
            >
              {isPostOpen && <Post userLocation={userLocation} onClose={setIsPostOpen} />}
            </div>
          </div>
        )}

        {false && selectedWidgy && (
          <Grid item xs={12} sm={6} md={4} style={{ zIndex: 4, top: '80vh' }}>
            <animated.div style={animationProps}>
              <Box
                sx={{
                  p: 2,
                  height: '500px',
                  overflow: 'hidden',
                }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={() => setWidgy(null)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <CardContent
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {selectedWidgy.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Creado por: {selectedWidgy.creator}
                    </Typography>
                    <Typography>{selectedWidgy.description}</Typography>
                    <Tabs
                      value={tabValue}
                      onChange={(event, newValue) => handleChangeTab(newValue)}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="inherit"
                      indicatorColor="secondary"
                      sx={{
                        '& .MuiTab-root': {
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <Tab label="Post Timeline" />
                      {selectedWidgy.type === 'rooms' && <Tab label="Room Render" />}
                      {selectedWidgy.type === 'stores' && <Tab label="Tienda" />}
                      {selectedWidgy.type === 'events' && <Tab label="Tickets" />}
                    </Tabs>
                    {tabValue === 0 && <PostTimeLine />}
                    {tabValue === 1 && !selectedWidgy.base_url && (
                      <RoomRender room_id={selectedWidgy?.id} />
                    )}
                    {tabValue === 1 && selectedWidgy.base_url && (
                      <Box
                        mt={2}
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <iframe
                          src={selectedWidgy.base_url}
                          title="Store Preview"
                          frameBorder="0"
                          style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                          }}
                          allowFullScreen
                          sandbox="allow-scripts allow-same-origin"
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </animated.div>
          </Grid>
        )}

        {filteredWidgets.map((widget, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <animated.div style={animationProps}>
              <Box
                sx={{
                  p: 2,
                }}
              >
                {widget.component}
              </Box>
            </animated.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Dashboard
