import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from './../../../config.js'
import Map, {
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Marker,
} from 'react-map-gl'
import { Box } from '@mui/material'
import Pin from './pin'
import './Map.css'
import 'mapbox-gl/dist/mapbox-gl.css'

export default function MapEdit(props) {
  const { isEditing, singleRoom, rooms: initialRooms = [] } = props
  const [marker, setMarker] = useState({
    latitude: 40,
    longitude: -100,
  })
  const [events, logEvents] = useState({})
  const [pos, setPos] = useState()
  const joinRoom = props.joinRoom
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [roomInfo, setRoomInfo] = useState(null)
  const [rooms, setRooms] = useState(initialRooms)
  const geolocateControlRef = useRef(null)
  const [isShowingPosition, setIsShowingPosition] = useState(false)
  const [zoom, setZoom] = useState(15)
  const [geojson, setGeojson] = useState(null)

  const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const defaultProps = useMemo(
    () => ({
      center: localStorage.getItem('lastPosition')
        ? JSON.parse(localStorage.getItem('lastPosition'))
        : {
            lat: 0,
            lng: 0,
          },
      zoom: 15,
    }),
    []
  )

  useEffect(() => {
    async function fetchGeolocation() {
      await sleep(1000)
      if (
        geolocateControlRef.current &&
        typeof geolocateControlRef.current.trigger === 'function'
      ) {
        geolocateControlRef.current.trigger()
      }
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = {
            lat,
            lng,
          }
          localStorage.setItem('lastPosition', JSON.stringify(pos))
          setPos(pos)
        },
        error => console.error('Geolocation error:', error)
      )
      if (props.rooms) {
        setRooms(props.rooms)
      }
    }
    fetchGeolocation()
  }, [props.rooms])

  const pins = rooms => {
    return rooms?.map((room, index) => (
      <Marker
        key={`marker-${index}`}
        longitude={room?.mapPosition?.longitude}
        latitude={room?.mapPosition?.latitude}
        anchor="bottom"
        onClick={e => {
          e.originalEvent.stopPropagation()
          setRoomInfo(room)
        }}
      >
        <Pin />
      </Marker>
    ))
  }

  const [features, setFeatures] = useState({})
  const [formValues, setFormValues] = useState({
    descripcion: '',
    fecha: null,
    hora: null,
    invitados: '',
    confirmados: '',
    longitude: '',
    latitude: '',
  })

  const onUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {
        ...currFeatures,
      }
      for (const f of e.features) {
        newFeatures[f.id] = f
      }
      return newFeatures
    })
  }, [])

  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {
        ...currFeatures,
      }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }
      return newFeatures
    })
  }, [])

  const onMarkerDragStart = useCallback(event => {
    logEvents(_events => ({
      ..._events,
      onDragStart: event.lngLat,
    }))
  }, [])

  const onMarkerDrag = useCallback(event => {
    logEvents(_events => ({
      ..._events,
      onDrag: event.lngLat,
    }))
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    })
  }, [])

  const onMarkerDragEnd = useCallback(event => {
    logEvents(_events => ({
      ..._events,
      onDragEnd: event.lngLat,
    }))
  }, [])

  const handleMapClick = event => {
    const { lng, lat } = event.lngLat
    setFormValues(prevState => ({
      ...prevState,
      longitude: lng,
      latitude: lat,
    }))
  }

  return (
    <div
      className="mapBox"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          height: 400,
          marginBottom: 2,
        }}
      >
        <Map
          initialViewState={{
            longitude: formValues.longitude || defaultProps.center.lng,
            latitude: formValues.latitude || defaultProps.center.lat,
            zoom: defaultProps.zoom,
          }}
          style={{
            width: '100%',
            height: '100%',
          }}
          mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
          mapboxAccessToken={config.automatic.mapBox_apiKey}
          onClick={handleMapClick}
        >
          <NavigationControl />
          <FullscreenControl />
          <GeolocateControl ref={geolocateControlRef} />
          <ScaleControl />
          {formValues.longitude && formValues.latitude && (
            <Marker longitude={formValues.longitude} latitude={formValues.latitude} />
          )}
          {pins(rooms)}
        </Map>
      </Box>
    </div>
  )
}
