// src/components/Followers.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  List,
  ListItem,
  Avatar,
  Typography,
  CircularProgress,
  Box,
  Pagination,
} from '@mui/material';
import { supabase } from '../../supabaseClient'; // Asegúrate de que la ruta es correcta
import { Link } from 'react-router-dom'; // Para los enlaces a perfiles

const Followers = ({ userData }) => {
  const [followersData, setFollowersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const followersPerPage = 10;

  // Memorizar followersIds para evitar recrear el array en cada render
  const followersIds = useMemo(() => userData?.followers || [], [userData]);

  useEffect(() => {
    const fetchFollowers = async () => {
      if (!followersIds.length) {
        setFollowersData([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Consultar los perfiles de los seguidores usando los IDs
        const { data: followersProfiles, error: followersError } = await supabase
          .from('user_profiles')
          .select('user_id, alias, profile_picture_url')
          .in('user_id', followersIds);

        if (followersError) {
          throw followersError;
        }

        setFollowersData(followersProfiles);
      } catch (err) {
        console.error('Error al obtener los seguidores:', err);
        setError('No se pudieron cargar los seguidores.');
      } finally {
        setLoading(false);
      }
    };

    fetchFollowers();
  }, [followersIds]);

  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Opcional: Scroll hacia arriba al cambiar de página
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Calcular los seguidores a mostrar en la página actual
  const indexOfLastFollower = currentPage * followersPerPage;
  const indexOfFirstFollower = indexOfLastFollower - followersPerPage;
  const currentFollowers = followersData.slice(indexOfFirstFollower, indexOfLastFollower);
  const totalPages = Math.ceil(followersData.length / followersPerPage);

  if (loading) {
    return (
      <Paper
        sx={{
          margin: 3,
          padding: 2,
          borderRadius: 2,
          bgcolor: 'grey.900',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <CircularProgress color="inherit" />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper
        sx={{
          margin: 3,
          padding: 2,
          borderRadius: 2,
          bgcolor: 'grey.900',
          color: 'red',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">{error}</Typography>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        margin: 3,
        padding: 2,
        borderRadius: 2,
        bgcolor: 'grey.900',
        color: 'white',
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        Seguidores
      </Typography>
      {followersData.length === 0 ? (
        <Typography variant="body1" align="center" color="grey.500">
          Este usuario no tiene seguidores.
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {currentFollowers.map((follower) => (
              <ListItem
                key={follower.user_id}
                component={Link}
                to={`/user/profile/${follower.user_id}`} // Ajusta la ruta según tu configuración
                sx={{
                  bgcolor: 'transparent',
                  borderRadius: 2,
                  width: 80, // Tamaño reducido
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 1,
                  textDecoration: 'none', // Quitar subrayado
                  color: 'inherit', // Mantener el color del texto
                  '&:hover': {
                    bgcolor: 'grey.800',
                  },
                }}
              >
                <Avatar
                  alt={follower.alias || 'Usuario'}
                  src={follower.profile_picture_url || ''}
                  sx={{
                    width: 40, // Tamaño reducido
                    height: 40,
                    marginBottom: 0.5,
                  }}
                />
                <Typography variant="body2" color="white" noWrap>
                  {follower.alias || 'Sin Alias'}
                </Typography>
              </ListItem>
            ))}
          </List>
          {/* Paginación */}
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ marginTop: 2 }}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default Followers;
