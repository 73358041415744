import React, { useRef } from 'react'
import { Box, Grid, Button } from '@mui/material'

const DatacenterPreview = () => {
  const iframeRef = useRef(null)

  const toggleFullScreen = () => {
    const iframe = iframeRef.current
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen()
      } else if (iframe.mozRequestFullScreen) {
        /* Firefox */
        iframe.mozRequestFullScreen()
      } else if (iframe.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        iframe.webkitRequestFullscreen()
      } else if (iframe.msRequestFullscreen) {
        /* IE/Edge */
        iframe.msRequestFullscreen()
      }
    }
  }

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <iframe
            ref={iframeRef}
            src="https://datacenter.nieriverso.com"
            width="100%"
            height="800px" // Ajusta el tamaño del iframe al 100% de la altura de la pantalla
            style={{
              border: 'none',
              zIndex: 1,
              position: 'relative',
              borderRadius: '70px!important',
            }} // Añade bordes redondeados
            title="Nuevoshopping Datacenter"
            loading="lazy" // Mejora el rendimiento con lazy loading
            allowFullScreen
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={toggleFullScreen} variant="contained" color="primary">
            Ver en pantalla completa
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DatacenterPreview
