import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  CardActions,
  InputLabel,
  Select,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DatePicker } from '@mui/lab'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useStore } from '../StoreContext'

function TaskList() {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [newTask, setNewTask] = useState({
    task_type: '',
    recipient_phone: '',
    message_content: '',
    scheduled_time: new Date().toISOString(),
    bot_id: '',
  })
  const [bots, setBots] = useState([])
  const { user } = useStore()

  useEffect(() => {
    if (!user?.user_id) return
    fetchTasks()
    fetchBots()
  }, [user])

  const fetchTasks = async () => {
    try {
      setError(null)
      setLoading(true)

      const { data, error } = await supabase.from('bots_task_queue').select('*')

      if (error) throw error

      setTasks(data)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchBots = async () => {
    if (!user) {
      setBots([])
      return
    }
    const { data: bots, error } = await supabase
      .from('bots')
      .select('*')
      .eq('user_id', user.id)
      .is('deleted', null)

    if (error) {
      console.error('Error fetching bots', error)
    } else {
      setBots(bots || [])
    }
  }

  const editTask = task => {
    setTaskToEdit(task)
    setNewTask(task)
  }

  const filteredTasks = tasks.filter(task =>
    task.message_content.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleCreateTask = async () => {
    try {
      const { data, error } = await supabase.from('bots_task_queue').insert([newTask])

      if (error) throw error

      setTasks([...tasks, data[0]])
      handleCancel()
    } catch (error) {
      console.error('Error creating task:', error.message)
    }
  }

  const handleEditTask = async () => {
    try {
      const { data, error } = await supabase.from('bots_task_queue').update(newTask).match({
        task_id: taskToEdit.task_id,
      })

      if (error) throw error

      const updatedTasks = tasks.map(task => (task.task_id === taskToEdit.task_id ? data[0] : task))

      setTasks(updatedTasks)
      handleCancel()
    } catch (error) {
      console.error('Error updating task:', error.message)
    }
  }

  const handleCancel = () => {
    setTaskToEdit(null)
    setNewTask({
      task_type: '',
      recipient_phone: '',
      message_content: '',
      scheduled_time: new Date().toISOString(),
      bot_id: '',
    })
  }

  return (
    <Box p={4}>
      <Typography color="white" variant="h4" gutterBottom>
        Task List
      </Typography>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <TextField
          label="Search Task"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          sx={{
            marginBottom: 2,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => setTaskToEdit({})}
        >
          Create Task
        </Button>
      </Box>
      <Grid container spacing={3}>
        {filteredTasks.map(task => (
          <Grid item xs={12} sm={6} md={4} key={task.task_id}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Task ID: {task.task_id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Recipient Phone: {task.recipient_phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Message Content: {task.message_content}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Scheduled Time: {task.scheduled_time}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Task Status: {task.task_status}
                </Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => editTask(task)} aria-label="Edit Task">
                  <EditIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={!!taskToEdit} onClose={handleCancel}>
        <DialogTitle>{taskToEdit ? 'Edit Task' : 'Create Task'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Task Type"
            variant="outlined"
            fullWidth
            value={newTask.task_type}
            onChange={e =>
              setNewTask({
                ...newTask,
                task_type: e.target.value,
              })
            }
            sx={{
              marginBottom: 2,
            }}
          />
          <TextField
            label="Recipient Phone"
            variant="outlined"
            fullWidth
            value={newTask.recipient_phone}
            onChange={e =>
              setNewTask({
                ...newTask,
                recipient_phone: e.target.value,
              })
            }
            sx={{
              marginBottom: 2,
            }}
          />
          <TextField
            label="Message Content"
            variant="outlined"
            fullWidth
            value={newTask.message_content}
            onChange={e =>
              setNewTask({
                ...newTask,
                message_content: e.target.value,
              })
            }
            sx={{
              marginBottom: 2,
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Scheduled Time"
              value={new Date(newTask.scheduled_time)}
              onChange={date =>
                setNewTask({
                  ...newTask,
                  scheduled_time: date.toISOString(),
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginBottom: 2,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <FormControl
            variant="outlined"
            fullWidth
            sx={{
              marginBottom: 2,
            }}
          >
            <InputLabel htmlFor="bot-select">Bot</InputLabel>
            <Select
              value={newTask.bot_id}
              onChange={e =>
                setNewTask({
                  ...newTask,
                  bot_id: e.target.value,
                })
              }
              label="Bot"
              inputProps={{
                name: 'bot',
                id: 'bot-select',
              }}
            >
              {bots.map(bot => (
                <MenuItem key={bot.id} value={bot.id}>
                  {bot.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={taskToEdit ? handleEditTask : handleCreateTask}
            variant="contained"
            color="primary"
          >
            {taskToEdit ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TaskList
