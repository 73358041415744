import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Divider,
  TextField,
  Slider,
  Tooltip,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoTooltip = ({ title }) => (
  <Tooltip title={title} arrow>
    <IconButton size="small" sx={{ ml: 1 }}>
      <InfoIcon fontSize="small" />
    </IconButton>
  </Tooltip>
);

const FineTuning = () => {
  // Estados para Configuración de Mensajes de Voz
  const [audioOption, setAudioOption] = useState('no-audio');
  const [voiceType, setVoiceType] = useState('alloy');
  const [audioActivated, setAudioActivated] = useState(false);

  // Estados para campos de personalización de Audio
  const [pitch, setPitch] = useState(50);
  const [speed, setSpeed] = useState(50);
  const [volume, setVolume] = useState(true);
  const [language, setLanguage] = useState('en');

  // Estados para opciones simplificadas de Audio
  const [temperature, setTemperature] = useState(0.8);
  const [topP, setTopP] = useState(0.9);
  const [stop, setStop] = useState(["\n", "user:"]);

  // Estados para Configuración de Procesamiento de Imágenes
  const [imageProcessingActivated, setImageProcessingActivated] = useState(false);

  // Estados para campos de personalización de Imagen
  const [imageResolution, setImageResolution] = useState('1080p');
  const [imageStyle, setImageStyle] = useState('realistic');
  const [enableFilters, setEnableFilters] = useState(true);

  // Handlers para Configuración de Mensajes de Voz
  const handleAudioOptionChange = (event) => {
    setAudioOption(event.target.value);
  };

  const handleVoiceTypeChange = (event) => {
    setVoiceType(event.target.value);
  };

  const handleAudioSwitchChange = () => {
    setAudioActivated((prevState) => !prevState);
  };

  const handlePitchChange = (event, newValue) => {
    setPitch(newValue);
  };

  const handleSpeedChange = (event, newValue) => {
    setSpeed(newValue);
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.checked);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleStopChange = (index, value) => {
    const newStop = [...stop];
    newStop[index] = value;
    setStop(newStop);
  };

  // Handlers para Configuración de Procesamiento de Imágenes
  const handleImageProcessingSwitchChange = () => {
    setImageProcessingActivated((prevState) => !prevState);
  };

  const handleImageResolutionChange = (event) => {
    setImageResolution(event.target.value);
  };

  const handleImageStyleChange = (event) => {
    setImageStyle(event.target.value);
  };

  const handleEnableFiltersChange = (event) => {
    setEnableFilters(event.target.checked);
  };

  return (
    <Grid  spacing={4}>
      <Grid item xs={12} md={10} lg={8}>
        {/* Configuración de Mensajes de Voz */}
        <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
          <Typography variant="h5" color="primary" gutterBottom>
            Configuración de Mensajes de Voz 🎤
          </Typography>

          <FormControl component="fieldset" fullWidth>
            {/* Activar Audios */}
            <FormControlLabel
              control={
                <Switch
                  checked={audioActivated}
                  onChange={handleAudioSwitchChange}
                  color="primary"
                />
              }
              label="Activar Audios"
            />

            {/* Opciones de Audio */}
            <RadioGroup
              aria-label="audio-options"
              name="audio-responses"
              value={audioOption}
              onChange={handleAudioOptionChange}
            >
              <FormControlLabel
                value="audio-responses"
                control={<Radio color="primary" />}
                label="Permitir únicamente respuestas de audio a mensajes de audio"
              />
              <FormControlLabel
                value="all-audio"
                control={<Radio color="primary" />}
                label="Responder con audio a todos los mensajes"
              />
            </RadioGroup>

            {/* Modelo de Síntesis de Voz */}
            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="voice-type-label">Modelo de Síntesis de Voz</InputLabel>
                <Select
                  labelId="voice-type-label"
                  value={voiceType}
                  onChange={handleVoiceTypeChange}
                  label="Modelo de Síntesis de Voz"
                  disabled={!audioActivated}
                >
                  <MenuItem value="alloy">Alloy</MenuItem>
                  <MenuItem value="echo">Echo</MenuItem>
                  <MenuItem value="onyx">Onyx</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Divider sx={{ my: 3 }} />

            {/* Campos de Personalización de Audio */}
            <Box>
              <Typography gutterBottom variant="body1">
                Pitch (Tono) <InfoTooltip title="Ajusta el tono de la voz." />
              </Typography>
              <Slider
                value={pitch}
                onChange={handlePitchChange}
                aria-labelledby="pitch-slider"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                color="secondary"
                disabled={!audioActivated}
              />
            </Box>

            <Box mt={3}>
              <Typography gutterBottom variant="body1">
                Velocidad <InfoTooltip title="Ajusta la velocidad de la voz." />
              </Typography>
              <Slider
                value={speed}
                onChange={handleSpeedChange}
                aria-labelledby="speed-slider"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                color="secondary"
                disabled={!audioActivated}
              />
            </Box>

            <Box mt={3} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={volume}
                    onChange={handleVolumeChange}
                    color="secondary"
                    disabled={!audioActivated}
                  />
                }
                label="Control de Volumen"
              />
            </Box>

            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="language-label">Idioma</InputLabel>
                <Select
                  labelId="language-label"
                  value={language}
                  onChange={handleLanguageChange}
                  label="Idioma"
                  disabled={!audioActivated}
                >
                  <MenuItem value="en">Inglés</MenuItem>
                  <MenuItem value="es">Español</MenuItem>
                  <MenuItem value="fr">Francés</MenuItem>
                  <MenuItem value="de">Alemán</MenuItem>
                  <MenuItem value="jp">Japonés</MenuItem>
                  <MenuItem value="zh">Chino</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Divider sx={{ my: 3 }} />

            {/* Opciones Simplificadas de Audio */}
            <Box>
              <Typography gutterBottom variant="body1">
                Temperatura <InfoTooltip title="Controla la aleatoriedad de las respuestas." />
              </Typography>
              <Slider
                value={temperature}
                onChange={(e, val) => setTemperature(val)}
                aria-labelledby="temperature-slider"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.1}
                color="secondary"
                disabled={false}
              />
            </Box>

            <Box mt={3}>
              <Typography gutterBottom variant="body1">
                Top P <InfoTooltip title="Probabilidad acumulativa para considerar las opciones." />
              </Typography>
              <Slider
                value={topP}
                onChange={(e, val) => setTopP(val)}
                aria-labelledby="topP-slider"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.1}
                color="secondary"
                disabled={false}
              />
            </Box>

            <Box mt={3}>
              <Typography gutterBottom variant="body1">
                Secuencias de Parada <InfoTooltip title='Secuencias de parada para la generación (ej. "\\n", "user:").' />
              </Typography>
              {stop.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <TextField
                    label={`Stop ${index + 1}`}
                    value={item}
                    onChange={(e) => handleStopChange(index, e.target.value)}
                    fullWidth
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={false}
                  />
                </Box>
              ))}
              {stop.length < 5 && (
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    color="primary"
                    onClick={() => setStop([...stop, ""])}
                    disabled={false}
                  >
                    <InfoIcon />
                    <Typography variant="button" sx={{ ml: 0.5 }}>
                      Añadir Stop
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </Box>
          </FormControl>
        </Paper>

        {/* Configuración de Procesamiento de Imágenes */}
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h5" color="primary" gutterBottom>
            Configuración de Procesamiento de Imágenes 🖼️
          </Typography>

          <FormControl component="fieldset" fullWidth>
            {/* Activar Procesamiento de Imágenes */}
            <FormControlLabel
              control={
                <Switch
                  checked={imageProcessingActivated}
                  onChange={handleImageProcessingSwitchChange}
                  color="primary"
                />
              }
              label="Activar Procesamiento de Imágenes"
            />

            {/* Opciones de Procesamiento de Imágenes */}
            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="image-resolution-label">Resolución de Imagen</InputLabel>
                <Select
                  labelId="image-resolution-label"
                  value={imageResolution}
                  onChange={handleImageResolutionChange}
                  label="Resolución de Imagen"
                  disabled={!imageProcessingActivated}
                >
                  <MenuItem value="720p">720p</MenuItem>
                  <MenuItem value="1080p">1080p</MenuItem>
                  <MenuItem value="4K">4K</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="image-style-label">Estilo de Imagen</InputLabel>
                <Select
                  labelId="image-style-label"
                  value={imageStyle}
                  onChange={handleImageStyleChange}
                  label="Estilo de Imagen"
                  disabled={!imageProcessingActivated}
                >
                  <MenuItem value="realistic">Realista</MenuItem>
                  <MenuItem value="cartoon">Cómic</MenuItem>
                  <MenuItem value="abstract">Abstracto</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box mt={3} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={enableFilters}
                    onChange={handleEnableFiltersChange}
                    color="secondary"
                    disabled={!imageProcessingActivated}
                  />
                }
                label="Habilitar Filtros"
              />
            </Box>
          </FormControl>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FineTuning;
