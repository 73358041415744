const dev = {
  avatar_host: 'http://localhost:8080',
  assets_host: 'http://localhost:8083',
  api_host: 'http://localhost:8082',
  mapBox_apiKey:
    'pk.eyJ1IjoibWFydHNvIiwiYSI6ImNsNjVhamQ0ODEzMTQzamx2M2JvMng1N2UifQ.DCEOXTWKe9VeiqA3PEy7tA',
  breakPint: {
    s: 400,
    m: 800,
    l: 1200,
  },
}

const prod = {
  avatar_host: 'https://avatar.nieriverso.com',
  assets_host: 'https://assets.nieriverso.com',
  api_host: 'https://api.nieriverso.com',
  mapBox_apiKey:
    'pk.eyJ1IjoibWFydHNvIiwiYSI6ImNsN2l1MzBkdjBsb2kzdnQ4a2hoMHhwZmYifQ.pQ9znvNXLq4Nwlkq61u-VQ',
  breakPint: {
    s: 400,
    m: 800,
    l: 1200,
  },
}

const automatic = dev

export default {
  dev,
  prod,
  automatic,
}
