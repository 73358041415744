import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Paper,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

const People = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [userIdToBlock, setUserIdToBlock] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = async () => {
    try {
      setLoading(true)
      const { data, error } = await supabase.from('user_profiles').select('*')
      if (error) throw error
      setUsers(data)
    } catch (error) {
      console.error('Error fetching users:', error.message)
      setError('Error fetching users')
    } finally {
      setLoading(false)
    }
  }

  const handleBlockUser = async userId => {
    try {
      setLoading(true)
      const { error } = await supabase
        .from('user_profiles')
        .update({
          blocked: true,
        })
        .match({
          user_id: userId,
        })
      if (error) throw error
      fetchUsers()
    } catch (error) {
      console.error('Error blocking user:', error.message)
      setError('Error blocking user')
    } finally {
      setLoading(false)
    }
  }

  const handleUnblockUser = async userId => {
    try {
      setLoading(true)
      const { error } = await supabase
        .from('user_profiles')
        .update({
          blocked: false,
        })
        .match({
          user_id: userId,
        })
      if (error) throw error
      fetchUsers()
    } catch (error) {
      console.error('Error unblocking user:', error.message)
      setError('Error unblocking user')
    } finally {
      setLoading(false)
    }
  }

  const confirmBlockUser = userId => {
    setUserIdToBlock(userId)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmBlock = () => {
    handleBlockUser(userIdToBlock)
    handleCloseDialog()
  }

  const handleConfirmUnblock = userId => {
    handleUnblockUser(userId)
  }

  const filteredUsers = users.filter(user => {
    const email = user.email || ''
    const name = user.name || ''
    const lastname = user.lastname || ''
    return (
      email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lastname.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
      }}
    >
      <Typography color={'white'} variant="h4" component="h1" gutterBottom>
        Usuarios
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item xs={8}>
          <TextField
            label="Buscar usuarios"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Grid>
      </Grid>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <>
          {filteredUsers.length === 0 ? (
            <Typography variant="body1">No se encontraron usuarios.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="users table">
                <TableHead>
                  <TableRow>
                    <TableCell>Perfil</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Experiencia</TableCell>
                    <TableCell>Influencia</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map(user => (
                    <TableRow key={user.user_id}>
                      <TableCell>
                        <Link
                          to={`/user/profile/${user.user_id}`}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          Ver perfil
                        </Link>
                      </TableCell>
                      <TableCell>
                        {user.name} {user.lastname}
                      </TableCell>
                      <TableCell>{user.experience}</TableCell>
                      <TableCell>{user.influence}</TableCell>
                      <TableCell>
                        {user.blocked ? (
                          <Button variant="contained" color="primary">
                            Seguir
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary">
                            Dejar de seguir
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  )
}

export default People
