import React, { useState, useRef } from 'react'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
  Button,
} from '@mui/material'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

const Filters = ({ onClose, onApplyFilters }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ])

  const [checked, setChecked] = useState({
    publicSpaces: true,
    shops: true,
    events: true,
    spaces3D: true,
    posts: true,
  })

  const calendarRef = useRef(null)
  const checkboxesRef = useRef(null)

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  const handleCheckboxChange = event => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    })
  }

  const getCheckboxStyle = name => {
    switch (name) {
      case 'posts':
        return { color: '#ff9800', backgroundColor: checked[name] ? '#2196f3' : 'inherit' }
      case 'shops':
        return { color: '#ff9800', backgroundColor: checked[name] ? '#ff9800' : 'inherit' }
      case 'events':
        return { color: '#ffeb3b', backgroundColor: checked[name] ? '#ffeb3b' : 'inherit' }
      case 'spaces3D':
        return { color: '#9c27b0', backgroundColor: checked[name] ? '#9c27b0' : 'inherit' }
      default:
        return { color: '#2196f3', backgroundColor: checked[name] ? 'inherit' : 'inherit' }
    }
  }

  const handleClickOutside = event => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target) &&
      checkboxesRef.current &&
      !checkboxesRef.current.contains(event.target)
    ) {
      if (onClose) {
        onClose()
      }
    }
  }

  const handleApplyFilters = () => {
    if (onApplyFilters) {
      onApplyFilters({ dateRange: state, filters: checked })
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <Box
      id="filters-modal-container"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Fondo semi-transparente
        zIndex: 1300, // Para asegurarse de que esté por encima de otros elementos
      }}
      onClick={handleClickOutside}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: { xs: 320, sm: 400 }, // Ajuste para pantallas móviles
          padding: 2,
          margin: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', marginBottom: 2 }}>
          Filtrar por:
        </Typography>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            minHeight: 'auto',
            '& .MuiTab-root': {
              fontSize: '0.875rem', // Tamaño de fuente más pequeño
              color: 'white', // Texto blanco
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#fff', // Indicador blanco
            },
          }}
        >
          <Tab label="Fecha" />
          <Tab label="Tipo" />
        </Tabs>
        {tabIndex === 0 && (
          <Box ref={calendarRef} sx={{ paddingTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DateRange
                  editableDateInputs={true}
                  onChange={item => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box ref={checkboxesRef} sx={{ paddingTop: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.publicSpaces}
                    onChange={handleCheckboxChange}
                    name="publicSpaces"
                    sx={getCheckboxStyle('publicSpaces')}
                  />
                }
                label="Espacios Públicos"
                sx={{ color: 'white' }} // Texto blanco
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.shops}
                    onChange={handleCheckboxChange}
                    name="shops"
                    sx={getCheckboxStyle('shops')}
                  />
                }
                label="Tiendas"
                sx={{ color: 'white' }} // Texto blanco
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.posts}
                    onChange={handleCheckboxChange}
                    name="posts"
                    sx={getCheckboxStyle('posts')}
                  />
                }
                label="Posts"
                sx={{ color: 'white' }} // Texto blanco
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.events}
                    onChange={handleCheckboxChange}
                    name="events"
                    sx={getCheckboxStyle('events')}
                  />
                }
                label="Eventos"
                sx={{ color: 'white' }} // Texto blanco
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.spaces3D}
                    onChange={handleCheckboxChange}
                    name="spaces3D"
                    sx={getCheckboxStyle('spaces3D')}
                  />
                }
                label="Espacios 3D"
                sx={{ color: 'white' }} // Texto blanco
              />
            </FormGroup>
          </Box>
        )}
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Button variant="contained" color="primary" onClick={handleApplyFilters}>
            Filtrar
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default Filters
