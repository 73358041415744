import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Grid, IconButton, Link as MuiLink } from '@mui/material'
import { Instagram } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'
import LangSelector from './LangSelector'
import { useTranslation } from 'react-i18next'
import { useStore } from './../../StoreContext'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'

const AnimatedBox = animated(Box)
const AnimatedGrid = animated(Grid)
const AnimatedTypography = animated(Typography)

const Footer = () => {
  const { t } = useTranslation()
  const { user } = useStore()
  const [siteName, setSiteName] = useState('')

  useEffect(() => {
    const url = new URL(window.location.href)
    const hostname = url.hostname
    const parts = hostname.split('.')

    // Excluir subdominio y la extensión de dominio (.com, .net, etc.)
    let domainName = parts.length > 2 ? parts.slice(-2, -1)[0] : parts[0]

    setSiteName(domainName)
  }, [])

  // Company Information Animation
  const [companyRef, companyInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const companyAnimation = useSpring({
    opacity: companyInView ? 1 : 0,
    transform: companyInView ? 'translateX(0)' : 'translateX(-20px)',
    config: {
      duration: 700,
    },
  })

  // Links Animation
  const [linksRef, linksInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const linksAnimation = useSpring({
    opacity: linksInView ? 1 : 0,
    transform: linksInView ? 'translateY(0)' : 'translateY(20px)',
    config: {
      duration: 1000,
    },
  })

  // Tools Animation
  const [toolsRef, toolsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const toolsAnimation = useSpring({
    opacity: toolsInView ? 1 : 0,
    transform: toolsInView ? 'scale(1)' : 'scale(0.9)',
    config: {
      duration: 1000,
    },
  })

  return (
    <Container
      component="footer"
      sx={{
        color: 'common.white',
        py: 6,
      }}
    >
      <Grid
        item
        style={{
          marginTop: '20vh',
          marginBottom: '70px',
        }}
        container
        xs={12}
        spacing={3}
        justifyContent="space-between"
      >
        {!user && (
          <AnimatedGrid item xs={12} ref={companyRef} style={companyAnimation}>
            <Grid
              item
              xs={12}
              md={8}
              justifyContent="space-between"
              style={{
                paddingBottom: '50px',
              }}
            >
              <Typography variant="h6" color="inherit" gutterBottom>
                {siteName}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t('footer.companyInfo')}
              </Typography>
              <br />
              <Typography variant="body2" gutterBottom>
                {t('footer.toolsOffer')}
              </Typography>
            </Grid>
          </AnimatedGrid>
        )}

        <br />
        <br />
        <br />

        {/* Navigation Links */}
        <AnimatedGrid item xs={12} md={4} ref={linksRef} style={linksAnimation}>
          <Typography variant="h6" color="inherit" gutterBottom>
            {t('footer.links')}
          </Typography>

          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="Instagram"
              color="inherit"
              component="a"
              href="https://www.instagram.com/nieriverso"
              target="_blank"
            >
              <Instagram />
            </IconButton>
          </Box>

          {false && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <MuiLink component={RouterLink} to="https://portal.nieriverso.com" color="inherit">
                {t('footer.portal')}
              </MuiLink>
            </Box>
          )}
        </AnimatedGrid>

        {false && (
          <AnimatedGrid item xs={12} md={4} ref={toolsRef} style={toolsAnimation}>
            <Typography variant="h6" color="inherit" gutterBottom>
              {t('footer.information')}
            </Typography>
            {true && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <MuiLink component={RouterLink} to="/flows" color="inherit">
                  {t('footer.integrationFlows')}
                </MuiLink>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <MuiLink component={RouterLink} to="/download" color="inherit">
                {t('footer.download')}
              </MuiLink>
            </Box>
          </AnimatedGrid>
        )}

        <AnimatedGrid item xs={12} md={4} ref={toolsRef} style={toolsAnimation}>
          <Typography variant="h6" color="inherit" gutterBottom>
            {t('footer.tools')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <MuiLink component={RouterLink} to="/qr" color="inherit">
              {t('footer.readerQR')}
            </MuiLink>
          </Box>
          <br/>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <MuiLink component={RouterLink} to="/link_device" color="inherit">
              {t('footer.brodcast')}
            </MuiLink>
          </Box>
        </AnimatedGrid>
      </Grid>
      <br />

      <AnimatedBox
        style={{
          ...linksAnimation,
          textAlign: 'center',
          marginBottom: '40px',
          marginTop: '40px',
        }}
      >
        <Typography variant="body2" color="inherit">
          © {new Date().getFullYear()} {siteName}.com
        </Typography>
        <Typography variant="body2" color="inherit">
          {t('footer.rightsReserved')}
        </Typography>
      </AnimatedBox>
      {!user && (
        <AnimatedBox
          style={{
            ...companyAnimation,
            textAlign: 'center',
            marginBottom: '-35px',
          }}
        >
          <LangSelector showText />
        </AnimatedBox>
      )}
    </Container>
  )
}

export default Footer
