import React, { useState, useRef } from 'react'
import { supabase } from './../../supabaseClient'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  TextField,
  CircularProgress,
  Typography,
  Container,
  Box,
  Alert,
  LinearProgress,
  InputAdornment,
  IconButton,
  Paper,
  Avatar,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useStore } from './../../StoreContext'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from 'react-spring'
import GoogleIcon from '@mui/icons-material/Google'
import ReCAPTCHA from 'react-google-recaptcha'

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [isLogin, setIsLogin] = useState(true)
  const navigate = useNavigate()
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [strengthColor, setStrengthColor] = useState('primary')
  const [strengthMessage, setStrengthMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useStore()
  const { t } = useTranslation()
  const [reCaptchaToken, setReCaptchaToken] = useState(null)
  const recaptchaRef = useRef(null)
  const [captchaRequired, setCaptchaRequired] = useState(false) // Añadido para controlar el captcha

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const calculatePasswordStrength = password => {
    let strength = 19
    let message = ''
    if (password.length >= 6) strength += 20
    if (password.length >= 8) {
      strength += 20
      message = t('password_strength.acceptable')
    }
    if (/[A-Z]/.test(password)) {
      strength += 20
      message = t('password_strength.strong')
    }
    if (/[0-9]/.test(password)) {
      strength += 20
      message = t('password_strength.very_strong')
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      strength += 20
      message = t('password_strength.excellent')
    }

    if (strength < 40) {
      setStrengthColor('error')
      message = t('password_strength.very_weak')
    } else if (strength < 80) {
      setStrengthColor('warning')
    } else {
      setStrengthColor('success')
    }

    setPasswordStrength(strength)
    setStrengthMessage(message)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    setSuccessMessage(null)

    let token = null

    if (captchaRequired && window.location.hostname !== 'localhost') {
      // Verifica el token de reCAPTCHA solo si se requiere el captcha
      token = await recaptchaRef.current.executeAsync()
      setReCaptchaToken(token)
      recaptchaRef.current.reset()

      if (!token) {
        setError(t('error.recaptcha_failed'))
        setLoading(false)
        return
      }
    }

    if (!isLogin && password !== confirmPassword) {
      setError(t('error.passwords_not_match'))
      setLoading(false)
      return
    }

    try {
      let resultado = null

      if (isLogin) {
        resultado = await supabase.auth.signInWithPassword({
          email,
          password,
          options: {
            data: {
              lang: 'es',
            },
          },
        })
      } else {
        resultado = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: {
              lang: 'es',
            },
          },
        })
      }

      const { user, session, error } = resultado

      if (error) throw error
      if (user && isLogin) onLogin(user, session)

      if (!isLogin) {
        setSuccessMessage(t('login.check_email_confirmation'))
      } else {
        const postLoginRedirect = localStorage.getItem('postLoginRedirect')

        if (postLoginRedirect) {
          navigate(postLoginRedirect)
          localStorage.removeItem('postLoginRedirect')
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Error:', error.message)
      setError(error.message)
      setCaptchaRequired(true) // Requerir captcha después de un intento fallido
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleSignIn = async () => {
    setLoading(true)
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      })

      if (error) throw error
    } catch (error) {
      console.error('Error:', error.message)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (user) navigate('/')

  const containerAnimation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    config: {
      duration: 1000,
    },
  })

  const itemAnimation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    delay: 200,
    config: {
      duration: 500,
    },
  })

  return (
    <animated.div style={containerAnimation}>
      <Container component="main" sx={{ width: 'fit-content' }}>
        {successMessage ? (
          <Paper
            elevation={3}
            sx={{
              mt: 8,
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: 'success.main',
                }}
              >
                <CheckCircleOutlineIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {t('registration.success_title')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  textAlign: 'center',
                }}
              >
                {successMessage}
              </Typography>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
                onClick={() => navigate('/')}
              >
                {t('registration.login_button')}
              </Button>
            </Box>
          </Paper>
        ) : (
          <Paper
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Typography component="h1" variant="h5">
              {isLogin ? t('login.sign_in') : t('login.sign_up')}
            </Typography>

            {error && (
              <Alert
                severity="error"
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              >
                {error}
              </Alert>
            )}

            <Link to="/forgot-password">{t('login.forgot_password')}</Link>

            <form
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
              }}
            >
              <animated.div style={itemAnimation}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('login.email')}
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </animated.div>
              <animated.div style={itemAnimation}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('login.password')}
                  type={showPassword ? 'text' : 'password'} id="password"
                   InputProps={{ endAdornment: (<InputAdornment position="end"> <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} > {showPassword ? <VisibilityOff /> : <Visibility />} </IconButton> </InputAdornment>), }} value={password} onChange={e => {
                    setPassword(e.target.value)
                    calculatePasswordStrength(e.target.value)
                  }} />
              </animated.div>
              {!isLogin && (
                <animated.div style={itemAnimation}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={t('login.confirm_password')}
                    type={showPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress
                      variant="determinate"
                      value={passwordStrength}
                      color={strengthColor}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {strengthMessage}
                    </Typography>
                  </Box>
                </animated.div>
              )}

              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={!email || !password}
                >
                  {isLogin ? t('login.sign_in') : t('login.sign_up')}
                </Button>
              )}

              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                sx={{ mb: 2 }}
                onClick={handleGoogleSignIn}
              >
                {t('login.sign_in_with_google')}
              </Button>

              {captchaRequired && window.location.hostname !== 'localhost' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lf9lughAAAAAEfrHRdQJuY-QrefA2qwAWQxRY-F"
                    size="invisible"
                    type="image"
                    onChange={token => setReCaptchaToken(token)}
                  />
                </Box>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span fullWidth variant="text" onClick={() => setIsLogin(!isLogin)}>
                  {isLogin ? t('login.create_account') : t('login.have_account')}
                </span>
              </div>
            </form>
          </Paper>
        )}
      </Container>
    </animated.div>
  )
}

export default Login