import * as React from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Footer2.css'

const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="footerNavegation">
      <div onClick={() => navigate('/mission')} className="footerNavegationOption">
        <span href="./mission">{t('login.mission')}</span>
      </div>
      <div onClick={() => navigate('/aboutus')} className="footerNavegationOption">
        <span href="./aboutus">{t('login.aboutus')}</span>
      </div>
      <div onClick={() => navigate('/terms')} className="footerNavegationOption">
        {t('login.terms')}
      </div>
      <div onClick={() => navigate('/security')} className="footerNavegationOption">
        {t('login.security')}
      </div>
    </div>
  )
}

export default Footer
