// src/components/EventQueue.js
import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import {
  Box,
  Button,
  Paper,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

const EventQueue = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [newEvent, setNewEvent] = useState({
    event_type: '',
    payload: '',
    status: 'pending',
    created_by: 'user123',
  }) // Change 'user123' as needed

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    try {
      setLoading(true)
      const { data, error } = await supabase.from('event_queue').select('*')
      if (error) throw error
      setEvents(data)
    } catch (error) {
      console.error('Error fetching events:', error.message)
      setError('Error fetching events')
    } finally {
      setLoading(false)
    }
  }

  const handleAddEvent = async () => {
    try {
      setLoading(true)
      const { error } = await supabase.from('event_queue').insert([newEvent])
      if (error) throw error
      fetchEvents()
      setNewEvent({
        event_type: '',
        payload: '',
        status: 'pending',
        created_by: 'user123',
      }) // Reset form fields
    } catch (error) {
      console.error('Error adding event:', error.message)
      setError('Error adding event')
    } finally {
      setLoading(false)
    }
  }

  const handleEditEvent = async id => {
    try {
      setLoading(true)
      const { error } = await supabase.from('event_queue').update(selectedEvent).match({
        id,
      })
      if (error) throw error
      fetchEvents()
      setSelectedEvent(null)
    } catch (error) {
      console.error('Error editing event:', error.message)
      setError('Error editing event')
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteEvent = async id => {
    try {
      setLoading(true)
      const { error } = await supabase.from('event_queue').delete().match({
        id,
      })
      if (error) throw error
      fetchEvents()
    } catch (error) {
      console.error('Error deleting event:', error.message)
      setError('Error deleting event')
    } finally {
      setLoading(false)
    }
  }

  const confirmDeleteEvent = event => {
    setSelectedEvent(event)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmDelete = () => {
    handleDeleteEvent(selectedEvent.id)
    handleCloseDialog()
  }

  const filteredEvents = events.filter(event => {
    const eventType = event.event_type || ''
    const payload = event.payload || ''
    return (
      eventType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payload.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  return (
    <Box
      p={4}
      sx={{
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Administrar Eventos
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item xs={8}>
          <TextField
            label="Buscar eventos"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Grid>
      </Grid>
      <Box mb={2}>
        <TextField
          label="Tipo de evento"
          variant="outlined"
          value={newEvent.event_type}
          onChange={e =>
            setNewEvent({
              ...newEvent,
              event_type: e.target.value,
            })
          }
          sx={{
            marginRight: 2,
          }}
        />
        <TextField
          label="Payload"
          variant="outlined"
          value={newEvent.payload}
          onChange={e =>
            setNewEvent({
              ...newEvent,
              payload: e.target.value,
            })
          }
          sx={{
            marginRight: 2,
            width: '30ch',
          }} // Increased width for payload field
          multiline
        />
        <Button variant="contained" color="primary" onClick={handleAddEvent}>
          Agregar Evento
        </Button>
      </Box>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <>
          {filteredEvents.length === 0 ? (
            <Typography variant="body1">No se encontraron eventos.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="events table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Tipo de Evento</TableCell>
                    <TableCell>Payload</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Creado</TableCell>
                    <TableCell>Procesado</TableCell>
                    <TableCell>Creado Por</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEvents.map(event => (
                    <TableRow key={event.id}>
                      <TableCell>{event.id}</TableCell>
                      <TableCell>{event.event_type}</TableCell>
                      <TableCell>{event.payload}</TableCell>
                      <TableCell>{event.status}</TableCell>
                      <TableCell>{new Date(event.created_at).toLocaleString()}</TableCell>
                      <TableCell>
                        {event.processed_at ? new Date(event.processed_at).toLocaleString() : 'N/A'}
                      </TableCell>
                      <TableCell>{event.created_by}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => confirmDeleteEvent(event)}
                          sx={{
                            marginRight: 1,
                          }}
                        >
                          Eliminar
                        </Button>
                        <Button variant="contained" onClick={() => setSelectedEvent(event)}>
                          Editar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      <Dialog open={Boolean(selectedEvent)} onClose={() => setSelectedEvent(null)}>
        <DialogTitle>Editar Evento</DialogTitle>
        <DialogContent>
          <DialogContentText>Edita los campos del evento y guarda los cambios.</DialogContentText>
          {selectedEvent && (
            <>
              <TextField
                label="Tipo de evento"
                variant="outlined"
                value={selectedEvent.event_type}
                onChange={e =>
                  setSelectedEvent({
                    ...selectedEvent,
                    event_type: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
              />
              <TextField
                label="Payload"
                variant="white"
                value={selectedEvent.payload}
                onChange={e =>
                  setSelectedEvent({
                    ...selectedEvent,
                    payload: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
                multiline
              />
              <TextField
                label="Estado"
                variant="outlined"
                value={selectedEvent.status}
                onChange={e =>
                  setSelectedEvent({
                    ...selectedEvent,
                    status: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
              />
              <TextField
                label="Creado Por"
                variant="outlined"
                value={selectedEvent.created_by}
                onChange={e =>
                  setSelectedEvent({
                    ...selectedEvent,
                    created_by: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedEvent(null)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleEditEvent(selectedEvent.id)} color="secondary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar este evento? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EventQueue
