import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField, Typography, CircularProgress, Alert, Paper } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers'
import 'mapbox-gl/dist/mapbox-gl.css'
import config from './../../config.js'
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  ScaleControl,
} from 'react-map-gl'
import dayjs from 'dayjs'

function EventsView() {
  const { t } = useTranslation()
  const { eventId } = useParams()
  const navigate = useNavigate()
  const [event, setEvent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState({
    name: '',
    descripcion: '',
    fecha: null,
    hora: null,
    invitados: '',
    confirmados: '',
    longitude: '',
    latitude: '',
  })

  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    fetchEvent()
  }, [eventId])

  const fetchEvent = async () => {
    try {
      setError(null)
      setLoading(true)

      const { data, error } = await supabase.from('events').select('*').eq('id', eventId).single()

      if (error) throw error

      setEvent(data)
      setFormValues({
        name: data.name || '',
        descripcion: data.descripcion || '',
        fecha: data.fecha ? dayjs(data.fecha) : null,
        hora: data.hora ? dayjs(`1970-01-01T${data.hora}`) : null,
        invitados: data.invitados || '',
        confirmados: data.confirmados || '',
        longitude: data.longitude || '',
        latitude: data.latitude || '',
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      [name]: '',
    }))
  }

  const handleDateChange = date => {
    setFormValues(prevState => ({
      ...prevState,
      fecha: date,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      fecha: '',
    }))
  }

  const handleTimeChange = time => {
    setFormValues(prevState => ({
      ...prevState,
      hora: time,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      hora: '',
    }))
  }

  const validateForm = () => {
    const errors = {}

    if (!formValues.name) {
      errors.name = 'El nombre es obligatorio.'
    }
    if (!formValues.descripcion) {
      errors.descripcion = 'La descripción es obligatoria.'
    }
    if (!formValues.fecha) {
      errors.fecha = 'La fecha es obligatoria.'
    }
    if (!formValues.hora) {
      errors.hora = 'La hora es obligatoria.'
    }
    return errors
  }

  const handleSave = async () => {
    const errors = validateForm()
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    try {
      setError(null)
      setLoading(true)

      const normalizedValues = {
        ...formValues,
        fecha: formValues.fecha ? formValues.fecha.toISOString().split('T')[0] : null,
        hora:
          formValues.hora instanceof Date
            ? formValues.hora.toTimeString().split(' ')[0].substring(0, 5)
            : null,
        invitados: formValues.invitados ? parseInt(formValues.invitados, 10) : null,
        confirmados: formValues.confirmados ? parseInt(formValues.confirmados, 10) : null,
        longitude: formValues.longitude ? parseFloat(formValues.longitude) : null,
        latitude: formValues.latitude ? parseFloat(formValues.latitude) : null,
      }

      const { error } = await supabase.from('events').update(normalizedValues).eq('id', eventId)

      if (error) throw error

      navigate('/events')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleMapClick = event => {
    const { lng, lat } = event.lngLat
    setFormValues(prevState => ({
      ...prevState,
      longitude: lng,
      latitude: lat,
    }))
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 500,
          width: '100%',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              color: 'primary.main',
              textAlign: 'center',
              marginBottom: 3,
            }}
          >
            {t('editEvent')}
          </Typography>
          <TextField
            label={t('eventName')}
            variant="outlined"
            fullWidth
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label={t('eventDescription')}
            variant="outlined"
            fullWidth
            name="descripcion"
            value={formValues.descripcion}
            onChange={handleInputChange}
            error={!!formErrors.descripcion}
            helperText={formErrors.descripcion}
            sx={{ marginBottom: 2 }}
          />
          <Box
            sx={{
              height: 'auto',

              marginBottom: 2,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <DatePicker
              label={t('date')}
              value={formValues.fecha}
              onChange={handleDateChange}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.fecha}
                  helperText={formErrors.fecha}
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              height: 'auto',
              marginBottom: 2,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <TimePicker
              label={t('time')}
              value={formValues.hora}
              onChange={handleTimeChange}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.hora}
                  helperText={formErrors.hora}
                  sx={{ marginBottom: 2 }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              marginBottom: 2,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <TextField
              label={t('guests')}
              variant="outlined"
              fullWidth
              name="invitados"
              value={formValues.invitados}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />{' '}
          </Box>

          <Box
            sx={{
              marginBottom: 2,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <TextField
              label={t('confirmed')}
              variant="outlined"
              fullWidth
              name="confirmados"
              value={formValues.confirmados}
              onChange={handleInputChange}
              sx={{ marginBottom: 2 }}
            />
          </Box>

          <Box
            sx={{
              height: 300,
              marginBottom: 2,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Map
              initialViewState={{
                longitude: formValues.longitude || -3.7038,
                latitude: formValues.latitude || 40.4168,
                zoom: 14,
              }}
              style={{
                width: '100%',
                height: '100%',
              }}
              mapStyle="mapbox://styles/martso/clyw8l0eh00il01qo3p0re5pl"
              mapboxAccessToken={config.automatic.mapBox_apiKey}
              onClick={handleMapClick}
            >
              <NavigationControl />
              <FullscreenControl />
              <GeolocateControl />
              <ScaleControl />
              {formValues.longitude && formValues.latitude && (
                <Marker longitude={formValues.longitude} latitude={formValues.latitude} />
              )}
            </Map>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              sx={{
                marginRight: 2,
              }}
            >
              {t('save')}
            </Button>
            <Button onClick={() => navigate('/events')} variant="outlined">
              {t('cancel')}
            </Button>
          </Box>
        </LocalizationProvider>
      </Paper>
    </Box>
  )
}

export default EventsView
