import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Tabs,
  Tab,
  Tooltip,
  TextField,
  Alert,
} from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from './../../supabaseClient'
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
  ScaleControl,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import config from './../../config.js'
import PostTimeLine from '../molecules/timeline/PostTimeLine'
import EditIcon from '@mui/icons-material/Edit'
import { useStore } from './../../StoreContext'
import RoomRender from './RoomRender.js'

const RoomView = () => {
  const { user } = useStore()
  const [room, setRoom] = useState({
    name: '',
    description: '',
    images: [],
    latitude: 0,
    longitude: 0,
  })
  const [viewport, setViewport] = useState({
    latitude: 40.4168,
    longitude: -3.7038,
    zoom: 14,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const { roomId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchRoom = async () => {
      setLoading(true)
      const { data, error } = await supabase.from('rooms').select('*').eq('id', roomId).single()
      if (error) {
        console.error('Error fetching room:', error)
        setError('Failed to fetch room data.')
      } else {
        setRoom(data)
        setViewport({
          latitude: data.latitude,
          longitude: data.longitude,
          zoom: 14,
        })
      }
      setLoading(false)
    }
    fetchRoom()
  }, [roomId])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} lg={12}>
          <Paper
            sx={{
              padding: 3,
              margin: '0 auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  flexGrow: 1,
                }}
              >
                {room.name}
              </Typography>
              {user.id === room.user_id && (
                <Tooltip title="Editar">
                  <IconButton onClick={() => navigate(`/rooms/${room.id}/edit`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
            {loading && <CircularProgress />}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              textColor="inherit"
              indicatorColor="secondary"
              sx={{
                '& .MuiTab-root': {
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                },
              }}
            >
              <Tab label="Visitar" />
              <Tab label="Posts" />
              <Tab label="Información" />
              <Tab label="Desafíos" />
              <Tab label="Eventos" />
            </Tabs>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              {[0, 1, 2, 3, 4, 5].map(index => (
                <Box
                  key={index}
                  sx={{
                    height: 10,
                    width: 10,
                    borderRadius: '50%',
                    backgroundColor: tabIndex === index ? 'secondary.main' : 'grey.500',
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <br />
            <Box
              style={{
                padding: '0px',
              }}
            >
              {tabIndex === 0 && (
                <Grid container sm={12}>
                  <Grid item sm={12}>
                    {roomId && <RoomRender room_id={roomId} />}
                  </Grid>
                </Grid>
              )}
              {tabIndex === 2 && (
                <Box>
                  <TextField
                    label="Name"
                    name="name"
                    value={room.name}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    label="Description"
                    name="description"
                    value={room.description}
                    fullWidth
                    sx={{
                      marginBottom: 2,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Box>
                    <Grid sm={12}>
                      <Typography variant="h6">Location</Typography>
                      <Grid
                        sm={12}
                        sx={{
                          height: 400,
                          marginBottom: 2,
                        }}
                      >
                        <Map
                          initialViewState={viewport}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          mapStyle="mapbox://styles/mapbox/streets-v12"
                          mapboxAccessToken={config.automatic.mapBox_apiKey}
                        >
                          <NavigationControl />
                          <FullscreenControl />
                          <GeolocateControl />
                          <ScaleControl />
                          {room.longitude && room.latitude && (
                            <Marker longitude={room.longitude} latitude={room.latitude} />
                          )}
                        </Map>
                        <TextField
                          label="Latitude"
                          name="latitude"
                          value={room.latitude}
                          fullWidth
                          sx={{
                            marginBottom: 2,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          label="Longitude"
                          name="longitude"
                          value={room.longitude}
                          fullWidth
                          sx={{
                            marginBottom: 2,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}

              {tabIndex === 1 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Posts
                  </Typography>
                  <PostTimeLine />
                </Box>
              )}
              {tabIndex === 4 && <Box></Box>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RoomView
