import React from 'react'
// Import all the necessary icons from Material-UI
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import StoreIcon from '@mui/icons-material/Store'
import RealEstateIcon from '@mui/icons-material/Apartment'
import VlogIcon from '@mui/icons-material/VideoCameraFront'
import QrCodeIcon from '@mui/icons-material/QrCode2'
import BookOnlineIcon from '@mui/icons-material/BookOnline'
import FolderIcon from '@mui/icons-material/Folder'
import CodeIcon from '@mui/icons-material/Code'
import AndroidIcon from '@mui/icons-material/Android'
import DescriptionIcon from '@mui/icons-material/Description'
import ImageIcon from '@mui/icons-material/Image'
import AudiotrackIcon from '@mui/icons-material/Audiotrack'
import InventoryIcon from '@mui/icons-material/Inventory'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'
import CoffeeIcon from '@mui/icons-material/Coffee'
import ListAltIcon from '@mui/icons-material/ListAlt'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import HelpIcon from '@mui/icons-material/Help'
import AssistantIcon from '@mui/icons-material/Assistant'
import ReportIcon from '@mui/icons-material/Report'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PsychologyIcon from '@mui/icons-material/Psychology'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import SettingsIcon from '@mui/icons-material/Settings'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import BuildIcon from '@mui/icons-material/Build'
import RoomServiceIcon from '@mui/icons-material/RoomService'
import BarChartIcon from '@mui/icons-material/BarChart'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import WarningIcon from '@mui/icons-material/Warning'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import WidgetsIcon from '@mui/icons-material/Widgets' // For 3D objects inventory
import MapIcon from '@mui/icons-material/Map' // For maps
import ViewInArIcon from '@mui/icons-material/ViewInAr' // For spaces or AR experiences
// Import additional icons as needed
import ChatIcon from '@mui/icons-material/Chat' // Podría representar Instagram Bots como una alternativa
import WebIcon from '@mui/icons-material/Web' // Para representar Web Bot
import ApiIcon from '@mui/icons-material/Api' // Representa un API Bot
import EventIcon from '@mui/icons-material/Event'
import SellIcon from '@mui/icons-material/Sell'
import CreateIcon from '@mui/icons-material/Create'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import KeyboardIcon from '@mui/icons-material/Keyboard' // Para Generador de Texto
import BrushIcon from '@mui/icons-material/Brush' // Para Generador de Imágenes
import VideoCameraIcon from '@mui/icons-material/Videocam' // Para Generador de Videos
import MicrophoneIcon from '@mui/icons-material/Mic' // Para Sintetizador de Audio
import SecurityIcon from '@mui/icons-material/Security' // Para Moderador
import Poll from '@mui/icons-material/Poll' // Para Moderador
import EventAvailable from '@mui/icons-material/EventAvailable' // Para Moderador
import Notifications from '@mui/icons-material/Notifications' // Para Moderador

import CloudIcon from '@mui/icons-material/Cloud' // Para la categoría Cloud en general
// Para la categoría Cloud en general
import WordpressIcon from '@mui/icons-material/Description' // Un icono representativo para WordPress
import DomainIcon from '@mui/icons-material/Language' // Un icono representativo para Dominios
import StorageIcon from '@mui/icons-material/Storage' // Para Almacenamiento en la Nube
// Añade aquí los imports para los demás submenús si los necesitas
import WebDesignIcon from '@mui/icons-material/WebAsset' // Un icono para Blog y WebPage
import HotelIcon from '@mui/icons-material/Hotel' // Un icono para Hotelería y Residenciales
// Añade aquí los imports para los demás submenús si los necesitas
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu' // Un icono para Hotelería y Residenciales

import GpsFixedIcon from '@mui/icons-material/GpsFixed' // Un icono para Hotelería y Residenciales

import CellTower from '@mui/icons-material/CellTower' // Un icono para Hotelería y Residenciales
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ForumIcon from '@mui/icons-material/Forum'
import CellTowerIcon from '@mui/icons-material/CellTower' // Asumiendo que este es tu "BroadcastIcon"
import RadioIcon from '@mui/icons-material/Radio' // Este ya coincide con "RadioIcon"
import DeviceHubIcon from '@mui/icons-material/DeviceHub' // Asumiendo que este podría ser tu "IoTIcon"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo' // Asumiendo que este podría ser tu "StreamingIcon"

import DisabledByDefault from '@mui/icons-material/DisabledByDefault' // Asumiendo que este podría ser tu "StreamingIcon"
import SubscriptionIcon from '@mui/icons-material/Subscriptions' // Un icono para Hotelería y Residenciales
import QueueIcon from '@mui/icons-material/Queue'

import {
  Assignment,
  Email,
  VideoLabel,
  ThreeDRotation,
  ImageOutlined,
  DescriptionOutlined,
  Gamepad,
  ScreenShare,
} from '@mui/icons-material'

const iconMapping = {
  QueueIcon: (
    <QueueIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  StorageIcon: (
    <StorageIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  Disabled: (
    <DisabledByDefault
      style={{
        color: '#000!important',
      }}
    />
  ),
  BroadcastIcon: (
    <CellTowerIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  RadioIcon: (
    <RadioIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  IoTIcon: (
    <DeviceHubIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  StreamingIcon: (
    <OndemandVideoIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  VrIcon: (
    <ThreeDRotationIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WebDesignIcon: (
    <WebDesignIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  HotelIcon: (
    <HotelIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  SubscriptionIcon: (
    <SubscriptionIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  MenuIcon: (
    <MenuIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  HomeIcon: (
    <HomeIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  StoreIcon: (
    <StoreIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  RestaurantMenuIcon: (
    <RestaurantMenuIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  RealEstateIcon: (
    <RealEstateIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  VlogIcon: (
    <VlogIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  QrCodeIcon: (
    <QrCodeIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  BookOnlineIcon: (
    <BookOnlineIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  FolderIcon: (
    <FolderIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  CodeIcon: (
    <CodeIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AndroidIcon: (
    <AndroidIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  DescriptionIcon: (
    <DescriptionIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ImageIcon: (
    <ImageIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AudiotrackIcon: (
    <AudiotrackIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  InventoryIcon: (
    <InventoryIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ThreeDRotationIcon: (
    <ThreeDRotationIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  CoffeeIcon: (
    <CoffeeIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ListAltIcon: (
    <ListAltIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  VerifiedUserIcon: (
    <VerifiedUserIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  HelpIcon: (
    <HelpIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AssistantIcon: (
    <AssistantIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ReportIcon: (
    <ReportIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  CloudIcon: (
    <CloudIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  MonetizationOnIcon: (
    <MonetizationOnIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // Agregar el nuevo ícono al mapeo
  MapIcon: (
    <MapIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  PsychologyIcon: (
    <PsychologyIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ConfirmationNumberIcon: (
    <ConfirmationNumberIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  // ...otros íconos...
  // Íconos adicionales
  SettingsIcon: (
    <SettingsIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AdminPanelSettingsIcon: (
    <AdminPanelSettingsIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  PeopleIcon: (
    <PeopleIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  PersonIcon: (
    <PersonIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  BuildIcon: (
    <BuildIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  RoomServiceIcon: (
    <RoomServiceIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  BarChartIcon: (
    <BarChartIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ShowChartIcon: (
    <ShowChartIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  NotificationsActiveIcon: (
    <NotificationsActiveIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WarningIcon: (
    <WarningIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WhatsAppIcon: (
    <WhatsAppIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  MenuIcon: (
    <MenuIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  HomeIcon: (
    <HomeIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  StoreIcon: (
    <StoreIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  GpsFixedIcon: (
    <GpsFixedIcon
      style={{
        color: '#000!important',
      }}
    />
  ),

  NieriversoIcon: (
    <ViewInArIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // This is a hypothetical name, replace with actual import
  MapaIcon: (
    <MapIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  Espacios3DIcon: (
    <WidgetsIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // This icon can represent 3D spaces in a generic way
  Objetos3DIcon: (
    <WidgetsIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // For 3D objects inventory
  RadiosIcon: (
    <RadioIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  GPTBotsIcon: (
    <AndroidIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WhatsappBotsIcon: (
    <WhatsAppIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  InstagramBotsIcon: (
    <ChatIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WebBotIcon: (
    <WebIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  APIBotIcon: (
    <ApiIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // For radios
  BookOnlineIcon: (
    <BookOnlineIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  EventIcon: (
    <EventIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  SellIcon: (
    <SellIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  CreateIcon: (
    <CreateIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  HistoryEduIcon: (
    <HistoryEduIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  BookmarkIcon: (
    <BookmarkIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  PsychologyIcon: (
    <PsychologyIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  DescriptionIcon: (
    <DescriptionIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ImageIcon: (
    <ImageIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AudiotrackIcon: (
    <AudiotrackIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  CellTower: (
    <CellTower
      style={{
        color: '#000!important',
      }}
    />
  ),
  // Nuevos íconos para Generadores IA
  KeyboardIcon: (
    <KeyboardIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // Para Generador de Texto
  BrushIcon: (
    <BrushIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // Para Generador de Imágenes
  VideoCameraIcon: (
    <VideoCameraIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // Para Generador de Videos
  MicrophoneIcon: (
    <MicrophoneIcon
      style={{
        color: '#000!important',
      }}
    />
  ), // Para Sintetizador de Audio
  SecurityIcon: (
    <SecurityIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  DomainIcon: (
    <DomainIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  StorageIcon: (
    <StorageIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  Poll: (
    <Poll
      style={{
        color: '#000!important',
      }}
    />
  ),
  EventAvailable: (
    <EventAvailable
      style={{
        color: '#000!important',
      }}
    />
  ),
  Notifications: (
    <Notifications
      style={{
        color: '#000!important',
      }}
    />
  ),
  ContactPhoneIcon: (
    <ContactPhoneIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  AssessmentIcon: (
    <AssessmentIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  ForumIcon: (
    <ForumIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  WordpressIcon: (
    <WordpressIcon
      style={{
        color: '#000!important',
      }}
    />
  ),
  Assignment: (
    <Assignment
      style={{
        color: '#000!important',
      }}
    />
  ),
  Email: (
    <Email
      style={{
        color: '#000!important',
      }}
    />
  ),
  VideoLabel: (
    <VideoLabel
      style={{
        color: '#000!important',
      }}
    />
  ),
  ThreeDRotation: (
    <ThreeDRotation
      style={{
        color: '#000!important',
      }}
    />
  ),
  // Add more icon mappings here
  ImageOutlinedIcon: (
    <ImageOutlined
      style={{
        color: '#000!important',
      }}
    />
  ),
  DescriptionOutlinedIcon: (
    <DescriptionOutlined
      style={{
        color: '#000!important',
      }}
    />
  ),
  GamepadIcon: (
    <Gamepad
      style={{
        color: '#000!important',
      }}
    />
  ),
  ScreenShareIcon: (
    <ScreenShare
      style={{
        color: '#000!important',
      }}
    />
  ),
}

const IconComponent = ({ iconName }) => {
  return iconMapping[iconName] || null
}

export { IconComponent, iconMapping }
