import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { supabase } from './../supabaseClient'

const ProtectedRoute = ({ children }) => {
  const [session, setSession] = useState(supabase.auth.getSession())
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Actualiza el estado de la sesión cuando cambie
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session)
    })

    // Comprueba si ya hay una sesión al montar el componente
    if (session) {
      setLoading(false)
    }

    // Limpia el listener al desmontar
    return () => {
      //authListener?.subscription
    }
  }, [])

  if (loading) {
    return null;<div>Loading...</div> // O cualquier otra representación del estado de carga
  }

  if (!session) {
    // Si no hay sesión, redirige al usuario a la página de login
    return <Navigate to="/login" />
  }

  // Si hay sesión, muestra el componente
  return children
}

export default ProtectedRoute
