import React, { useEffect, useMemo } from 'react'
import { Box, Typography, Container } from '@mui/material'
import ReactFlow, {
  useEdgesState,
  useNodesState,
  addEdge,
  Handle,
  applyNodeChanges,
  applyEdgeChanges,
} from 'react-flow-renderer'

import 'react-flow-renderer/dist/style.css'
import 'react-flow-renderer/dist/theme-default.css'

import { iconMapping } from './IconComponent' // Ensure this file correctly exports your icons
import { useStore } from './../../StoreContext'

const FlowDemo = ({ menuData, userPermissions, isStaticMenu, setIsStaticMenu }) => {
  const { menuOption, setMenuOption } = useStore()

  const initialEdges = [
    {
      id: 'person-qr',
      source: 'person',
      target: 'qr',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'person-qr',
      source: 'bot',
      target: 'person',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'person-qr2',
      source: 'portal',
      target: 'person',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'person-qr22',
      source: 'portal',
      target: 'object3d',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'person-qr4',
      source: 'person',
      target: 'bot',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'person-qr3',
      source: 'person',
      target: 'portal',
      type: 'customEdge',
      animated: true,
    },
    {
      id: 'qr-bot',
      source: 'qr',
      target: 'bot',
      type: 'customEdge',
      animated: true,
      label: '', // Specific context
    },
    {
      id: 'qr-store',
      source: 'qr',
      target: 'store',
      animated: true,
      type: 'customEdge',
      label: '', // Added preposition for clarity
    },
    {
      id: 'bot-store',
      source: 'bot',
      target: 'store',
      type: 'customEdge',
      animated: true,
      label: '', // Describes functionality
    },
    {
      id: 'qr-object3d',
      source: 'qr',
      type: 'customEdge',
      target: 'object3d',
      animated: true,
      label: '', // Simpler and clearer
    },
    {
      id: 'qr-virtualSpace',
      source: 'qr',
      target: 'virtualSpace',
      animated: true,
      type: 'customEdge',
      label: '', // More specific action
    },
    {
      id: 'object3d-store1',
      source: 'object3d',
      target: 'store',
      type: 'customEdge',
      animated: true,
      label: '', // Added context
    },
    {
      id: 'object3d-store',
      source: 'store',
      target: 'virtualSpace',
      type: 'customEdge',
      animated: true,
      label: '', // Added context
    },
    {
      id: 'object3d-store2',
      source: 'store',
      target: 'bot',
      type: 'customEdge',
      animated: true,
      label: '', // Added context
    },
    {
      id: 'object3d-virtualSpace',
      source: 'object3d',
      target: 'virtualSpace',
      animated: true,
      type: 'customEdge',
      label: '', // Clearer description
    },
    {
      id: 'virtualSpace-store',
      source: 'virtualSpace',
      target: 'store',
      type: 'customEdge',
      animated: true,
      label: '', // Improved wording
    },
    {
      id: 'bot-eventos',
      source: 'bot',
      target: 'eventos',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'eventos-bot',
      source: 'eventos',
      target: 'bot',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'eventos-qr',
      source: 'qr',
      target: 'eventos',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'agenda-bot2',
      source: 'bot',
      target: 'agenda',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'iot-person',
      source: 'iot',
      target: 'person',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'person-iot',
      source: 'person',
      target: 'iot',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    ,
    {
      id: 'iot-portal',
      source: 'iot',
      target: 'portal',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'portal-iot',
      source: 'portal',
      target: 'iot',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'agenda-bot23',
      source: 'agenda',
      target: 'bot',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
    {
      id: 'agenda-bot1',
      source: 'qr',
      target: 'agenda',
      animated: true,
      arrowHeadType: 'arrowclosed',
      label: '',
      style: {
        stroke: '#FFF',
      },
    },
  ]

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const onConnect = params => setEdges(eds => addEdge(params, eds))

  // Función para simular la edición de un edge
  const editEdge = (oldEdgeId, newTargetId) => {
    setEdges(eds =>
      eds.map(edge => {
        if (edge.id === oldEdgeId) {
          return {
            ...edge,
            target: newTargetId,
          }
        }
        return edge
      })
    )
  }

  useEffect(() => {
    let initialNodes = []

    if (true || menuOption?.permission_required === '/qr/codes') {
      initialNodes = [
        {
          id: 'iot',
          type: 'customNode',
          data: {
            label: 'Cosas con internet',
            icon: 'BroadcastIcon',
            background: '#0000FF',
            description: '',
          },
          position: {
            x: -28.673054549713342,
            y: 173.17819699254244,
          },
          width: 89,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: -28.673054549713342,
            y: 173.17819699254244,
          },
          dragging: false,
        },
        {
          id: 'portal',
          type: 'customNode',
          data: {
            label: 'Portal',
            icon: 'NieriversoIcon',
            background: '#7917c0',
            description: '',
          },
          position: {
            x: 72.93765688832923,
            y: 287.8141587170432,
          },
          width: 43,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 72.93765688832923,
            y: 287.8141587170432,
          },
          dragging: false,
        },
        {
          id: 'agenda',
          type: 'customNode',
          data: {
            label: 'Agenda Inteligente',
            icon: 'BookOnlineIcon',
            background: '#0ec60e',
            description: 'Organización de eventos y citas.',
          },
          position: {
            x: 210.02071046240613,
            y: -45.51913319546338,
          },
          width: 89,
          height: 22,
          selected: true,
          positionAbsolute: {
            x: 210.02071046240613,
            y: -45.51913319546338,
          },
          dragging: true,
        },
        {
          id: 'eventos',
          type: 'customNode',
          data: {
            label: 'Eventos',
            icon: 'EventIcon',
            background: '#FF0000',
            description: 'Organización y gestión de eventos.',
          },
          position: {
            x: -9.653719726160233,
            y: -80.20009757969206,
          },
          width: 51,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: -9.653719726160233,
            y: -80.20009757969206,
          },
          dragging: false,
        },
        {
          id: 'person',
          type: 'customNode',
          data: {
            label: 'Personas',
            icon: 'PeopleIcon',
            background: '#0efbfa',
            description: 'Gestión y administración de personas, incluyendo usuarios y clientes.',
          },
          position: {
            x: 126.14619001635796,
            y: -118.18014858762982,
          },
          width: 56,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 126.14619001635796,
            y: -118.18014858762982,
          },
          dragging: false,
        },
        {
          id: 'qr',
          type: 'customNode',
          data: {
            label: 'Códigos QR',
            icon: 'QrCodeIcon',
            background: '#0ec60e',
            description: 'Genere códigos QR únicos para marketing, información de productos y más.',
          },
          position: {
            x: -44.27392896851394,
            y: 45.43417211993781,
          },
          width: 66,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: -44.27392896851394,
            y: 45.43417211993781,
          },
          dragging: false,
        },
        {
          id: 'bot',
          type: 'customNode',
          data: {
            label: 'Bots GPT',
            icon: 'GPTBotsIcon',
            background: '#0ec60e',
            description: 'Potencie su interacción digital con bots GPT avanzados.',
          },
          position: {
            x: 215.32154234968982,
            y: 60.24969565614251,
          },
          width: 57,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 215.32154234968982,
            y: 60.24969565614251,
          },
          dragging: false,
        },
        {
          id: 'store',
          type: 'customNode',
          data: {
            label: 'Tiendas Online',
            icon: 'StoreIcon',
            background: '#FF0000',
            description: 'Creación y gestión de tiendas en línea con herramientas avanzadas.',
          },
          position: {
            x: 239.7939982183873,
            y: 136.45340035509838,
          },
          width: 76,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 239.7939982183873,
            y: 136.45340035509838,
          },
          dragging: false,
        },
        {
          id: 'object3d',
          type: 'customNode',
          data: {
            label: 'Objeto 3D',
            icon: 'ThreeDRotationIcon',
            background: '#7917c0',
            description: 'Gestione su colección de objetos 3D dentro del universo virtual.',
          },
          position: {
            x: 160.3911472204066,
            y: 168.73791547778512,
          },
          width: 58,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 160.3911472204066,
            y: 168.73791547778512,
          },
          dragging: false,
        },
        {
          id: 'virtualSpace',
          type: 'customNode',
          data: {
            label: 'Espacios 3D',
            icon: 'NieriversoIcon',
            background: '#7917c0',
            description: 'Cree y personalice sus propios espacios 3D en el Nieriverso.',
          },
          position: {
            x: 197.4722843097348,
            y: 292.6805562745068,
          },
          width: 67,
          height: 22,
          selected: false,
          positionAbsolute: {
            x: 197.4722843097348,
            y: 292.6805562745068,
          },
          dragging: false,
        },
      ]
    }
    setNodes(initialNodes)
    setEdges(initialEdges)
  }, [menuOption])

  function hexToRGBA(hex, opacity) {
    let r = parseInt(hex?.slice(1, 3), 16)
    let g = parseInt(hex?.slice(3, 5), 16)
    let b = parseInt(hex?.slice(5, 7), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  const handleNodesChange = changes => {
    setNodes(nds => applyNodeChanges(changes, nds))
    console.log('Nodos actualizados:', applyNodeChanges(changes, nodes))
    console.log(JSON.stringify(nodes))
  }

  const handleEdgesChange = changes => {
    setEdges(eds => applyEdgeChanges(changes, eds))
    console.log('Conexiones actualizadas:', applyEdgeChanges(changes, edges))
    console.log(JSON.stringify(edges))
  }

  const CustomNodeComponent = ({ data }) => {
    const Icon = iconMapping[data.icon] // Ensure iconMapping returns a valid React component

    // console.log(data);

    return (
      <div
        style={{
          padding: '10px',
          hidden: true,
          borderRadius: '5px',
          color: '#FFF',
          border: '1px solid ' + data.background,
          background: 'ffffffcc',
          zIndex: 1,
          height: 15,
          width: '(100% + 5px)',
        }}
        onClick={() => setMenuOption(data)}
      >
        <Handle
          type="target"
          position="left"
          style={{
            background: '#555',
          }}
        />
        {/* Ajustar Box para usar flexbox y centrar el icono (y el cuadrado) */}
        <Box
          sx={{
            display: 'flex', // Usa flexbox
            position: 'relative',
            top: -20,
            justifyContent: 'center', // Centra horizontalmente
            alignItems: 'center', // Centra verticalmente
            width: '100%', // Asegura que Box ocupe todo el ancho del contenedor
            height: '100%', // Asegura que Box ocupe todo el alto del contenedor
            '&::before': {
              content: '""',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)', // Ajusta para centrar exactamente en el medio
              width: 25, // Tamaño del cuadrado
              height: 25, // Tamaño del cuadrado
              borderRadius: '10%', // Bordes ligeramente redondeados
              background: hexToRGBA(data.background, 1),
              zIndex: 1, // Coloca el cuadrado detrás del icono
              opacity: 0.8,
            },
          }}
        >
          <div
            style={{
              zIndex: 2,
            }}
          >
            {Icon}
          </div>
        </Box>
        <br />

        <div
          style={{
            top: '-38px',
            position: 'relative',
            fontSize: '0.5rem',
            color: '#FFF',
            width: '80%',
            textAlign: 'center',
            display: 'inline',
          }}
        >
          {data.label}
        </div>

        <Handle
          type="source"
          position="right"
          style={{
            background: '#555',
          }}
        />
      </div>
    )
  }

  const nodeTypes = useMemo(
    () => ({
      customNode: CustomNodeComponent,
    }),
    []
  )

  const customEdgeTypes = {
    customEdge: {
      backgroundColor: '#0000FF',
      border: '2px solid #0000FF',
      color: '#FFFFFF',
      labelStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      arrowHeadType: 'arrow',
      arrowHeadSize: 10,
      handleStyle: {
        backgroundColor: '#FF0000',
      },
      selected: {
        backgroundColor: '#00FF00',
      },
      hover: {
        backgroundColor: '#FFFF00',
      },
    },
  }

  const edgeTypes = useMemo(
    () => ({
      edgeTypes: CustomNodeComponent,
    }),
    []
  )

  return (
    <>
      <Container
        style={{
          margin: 0,
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            mt: 2,
            p: 0,
            margin: 0,
            margin: 0,
            padding: 0,
            background: 'transparent',
          }}
        >
          <Box
            style={{
              display: 'grid',
              margin: '0px',
              width: '100%',
            }}
          >
            <Typography
              style={{
                justifySelf: 'center',
              }}
              variant="h2"
              gutterBottom
              component="div"
              color={'white'}
              sx={{
                fontWeight: 'bold',
                fontSize: 20,
                padding: '20px',
              }}
            >
              Visualización de posibles flujos de integración entre nuestros servicios
            </Typography>
          </Box>

          <div
            style={{
              height: '600px',
              width: '100%',
            }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={handleNodesChange}
              onEdgesChange={handleEdgesChange}
              onConnect={onConnect}
              fitView
              nodeTypes={nodeTypes}
              deleteKeyCode={46} /* 'delete'-key */
              edgeTypes={edgeTypes} // Usa la configuración de tipos de borde
            />
          </div>
        </Box>
      </Container>
    </>
  )
}

export default FlowDemo
