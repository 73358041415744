import React, { useState, useEffect } from 'react'
import { supabase } from './../../supabaseClient'
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useStore } from './../../StoreContext'
import { Canvas } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import DeleteIcon from '@mui/icons-material/Delete'

// Component to load and display GLTF model
const ModelViewer = ({ modelUrl }) => {
  const { scene } = useGLTF(modelUrl) // Use useGLTF from @react-three/drei
  return <primitive object={scene} scale={2} />
}

const Inventario = () => {
  const [models, setModels] = useState([])
  const [filteredModels, setFilteredModels] = useState([])
  const [open, setOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    file: null,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [filterCategory, setFilterCategory] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useStore()

  useEffect(() => {
    if (user) {
      loadModels()
    }
  }, [user])

  useEffect(() => {
    applyFilters()
  }, [searchTerm, filterCategory, models])

  const loadModels = async () => {
    if (!user) return
    try {
      const { data, error } = await supabase.from('objects').select('*').eq('user_id', user.id)
      if (error) throw error
      setModels(data)
    } catch (error) {
      setError(error.message)
      enqueueSnackbar('Error loading models: ' + error.message, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const applyFilters = () => {
    let filtered = models
    if (searchTerm) {
      filtered = filtered.filter(
        model =>
          model.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          model.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    if (filterCategory) {
      filtered = filtered.filter(model => model.category === filterCategory)
    }
    setFilteredModels(filtered)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = e => {
    const { name, value, files } = e.target
    if (files) {
      setFormData({
        ...formData,
        file: files[0],
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setUploading(true)

    if (formData.file && formData.file.name.toLowerCase().endsWith('.glb')) {
      try {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('objects')
          .upload(`${user.id}/${formData.file.name}`, formData.file, {
            cacheControl: '3600',
            upsert: false,
          })

        if (uploadError) throw uploadError

        const { data, error } = await supabase
          .from('objects')
          .insert([
            {
              name: formData.name,
              description: formData.description,
              file_url: uploadData.path,
              user_id: user.id,
            },
          ])
          .single()

        if (error) throw error

        setModels([...models, data])
        handleClose()
      } catch (error) {
        enqueueSnackbar('Error: ' + error.message, {
          variant: 'error',
        })
      } finally {
        setUploading(false)
      }
    } else {
      enqueueSnackbar('Solo se permiten archivos GLTF.', {
        variant: 'error',
      })
      setUploading(false)
    }
  }

  const handleDeleteModel = async modelId => {
    try {
      const { error } = await supabase.from('objects').delete().eq('id', modelId)
      if (error) throw error

      setModels(models.filter(model => model.id !== modelId))
      enqueueSnackbar('Model deleted successfully', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Error deleting model: ' + error.message, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  }

  return (
    <Box
      p={4}
      sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: 'white',
        }}
      >
        Inventario de objetos 3D
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Buscar"
            variant="outlined"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Categoría</InputLabel>
            <Select
              value={filterCategory}
              onChange={e => setFilterCategory(e.target.value)}
              label="Categoría"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* Añade más categorías según sea necesario */}
              <MenuItem value="categoria1">Categoría 1</MenuItem>
              <MenuItem value="categoria2">Categoría 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleOpen} fullWidth>
            Agregar objeto 3D
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Agregar nuevo objeto 3D
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                fullWidth
                name="name"
                label="Nombre del modelo"
                value={formData.name}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                name="description"
                label="Descripción"
                value={formData.description}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <input
                type="file"
                accept=".glb"
                onChange={handleChange}
                style={{
                  display: 'block',
                  marginBottom: 10,
                }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={uploading}
              fullWidth
            >
              {uploading ? 'Subiendo...' : 'Enviar'}
            </Button>
          </form>
        </Box>
      </Modal>
      <Grid container spacing={3}>
        {filteredModels.map((model, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  <IconButton onClick={() => handleDeleteModel(model.id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                  {model.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {model.description}
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{ width: '100%', height: '200px' }}>
                  <Canvas>
                    <ambientLight intensity={0.5} />
                    <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={0.8} />
                    <ModelViewer modelUrl={model.file_url} />
                  </Canvas>
                </div>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Inventario
