// BotComponent.js
import React, { useState, useEffect } from 'react'
import './BotComponent.css'
import './BotComponent.scss'
import { Box, Button } from '@mui/material'

import botija1 from './../../assets/botija1.gif'

import TypewriterEffect from './Typewriter'
import WhatsBotComponentIcon from '@mui/icons-material/WhatsApp'
import { useTranslation } from 'react-i18next'

function BotComponent() {
  const [resetTypewriter, setResetTypewriter] = useState(false)
  const { t } = useTranslation()

  const [greeting, setGreeting] = useState('')

  const updateGreeting = () => {
    setResetTypewriter(true)
    const randomIndex = Math.floor(Math.random() * greetings.length)
    setGreeting(greetings[randomIndex])
    setTimeout(setResetTypewriter(false), 5000)
  }

  useEffect(() => {
    setResetTypewriter(false)

    updateGreeting()
    //setResetTypewriter(false); // Desactiva el reset después de seleccionar un nuevo mensaje

    // Cambia el saludo cada 10 segundos
  }, [])

  // State variables to hold the current images
  const [botijaImage, setBotijaImage] = useState(botija1)

  const whatsBotComponentButtonStyle = {
    backgroundColor: '#25D366', // Color de WhatsBotComponent
    color: 'white', // Color del texto
    borderRadius: '20px', // Bordes redondeados
    padding: '10px 20px', // Espaciado interno
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Sombra para darle profundidad
    '&:hover': {
      backgroundColor: '#128C7E', // Un tono más oscuro para el efecto hover
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Sombra más pronunciada en hover
    },
    transition: 'all 0.3s ease', // Transición suave para los cambios de estilo
    animation: '$keyframes 1.5s infinite', // Aplica la animación de zumbido
  }

  const greetings = ['greeting_1', 'greeting_2', 'greeting_3']

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      my={1}
      className="force-center"
    >
      <Box
        style={{
          marginTop: '50px',
          marginBottom: '15px',
          textAlign: 'center',
        }}
      >
        <div className="botija-container">
          <img src={botijaImage} alt="Botija" className="botija-image" />

          <Box
            sx={{
              textAlign: 'center',
              maxWidth: '100%',
              alignSelf: 'center',
              marginTop: '15px',
              marginBottom: '30px',
            }}
          >
            <TypewriterEffect
              width={400}
              text={t(greeting)}
              speed={20}
              reset={resetTypewriter}
              size={10}
            />
          </Box>
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 2,
          }}
        >
          <a
            href="https://api.whatsapp.com/send?phone=59896557877"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              variant="contained"
              endIcon={
                <WhatsBotComponentIcon
                  style={{
                    fontSize: '1.5rem',
                  }}
                />
              }
              style={whatsBotComponentButtonStyle}
              className="latido"
              onClick={() =>
                window.open('https://api.whatsapp.com/send?phone=59896557877', '_blank')
              }
            >
              Habla con Asistencia
            </Button>
          </a>
        </Box>

        <br />
      </Box>
    </Box>
  )
}

export default BotComponent
