import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const Breadcrumbs = () => {
  const location = useLocation()
  const path = location.pathname

  // Do not display "inicio" if we're on the root path
  const pathSegments = path === '/' ? [] : path.split('/').filter(Boolean)

  // If we're not at the root and "inicio" is not the first segment, add it
  if (path !== '/' && pathSegments[0] !== 'inicio') {
    pathSegments.unshift('inicio')
  }

  const createPath = index => {
    // If the segment is "inicio", return root path
    if (index === 0 && pathSegments[0] === 'inicio') {
      return '/'
    }

    // Otherwise, create the path normally
    return `/${pathSegments.slice(1, index + 1).join('/')}`
  }

  return (
    <Box
      sx={{
        marginLeft: '5%',
      }}
    >
      <MuiBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          color: 'white',
        }}
      >
        {pathSegments.map((segment, index) => {
          const isLast = index === pathSegments.length - 1
          const currentPath = createPath(index)
          return isLast ? (
            <Typography
              color="textSecondary"
              key={index}
              sx={{
                color: 'grey',
              }}
            >
              {segment}
            </Typography>
          ) : (
            <Link
              to={currentPath}
              key={index}
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              {index === 0 && segment === 'inicio' ? 'Inicio' : segment}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs
