/* prettier-ignore-file */
import React, { useState } from 'react'
import { Grid, Container, Button, Typography, Box, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
import CardsCarrouselSpring from './carousell/CardsCarrouselSpring.js'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import {
  useMediaQuery,
} from '@mui/material'
import videoMap from './../../assets/videos/videomap.mp4'
import iphone14 from './../../assets/frames/iphone14.png'

const AnimatedBox = ({ children, animationProps }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, threshold: 0.1,
  })
  const animation = useSpring({
    ...animationProps, opacity: inView ? 1 : 0,
  })

  return (
    <animated.div ref={ref} style={animation}>
      {children}
    </animated.div>
  )
}

const LandingPage = () => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleOpenDetails = () => {
    setIsOpen(!isOpen)
  }

  const textStyle = {
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)', transition: 'transform 0.3s ease', '&:hover': {
      transform: 'scale(1.05)',
    }, color: 'white',
  }

  const buttonAnimationProps = useSpring({
    transform: 'translateY(0)', from: {
      transform: 'translateY(20px)',
    }, opacity: 1, delay: 500, config: {
      tension: 220, friction: 120,
    },
  })

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12}>
          {!isOpen && (
            <>
              <Box
                sx={{
                  paddingTop: '40px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}>
                <AnimatedBox
                  animationProps={{
                    transform: 'translateX(0)', from: {
                      transform: 'translateX(-100px)',
                    }, delay: 2000,
                  }}>
                  <Typography
                    variant="h1"
                    sx={{
                      marginTop: '10px', maxWidth: '800px', textAlign: 'center', fontSize: 24, ...textStyle,
                    }}>
                    {t('home.title')}
                  </Typography>
                </AnimatedBox>
              </Box>

              <Box
                sx={{
                  display: 'flex', justifyContent: 'center', mt: 4,
                }}>
                <AnimatedBox
                  animationProps={{
                    transform: 'translateX(0)', from: {
                      transform: 'translateX(100px)',
                    }, delay: 4000,
                  }}>
                  {isOpen ? (
                    <Button
                      className="latido0"
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to="/login"
                      sx={{
                        zIndex: 3, textTransform: 'none',
                      }}>
                      {t('home.start')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        zIndex: 3, textTransform: 'none',
                      }}
                      onClick={handleOpenDetails}>
                      {t('home.learnMore')}
                    </Button>
                  )}
                </AnimatedBox>
              </Box>

              <Box
                sx={{
                  paddingTop: '0px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}>
                <AnimatedBox
                  animationProps={{
                    transform: 'translateX(0)', from: {
                      transform: 'translateX(100px)',
                    }, delay: 3000,
                  }}>
                  <Typography
                    variant="h2"
                    sx={{
                      marginTop: '20px', maxWidth: '800px', textAlign: 'center', fontSize: 20, ...textStyle,
                    }}>
                    {t('home.subtitle')}
                  </Typography>
                </AnimatedBox>
              </Box>

              <Box
                className="block"
                style={{
                  left: isMobile ? '25%' : "65%", position: 'relative', paddingLeft: '-30px',
                }}>
                {/* Segundo latido */}
                <AnimatedBox
                  animationProps={{
                    transform: 'translateX(0)',
                    from: {
                      transform: 'translateX(100px)',
                    },
                    delay: 2000, // 2 segundos de retraso
                  }}
                >
                  <div
                    className="latido2"
                    style={{
                      position: 'absolute',
                      rotate: '6.4304rad',
                      transform: 'translate(0%, -10%)',
                      top: '0%',
                      left: '5%',
                      zIndex: 2,
                    }}
                  >
                    <img
                      style={{
                        width: 'auto',
                        height: '524px',
                        position: 'absolute',
                        top: '-11px',
                        left: '-14px',
                        zIndex: 2,
                      }}
                      src={iphone14}
                      alt="loading..."
                    />

                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      disablePictureInPicture
                      style={{
                        position: 'absolute',
                        width: 'auto',
                        height: '500px',
                        zIndex: 1,
                      }}
                      src={videoMap}
                      alt="loading..."
                    />

                  </div>
                </AnimatedBox>

              </Box>


              {true && <Box sx={{}}>
                <AnimatedBox
                  animationProps={{
                    transform: 'translateX(0)', from: {
                      transform: 'translateX(100px)',
                    }, delay: 1000,
                  }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      marginTop: '100vh',
                    }}>
                      {false && <CardsCarrouselSpring />}
                  </Grid>
                </AnimatedBox>
              </Box>}

            </>
          )}

          {isOpen && (
            <Box
              sx={{
                paddingTop: '20px', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center',
              }}>
              <AnimatedBox
                animationProps={{
                  transform: 'translateY(0)', from: {
                    transform: 'translateY(50px)',
                  }, delay: 200,
                }}>
                <Typography
                  variant="h2"
                  sx={{
                    marginTop: '40px', maxWidth: '800px', textAlign: 'center', fontSize: 20, ...textStyle,
                  }}>
                  {t('home.description2')}
                </Typography>
              </AnimatedBox>
              <animated.div style={buttonAnimationProps}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="latido2"
                  component={Link}
                  to="/login"
                  sx={{
                    marginTop: '20px', textTransform: 'none',
                  }}>
                  {t('login.signIn')}
                </Button>
              </animated.div>
            </Box>
          )}
        </Grid>

        {false && (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              marginTop: 100,
            }}>
            <Box
              allowFullScreen
              style={{
                display: 'grid', margin: '15px', width: '100%',
              }}>
              <Typography
                style={{
                  justifySelf: 'center',
                }}
                variant="h4"
                gutterBottom
                component="div"
                color={'white'}
                sx={{
                  fontSize: 25, ...textStyle,
                }}>
                {t('home.newOffer')}
              </Typography>
            </Box>
          </Grid>
        )}

        {false && (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              marginTop: 150,
            }}>
            {true && (
              <>
                <br />
                <br />
                <AnimatedBox
                  animationProps={{
                    transform: 'scale(1)', from: {
                      transform: 'scale(0.9)',
                    },
                  }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    color={'white'}
                    sx={{
                      fontWeight: 'bold', ...textStyle,
                    }}>
                    {t('home.allInOne')}
                  </Typography>
                </AnimatedBox>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'transparent', border: 'none', fontSize: '5rem', boxShadow: 'none',
                  }}
                  className="jump-animation">
                  &#8595;
                </Button>
              </>
            )}
          </Grid>
        )}
      </Grid>
      {false && (
        <FloatingWhatsApp
          phoneNumber="+59896557877" // Reemplaza con tu número de teléfono
          accountName="Asistente Nieriverso" // Nombre de la cuenta
          onClick={() => console.log('Botón de WhatsApp clickeado')} // Función de callback al hacer clic
          onSubmit={(event, value) => console.log('Formulario de WhatsApp enviado', value)} // Función de callback al enviar el formulario
          onClose={() => console.log('Widget de WhatsApp cerrado')} // Función de callback al cerrar el widget
          onLoopDone={() => console.log('Loop de notificaciones completado')} // Función de callback cuando se completa el loop de notificaciones
          onNotification={() => console.log('Notificación mostrada')} // Función de callback cuando se muestra una notificación
          //avatar="/path/to/avatar.png" // Ruta al avatar de usuario
          statusMessage="Online" // Mensaje de estado debajo del nombre de cuenta
          chatMessage={t('assistant.hi')} // Mensaje predeterminado dentro del cuadro de chat
          placeholder={t('assistant.write')} // Placeholder del input
          messageDelay={2} // Retraso en segundos antes de mostrar el mensaje de chat
          darkMode={false} // Modo oscuro
          allowClickAway={false} // Permitir cerrar el chat al hacer clic afuera
          allowEsc={false} // Permitir cerrar el chat al presionar Escape
          className="custom-floating-whatsapp" // Clase CSS para el contenedor principal
          buttonClassName="custom-floating-whatsapp-button" // Clase CSS para el botón de WhatsApp
          style={{}} // Estilos inline para el contenedor principal
          buttonStyle={{}} // Estilos inline para el botón de WhatsApp
          chatboxHeight={320} // Altura del cuadro de chat
          chatboxClassName="custom-floating-whatsapp-chatbox" // Clase CSS para el cuadro de chat
          chatboxStyle={{}} // Estilos inline para el cuadro de chat
          notification={false} // Permitir notificaciones
          notificationDelay={60} // Retraso entre notificaciones en segundos
          notificationSound={false} // Permitir sonido de notificación
          notificationSoundSrc="/path/to/notification-sound.mp3" // Fuente personalizada para el sonido de notificación
          notificationLoop={0} // Repetir bucle de notificaciones
          notificationStyle={{}} // Estilos inline para el indicador de notificación
          notificationClassName="custom-floating-whatsapp-notification" // Clase CSS para el indicador de notificación
        />
      )}
    </Container>
  )
}

export default LandingPage
