import React from 'react'
import { Container, Grid } from '@mui/material'
import FlowDemo from './../components/layout/FlowDemo'

const Flows = ({ onFlows }) => {
  return (
    <Container component="main" maxWidth="sm">
      {true && (
        <Grid
          item
          xs={12}
          md={12}
          className=""
          style={{
            margin: '10%',
          }}
        >
          <FlowDemo
            isStaticMenu={false}
            setIsStaticMenu={() => {}}
            style={{
              maxWidth: '500px',
              overflowX: 'auto',
            }}
          />
        </Grid>
      )}
    </Container>
  )
}

export default Flows
