import React, { useEffect, useState } from 'react'
import {
  Grid,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Box,
  Stack,
  Tooltip
} from '@mui/material'
import { supabase } from './../../../supabaseClient'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTheme, alpha } from '@mui/material/styles'
import MessageIcon from '@mui/icons-material/Message'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import UpdateIcon from '@mui/icons-material/Update'

const Conversations = ({ bot, searchTerm, setSearchTerm, setChatSelected }) => {
  const [botChats, setBotsChats] = useState(null)
  const [userProfiles, setUserProfiles] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    const fetchChatsAndProfiles = async () => {
      try {
        // Fetch chats
        const { data: chatsData, error: chatsError } = await supabase
          .from('bots_chats')
          .select('*')
          .eq('bot_id', bot.id)

        if (chatsError) {
          throw chatsError
        }

        setBotsChats(chatsData)

        // Extract phone numbers from chats
        const phoneNumbers = chatsData.map(chat => {
          const number = chat.from.split('@')[0]
          return `+${number}`
        })

        // Remove duplicates
        const uniqueNumbers = [...new Set(phoneNumbers)]

        console.log('Unique Phone Numbers:', uniqueNumbers)

        if (uniqueNumbers.length === 0) {
          setUserProfiles({})
          setLoading(false)
          return
        }

        // Fetch user profiles matching the phone numbers
        const { data: profilesData, error: profilesError } = await supabase
          .from('user_profiles')
          .select('phone_number, profile_picture_url, alias')
          .in('phone_number', uniqueNumbers) // Mantener el '+'

        if (profilesError) {
          throw profilesError
        }

        console.log('Profiles Data:', profilesData)

        // Create a mapping from phone number to profile
        const profilesMap = {}
        profilesData.forEach(profile => {
          profilesMap[profile.phone_number] = profile
        })

        console.log('Profiles Map:', profilesMap)
        setUserProfiles(profilesMap)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching chats or profiles:', err)
        setError('Hubo un error al cargar las conversaciones.')
        setLoading(false)
      }
    }

    if (bot.from) {
      fetchChatsAndProfiles()
    }
  }, [bot.from, bot.id])

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        padding={2}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        padding={2}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    )
  }

  // Función para calcular el color de fondo basado en el índice
  const getBackgroundColor = (index) => {
    const maxShade = 0.1 // Ajusta este valor para controlar la intensidad de la sombra
    const shade = Math.min(index * 0.02, maxShade) // Limita la sombra máxima
    return alpha(theme.palette.primary.main, 1 - shade)
  }

  // Función para formatear fechas
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <Grid item xs={12} md={12}>
      <Paper
        sx={{
          padding: 2,
          margin: 2,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <TextField
          label="Buscar Chat"
          fullWidth
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          margin="normal"
          variant="outlined"
          inputProps={{ 'aria-label': 'Buscar Chat' }}
        />
        <List>
          {botChats
            ?.filter(chat => {
              const sender = chat.from.toLowerCase()
              const lastMessage = chat.last_message?.toLowerCase()
              const term = searchTerm?.toLowerCase() || ''
              return (
                sender.includes(term) ||
                (lastMessage && lastMessage.includes(term))
              )
            })
            .map((chat, index) => {
              const phoneNumber = `+${chat.from.split('@')[0]}`
              const userProfile = userProfiles[phoneNumber]

              return (
                <ListItem
                  key={chat.from}
                  button
                  selected={chat.from === bot?.chatSelected?.from}
                  onClick={() => setChatSelected(chat)}
                  alignItems="flex-start"
                  sx={{
                    borderRadius: 1,
                    marginY: 0.5,
                    backgroundColor:
                      chat.from === bot?.chatSelected?.from
                        ? theme.palette.action.selected
                        : getBackgroundColor(index),
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <ListItemAvatar>
                    {userProfile && userProfile.profile_picture_url ? (
                      <Avatar
                        alt={userProfile.alias || phoneNumber}
                        src={userProfile.profile_picture_url}
                      />
                    ) : (
                      <Avatar>
                        {phoneNumber
                          .replace(/\D/g, '')
                          .slice(-2)}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      marginLeft: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        {userProfile ? userProfile.alias : phoneNumber}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {formatDate(chat.last_updated)}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      noWrap
                      sx={{ marginTop: 0.5 }}
                    >
                      {chat.last_message}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 1,
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <MessageIcon fontSize="small" color="action" />
                        <Typography variant="caption" color="text.secondary">
                          {chat.messages_received} mensajes
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CalendarTodayIcon fontSize="small" color="action" />
                        <Typography variant="caption" color="text.secondary">
                          {formatDate(chat.created_at)}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </ListItem>
              )
            })}
        </List>
      </Paper>
    </Grid>
  )
}

export default Conversations
