import React from 'react'
import { Typography } from '@mui/material'
import { Sync, QrCode2, CheckCircle, Close as CloseIcon } from '@mui/icons-material'
// Ícono de monedas
// Importa Link
import { CircularProgress } from '@mui/material'

const GetStatus = props => {
  const { bot } = props

  switch (bot.status) {
    case 'loading':
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Sync
            color="action"
            style={{
              marginRight: 8,
            }}
          />
          <Typography>{`Cargando... ${bot.loading}%`}</Typography>
        </div>
      )
    case 'disconnected':
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CloseIcon
            color="error"
            style={{
              marginRight: 8,
            }}
          />
          <Typography>Desconectado</Typography>
        </div>
      )
    case 'building':
      return (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: '0.5rem',
          }}
        >
          <CircularProgress
            size={20}
            thickness={5}
            style={{
              marginRight: '0.5rem',
            }}
          />
          <Typography variant="body2">Creando</Typography>
        </div>
      )
    case 'qr':
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <QrCode2
            color="action"
            style={{
              marginRight: 8,
            }}
          />
          <Typography>Pendiente de escaneo</Typography>
        </div>
      )
    case 'ok':
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircle
            color="success"
            style={{
              marginRight: 8,
            }}
          />
          <Typography>Operacional</Typography>
        </div>
      )
    default:
      return (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: '0.5rem',
          }}
        >
          <CircularProgress
            size={20}
            thickness={5}
            style={{
              marginRight: '0.5rem',
            }}
          />
          <Typography variant="body2">Creando</Typography>
        </div>
      )
  }
}
export default GetStatus
