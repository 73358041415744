import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStore } from './../../StoreContext'
import { supabase } from './../../supabaseClient'

import Menu from './menu/Menu'
import menuMetadata from './menu-metadata.json'

import Footer from './Footer'
import Footer2 from './Footer2'
import { AccountCircle, Notifications, Chat, Menu as MenuIcon } from '@mui/icons-material'
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Box,
  Badge,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import Breadcrumbs from './Breadcrumbs'
import TitleFeature from './TitleFeature'
import UserBalance from '../../pages/UserBalance'
import UserMenu from './../../pages/UserMenu'
import UserNotifications from './../../pages/UserNotifications'
import UserChats from './../../pages/UserChats'
import { useSpring, animated } from 'react-spring'
import MapComponent from './../metaverse/Map/MapComponent'
import space from './../../assets/background/space.mp4'

const Layout = ({ children }) => {
  const navigate = useNavigate()
  const { user, userProfile } = useStore()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const [notificationsCount, setNotificationsCount] = useState(0) // Inicializa con un valor
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(false)
  const [chatsCount, setChatsCount] = useState(0) // Inicializa con un valor
  const [chatAnchorEl, setChatAnchorEl] = useState(false)
  const [isStaticMenu, setIsStaticMenu] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [swing, setSwing] = useState(false)
  const location = useLocation()
  const { userSettings, userStores, userBots, userEvents, userRooms, setMenuOption, userPosts } =
    useStore()
  const [searchTerm, setSearchTerm] = useState('')
  const [showWidgets, setShowWidgets] = useState(false)
  const [selectedWidgy, setWidgy] = useState(null)
  const [tabValue, setTabValue] = useState(0)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isPostOpen, setIsPostOpen] = useState(false)
  const [map_points, setMap_points] = useState(null)
  const [userLocation, setUserLocation] = useState({})
  const [siteName, setSiteName] = useState('')

  useEffect(() => {
    const url = new URL(window.location.href)
    const hostname = url.hostname
    const parts = hostname.split('.')

    // Excluir subdominio y la extensión de dominio (.com, .net, etc.)
    let domainName = parts.length > 2 ? parts.slice(-2, -1)[0] : parts[0]

    setSiteName(domainName)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShowWidgets(true)
    }, 100)

    // Combinar todos los arrays en uno solo
    const combinedPoints = [
      ...(userStores || []),
      ...(userRooms || []),
      ...(userEvents || []),
      ...(userPosts || []),
    ]

    setMap_points(combinedPoints)
  }, [userStores, userRooms, userEvents, userPosts, userBots])

  const vibrateDevice = () => {
    if (navigator.vibrate) {
      navigator.vibrate(200)
    }
  }

  const animationProps = useSpring({
    opacity: showWidgets ? 1 : 0,
    marginTop: showWidgets ? 0 : -50,
    from: {
      opacity: 0,
      marginTop: -50,
    },
  })

  const handleLocationUpdate = location => {
    console.log(location)
    setUserLocation(location)
  }

  const handleInteraction = () => {
    navigate('/')
    setSwing(true)
    setTimeout(() => setSwing(false), 1000)
  }

  useEffect(() => {
    // console.log(userProfile)
    if (user && !userProfile) {
      window.location.reload()
    }
  }, [userProfile])

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const { count } = await supabase
          .from('user_notifications')
          .select('*', {
            count: 'exact',
          })
          .eq('user_id', user.id)
          .eq('is_read', false)
        setNotificationsCount(count)
      } catch (error) {
        console.error('Error al obtener el conteo de notificaciones', error)
      }
    }

    if (user) {
      fetchNotificationCount()
    }
  }, [user])

  useEffect(() => {
    if (isMobile) {
      setIsStaticMenu(false)
    }
  }, [isMobile])

  const handleNotificationIconClick = event => {
    setNotificationAnchorEl(event.currentTarget)
  }

  const handleChatIconClick = event => {
    setChatAnchorEl(event.currentTarget)
  }

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null)
  }

  const handleChatMenuClose = () => {
    setChatAnchorEl(null)
  }

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const isHidden = location.pathname.length > 1

  return (
    <>
      {user && map_points && (
        <MapComponent
          isHidden={isHidden || !user}
          isFixed={true}
          onLocationUpdate={handleLocationUpdate}
          isEditing={false}
          setPoint={setWidgy}
          pointers={map_points}
          pointer_selected={selectedWidgy}
          triggerGeolocate={isPostOpen}
          showFromSpace={true}
        />
      )}

      {!user && (
        <Box
          component="video"
          src={space} // Cambia esto a la URL de tu video
          sx={{
            position: 'fixed',
            top: -30,
            left: 0,
            width: '100%',
            height: '105%',
            objectFit: 'cover',
            zIndex: 1,
          }}
          autoPlay
          muted
          playsInline
          disablePictureInPicture
        />
      )}

      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AppBar
            position="static"
            color="primary"
            className={'header'}
            sx={{
              position: 'fixed',
              borderRadius: 0,
              backgroundColor: isHidden || !user ? '#00000085' : '',
            }}
          >
            <Toolbar>
              {user && (
                <IconButton
                  className="latido2"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    mr: 2,
                  }}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  {true && !isStaticMenu && (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                      <MenuIcon
                        sx={{
                          flexGrow: 1,
                          [theme.breakpoints.up('md')]: {
                            fontSize: '2rem', // Adjusted size for larger screens
                          },
                          [theme.breakpoints.down('md')]: {
                            fontSize: '1.5rem', // Adjusted size for smaller screens
                          },
                          color: 'white',
                          padding: '0px!important',
                        }}
                      />
                      {!isMobile && (
                        <Typography variant="h6" sx={{ marginLeft: 1 }}>
                          Menu
                        </Typography>
                      )}
                    </Box>
                  )}
                </IconButton>
              )}
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                  <Typography
                    className={`title3d styled-logo-text pixelated-text ${swing ? 'swing' : ''}`}
                    noWrap
                    component="div"
                    sx={{
                      border: 'none',
                      flexGrow: 1,
                      fontFamily: "'Press Start 2P', sans-serif!important",
                      color: '#B69358!important',
                      fontSize: {
                        md: '2rem',
                        sm: user ? '1rem' : '2rem',
                      },
                      textAlign: 'center',
                    }}
                    onClick={handleInteraction}
                    onTouchStart={() => {
                      handleInteraction()
                      vibrateDevice()
                    }}
                  >
                    {false && (
                      <img
                        src="/name.png"
                        className="latido3"
                        style={{
                          width: isMobile ? (user ? '120px' : '200px') : '200px',
                          height: 'auto',
                        }}
                      />
                    )}
                    {siteName}
                  </Typography>
                </Grid>

                <Grid item>
                  {user ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: isMobile ? '1!important' : 1,
                        bgcolor: '#0e38610',
                      }}
                    >
                      {false && <>
                        <Box
                          aria-label="notificaciones"
                          color="inherit"
                          sx={{
                            bgcolor: '#0e38610',
                            margin: 0,
                          }}
                          className="latido0"
                        >
                          <Badge
                            badgeContent={chatsCount}
                            color="secondary"
                            onClick={handleChatIconClick}
                          >
                            <Chat
                              sx={{
                                bgcolor: '#0e38610',
                                color: 'white',
                                fontSize: isMobile ? '1.3rem' : '2rem',
                              }}
                            />
                          </Badge>

                          <UserChats
                            anchorEl={chatAnchorEl}
                            open={Boolean(chatAnchorEl)}
                            handleClose={handleChatMenuClose}
                          />
                        </Box>
                        <Box
                          aria-label="notificaciones"
                          color="inherit"
                          sx={{
                            bgcolor: '#0e38610',
                            margin: 0,
                          }}
                          className="latido0"
                        >
                          <Badge
                            badgeContent={notificationsCount}
                            color="secondary"
                            onClick={handleNotificationIconClick}
                          >
                            <Notifications
                              sx={{
                                bgcolor: '#0e38610',
                                color: 'white',
                                fontSize: isMobile ? '1.3rem' : '2rem',
                              }}
                            />
                          </Badge>

                          <UserNotifications
                            anchorEl={notificationAnchorEl}
                            open={Boolean(notificationAnchorEl)}
                            handleClose={handleNotificationMenuClose}
                          />
                        </Box>
                      </>}
                      <UserBalance isMobile={isMobile} />

                      <AccountCircle
                        sx={{
                          bgcolor: '#0e38610',
                          color: 'white',
                          margin: 0,
                          fontSize: isMobile ? '1.3rem' : '2rem',
                        }}
                        onClick={handleMenuClick}
                        className="latido"
                      />
                    </Box>
                  ) : (
                    <AccountCircle
                      sx={{
                        bgcolor: '#0e38610',
                        color: 'white',
                        margin: 0,
                        fontSize: '2rem',
                      }}
                      onClick={() => navigate('/login')}
                      className="latido"
                    />
                  )}
                </Grid>
              </Grid>
            </Toolbar>

            <Breadcrumbs path={location.pathname} />
          </AppBar>
          <main className="content">
            <>
              {user && !isStaticMenu && (
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  style={{
                    zIndex: 99,
                  }}
                >
                  <Menu
                    menuData={menuMetadata}
                    isStaticMenu={isStaticMenu}
                    setIsStaticMenu={setIsStaticMenu}
                    hideAdmin={!userProfile?.is_admin}
                  />
                </Drawer>
              )}

              {user && isStaticMenu && (
                <Grid zIndex={3} item xs={12} md={3} lg={2}>
                  <Box
                    sx={{
                      position: 'relative',
                      top: '0px',
                      zIndex: 1,
                    }}
                  >
                    <Menu
                      menuData={menuMetadata}
                      isStaticMenu={isStaticMenu}
                      setIsStaticMenu={setIsStaticMenu}
                      hideAdmin={!userProfile?.is_admin}
                    />
                  </Box>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                md={isStaticMenu ? (user ? 9 : 12) : 12}
                lg={isStaticMenu ? (user ? 10 : 12) : 12}
                style={{
                  paddingTop: '150px',
                }}
              >
                {user && (
                  <TitleFeature
                    menuData={menuMetadata}
                    isStaticMenu={false}
                    setIsStaticMenu={() => { }}
                  />
                )}
                <div
                  style={{
                    paddingTop: '0px',
                  }}
                >
                  {children}
                </div>
              </Grid>
              {true && (
                <>
                  {!user && <Footer />}

                  {!user && <Footer2 />}
                </>
              )}
            </>
          </main>
        </Box>

        {user && (
          <UserMenu anchorEl={anchorEl} open={Boolean(anchorEl)} handleClose={handleMenuClose} />
        )}
      </Box>
    </>
  )
}

export default Layout
