import { supabase } from './../../supabaseClient'
// Importa useStore

const fetchUserEvents = async user => {
  try {
    if (!user) throw error
    const { data, error } = await supabase.from('events').select('*').eq('user_id', user.id)
    //   console.log(data);
    if (error) throw error

    // setEvents(data);
    return data
  } catch (error) {
    //setError(error.message);
  } finally {
    //setLoading(false);
  }
}

export { fetchUserEvents }
