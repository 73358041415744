import React, { Component } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// Importar archivos CSS
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Agenda.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

class App extends Component {
  state = {
    events: [
      {
        id: 0,
        title: "Reunión de equipo",
        start: new Date(),
        end: new Date(moment().add(1, "hour").toDate()),
        desc: "Discusión sobre el proyecto",
        color: "blue",
        avatar: "https://randomuser.me/api/portraits/men/32.jpg",
        userName: "Juan Pérez",
      },
      {
        id: 1,
        title: "Cita con el doctor",
        start: new Date(
          moment().add(2, "days").set({ hour: 10, minute: 0 }).toDate()
        ),
        end: new Date(
          moment().add(2, "days").set({ hour: 11, minute: 0 }).toDate()
        ),
        desc: "Chequeo médico anual",
        color: "green",
        avatar: "https://randomuser.me/api/portraits/women/44.jpg",
        userName: "Ana Gómez",
      },
      {
        id: 2,
        title: "Almuerzo con María",
        start: new Date(
          moment().add(3, "days").set({ hour: 12, minute: 30 }).toDate()
        ),
        end: new Date(
          moment().add(3, "days").set({ hour: 13, minute: 30 }).toDate()
        ),
        desc: "Restaurante italiano",
        color: "red",
        avatar: "https://randomuser.me/api/portraits/women/68.jpg",
        userName: "María López",
      },
    ],
    selectedEvent: null,
    modalOpen: false,
    // Nuevos campos para editar el evento
    editedEvent: null,
  };

  // Manejar la selección de eventos (mostrar detalles)
  handleSelectEvent = (event) => {
    this.setState({
      selectedEvent: event,
      editedEvent: { ...event }, // Hacemos una copia para editar
      modalOpen: true,
    });
  };

  // Cerrar el modal
  closeModal = () => {
    this.setState({
      selectedEvent: null,
      editedEvent: null,
      modalOpen: false,
    });
  };

  // Manejar la eliminación del evento
  deleteEvent = () => {
    const { selectedEvent } = this.state;
    const confirmDelete = window.confirm("¿Deseas eliminar este evento?");
    if (confirmDelete) {
      this.setState({
        events: this.state.events.filter((e) => e.id !== selectedEvent.id),
        selectedEvent: null,
        editedEvent: null,
        modalOpen: false,
      });
    }
  };

  // Manejar la actualización del evento
  saveEvent = () => {
    const { editedEvent } = this.state;
    this.setState((state) => {
      const events = state.events.map((event) =>
        event.id === editedEvent.id ? editedEvent : event
      );
      return {
        events,
        selectedEvent: null,
        editedEvent: null,
        modalOpen: false,
      };
    });
  };

  // Manejar cambios en los campos del formulario
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      editedEvent: {
        ...state.editedEvent,
        [name]: value,
      },
    }));
  };

  // Manejar cambios en las fechas
  handleDateChange = (e, field) => {
    const value = e.target.value;
    this.setState((state) => ({
      editedEvent: {
        ...state.editedEvent,
        [field]: new Date(value),
      },
    }));
  };

  // Manejar la creación de eventos
  handleSelectSlot = ({ start, end, action }) => {
    if (action === "select") {
      const title = window.prompt("Nombre del nuevo evento");
      if (title) {
        const userName = window.prompt("Nombre del usuario");
        const newEvent = {
          id: this.state.events.length,
          title,
          start,
          end,
          desc: "",
          color: this.getRandomColor(),
          avatar: "https://randomuser.me/api/portraits/lego/1.jpg", // Puedes personalizar el avatar
          userName: userName || "Anónimo",
        };
        this.setState({
          events: [...this.state.events, newEvent],
        });
      }
    }
  };

  // Generar un color aleatorio
  getRandomColor = () => {
    const colors = ["blue", "green", "red", "orange", "purple"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Manejar el redimensionamiento de eventos
  onEventResize = (data) => {
    const { start, end, event } = data;

    this.setState((state) => {
      const events = state.events.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      );
      return { events };
    });
  };

  // Manejar el arrastre de eventos
  onEventDrop = (data) => {
    const { start, end, event } = data;

    this.setState((state) => {
      const events = state.events.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      );
      return { events };
    });
  };

  // Personalizar la apariencia de los eventos
  eventStyleGetter = (event) => {
    const backgroundColor = event.color || "blue";
    const style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style,
    };
  };

  render() {
    const { modalOpen, editedEvent } = this.state;

    return (
      <div className="App">
        <DnDCalendar
          defaultDate={moment().toDate()}
          defaultView="week"
          events={this.state.events}
          localizer={localizer}
          onEventDrop={this.onEventDrop}
          onEventResize={this.onEventResize}
          resizable
          selectable
          onSelectEvent={this.handleSelectEvent}
          onSelectSlot={this.handleSelectSlot}
          step={5}
          timeslots={12}
          views={["month", "week", "day", "agenda"]}
          style={{ height: "80vh" }}
          eventPropGetter={this.eventStyleGetter}
          messages={{
            today: "Hoy",
            previous: "Anterior",
            next: "Siguiente",
            month: "Mes",
            week: "Semana",
            day: "Día",
            agenda: "Agenda",
            date: "Fecha",
            time: "Hora",
            event: "Evento",
            noEventsInRange: "No hay eventos en este rango.",
            showMore: (total) => `+ Ver más (${total})`,
          }}
        />

        {/* Modal para mostrar y editar detalles del evento */}
        {modalOpen && editedEvent && (
          <div className="modal-overlay" onClick={this.closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <img
                  src={editedEvent.avatar}
                  alt="Avatar"
                  className="avatar"
                />
                <h2>{editedEvent.userName}</h2>
              </div>
              <div className="modal-body">
                <label>
                  Título:
                  <input
                    type="text"
                    name="title"
                    value={editedEvent.title}
                    onChange={this.handleInputChange}
                  />
                </label>
                <label>
                  Descripción:
                  <textarea
                    name="desc"
                    value={editedEvent.desc}
                    onChange={this.handleInputChange}
                  />
                </label>
                <label>
                  Inicio:
                  <input
                    type="datetime-local"
                    value={moment(editedEvent.start).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    onChange={(e) => this.handleDateChange(e, "start")}
                  />
                </label>
                <label>
                  Fin:
                  <input
                    type="datetime-local"
                    value={moment(editedEvent.end).format("YYYY-MM-DDTHH:mm")}
                    onChange={(e) => this.handleDateChange(e, "end")}
                  />
                </label>
              </div>
              <div className="modal-footer">
                <button className="btn delete-btn" onClick={this.deleteEvent}>
                  Eliminar
                </button>
                <button className="btn save-btn" onClick={this.saveEvent}>
                  Guardar
                </button>
                <button className="btn close-btn" onClick={this.closeModal}>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
