// src/pages/UserProfilePage.js

import React, { useState, useEffect } from 'react';
import { supabase } from './../../supabaseClient';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Box,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { useStore } from './../../StoreContext';
import { useParams } from 'react-router-dom';
import { Description, Edit, Save, Cancel, AccountCircle } from '@mui/icons-material';
import Followers from './Followers';
import Following from './Following';
import Experience from './Experience';
import ProfilePictureEditor from './ProfilePictureEditor';

const UserProfilePage = () => {
  const { user, setMenuOption, userProfile, setUserProfile } = useStore(); // Asegúrate de tener setUserProfile en tu StoreContext
  const { userId } = useParams();

  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [followError, setFollowError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  console.log(user, userData, userProfile);

  const fetchUserData = async () => {
    try {
      const id = userId || userProfile?.user_id || user?.user_id;
      console.log('Fetching user data for ID:', id);
      if (!id) return;

      const { data, error } = await supabase
        .from('user_profiles')
        .select('*')
        .eq('user_id', id)
        .single();

      if (error) {
        throw error;
      }

      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  useEffect(() => {
    fetchUserData();
    setMenuOption(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userProfile]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const id = userData.user_id; // Asegúrate de usar user_id

      const { data, error } = await supabase
        .from('user_profiles')
        .update(userData)
        .eq('user_id', id)
        .select(); // Añadido .select() para obtener los datos actualizados

      if (error) {
        throw error;
      }

      console.log('Profile updated successfully:', data);
      setIsEditing(false);
      fetchUserData(); // Refrescar los datos después de la actualización

      // Actualizar el perfil en el Store si es el perfil del usuario actual
      if (userProfile?.user_id === id) {
        setUserProfile(data[0]);
      }
    } catch (error) {
      console.error('Error updating profile:', error.message);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchUserData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProfilePictureUpdate = (newUrl) => {
    setUserData((prevData) => ({
      ...prevData,
      profile_picture_url: newUrl,
    }));
  };

  const handleFollow = async () => {
    if (!user || !userProfile) {
      setFollowError('Debes estar autenticado para seguir a usuarios.');
      return;
    }

    setFollowLoading(true);
    setFollowError(null);

    try {
      const isFollowing = userProfile.following?.includes(userData.user_id);

      let updatedFollowing;
      if (isFollowing) {
        // Dejar de seguir
        updatedFollowing = userProfile.following.filter((id) => id !== userData.user_id);
      } else {
        // Seguir
        updatedFollowing = [...(userProfile.following || []), userData.user_id];
      }

      const { data, error } = await supabase
        .from('user_profiles')
        .update({ following: updatedFollowing })
        .eq('user_id', userProfile.user_id)
        .select(); // Añadido .select() para obtener los datos actualizados

      if (error) {
        throw error;
      }

      // Verificar que se recibió un usuario actualizado
      if (data && data.length > 0) {
        // Actualizar el perfil en el Store
        setUserProfile(data[0]);
        setSuccessMessage(isFollowing ? 'Has dejado de seguir a este usuario.' : 'Has seguido a este usuario.');
      } else {
        throw new Error('No se recibieron datos actualizados.');
      }
    } catch (error) {
      console.error('Error al seguir/dejar de seguir:', error.message);
      setFollowError('No se pudo completar la acción. Inténtalo de nuevo.');
    } finally {
      setFollowLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setFollowError(null);
    setSuccessMessage('');
  };

  if (!userData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
         
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const isOwnProfile = userProfile?.user_id === userData.user_id;
  const isFollowing = userProfile?.following?.includes(userData.user_id);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        minHeight: '100vh',
        padding: 2,
        width: '100%',
        // Fondo para mejor contraste
      }}
    >
      <Grid item xs={12} md={10} lg={8}>
        <Box
          elevation={3}
          sx={{
            padding: 2,
            borderRadius: 2,
            color: 'white',
            
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              marginBottom: 2,
              position: 'relative',
            }}
          >
            <ProfilePictureEditor
              userId={userData.user_id}
              profilePictureUrl={userData.profile_picture_url}
              onUpdateProfilePicture={handleProfilePictureUpdate}
              editable={isOwnProfile}
            />
            <Box sx={{ marginLeft: { sm: 2, xs: 0 }, marginTop: { xs: 1, sm: 0 } }}>
              <Typography variant="h6" gutterBottom>
                {userData.alias}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {userData.bio || 'Sin biografía.'}
              </Typography>
              {/* Botón Seguir/Dejar de Seguir */}
              {!isOwnProfile && (
                <Button
                  variant={isFollowing ? 'outlined' : 'contained'}
                  color="primary"
                  onClick={handleFollow}
                  disabled={followLoading}
                  sx={{ marginTop: 1 }}
                >
                  {isFollowing ? 'Dejar de Seguir' : 'Seguir'}
                </Button>
              )}
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  minHeight: '300px',
                 
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="subtitle1">Sobre mí</Typography>
                  {isOwnProfile && !isEditing && (
                    <IconButton
                      color="primary"
                      onClick={handleEdit}
                      sx={{
                        padding: 0,
                      }}
                    >
                      <Edit
                        sx={{
                          fontSize: 20,
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
                <List>
                  {[
                    {
                      label: 'Alias',
                      icon: (
                        <AccountCircle
                          sx={{
                            fontSize: 24,
                          }}
                        />
                      ),
                      key: 'alias',
                    },
                    {
                      label: 'Biografía',
                      icon: (
                        <Description
                          sx={{
                            fontSize: 24,
                          }}
                        />
                      ),
                      key: 'bio',
                    },
                  ].map((item) => (
                    <ListItem
                      key={item.key}
                      sx={{
                        borderRadius: 2,
                        mb: 1,
                        backgroundColor: 'grey.600',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: 'white',
                          minWidth: '36px',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      {isEditing ? (
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          id={item.key}
                          name={item.key}
                          label={item.label}
                          value={userData[item.key] || ''}
                          onChange={handleChange}
                          sx={{
                            input: {
                              color: 'white',
                              height: '1.2em',
                            },
                            label: {
                              color: 'grey.500',
                            },
                            fieldset: {
                              borderColor: 'grey.700',
                            },
                          }}
                        />
                      ) : (
                        <ListItemText
                          primary={userData[item.key] || 'Sin información'}
                          secondary={item.label}
                          primaryTypographyProps={{
                            color: 'white',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            color: 'grey.500',
                            variant: 'caption',
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
                {isEditing && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        marginRight: 1,
                      }}
                      startIcon={<Save />}
                      onClick={handleSave}
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<Cancel />}
                      onClick={handleCancel}
                      sx={{
                        color: 'white',
                        borderColor: 'grey.700',
                      }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  minHeight: '300px',
                 
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Experiencia
                </Typography>
                <Experience userData={userData} /> {/* Asegúrate de pasar userData si es necesario */}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  minHeight: '300px',
                 
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Seguidores
                </Typography>
                <Followers userData={userData} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  minHeight: '300px',
                 
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Siguiendo
                </Typography>
                <Following userData={userData} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Snackbar para mensajes de éxito y error */}
        <Snackbar
          open={!!followError || !!successMessage}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          {followError ? (
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              {followError}
            </Alert>
          ) : (
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          )}
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default UserProfilePage;
